import React, { useContext, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { useHistory, Link } from 'react-router-dom';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import { ClientWithOnReconnected } from '../../utils/apollo';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import { SKIP_REASONS } from '../../utils/constants';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import '../../styles/page/ExpertActiveRequests.scss';
import {
  RequestActiveForExpertFulllQuery,
  RequestStartReadSessionMutation,
  RequestStartReadSessionMutationVariables,
  RequestSkipMutation,
  RequestSkipMutationVariables,
  ExpertDetailsQuery,
} from '../../gql/graphql';
import { RequestSummary } from '../../utils/gql';
import { prefabPriceStr, isLeadSuggested } from '../../utils/format';
import logo from '../../images/storetasker-logo-black.png';

const requestActiveForExpertQuery = gql`
  query RequestActiveForExpertFulll {
    requestActiveForExpert {
      ...RequestSummary
    }
  }
  ${RequestSummary}
`;

const requestStartReadSessionMutation = gql`
  mutation RequestStartReadSession($requestId: ID!, $isExtension: Boolean!) {
    requestStartReadSession(requestId: $requestId, isExtension: $isExtension) {
      ...RequestSummary
    }
  }
  ${RequestSummary}
`;

const requestSkipMutation = gql`
  mutation RequestSkip($requestId: ID!, $reason: String!, $moreDetail: String) {
    requestSkip(
      requestId: $requestId
      reason: $reason
      moreDetail: $moreDetail
    ) {
      ...RequestSummary
    }
  }
  ${RequestSummary}
`;

interface ExpertActiveRequestsProps {
  expertId: string;
  expertDetails?: ExpertDetailsQuery['expertDetails'];
  socketClient: ClientWithOnReconnected;
  blockClaims?: string;
}

const ExpertActiveRequests = ({
  expertId,
  expertDetails,
  socketClient,
  blockClaims,
}: ExpertActiveRequestsProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const [stateSkipId, setCurrSkipId] = useState('');
  const { data, error, loading, refetch } =
    useQuery<RequestActiveForExpertFulllQuery>(requestActiveForExpertQuery, {
      returnPartialData: true,
    });
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('ExpertActiveRequests socketClient onReconnected');
      refetch().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetch]);
  const activeLeadsForExpert = _.uniqBy(
    [...((data && data.requestActiveForExpert) || [])].filter(
      (r) =>
        !r.cancelledAt &&
        !r.inReview &&
        r.isActive &&
        (!expertDetails || !expertDetails.isNoKeyAccounts || !r.isKeyAccount) &&
        !r.expertsSkippedReason.find((skip) => skip.expertStr === expertId) &&
        !r.needsApproval.find((na) => na.expertStr === expertId) &&
        r.potentialMatchesStr.indexOf(expertId) >= 0 &&
        !blockClaims,
    ),
    'id',
  ).sort(
    (a, b) =>
      (isLeadSuggested(expertId, b, expertDetails)
        ? Number.POSITIVE_INFINITY
        : b.createdAt) -
        (isLeadSuggested(expertId, a, expertDetails)
          ? Number.POSITIVE_INFINITY
          : a.createdAt) || 0, // || 0 is to protect against NaN on infinity minus infinity
  );
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [activeLeadsForExpert]);
  const currSkipId =
    stateSkipId && activeLeadsForExpert.find((l) => l.id === stateSkipId)
      ? stateSkipId
      : '';
  const [trySkip] = useMutation<
    RequestSkipMutation,
    RequestSkipMutationVariables
  >(requestSkipMutation);
  function trySkipRequest(
    requestId: string,
    reason: string,
    moreDetail?: string,
  ) {
    console.log('trySkipRequest', requestId);
    const request = activeLeadsForExpert.find((r) => r.id === requestId);
    if (request) {
      trySkip({
        optimisticResponse: {
          requestSkip: {
            ...request,
            ...{
              expertsSkippedReason: request.expertsSkippedReason.concat({
                __typename: 'ExpertSkippedReason',
                expertStr: expertId,
              }),
              potentialMatchesStr: request.potentialMatchesStr.filter(
                (pm) => pm !== expertId,
              ),
            },
          },
        },
        variables: {
          moreDetail,
          reason,
          requestId,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Skip Error');
        logError(err, {
          component: 'ExpertActiveRequests',
          func: 'trySkipRequest',
        });
      });
    }
    setCurrSkipId('');
  }
  const [tryReadSession] = useMutation<
    RequestStartReadSessionMutation,
    RequestStartReadSessionMutationVariables
  >(requestStartReadSessionMutation);
  function tryStartReadSession(requestId: string) {
    console.log('tryStartReadSession', requestId);
    const request = activeLeadsForExpert.find((r) => r.id === requestId);
    if (request && !request.readSessionClaimedBy) {
      tryReadSession({
        optimisticResponse: {
          requestStartReadSession: {
            ...request,
            ...{
              readLog: request.readLog.concat({
                __typename: 'ReadLog',
                readByStr: expertId,
              }),
              readSessionClaimedBy: {
                __typename: 'Expert',
                firstName: (expertDetails && expertDetails.firstName) || '',
                id: expertId,
              },
              readSessionClaimedByStr: expertId,
            },
          },
        },
        variables: {
          isExtension: false,
          requestId,
        },
      }).catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Read Session Error',
        );
        logError(err, {
          component: 'ExpertActiveRequests',
          func: 'tryStartReadSession',
        });
      });
    }
    // redirect url
    history.push('/leads/' + requestId);
  }
  function blockClickPropagation(ev: React.MouseEvent) {
    if (ev) {
      // ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  return (
    <div className="DashboardScreen LeadsScreen">
      <Link className="DashboardScreenLogoLink" to="/home">
        <img
          className="DashboardScreenLogo"
          src={logo}
          alt="Storetasker Logo"
        />
      </Link>
      <div className="DashboardScreenContent LeadsScreenContent">
        {!loading && (!activeLeadsForExpert.length || !!blockClaims) ? (
          <div className="LeadsScreenEmpty">
            <div className="LeadsScreenEmptyTitle">
              We&apos;ll notify you when we have a relevant client opportunity
            </div>
            {error ? (
              <div className="LeadsScreenEmptyBody">
                {errorUtils.getErrorMessage(error) || 'Loading Error'}
              </div>
            ) : (
              <div className="LeadsScreenEmptyBody">
                {blockClaims ||
                  "Make sure your account and notifications are up to date. Anytime Storetasker finds a client opportunity where you'd be a relevant fit, we'll send you a notification."}
              </div>
            )}
          </div>
        ) : (
          <div className="LeadsScreenTiles">
            {activeLeadsForExpert.map((l) => (
              <div
                key={l.id}
                className={
                  'PotentialLeadRequest ' +
                  (l.readSessionClaimedBy
                    ? ' PotentialLeadRequestClaimed '
                    : '') +
                  (l.readSessionClaimedBy &&
                  l.readSessionClaimedBy.id === expertId
                    ? ' PotentialLeadRequestClaimedByYou '
                    : '') +
                  (currSkipId && currSkipId !== l.id
                    ? ' PotentialLeadRequestOtherSkip '
                    : '') +
                  (currSkipId && currSkipId === l.id
                    ? ' PotentialLeadRequestYourSkip '
                    : '')
                }
              >
                <div className="PotentialLeadRequestTop">
                  {currSkipId && currSkipId === l.id ? (
                    <div className="PotentialLeadRequestTopWhySkip">
                      Why do you want to skip?
                    </div>
                  ) : (
                    <div className="PotentialLeadRequestTopText">
                      {l.brand && l.brand.name ? l.brand.name : 'New Lead'}
                    </div>
                  )}
                </div>
                {isLeadSuggested(expertId, l, expertDetails) ? (
                  <div className="PotentialLeadRequestUnreadTag PotentialLeadRequestUnreadTagSuggested">
                    suggested for you
                  </div>
                ) : (
                  <Fragment>
                    {!l.readLog.find((rl) => rl.readByStr === expertId) && (
                      <div className="PotentialLeadRequestUnreadTag">new!</div>
                    )}
                  </Fragment>
                )}
                <div
                  className="PotentialLeadRequestBody"
                  onClick={() => tryStartReadSession(l.id)}
                >
                  {l.projectPrefab && l.projectPrefab.title && (
                    <div className="PotentialLeadRequestBodyTitle">
                      {l.projectPrefab.title}{' '}
                      {l.projectPrefab.priceType &&
                        l.projectPrefab.priceType !== 'NONE' &&
                        l.projectPrefab.cents && (
                          <span>
                            (
                            {prefabPriceStr(
                              l.projectPrefab.priceType,
                              l.projectPrefab.cents,
                            )}
                            )
                          </span>
                        )}
                    </div>
                  )}
                  <ReadOnlyEditor
                    className="PotentialLeadRequestBodyContent"
                    content={
                      l.projectPrefab
                        ? l.projectPrefab.description || ''
                        : l.content || ''
                    }
                  />
                  {!!l.isMultipleMatch && !l.isHqApprove && (
                    <div
                      className="PotentialLeadRequestBodyPro"
                      data-tip="This client has requested to meet up to 3 experts upfront. This lead does not count toward your close rate."
                    >
                      Storetasker Pro
                    </div>
                  )}
                  {!!l.isHqApprove && (
                    <div
                      className="PotentialLeadRequestBodyHqApprove"
                      data-tip="Storetasker HQ is personally helping this client with their search. This lead does not count toward your close rate."
                    >
                      HQ Assisted
                    </div>
                  )}
                  {!!l.isAgency &&
                    !l.isMultipleMatch &&
                    !l.isHqApprove &&
                    !l.isKeyAccount && (
                      <div
                        className="PotentialLeadRequestBodyIsAgency"
                        data-tip="This lead is from an Agency. Agency clients typically have ongoing work across their portfolio and are more likely to want a retainer."
                      >
                        Agency
                      </div>
                    )}
                  {!!l.isKeyAccount &&
                    !l.isAgency &&
                    !l.isMultipleMatch &&
                    !l.isHqApprove && (
                      <div
                        className="PotentialLeadRequestBodyVIP"
                        data-tip="Storetasker HQ has identified this lead as a VIP. Typically a well known, large, or high budget client. Take extra care!"
                      >
                        VIP
                      </div>
                    )}
                  {l.readSessionClaimedBy && (
                    <div className="PotentialLeadRequestBodyNotify">
                      <div className="PotentialLeadRequestBodyNotifyText">
                        {l.readSessionClaimedBy.id === expertId
                          ? 'You Are '
                          : (l.readSessionClaimedBy.firstName || 'Someone') +
                            ' Is '}
                        Viewing
                      </div>
                    </div>
                  )}
                  {currSkipId && currSkipId === l.id && (
                    <div
                      className="PotentialLeadRequestBodySkipFlow"
                      onClick={blockClickPropagation}
                    >
                      {SKIP_REASONS.map((s) => (
                        <div
                          key={s.value}
                          onClick={() => trySkipRequest(l.id, s.value)}
                          className="PotentialLeadRequestBodySkipOption"
                        >
                          {s.text}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="PotentialLeadRequestFooter">
                  {l.brand && (l.brand.url || l.brand.shopifyAdminURL) && (
                    <a
                      href={
                        'http://' +
                        (l.brand.url || l.brand.shopifyAdminURL || '')
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="PotentialLeadRequestFooterLink"
                    >
                      View Website
                    </a>
                  )}
                  {(!currSkipId || currSkipId === l.id) && (
                    <div
                      className="PotentialLeadRequestFooterSkip"
                      onClick={() => setCurrSkipId(currSkipId ? '' : l.id)}
                    >
                      {currSkipId ? 'Cancel' : 'Skip'}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

ExpertActiveRequests.propTypes = {
  blockClaims: PropTypes.string,
  expertDetails: PropTypes.object,
  expertId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

ExpertActiveRequests.defaultProps = {
  blockClaims: '',
  expertDetails: null,
};

export default ExpertActiveRequests;
