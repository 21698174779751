import React, { useState, Fragment, useContext, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useMutation, useQuery, ApolloError, gql } from '@apollo/client';
import { ContentState, convertToRaw } from 'draft-js';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { Link, useHistory } from 'react-router-dom';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import RichTextEditor from '../feature/RichTextEditor';
import MultiSelect from '../feature/MultiSelect';
import {
  prefabPriceStr,
  tagLabelExperience,
  tagLabelFocus,
  tagLabelSkill,
  tagLabelTimeline,
  tagLabelTool,
} from '../../utils/format';
import envUtils from '../../utils/env';
import { IFilestackFileUpload } from '../../utils/filestack';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import {
  SUPPORT_EXPERT_ID,
  CONTINENTS,
  EXPERIENCE_OPTIONS,
  FOCUS_OPTIONS,
  SKILL_OPTIONS,
  TOOL_OPTIONS,
  TIMELINE_OPTIONS,
} from '../../utils/constants';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { RequestDetailHq, ExpertSummary, MatchDetail } from '../../utils/gql';
import {
  RequestDetailsAdminQuery,
  RequestDetailsAdminQueryVariables,
  RequestEditByAdminMutation,
  RequestEditByAdminMutationVariables,
  RequestMatchByAdminMutation,
  RequestMatchByAdminMutationVariables,
  RequestSuggestExpertMutation,
  RequestSuggestExpertMutationVariables,
  RequestCancelCompletedByAdminMutation,
  RequestCancelCompletedByAdminMutationVariables,
  RequestReviewByAdminMutation,
  RequestReviewByAdminMutationVariables,
  RequestRestartMatchingByAdminMutation,
  RequestRestartMatchingByAdminMutationVariables,
  RequestManualIsMultipleByAdminMutation,
  RequestManualIsMultipleByAdminMutationVariables,
  RequestManualIsHqApproveByAdminMutation,
  RequestManualIsHqApproveByAdminMutationVariables,
  RequestStopSearchMultipleByAdminMutation,
  RequestStopSearchMultipleByAdminMutationVariables,
  RequestCancelByAdminMutation,
  RequestCancelByAdminMutationVariables,
  RequestUndoCancelByAdminMutation,
  RequestUndoCancelByAdminMutationVariables,
  RequestAssignAdminMutation,
  RequestAssignAdminMutationVariables,
  ExpertSearchForAdminMatchMutation,
  ExpertSearchForAdminMatchMutationVariables,
} from '../../gql/graphql';
const ReactFilestack = require('filestack-react').default; // eslint-disable-line @typescript-eslint/no-var-requires

const requestDetailsAdminQuery = gql`
  query RequestDetailsAdmin($requestId: ID!) {
    requestDetails(requestId: $requestId) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const requestEditByAdminMutation = gql`
  mutation RequestEditByAdmin(
    $requestId: ID!
    $description: String!
    $descriptionPlainText: String!
    $newFiles: [FileDetails!]!
    $existingFiles: [ID!]!
    $tagBudgetMin: String!
    $tagBudgetMax: String!
    $tagExperience: String!
    $tagFocus: [String!]!
    $tagLocations: [String!]!
    $tagSkills: [String!]!
    $tagTimeline: String!
    $tagTools: [String!]!
  ) {
    requestEditByAdmin(
      requestId: $requestId
      description: $description
      descriptionPlainText: $descriptionPlainText
      newFiles: $newFiles
      existingFiles: $existingFiles
      tagBudgetMin: $tagBudgetMin
      tagBudgetMax: $tagBudgetMax
      tagExperience: $tagExperience
      tagFocus: $tagFocus
      tagLocations: $tagLocations
      tagSkills: $tagSkills
      tagTimeline: $tagTimeline
      tagTools: $tagTools
    ) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const expertSearchForAdminMatchMutation = gql`
  mutation ExpertSearchForAdminMatch(
    $searchQuery: String!
    $onlyActives: Boolean
  ) {
    expertSearchForAdmin(searchQuery: $searchQuery, onlyActives: $onlyActives) {
      ...ExpertSummary
    }
  }
  ${ExpertSummary}
`;

const requestMatchByAdminMutation = gql`
  mutation RequestMatchByAdmin($requestId: ID!, $expertId: ID!) {
    requestMatchByAdmin(requestId: $requestId, expertId: $expertId) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const requestSuggestExpertMutation = gql`
  mutation RequestSuggestExpert(
    $requestId: ID!
    $expertId: ID!
    $isRemove: Boolean!
  ) {
    requestSuggestExpert(
      requestId: $requestId
      expertId: $expertId
      isRemove: $isRemove
    ) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const requestCancelCompletedByAdminMutation = gql`
  mutation RequestCancelCompletedByAdmin($requestId: ID!) {
    requestCancelCompletedByAdmin(requestId: $requestId) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const requestReviewByAdminMutation = gql`
  mutation RequestReviewByAdmin($requestId: ID!, $reason: String) {
    requestReviewByAdmin(requestId: $requestId, reason: $reason) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const requestRestartMatchingByAdminMutation = gql`
  mutation RequestRestartMatchingByAdmin(
    $requestId: ID!
    $sendAutoMessage: Boolean
  ) {
    requestRestartMatchingByAdmin(
      requestId: $requestId
      sendAutoMessage: $sendAutoMessage
    ) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const requestManualIsMultipleByAdminMutation = gql`
  mutation RequestManualIsMultipleByAdmin($requestId: ID!) {
    requestManualIsMultipleByAdmin(requestId: $requestId) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const requestManualIsHqApproveByAdminMutation = gql`
  mutation RequestManualIsHqApproveByAdmin($requestId: ID!) {
    requestManualIsHqApproveByAdmin(requestId: $requestId) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const requestStopSearchMultipleByAdminMutation = gql`
  mutation RequestStopSearchMultipleByAdmin($requestId: ID!, $reason: String!) {
    requestStopSearchMultipleByAdmin(requestId: $requestId, reason: $reason) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const requestCancelByAdminMutation = gql`
  mutation RequestCancelByAdmin(
    $requestId: ID!
    $reason: String!
    $moreDetail: String
  ) {
    requestCancelByAdmin(
      requestId: $requestId
      reason: $reason
      moreDetail: $moreDetail
    ) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const requestUndoCancelByAdminMutation = gql`
  mutation RequestUndoCancelByAdmin($requestId: ID!) {
    requestUndoCancelByAdmin(requestId: $requestId) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

const requestAssignAdminMutation = gql`
  mutation RequestAssignAdmin($requestId: ID!, $adminAssignment: String!) {
    requestAssignAdmin(
      requestId: $requestId
      adminAssignment: $adminAssignment
    ) {
      ...RequestDetailHq
    }
  }
  ${RequestDetailHq}
`;

interface AdminRequestDetailProps {
  requestId: string;
  socketClient: ClientWithOnReconnected;
}

const initTime = new Date().getTime();

const AdminRequestDetail = ({
  requestId,
  socketClient,
}: AdminRequestDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const [editLoading, setEditLoading] = useState(false);
  const [adminLoading, setAdminLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [actionMultipleLoading, setActionMultipleLoading] = useState(false);
  const [actionHqApproveLoading, setActionHqApproveLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tagTools, setTagTools] = useState([] as string[]);
  const [tagFocus, setTagFocus] = useState([] as string[]);
  const [tagLocations, setTagLocations] = useState([] as string[]);
  const [tagSkills, setTagSkills] = useState([] as string[]);
  const [tagExperience, setTagExperience] = useState('');
  const [tagTimeline, setTagTimeline] = useState('');
  const [tagBudgetMax, setTagBudgetMax] = useState('');
  const [tagBudgetMin, setTagBudgetMin] = useState('');
  const [requestFiles, setRequestFiles] = useState(
    [] as IFilestackFileUpload[],
  );
  const [editorLatest, setEditorLatest] = useState<ContentState | null>(null);
  const [matchSpecific, setMatchSpecific] = useState('');
  const [restartMatching, setRestartMatching] = useState(false);
  const [cancelRequest, setCancelRequest] = useState(false);
  const [undoCancelRequest, setUndoCancelRequest] = useState(false);
  const [adminAssignment] = useState('');
  const [stopSearchMultiple, setStopSearchMultiple] = useState(false);
  const [putInReview, setPutInReview] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState<
    ExpertSearchForAdminMatchMutation['expertSearchForAdmin'] | null
  >(null);
  const [searchInputSuggested, setSearchInputSuggested] = useState('');
  const [searchResultsSuggested, setSearchResultsSuggested] = useState<
    ExpertSearchForAdminMatchMutation['expertSearchForAdmin'] | null
  >(null);
  const [primaryPanel, setPrimaryPanel] = useState('Status');
  const {
    data: dataRequest,
    error: errorRequest,
    loading: loadingRequest,
    refetch: refetchRequest,
  } = useQuery<RequestDetailsAdminQuery, RequestDetailsAdminQueryVariables>(
    requestDetailsAdminQuery,
    {
      returnPartialData: true,
      variables: {
        requestId,
      },
    },
  );
  useEffect(() => {
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminRequestDetail socketClient onReconnected');
      refetchRequest().catch(() => {});
    });
    return () => {
      reconnectedListener();
    };
  }, [socketClient, refetchRequest]);
  let fullErrorCover = '';
  if (errorRequest) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorRequest) ||
      'Could not load request details';
  }
  const requestDetails =
    dataRequest && dataRequest.requestDetails && dataRequest.requestDetails.id
      ? dataRequest.requestDetails
      : undefined;
  let hasCurrentMatchOrMultiple3 = false;
  if (requestDetails) {
    if (requestDetails.isMultipleMatch && requestDetails.matchLog) {
      hasCurrentMatchOrMultiple3 = requestDetails.matchLog.length >= 3;
    } else if (requestDetails.currentMatchStr) {
      hasCurrentMatchOrMultiple3 = true;
    }
  }
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [primaryPanel, requestDetails]);
  function startEditing() {
    if (
      !requestDetails ||
      requestDetails.currentMatchStr ||
      !requestDetails.isActive
    ) {
      return;
    }
    setIsEditing(true);
    setTagExperience(requestDetails.tagExperience);
    setTagFocus(requestDetails.tagFocus);
    setTagLocations(requestDetails.tagLocations);
    setTagSkills(requestDetails.tagSkills);
    setTagTools(requestDetails.tagTools);
    setTagTimeline(requestDetails.tagTimeline);
    setTagBudgetMax(requestDetails.tagBudgetMax);
    setTagBudgetMin(requestDetails.tagBudgetMin);
    setRequestFiles(
      (requestDetails.files || []).map((f) => ({
        container: '',
        filename: f.filename,
        key: '',
        url: f.url,
      })),
    );
  }
  function fileError(error: Error) {
    console.log('Filestack fileError', error);
    addNotification(
      (error && error.message) || 'File upload error. Please try again.',
      undefined,
      5000,
    );
  }
  function fileUploaded(filestackRes: any) {
    console.log('Filestack fileUploaded', filestackRes);
    if (filestackRes && filestackRes.filesUploaded) {
      setRequestFiles(
        requestFiles.concat(
          filestackRes.filesUploaded.map((f: any) => ({
            container: f.container || '',
            filename: f.filename || '',
            handle: f.handle || '',
            key: f.key || '',
            mimetype: f.mimetype || '',
            size: (f.size || '').toString(),
            source: f.source || '',
            uploadId: f.uploadId || '',
            url: f.url || '',
          })) as IFilestackFileUpload[],
        ),
      );
    }
  }
  function removeFile(filestackUploadUrl: string) {
    console.log('Filestack removeFile', filestackUploadUrl);
    setRequestFiles(requestFiles.filter((f) => f.url !== filestackUploadUrl));
  }
  const [tryEditRequest] = useMutation<
    RequestEditByAdminMutation,
    RequestEditByAdminMutationVariables
  >(requestEditByAdminMutation);
  function onSaveEdits() {
    if (editLoading || !requestDetails) return;
    let draftContent = '';
    let plainTextContent = '';
    if (editorLatest && editorLatest.hasText()) {
      plainTextContent = editorLatest.getPlainText().trim();
      if (plainTextContent.replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(editorLatest));
        } catch (rawError) {
          console.log('email convertToRaw error', rawError);
        }
      }
    }
    if (requestDetails.currentMatchStr || !requestDetails.isActive) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    if (!draftContent) {
      addNotification('Please submit a description.', undefined, 5000);
      return;
    }
    const existingFiles = (requestDetails.files || [])
      .filter((f) => requestFiles.find((o) => o.url === f.url))
      .map((f) => f.id);
    const newFiles = requestFiles.filter(
      (f) => !(requestDetails.files || []).find((o) => o.url === f.url),
    );
    setEditLoading(true);
    tryEditRequest({
      variables: {
        description: draftContent,
        descriptionPlainText: plainTextContent,
        existingFiles,
        newFiles,
        requestId,
        tagBudgetMax,
        tagBudgetMin,
        tagExperience,
        tagFocus,
        tagLocations,
        tagSkills,
        tagTimeline,
        tagTools,
      },
    })
      .then(() => {
        setEditLoading(false);
        setIsEditing(false);
      })
      .catch((err: ApolloError) => {
        setEditLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Request Edit Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryEditRequest',
        });
      });
  }
  const [tryExpertSearch] = useMutation<
    ExpertSearchForAdminMatchMutation,
    ExpertSearchForAdminMatchMutationVariables
  >(expertSearchForAdminMatchMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput || searchInput.trim().length <= 2) {
      if (!searchInput.trim().length) {
        setSearchResults(null);
      } else {
        addNotification('At least 3 characters!', undefined, 5000);
      }
      return;
    }
    tryExpertSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        if (dataMatchSearch && dataMatchSearch.expertSearchForAdmin) {
          console.log('tryExpertSearch', dataMatchSearch.expertSearchForAdmin);
          setSearchResults(dataMatchSearch.expertSearchForAdmin);
        }
      },
      variables: {
        onlyActives: true,
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Expert Search Error');
      logError(err, {
        component: 'AdminRequestDetail',
        func: 'tryExpertSearch',
      });
      setSearchResults(null);
    });
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
  }
  function onSearchSuggestedStart() {
    console.log('onSearchSuggestedStart', searchInputSuggested);
    if (!searchInputSuggested || searchInputSuggested.trim().length <= 2) {
      if (!searchInputSuggested.trim().length) {
        setSearchResultsSuggested(null);
      } else {
        addNotification('At least 3 characters!', undefined, 5000);
      }
      return;
    }
    tryExpertSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        if (dataMatchSearch && dataMatchSearch.expertSearchForAdmin) {
          console.log('tryExpertSearch', dataMatchSearch.expertSearchForAdmin);
          setSearchResultsSuggested(dataMatchSearch.expertSearchForAdmin);
        }
      },
      variables: {
        onlyActives: true,
        searchQuery: searchInputSuggested,
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Expert Search Suggested Error',
      );
      logError(err, {
        component: 'AdminRequestDetail',
        func: 'tryExpertSearchSuggested',
      });
      setSearchResultsSuggested(null);
    });
  }
  function handleSearchInputSuggestedKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchSuggestedStart();
    }
  }
  const [tryRequestCancelCompleted] = useMutation<
    RequestCancelCompletedByAdminMutation,
    RequestCancelCompletedByAdminMutationVariables
  >(requestCancelCompletedByAdminMutation);
  function onRequestCancelCompleted() {
    if (completeLoading || !requestDetails) return;
    if (
      requestDetails.currentMatchStr ||
      requestDetails.isActive ||
      requestDetails.cancelledCompleted
    ) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    setCompleteLoading(true);
    tryRequestCancelCompleted({
      variables: {
        requestId,
      },
    })
      .then(() => {
        setCompleteLoading(false);
      })
      .catch((err: ApolloError) => {
        setCompleteLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Cancel Completed Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryRequestCancelCompleted',
        });
      });
  }
  function confirmStopSearchMultiple() {
    setStopSearchMultiple(true);
  }
  function cancelStopSearchMultiple() {
    setStopSearchMultiple(false);
  }
  const [tryStopSearchMultiple] = useMutation<
    RequestStopSearchMultipleByAdminMutation,
    RequestStopSearchMultipleByAdminMutationVariables
  >(requestStopSearchMultipleByAdminMutation);
  function onStopSearchMultiple(reason: string) {
    if (modalLoading || !requestDetails) return;
    if (requestDetails.inReview || !requestDetails.isActive) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
    }
    setModalLoading(true);
    tryStopSearchMultiple({
      variables: {
        reason,
        requestId,
      },
    })
      .then(() => {
        setModalLoading(false);
        setStopSearchMultiple(false);
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Stop Search Multiple Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryStopSearchMultiple',
        });
      });
  }
  function confirmPutInReview() {
    setPutInReview(true);
  }
  function cancelPutInReview() {
    setPutInReview(false);
  }
  const [tryRequestReview] = useMutation<
    RequestReviewByAdminMutation,
    RequestReviewByAdminMutationVariables
  >(requestReviewByAdminMutation);
  function onRequestReview() {
    if (modalLoading || !requestDetails) return;
    if (requestDetails.inReview || !requestDetails.isActive) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
    }
    setModalLoading(true);
    tryRequestReview({
      variables: {
        reason: '',
        requestId,
      },
    })
      .then(() => {
        setModalLoading(false);
        setPutInReview(false);
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Put In Review Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryRequestReview',
        });
      });
  }
  function confirmRestartMatching() {
    setRestartMatching(true);
  }
  function cancelRestartMatching() {
    setRestartMatching(false);
  }
  const [tryRequestRestartMatching] = useMutation<
    RequestRestartMatchingByAdminMutation,
    RequestRestartMatchingByAdminMutationVariables
  >(requestRestartMatchingByAdminMutation);
  function onRequestRestartMatching(sendAutoMessage?: boolean) {
    if (modalLoading || !requestDetails) return;
    if (!requestDetails.inReview || !requestDetails.isActive) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
    }
    setModalLoading(true);
    tryRequestRestartMatching({
      variables: {
        requestId,
        sendAutoMessage,
      },
    })
      .then(() => {
        setModalLoading(false);
        setRestartMatching(false);
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Restart Matching Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryRequestRestartMatching',
        });
      });
  }
  const [tryAssignAdmin] = useMutation<
    RequestAssignAdminMutation,
    RequestAssignAdminMutationVariables
  >(requestAssignAdminMutation);
  function onAssignAdmin(theAssignment: string) {
    if (adminLoading) return;
    setAdminLoading(true);
    tryAssignAdmin({
      variables: {
        adminAssignment: theAssignment,
        requestId,
      },
    })
      .then(() => {
        setAdminLoading(false);
      })
      .catch((err: ApolloError) => {
        setAdminLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Assign Admin Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryAssignAdmin',
        });
      });
  }
  function confirmCancelRequest() {
    setCancelRequest(true);
  }
  function cancelCancelRequest() {
    setCancelRequest(false);
  }
  const [tryRequestManualIsHqApprove] = useMutation<
    RequestManualIsHqApproveByAdminMutation,
    RequestManualIsHqApproveByAdminMutationVariables
  >(requestManualIsHqApproveByAdminMutation);
  function onManualIsHqApprove() {
    if (actionHqApproveLoading) return;
    setActionHqApproveLoading(true);
    tryRequestManualIsHqApprove({
      variables: {
        requestId,
      },
    })
      .then(() => {
        setActionHqApproveLoading(false);
      })
      .catch((err: ApolloError) => {
        setActionHqApproveLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Manual HQ Approve Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryRequestManualIsHqApprove',
        });
      });
  }
  const [tryRequestManualIsMultiple] = useMutation<
    RequestManualIsMultipleByAdminMutation,
    RequestManualIsMultipleByAdminMutationVariables
  >(requestManualIsMultipleByAdminMutation);
  function onManualIsMultiple() {
    if (actionMultipleLoading) return;
    setActionMultipleLoading(true);
    tryRequestManualIsMultiple({
      variables: {
        requestId,
      },
    })
      .then(() => {
        setActionMultipleLoading(false);
      })
      .catch((err: ApolloError) => {
        setActionMultipleLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Manual Multiple Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryRequestManualIsMultiple',
        });
      });
  }

  const [tryRequestCancel] = useMutation<
    RequestCancelByAdminMutation,
    RequestCancelByAdminMutationVariables
  >(requestCancelByAdminMutation);
  function onRequestCancel(reason: string, moreDetail?: string) {
    if (modalLoading || !requestDetails) return;
    if (
      !requestDetails.inReview ||
      !requestDetails.isActive ||
      (requestDetails.isMultipleMatch && requestDetails.currentMatchStr)
    ) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
    }
    setModalLoading(true);
    tryRequestCancel({
      variables: {
        moreDetail,
        reason,
        requestId,
      },
    })
      .then(() => {
        setModalLoading(false);
        setCancelRequest(false);
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Request Cancel Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryRequestCancel',
        });
      });
  }
  function confirmUndoCancelRequest() {
    setUndoCancelRequest(true);
  }
  function cancelUndoCancelRequest() {
    setUndoCancelRequest(false);
  }
  const [tryRequestUndoCancel] = useMutation<
    RequestUndoCancelByAdminMutation,
    RequestUndoCancelByAdminMutationVariables
  >(requestUndoCancelByAdminMutation);
  function onRequestUndoCancel() {
    if (modalLoading || !requestDetails) return;
    if (
      requestDetails.inReview ||
      requestDetails.isActive ||
      !requestDetails.cancelledAt
    ) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
    }
    setModalLoading(true);
    tryRequestUndoCancel({
      variables: {
        requestId,
      },
    })
      .then(() => {
        setModalLoading(false);
        setUndoCancelRequest(false);
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Request Undo Cancel Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryRequestUndoCancel',
        });
      });
  }
  const [trySuggestExpert] = useMutation<
    RequestSuggestExpertMutation,
    RequestSuggestExpertMutationVariables
  >(requestSuggestExpertMutation);
  function confirmSuggested(
    expertId: string,
    expertFirst?: string,
    expertLast?: string,
  ) {
    console.log('confirmSuggested', requestId);
    if (!requestDetails) return;
    if (
      hasCurrentMatchOrMultiple3 ||
      !requestDetails.isActive ||
      requestDetails.inReview
    ) {
      addNotification(
        'Request is wrong status for suggesting experts, refresh and try again.',
        undefined,
        5000,
      );
    }
    trySuggestExpert({
      optimisticResponse: {
        requestSuggestExpert: {
          ...requestDetails,
          expertsSkippedReason: (
            (requestDetails && requestDetails.expertsSkippedReason) ||
            []
          ).filter((sk) => sk.expertStr !== expertId),
          potentialMatchesStr: (
            (requestDetails && requestDetails.potentialMatchesStr) ||
            []
          )
            .filter((pm) => pm !== expertId)
            .concat(expertId),
          suggestedMatches: (
            (requestDetails && requestDetails.suggestedMatches) ||
            []
          )
            .filter((pm) => pm.id !== expertId)
            .concat([
              {
                __typename: 'Expert',
                firstName: expertFirst || '',
                id: expertId,
                lastName: expertLast || '',
              },
            ]),
          suggestedMatchesStr: (
            (requestDetails && requestDetails.suggestedMatchesStr) ||
            []
          )
            .filter((pm) => pm !== expertId)
            .concat(expertId),
        },
      },
      variables: {
        expertId,
        isRemove: false,
        requestId,
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Suggest Expert Error',
      );
      logError(err, {
        component: 'AdminRequestDetail',
        func: 'trySuggestExpert',
      });
    });
    setSearchInputSuggested('');
    setSearchResultsSuggested(null);
  }
  function removeSuggested(expertId: string) {
    console.log('removeSuggested', requestId);
    if (!requestDetails) return;
    if (
      hasCurrentMatchOrMultiple3 ||
      !requestDetails.isActive ||
      requestDetails.inReview
    ) {
      addNotification(
        'Request is wrong status for removing suggesting experts, refresh and try again.',
        undefined,
        5000,
      );
    }
    trySuggestExpert({
      optimisticResponse: {
        requestSuggestExpert: {
          ...requestDetails,
          suggestedMatches: (
            (requestDetails && requestDetails.suggestedMatches) ||
            []
          ).filter((pm) => pm.id !== expertId),
          suggestedMatchesStr: (
            (requestDetails && requestDetails.suggestedMatchesStr) ||
            []
          ).filter((pm) => pm !== expertId),
        },
      },
      variables: {
        expertId,
        isRemove: true,
        requestId,
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Remove Suggest Expert Error',
      );
      logError(err, {
        component: 'AdminRequestDetail',
        func: 'trySuggestExpert remove',
      });
    });
    setSearchInputSuggested('');
    setSearchResultsSuggested(null);
  }
  function confirmMatchSpecific(expertId: string) {
    setMatchSpecific(expertId);
  }
  function cancelMatchSpecific() {
    setMatchSpecific('');
  }
  const [tryMatchSpecific] = useMutation<
    RequestMatchByAdminMutation,
    RequestMatchByAdminMutationVariables
  >(requestMatchByAdminMutation);
  function onMatchSpecific() {
    if (modalLoading || !requestDetails) return;
    if (hasCurrentMatchOrMultiple3 || !requestDetails.isActive) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
    }
    setModalLoading(true);
    tryMatchSpecific({
      variables: {
        expertId: matchSpecific,
        requestId,
      },
    })
      .then(({ data: mutationData }) => {
        setModalLoading(false);
        setMatchSpecific('');
        if (mutationData && mutationData.requestMatchByAdmin) {
          history.push('/match/' + mutationData.requestMatchByAdmin.id);
        }
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Match Specifc Error',
        );
        logError(err, {
          component: 'AdminRequestDetail',
          func: 'tryMatchSpecific',
        });
      });
  }
  let statusStr = '?';
  let createDateFull = '';
  let createDateSummary = '';
  let cancelDateFull = '';
  let cancelDateSummary = '';
  if (requestDetails) {
    if (requestDetails.isMultipleMatch && requestDetails.isActive) {
      statusStr = 'Waiting for multiple match';
    } else if (requestDetails.currentMatchStr && !requestDetails.inReview) {
      statusStr = 'Matched';
    } else if (!requestDetails.isActive) {
      statusStr = 'Canceled';
    } else if (requestDetails.inReview) {
      statusStr = 'Needs Review';
    } else {
      statusStr = 'Waiting for single match';
    }
    const nowMoment = moment.tz(initTime, moment.tz.guess());
    if (requestDetails.createdAt) {
      const createMoment = moment.tz(
        requestDetails.createdAt,
        moment.tz.guess(),
      );
      createDateFull =
        createMoment.format('MMMM Do, YYYY') +
        ' at ' +
        createMoment.format('h:mma z');
      createDateSummary = createDateFull;
      if (nowMoment.isSame(createMoment, 'day')) {
        createDateSummary = 'Today at ' + createMoment.format('h:mma z');
      } else if (
        nowMoment.clone().subtract(1, 'day').isSame(createMoment, 'day')
      ) {
        createDateSummary = 'Yesterday at ' + createMoment.format('h:mma z');
      } else if (nowMoment.isSame(createMoment, 'week')) {
        createDateSummary =
          createMoment.format('dddd') + ' at ' + createMoment.format('h:mma z');
      } else if (nowMoment.isSame(createMoment, 'year')) {
        createDateSummary =
          createMoment.format('MMMM Do') +
          ' at ' +
          createMoment.format('h:mma z');
      }
    }
    if (requestDetails.cancelledAt) {
      const cancelMoment = moment.tz(
        requestDetails.cancelledAt,
        moment.tz.guess(),
      );
      cancelDateFull =
        cancelMoment.format('MMMM Do, YYYY') +
        ' at ' +
        cancelMoment.format('h:mma z');
      cancelDateSummary = cancelDateFull;
      if (nowMoment.isSame(cancelMoment, 'day')) {
        cancelDateSummary = 'Today at ' + cancelMoment.format('h:mma z');
      } else if (
        nowMoment.clone().subtract(1, 'day').isSame(cancelMoment, 'day')
      ) {
        cancelDateSummary = 'Yesterday at ' + cancelMoment.format('h:mma z');
      } else if (nowMoment.isSame(cancelMoment, 'week')) {
        cancelDateSummary =
          cancelMoment.format('dddd') + ' at ' + cancelMoment.format('h:mma z');
      } else if (nowMoment.isSame(cancelMoment, 'year')) {
        cancelDateSummary =
          cancelMoment.format('MMMM Do') +
          ' at ' +
          cancelMoment.format('h:mma z');
      }
    }
  }
  function blockClickPropagation(ev: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  const secondaryPanels = ['Status', 'Activity'].filter(
    (p) => p !== primaryPanel,
  );
  const allMatches = ((requestDetails && requestDetails.matchLog) || []).filter(
    (ml) => ml.matchedWho,
  );
  if (errorRequest || loadingRequest) {
    // ignore these
  }
  return (
    <div className="DashboardModal AdminRequestDetailModal">
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '') +
          (matchSpecific ||
          restartMatching ||
          cancelRequest ||
          undoCancelRequest ||
          putInReview ||
          stopSearchMultiple
            ? ' ThreadDetailViewWithModal '
            : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/requests')
              }
              className="ThreadDetailMainHeaderBack"
            >
              {requestDetails && requestDetails.brand
                ? requestDetails.brand.name
                : 'Back'}
            </div>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody AdminRequestDetailView">
            <div className="AdminLinkedRecords">
              {requestDetails && requestDetails.brand && (
                <Link
                  to={'/brands/' + requestDetails.brand.id}
                  className="AdminLinkedRecord"
                >
                  <div className="AdminLinkedRecordTitle">
                    {requestDetails.brand.name}
                  </div>
                  <div className="AdminLinkedRecordSubtitle">
                    {requestDetails.brand.url}
                  </div>
                  <div className="AdminLinkedRecordTag">Brand</div>
                </Link>
              )}
              {requestDetails && requestDetails.createdBy && (
                <Link
                  to={'/humans/' + requestDetails.createdBy.id}
                  className="AdminLinkedRecord"
                >
                  <div className="AdminLinkedRecordTitle">
                    {requestDetails.createdBy.proStart ? '(Pro) ' : ''}
                    {(requestDetails.createdBy.firstName || '') +
                      ' ' +
                      (requestDetails.createdBy.lastName || '')}
                  </div>
                  <div className="AdminLinkedRecordSubtitle">
                    {requestDetails.createdBy.primaryEmail ||
                      requestDetails.createdBy.primaryPhone ||
                      ''}
                  </div>
                  <div className="AdminLinkedRecordTag">Human</div>
                </Link>
              )}
              {requestDetails && requestDetails.projectPrefab && (
                <Link
                  to={'/extras/prefabs/' + requestDetails.projectPrefab.id}
                  className="AdminLinkedRecord"
                >
                  <div className="AdminLinkedRecordTitle">
                    {requestDetails.projectPrefab.title || ''}
                  </div>
                  <div className="AdminLinkedRecordSubtitle">
                    {requestDetails.projectPrefab.id}
                  </div>
                  <div className="AdminLinkedRecordTag">Prefab</div>
                </Link>
              )}
            </div>
            {requestDetails && (
              <Fragment>
                <div className="RequestDetailViewCard">
                  <div className="RequestDetailViewCardTop">
                    <div className="RequestDetailViewCardTopText">
                      Request Details
                    </div>
                  </div>
                  <div className="RequestDetailViewCardBody">
                    {requestDetails.projectPrefab &&
                      requestDetails.projectPrefab.title && (
                        <Fragment>
                          <div className="RequestDetailViewCardBodyField">
                            Prefab Title
                          </div>
                          <div className="RequestDetailViewCardBodyTitle">
                            {requestDetails.projectPrefab.title}{' '}
                            {requestDetails.projectPrefab.priceType &&
                              requestDetails.projectPrefab.priceType !==
                                'NONE' &&
                              requestDetails.projectPrefab.cents && (
                                <span>
                                  (
                                  {prefabPriceStr(
                                    requestDetails.projectPrefab.priceType,
                                    requestDetails.projectPrefab.cents,
                                  )}
                                  )
                                </span>
                              )}
                          </div>
                          {requestDetails.projectPrefab.estimatedCompletion && (
                            <div className="RequestDetailViewCardBodySubtitle">
                              Estimated completion:{' '}
                              {requestDetails.projectPrefab.estimatedCompletion}{' '}
                              day
                              {requestDetails.projectPrefab
                                .estimatedCompletion > 1
                                ? 's'
                                : ''}
                            </div>
                          )}
                        </Fragment>
                      )}
                    {requestDetails.projectPrefab && (
                      <Fragment>
                        <div className="RequestDetailViewCardBodyField">
                          Prefab Description
                        </div>
                        <ReadOnlyEditor
                          className="RequestDetailViewCardBodyContent"
                          content={
                            requestDetails.projectPrefab.description || ''
                          }
                        />
                      </Fragment>
                    )}
                    {requestDetails.projectPrefab &&
                      requestDetails.projectPrefab.internalNote && (
                        <Fragment>
                          <div className="RequestDetailViewCardBodyField">
                            Internal Note (only shown to experts)
                          </div>
                          <ReadOnlyEditor
                            className="RequestDetailViewCardBodyContent"
                            content={
                              requestDetails.projectPrefab.internalNote || ''
                            }
                          />
                        </Fragment>
                      )}
                    {requestDetails.projectPrefab && requestDetails.content && (
                      <Fragment>
                        <div className="RequestDetailViewCardBodyField">
                          Extra Details
                        </div>
                        <ReadOnlyEditor
                          className="RequestDetailViewCardBodyContent"
                          content={requestDetails.content || ''}
                        />
                      </Fragment>
                    )}
                    {!requestDetails.projectPrefab &&
                      requestDetails.content && (
                        <Fragment>
                          <div className="RequestDetailViewCardBodyField">
                            Description
                          </div>
                          {isEditing ? (
                            <div className="RequestDetailViewCardBodyContent">
                              <RichTextEditor
                                initContent={requestDetails.content}
                                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                                onUpdated={setEditorLatest}
                              />
                            </div>
                          ) : (
                            <ReadOnlyEditor
                              className="RequestDetailViewCardBodyContent"
                              content={requestDetails.content}
                            />
                          )}
                        </Fragment>
                      )}
                    <div className="RequestDetailViewCardBodyTags">
                      <div className="RequestDetailViewCardBodyField">
                        Experience
                      </div>
                      {isEditing ? (
                        <select
                          className="RequestDetailViewCardBodyFieldSelect"
                          required
                          value={tagExperience}
                          onChange={(e) =>
                            setTagExperience(e.currentTarget.value)
                          }
                        >
                          <option value="">Everyone</option>
                          {EXPERIENCE_OPTIONS.map((o) => (
                            <option key={o.value} value={o.value}>
                              {o.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <div className="RequestDetailViewCardBodyFieldValue">
                          {tagLabelExperience(
                            requestDetails.tagExperience || '',
                          ) || 'Everyone'}
                        </div>
                      )}
                      <div className="RequestDetailViewCardBodyField">
                        Focus
                      </div>
                      {isEditing ? (
                        <MultiSelect
                          options={FOCUS_OPTIONS}
                          currValues={tagFocus}
                          onChange={setTagFocus}
                          withTags
                        />
                      ) : (
                        <div className="RequestDetailViewCardBodyFieldValue">
                          {(requestDetails.tagFocus || [])
                            .map(tagLabelFocus)
                            .join(', ')}
                        </div>
                      )}
                      <div className="RequestDetailViewCardBodyField">
                        Locations
                      </div>
                      {isEditing ? (
                        <MultiSelect
                          options={CONTINENTS.map((v) => ({
                            label: v,
                            value: v,
                          }))}
                          currValues={tagLocations}
                          onChange={setTagLocations}
                          withTags
                        />
                      ) : (
                        <div className="RequestDetailViewCardBodyFieldValue">
                          {(requestDetails.tagLocations || []).join(', ') ||
                            'Anywhere'}
                        </div>
                      )}
                      <div className="RequestDetailViewCardBodyField">
                        Skills
                      </div>
                      {isEditing ? (
                        <MultiSelect
                          options={SKILL_OPTIONS}
                          currValues={tagSkills}
                          onChange={setTagSkills}
                          withTags
                        />
                      ) : (
                        <div className="RequestDetailViewCardBodyFieldValue">
                          {(requestDetails.tagSkills || [])
                            .map(tagLabelSkill)
                            .join(', ')}
                        </div>
                      )}
                      <div className="RequestDetailViewCardBodyField">
                        Tools
                      </div>
                      {isEditing ? (
                        <MultiSelect
                          options={TOOL_OPTIONS}
                          currValues={tagTools}
                          onChange={setTagTools}
                          withTags
                        />
                      ) : (
                        <div className="RequestDetailViewCardBodyFieldValue">
                          {(requestDetails.tagTools || [])
                            .map(tagLabelTool)
                            .join(', ')}
                        </div>
                      )}
                      <div className="RequestDetailViewCardBodyField">
                        Timeline
                      </div>
                      {isEditing ? (
                        <select
                          className="RequestDetailViewCardBodyFieldSelect"
                          required
                          value={tagTimeline}
                          onChange={(e) =>
                            setTagTimeline(e.currentTarget.value)
                          }
                        >
                          <option value="">None</option>
                          {TIMELINE_OPTIONS.map((o) => (
                            <option key={o.value} value={o.value}>
                              {o.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <div className="RequestDetailViewCardBodyFieldValue">
                          {tagLabelTimeline(requestDetails.tagTimeline || '')}
                        </div>
                      )}
                      <div className="RequestDetailViewCardBodyField">
                        Budget
                      </div>
                      {isEditing ? (
                        <div>
                          <div>
                            <input
                              className="ThreadDetailSidebarAdminFormInput"
                              type="text"
                              autoComplete="new-off"
                              spellCheck="false"
                              placeholder="$ min"
                              value={tagBudgetMin}
                              onChange={(e) =>
                                setTagBudgetMin(e.currentTarget.value)
                              }
                            />
                          </div>
                          <div>
                            <input
                              className="ThreadDetailSidebarAdminFormInput"
                              type="text"
                              autoComplete="new-off"
                              spellCheck="false"
                              placeholder="$ max"
                              value={tagBudgetMax}
                              onChange={(e) =>
                                setTagBudgetMax(e.currentTarget.value)
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="RequestDetailViewCardBodyFieldValue">
                          Min:{' '}
                          {requestDetails.tagBudgetMin
                            ? '$' + requestDetails.tagBudgetMin
                            : 'None'}
                          <br />
                          Max:{' '}
                          {requestDetails.tagBudgetMax
                            ? '$' + requestDetails.tagBudgetMax
                            : 'None'}
                        </div>
                      )}
                    </div>
                    <div className="RequestDetailViewCardBodyFiles">
                      <div className="RequestDetailViewCardBodyField">
                        Files{' '}
                        {!requestDetails.files || !requestDetails.files.length
                          ? '(none)'
                          : ''}
                      </div>
                      {!isEditing &&
                        !!requestDetails.files &&
                        requestDetails.files.map((f) => (
                          <a
                            key={f.id}
                            className="RequestDetailViewCardBodyFile"
                            href={f.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {f.filename}
                          </a>
                        ))}
                      {!!isEditing && (
                        <Fragment>
                          {requestFiles.map((f) => (
                            <div
                              key={f.url}
                              className="ThreadEditorFile"
                              onClick={() => removeFile(f.url || '')}
                            >
                              <span className="ThreadEditorFileText">
                                {f.filename}
                              </span>
                              <div className="ThreadEditorFileRemove" />
                            </div>
                          ))}
                          <ReactFilestack
                            apikey="AGxdviGoVRwWPL6lKEdnXz"
                            actionOptions={{
                              maxFiles: 20,
                              storeTo: {
                                container: envUtils.pick(
                                  'files.asklorem.com',
                                  'files-dev.asklorem.com',
                                  'files-dev.asklorem.com',
                                ),
                                location: 's3',
                                path: `${SUPPORT_EXPERT_ID}/`,
                                region: 'us-east-1',
                              },
                            }}
                            onSuccess={fileUploaded}
                            onError={fileError}
                            customRender={({ onPick }: { onPick: any }) => (
                              <div
                                onMouseDown={onPick}
                                className={
                                  'ThreadEditorUpload ' +
                                  (requestFiles.length
                                    ? ' ThreadEditorUploadHasFiles '
                                    : '')
                                }
                              >
                                {requestFiles.length ? '+' : 'Attach Files'}
                              </div>
                            )}
                          />
                        </Fragment>
                      )}
                    </div>
                  </div>
                  {!requestDetails.projectPrefab &&
                    !requestDetails.currentMatchStr &&
                    !!requestDetails.isActive && (
                      <div className="AdminActionBtns">
                        {isEditing ? (
                          <Fragment>
                            <div
                              className={
                                'AdminActionBtn ' +
                                (editLoading ? ' AdminActionBtnLoading ' : '')
                              }
                              onClick={onSaveEdits}
                            >
                              Save Changes
                            </div>
                            <div
                              className="AdminActionBtn AdminActionBtnDanger"
                              onClick={() => setIsEditing(false)}
                            >
                              Cancel Changes
                            </div>
                          </Fragment>
                        ) : (
                          <div
                            className="AdminActionBtn"
                            onClick={startEditing}
                          >
                            Edit
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </Fragment>
            )}
          </div>
          {!!matchSpecific && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelMatchSpecific}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Confirm Match
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        The customer will get a notification that they&apos;ve
                        been matched.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={onMatchSpecific}
                          >
                            Yes, match this request
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelMatchSpecific}
                          >
                            No, never mind, do nothing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelMatchSpecific}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!restartMatching && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelRestartMatching}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Restart Matching?
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        Unless they were previously matched to someone else, no
                        notifications will be sent.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          {allMatches.length > 1 &&
                            !!requestDetails &&
                            !requestDetails.isMultipleMatch && (
                              <div
                                className="ThreadDetailMainFocusModalChoicesOption"
                                onClick={() => onRequestRestartMatching(true)}
                              >
                                Yes, restart matching and send automated
                                messages
                              </div>
                            )}
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() => onRequestRestartMatching(false)}
                          >
                            {!!requestDetails &&
                            !!(
                              allMatches.length > 1 ||
                              requestDetails.isMultipleMatch
                            )
                              ? "Yes, restart matching, but don't send automated messages"
                              : 'Yes, restart matching (fyi customer will not get automated messages)'}
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelRestartMatching}
                          >
                            No, never mind, do nothing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelRestartMatching}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!cancelRequest && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelCancelRequest}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Cancel Request?
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        No notifications will sent. Up to you to follow up.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Why are we canceling?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onRequestCancel('MATCH_UNSUCCESSFUL')
                            }
                          >
                            Client wants to cancel after unsuccessful match
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onRequestCancel('FOUND_SOMEONE_ELSE')
                            }
                          >
                            Client found someone else to do it
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onRequestCancel('FIGURED_IT_OUT_MYSELF')
                            }
                          >
                            Client figured it out on their own
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onRequestCancel('NO_LONGER_PURSUING_THIS_PROJECT')
                            }
                          >
                            Client is no longer pursuing the project
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onRequestCancel('CLIENT_UNRESPONSIVE')
                            }
                          >
                            Client is unresponsive
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onRequestCancel('CLIENT_HAS_NO_IDEA')
                            }
                          >
                            Client has no idea what they want
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() => onRequestCancel('SPAM_JUNK')}
                          >
                            Spam / Junk
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() => onRequestCancel('NO_MATCH_FOUND')}
                          >
                            No match found (we need to follow up)
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() => onRequestCancel('CONNECT_WITH_HQ')}
                          >
                            Client wants to connect with HQ
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() => onRequestCancel('OTHER')}
                          >
                            Other
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelCancelRequest}
                          >
                            Never mind, do not cancel!
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelCancelRequest}
                    >
                      never mind
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!stopSearchMultiple && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelStopSearchMultiple}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Stop Searching For More Experts?
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        No notifications will sent. Up to you to follow up.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Why are we stopping further search?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onStopSearchMultiple('MATCH_SUCCESSFUL')
                            }
                          >
                            Client found a successful match!
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onStopSearchMultiple('FOUND_SOMEONE_ELSE')
                            }
                          >
                            Client found someone else to do it
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onStopSearchMultiple('FIGURED_IT_OUT_MYSELF')
                            }
                          >
                            Client figured it out on their own
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onStopSearchMultiple('MATCHES_UNSUCCESSFUL')
                            }
                          >
                            Client is not happy with matches so far
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onStopSearchMultiple(
                                'NO_LONGER_PURSUING_THIS_PROJECT',
                              )
                            }
                          >
                            Client is no longer pursuing the project
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onStopSearchMultiple('CLIENT_UNRESPONSIVE')
                            }
                          >
                            Client is unresponsive
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onStopSearchMultiple('CLIENT_HAS_NO_IDEA')
                            }
                          >
                            Client has no idea what they want
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() => onStopSearchMultiple('SPAM_JUNK')}
                          >
                            Spam / Junk
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() =>
                              onStopSearchMultiple('CONNECT_WITH_HQ')
                            }
                          >
                            Client wants to connect with HQ
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() => onStopSearchMultiple('OTHER')}
                          >
                            Other
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelStopSearchMultiple}
                          >
                            Never mind, do not cancel!
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelStopSearchMultiple}
                    >
                      never mind
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!undoCancelRequest && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelUndoCancelRequest}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Undo Cancelled?
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        No notifications will sent. This will move the request
                        into review.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={onRequestUndoCancel}
                          >
                            Yes, undo cancel and put this request in review
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelUndoCancelRequest}
                          >
                            No, never mind, do nothing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelUndoCancelRequest}
                    >
                      never mind
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!putInReview && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelPutInReview}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        {requestDetails &&
                        requestDetails.currentMatchStr &&
                        !requestDetails.isMultipleMatch
                          ? 'Unmatch + '
                          : 'Pause Matching + '}{' '}
                        Put In Review?
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        Notifications will not be sent to anyone. This will
                        un-match people and cancel quotes if they exist.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() => onRequestReview()}
                          >
                            Yes, put this request in review
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelPutInReview}
                          >
                            No, never mind, do nothing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelPutInReview}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div className="ThreadDetailSidebar">
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => setPrimaryPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
          </div>
          <div className="ThreadDetailSidebarBody">
            <div className="ThreadDetailSidebarBodyPanels">
              {primaryPanel === 'Status' && requestDetails && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelStatus">
                  <div className="ThreadDetailSidebarAdminSection">
                    <div className="ThreadDetailSidebarAdminSectionBig">
                      {statusStr}
                    </div>
                    {requestDetails.cancelledHow && (
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Canceled by: {requestDetails.cancelledHow}
                      </div>
                    )}
                    {requestDetails.cancelledReason && (
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Cancel reason: {requestDetails.cancelledReason}
                      </div>
                    )}
                    {requestDetails.cancelledMoreDetail && (
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        More detail: {requestDetails.cancelledMoreDetail}
                      </div>
                    )}
                    {requestDetails.inReview && (
                      <div className="ThreadDetailSidebarAdminSectionDescription">
                        Review reason: {requestDetails.inReview}
                      </div>
                    )}
                    <div
                      className="ThreadDetailSidebarAdminSectionDescription"
                      data-tip={createDateFull}
                    >
                      Submitted: {createDateSummary}
                    </div>
                    {cancelDateSummary && (
                      <div
                        className="ThreadDetailSidebarAdminSectionDescription"
                        data-tip={cancelDateFull}
                      >
                        Cancelled: {cancelDateSummary}
                      </div>
                    )}
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Wait: {requestDetails.waitType}
                    </div>
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Contact Preference: {requestDetails.contactPreference}
                    </div>
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Is Multiple Match:{' '}
                      {requestDetails.isMultipleMatch ? 'True' : 'False'}
                    </div>
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Is Agency: {requestDetails.isAgency || 'False'}
                    </div>
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Is Ongoing: {requestDetails.isOngoing ? 'True' : 'False'}
                    </div>
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Is Agency / Studio Search:{' '}
                      {requestDetails.isSearchAgency ? 'True' : 'False'}
                    </div>
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Is HQ Approve:{' '}
                      {requestDetails.isHqApprove ? 'True' : 'False'}
                    </div>
                    {!requestDetails.isActive &&
                      !requestDetails.currentMatchStr &&
                      !!requestDetails.cancelledCompleted && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Canceled review: completed
                        </div>
                      )}
                    {requestDetails.isActive && requestDetails.inReview && (
                      <Fragment>
                        <div
                          className="ThreadDetailSidebarAdminAction"
                          onClick={confirmRestartMatching}
                        >
                          restart matching
                        </div>
                        {!!(
                          !requestDetails.isMultipleMatch ||
                          !requestDetails.currentMatchStr
                        ) && (
                          <div
                            className="ThreadDetailSidebarAdminAction ThreadDetailSidebarAdminActionBAD"
                            onClick={confirmCancelRequest}
                          >
                            cancel request
                          </div>
                        )}
                      </Fragment>
                    )}
                    {!requestDetails.isActive &&
                      !requestDetails.inReview &&
                      !!requestDetails.cancelledAt && (
                        <div
                          className="ThreadDetailSidebarAdminAction ThreadDetailSidebarAdminActionBAD"
                          onClick={confirmUndoCancelRequest}
                        >
                          undo cancel
                        </div>
                      )}
                    {requestDetails.isActive && !requestDetails.inReview && (
                      <div
                        className="ThreadDetailSidebarAdminAction ThreadDetailSidebarAdminActionBAD"
                        onClick={confirmPutInReview}
                      >
                        {requestDetails.currentMatchStr &&
                        !requestDetails.isMultipleMatch
                          ? 'unmatch + '
                          : 'pause matching + '}
                        put in review
                      </div>
                    )}
                    {!!requestDetails.isMultipleMatch &&
                      !!requestDetails.currentMatchStr &&
                      !!requestDetails.isActive && (
                        <div
                          className="ThreadDetailSidebarAdminAction ThreadDetailSidebarAdminActionBAD"
                          onClick={confirmStopSearchMultiple}
                        >
                          stop search for more experts
                        </div>
                      )}
                    {!requestDetails.isActive &&
                      !requestDetails.currentMatchStr &&
                      !requestDetails.cancelledCompleted && (
                        <div
                          className={
                            'ThreadDetailSidebarAdminAction ' +
                            (completeLoading
                              ? ' ThreadDetailSidebarAdminActionLoading '
                              : '')
                          }
                          onClick={onRequestCancelCompleted}
                        >
                          complete canceled review
                        </div>
                      )}
                  </div>
                  {!requestDetails.isMultipleMatch &&
                    !requestDetails.projectPrefab &&
                    !requestDetails.currentMatchStr &&
                    !!requestDetails.isActive && (
                      <div className="ThreadDetailSidebarAdminSection">
                        <div
                          className={
                            'ThreadDetailSidebarAdminAction ' +
                            (actionMultipleLoading
                              ? ' ThreadDetailSidebarAdminActionLoading '
                              : '')
                          }
                          onClick={onManualIsMultiple}
                        >
                          turn on multiple match
                        </div>
                      </div>
                    )}
                  {!requestDetails.isHqApprove &&
                    !requestDetails.projectPrefab &&
                    !requestDetails.currentMatchStr &&
                    !!requestDetails.isActive && (
                      <div className="ThreadDetailSidebarAdminSection">
                        <div
                          className={
                            'ThreadDetailSidebarAdminAction ' +
                            (actionHqApproveLoading
                              ? ' ThreadDetailSidebarAdminActionLoading '
                              : '')
                          }
                          onClick={onManualIsHqApprove}
                        >
                          turn on hq approve
                        </div>
                      </div>
                    )}
                  {!!allMatches.length && (
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Matched With
                      </div>
                      <div className="ThreadDetailSidebarAdminSectionRows">
                        {allMatches.map((ml) => (
                          <Link
                            key={ml.matchStr}
                            className="ThreadDetailSidebarAdminSectionRow"
                            to={'/match/' + ml.matchStr}
                          >
                            <div className="ThreadDetailSidebarAdminSectionRowText">
                              {ml.matchedWho.fullName || ''}
                            </div>
                            {!!ml.unmatchedHow && (
                              <div className="ThreadDetailSidebarAdminSectionRowReason">
                                Who did unclaim?: {ml.unmatchedHow}
                              </div>
                            )}
                            {!!ml.unmatchedReason && (
                              <div className="ThreadDetailSidebarAdminSectionRowReason">
                                Reason: {ml.unmatchedReason}
                              </div>
                            )}
                            {!!ml.unmatchedMoreDetail && (
                              <div className="ThreadDetailSidebarAdminSectionRowReason ThreadDetailSidebarAdminSectionRowReasonOverflow">
                                More detail: {ml.unmatchedMoreDetail}
                              </div>
                            )}
                            <div className="ThreadDetailSidebarAdminSectionRowTag">
                              Match
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="ThreadDetailSidebarAdminSection ThreadDetailSidebarAdminSectionMinHeight">
                    {adminLoading ? (
                      <div className="ThreadDetailSidebarAdminSectionLoading" />
                    ) : (
                      <Fragment>
                        {!!requestDetails.adminAssigned && (
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Assigned to: {requestDetails.adminAssigned}
                          </div>
                        )}
                        {requestDetails.adminAssigned && (
                          <div
                            className="ThreadDetailSidebarAdminAction"
                            onClick={() => onAssignAdmin('')}
                          >
                            Remove Assignment: {requestDetails.adminAssigned}
                          </div>
                        )}
                        <select
                          className="ThreadDetailSidebarAdminActionSelect"
                          value={adminAssignment}
                          onChange={(e) => onAssignAdmin(e.currentTarget.value)}
                        >
                          <option value="" disabled>
                            Assign team member
                          </option>
                          <option value="Richard">Richard</option>
                          <option value="Tim">Tim</option>
                          <option value="Gayle">Gayle</option>
                          <option value="Charlie">Charlie</option>
                        </select>
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
              {primaryPanel === 'Activity' && requestDetails && (
                <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelActivity">
                  <div className="ThreadDetailSidebarAdminSection">
                    <div className="ThreadDetailSidebarAdminSectionBig">
                      {statusStr}
                    </div>
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Wait: {requestDetails.waitType}
                    </div>
                    {!hasCurrentMatchOrMultiple3 &&
                      !requestDetails.inReview &&
                      requestDetails.isActive && (
                        <div className="ThreadDetailSidebarAdminSectionDescription">
                          Potential Matches:{' '}
                          {requestDetails.potentialMatchesStr
                            ? requestDetails.potentialMatchesStr.length
                            : '?'}
                        </div>
                      )}
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Reads:{' '}
                      {requestDetails.readLog
                        ? requestDetails.readLog.length
                        : '?'}
                    </div>
                    <div className="ThreadDetailSidebarAdminSectionDescription">
                      Skips:{' '}
                      {requestDetails.expertsSkippedReason
                        ? requestDetails.expertsSkippedReason.length
                        : '?'}
                    </div>
                  </div>
                  {requestDetails.suggestedMatches &&
                    requestDetails.potentialMatchesStr &&
                    requestDetails.isActive &&
                    !requestDetails.inReview &&
                    !hasCurrentMatchOrMultiple3 && (
                      <div className="ThreadDetailSidebarAdminSection">
                        <div className="ThreadDetailSidebarAdminSectionTitle">
                          Manually Suggested Experts
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionRows">
                          {requestDetails.suggestedMatches
                            .filter((suggest) =>
                              requestDetails.potentialMatchesStr.find(
                                (eStr) => eStr === suggest.id,
                              ),
                            )
                            .map((suggest) => (
                              <div
                                key={suggest.id}
                                className="ThreadDetailSidebarAdminSectionRow ThreadDetailSidebarAdminSectionRowRemove"
                                onClick={() => removeSuggested(suggest.id)}
                              >
                                <div className="ThreadDetailSidebarAdminSectionRowText">
                                  {suggest.firstName || suggest.lastName
                                    ? (suggest.firstName || '') +
                                      ' ' +
                                      (suggest.lastName || '')
                                    : suggest.id}
                                </div>
                                <div className="ThreadDetailSidebarAdminSectionRowReason">
                                  Click to remove
                                </div>
                                <div className="ThreadDetailSidebarAdminSectionRowRemovable">
                                  x
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="ThreadDetailSidebarAdminSearch">
                          <div className="ThreadDetailSidebarAdminSearchBar">
                            <input
                              type="text"
                              className="ThreadDetailSidebarAdminSearchBarInput"
                              autoComplete="new-off"
                              spellCheck="false"
                              placeholder="Search"
                              value={searchInputSuggested}
                              onChange={(e) =>
                                setSearchInputSuggested(e.target.value)
                              }
                              onKeyDown={handleSearchInputSuggestedKeyDown}
                            />
                          </div>
                          <div className="ThreadDetailSidebarAdminSearchResults">
                            {searchResultsSuggested &&
                              !searchResultsSuggested.length && (
                                <div className="ThreadDetailSidebarAdminEmpty">
                                  No experts found. Try another search query to
                                  find who you&apos;re looking for.
                                </div>
                              )}
                            {searchResultsSuggested &&
                              searchResultsSuggested.map((expertResult) => (
                                <div
                                  key={expertResult.id}
                                  className="ThreadDetailSidebarAdminSearchResult"
                                  onClick={() =>
                                    confirmSuggested(
                                      expertResult.id,
                                      expertResult.firstName,
                                      expertResult.lastName,
                                    )
                                  }
                                >
                                  <div className="ThreadDetailSidebarAdminSearchResultTitle">
                                    {(
                                      (expertResult.firstName || '') +
                                      ' ' +
                                      (expertResult.lastName || '')
                                    ).trim()}
                                  </div>
                                  <div className="ThreadDetailSidebarAdminSearchResultSubtitle">
                                    {expertResult.primaryPublicEmail || ''}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    )}
                  {requestDetails.needsApproval &&
                    requestDetails.isActive &&
                    !requestDetails.inReview &&
                    !hasCurrentMatchOrMultiple3 && (
                      <div className="ThreadDetailSidebarAdminSection">
                        <div className="ThreadDetailSidebarAdminSectionTitle">
                          Need HQ Approval
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionRows">
                          {requestDetails.needsApproval
                            .filter((na) => na.expert)
                            .map((na) => (
                              <div
                                key={na.expert.id}
                                className="ThreadDetailSidebarAdminSectionRow ThreadDetailSidebarAdminSectionRowRemove"
                                onClick={() =>
                                  confirmMatchSpecific(na.expert.id)
                                }
                              >
                                <div className="ThreadDetailSidebarAdminSectionRowText">
                                  {na.expert.firstName || na.expert.lastName
                                    ? (na.expert.firstName || '') +
                                      ' ' +
                                      (na.expert.lastName || '')
                                    : na.expert.id}
                                </div>
                                <div className="ThreadDetailSidebarAdminSectionRowReason">
                                  Click to approve
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  {!hasCurrentMatchOrMultiple3 && !!requestDetails.isActive && (
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Match With A Specifc Expert?
                      </div>
                      <div className="ThreadDetailSidebarAdminSearch">
                        <div className="ThreadDetailSidebarAdminSearchBar">
                          <input
                            type="text"
                            className="ThreadDetailSidebarAdminSearchBarInput"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Search"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            onKeyDown={handleSearchInputKeyDown}
                          />
                        </div>
                        <div className="ThreadDetailSidebarAdminSearchResults">
                          {searchResults && !searchResults.length && (
                            <div className="ThreadDetailSidebarAdminEmpty">
                              No experts found. Try another search query to find
                              who you&apos;re looking for.
                            </div>
                          )}
                          {searchResults &&
                            searchResults.map((expertResult) => (
                              <div
                                key={expertResult.id}
                                className="ThreadDetailSidebarAdminSearchResult"
                                onClick={() =>
                                  confirmMatchSpecific(expertResult.id)
                                }
                              >
                                <div className="ThreadDetailSidebarAdminSearchResultTitle">
                                  {(
                                    (expertResult.firstName || '') +
                                    ' ' +
                                    (expertResult.lastName || '')
                                  ).trim()}
                                </div>
                                <div className="ThreadDetailSidebarAdminSearchResultSubtitle">
                                  {expertResult.primaryPublicEmail || ''}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {requestDetails.readLog &&
                    requestDetails.expertsSkippedReason && (
                      <div className="ThreadDetailSidebarAdminSection">
                        <div className="ThreadDetailSidebarAdminSectionTitle">
                          Read/Skip Log
                        </div>
                        <div className="ThreadDetailSidebarAdminSectionRows">
                          {requestDetails.expertsSkippedReason.map((sk) => (
                            <Link
                              key={sk.expertStr}
                              className="ThreadDetailSidebarAdminSectionRow"
                              to={'/experts/' + sk.expertStr}
                            >
                              <div className="ThreadDetailSidebarAdminSectionRowText">
                                {sk.expert
                                  ? (sk.expert.firstName || '') +
                                    ' ' +
                                    (sk.expert.lastName || '')
                                  : sk.expertStr}
                              </div>
                              <div className="ThreadDetailSidebarAdminSectionRowReason">
                                Skipped: {sk.reason}
                              </div>
                              <div className="ThreadDetailSidebarAdminSectionRowTag">
                                Expert
                              </div>
                            </Link>
                          ))}
                          {requestDetails.readLog
                            .filter(
                              (rl) =>
                                !requestDetails.expertsSkippedReason.find(
                                  (sk) => sk.expertStr === rl.readByStr,
                                ),
                            )
                            .map((rl) => (
                              <Link
                                key={rl.readByStr}
                                className="ThreadDetailSidebarAdminSectionRow"
                                to={'/experts/' + rl.readByStr}
                              >
                                <div className="ThreadDetailSidebarAdminSectionRowText">
                                  {rl.readBy
                                    ? (rl.readBy.firstName || '') +
                                      ' ' +
                                      (rl.readBy.lastName || '')
                                    : rl.readByStr}
                                </div>
                                <div className="ThreadDetailSidebarAdminSectionRowReason">
                                  Read
                                </div>
                                <div className="ThreadDetailSidebarAdminSectionRowTag">
                                  Expert
                                </div>
                              </Link>
                            ))}
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/requests')
              }
              className="DashboardErrorCoverNav"
            >
              back
            </div>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminRequestDetail.propTypes = {
  requestId: PropTypes.string.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default AdminRequestDetail;
