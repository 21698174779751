/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useRef,
} from 'react';
import copyToClipboard from 'clipboard-copy';
import { SingleDatePicker } from 'react-dates';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import {
  useMutation,
  useQuery,
  useLazyQuery,
  ApolloError,
  gql,
} from '@apollo/client';
import { ClientWithOnReconnected } from '../../utils/apollo';
import { Link, useHistory } from 'react-router-dom';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import alertUtils from '../../utils/alert';
import templateUtils from '../../utils/template';
import {
  centsDollarsRounded,
  formatNumberWithCommas,
  replyToSubject,
  quoteStatusFormatted,
  meetingResponseFormatted,
  searchResultType,
  unreadReasonFormatted,
  formatValidPhoneNumber,
  getNextPaymentDate,
  kpiNumber,
  kpiDollars,
  validClientFee,
  validDiscountCode,
  pitchDiscountCode,
  amountDiscountCode,
  expertTaggedName,
} from '../../utils/format';
import tokenUtils from '../../utils/token';
import envUtils from '../../utils/env';
import {
  QuoteStatus,
  ExpertDetailsQuery,
  TemplatesQuery,
  MatchDetailsQuery,
  MatchDetailsQueryVariables,
  MeetingActiveForMatchQuery,
  MeetingActiveForMatchQueryVariables,
  MeetingAllForMatchQuery,
  MeetingAllForMatchQueryVariables,
  FileActiveForMatchQuery,
  FileActiveForMatchQueryVariables,
  ProjectPastForMatchQuery,
  ProjectPastForMatchQueryVariables,
  MeetingActiveForExpertCacheOnlyQuery,
  UserNoteClientQuery,
  UserNoteClientQueryVariables,
  UserNoteEditClientMutation,
  UserNoteEditClientMutationVariables,
  UserNoteUpdatedClientSubscription,
  UserNoteUpdatedClientSubscriptionVariables,
  MatchThreadPaginatedQuery,
  MatchThreadPaginatedQueryVariables,
  MatchThreadSearchMutation,
  MatchThreadSearchMutationVariables,
  MatchThreadEventUpdatedSubscription,
  MatchThreadEventUpdatedSubscriptionVariables,
  MatchFileUploadUpdatedSubscription,
  MatchFileUploadUpdatedSubscriptionVariables,
  MatchMarkAsReadUnreadMutation,
  MatchMarkAsReadUnreadMutationVariables,
  MatchExpertPinnedMutation,
  MatchExpertPinnedMutationVariables,
  MatchExpertTaggedMutation,
  MatchExpertTaggedMutationVariables,
  MatchAnotherExpertMutation,
  MatchAnotherExpertMutationVariables,
  MatchSnoozeExpertMutation,
  MatchSnoozeExpertMutationVariables,
  MatchSetDangerZoneMutation,
  MatchSetDangerZoneMutationVariables,
  ProjectCancelMutation,
  ProjectCancelMutationVariables,
  ProjectUndoMarkCompleteMutation,
  ProjectUndoMarkCompleteMutationVariables,
  FileArchiveMutation,
  FileArchiveMutationVariables,
  MatchThreadSendTextMessageMutation,
  MatchThreadSendTextMessageMutationVariables,
  MatchThreadSendEmailMessageMutation,
  MatchThreadSendEmailMessageMutationVariables,
  MatchThreadOutboundPhoneCallMutation,
  MatchThreadOutboundPhoneCallMutationVariables,
  MeetingInviteResponseMatchMutation,
  MeetingInviteResponseMatchMutationVariables,
  MeetingInviteCreateMatchMutation,
  MeetingInviteCreateMatchMutationVariables,
  MeetingInviteUpdateMatchMutation,
  MeetingInviteUpdateMatchMutationVariables,
  MeetingInviteCancelMatchMutation,
  MeetingInviteCancelMatchMutationVariables,
  BrandAddHumanMutation,
  BrandAddHumanMutationVariables,
  BrandCreateHumanMutation,
  BrandCreateHumanMutationVariables,
  HumanEditByExpertMutation,
  HumanEditByExpertMutationVariables,
  BrandIgnoreHumanMutation,
  BrandIgnoreHumanMutationVariables,
  BrandMergeHumanMutation,
  BrandMergeHumanMutationVariables,
  ProjectPaginateBrandDetailQuery,
  ProjectPaginateBrandDetailQueryVariables,
  OrphanUnthreadEmailFromMatchMutation,
  OrphanUnthreadEmailFromMatchMutationVariables,
  OrphanUnthreadTextFromMatchMutation,
  OrphanUnthreadTextFromMatchMutationVariables,
  OrphanUnthreadPhoneCallFromMatchMutation,
  OrphanUnthreadPhoneCallFromMatchMutationVariables,
  OrphanUnthreadMeetingFromMatchMutation,
  OrphanUnthreadMeetingFromMatchMutationVariables,
  MatchArchiveOldLeadMutation,
  MatchArchiveOldLeadMutationVariables,
  RequestUnmatchByExpertMutation,
  RequestUnmatchByExpertMutationVariables,
  BrandEditByExpertMutation,
  BrandEditByExpertMutationVariables,
  ExpertShareClientMutation,
  ExpertShareClientMutationVariables,
  ExpertSearchForExpertMutation,
  ExpertSearchForExpertMutationVariables,
} from '../../gql/graphql';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { IFilestackFileUpload } from '../../utils/filestack';
import UserNoteEditor from '../feature/UserNoteEditor';
import RequestThreadEvent from '../threadEvent/RequestThreadEvent';
import EmailMessageThreadEvent from '../threadEvent/EmailMessageThreadEvent';
import TextMessageThreadEvent from '../threadEvent/TextMessageThreadEvent';
import QuoteThreadEvent from '../threadEvent/QuoteThreadEvent';
import MessageThreadEvent from '../threadEvent/MessageThreadEvent';
import PhoneCallThreadEvent from '../threadEvent/PhoneCallThreadEvent';
import MeetingThreadEvent from '../threadEvent/MeetingThreadEvent';
import UnknownHuman from '../threadEvent/UnknownHuman';
import EmailEditor from '../feature/EmailEditor';
import MeetingEditor from '../feature/MeetingEditor';
import QuoteEditor from '../feature/QuoteEditor';
import TextEditor from '../feature/TextEditor';
import ReadOnlyEditor from '../feature/ReadOnlyEditor';
import MessageHqEditor from '../feature/MessageHqEditor';
import PhoneCallEditor from '../feature/PhoneCallEditor';
import LocationTimestamp from '../feature/LocationTimestamp';
import ThreadSearchResults from '../feature/ThreadSearchResults';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import HumanUserBubble from '../feature/HumanUserBubble';
import UnclaimActions from '../feature/UnclaimActions';
import ProjectCancelActions from '../feature/ProjectCancelActions';
import BrandSidebarEditor from '../feature/BrandSidebarEditor';
import HumanBrandSidebarEditor from '../feature/HumanBrandSidebarEditor';
import {
  MatchDetail,
  TextMessageEventDetail,
  EmailMessageEventDetail,
  MessageEventDetail,
  MeetingEventDetail,
  MeetingDetail,
  QuoteDetail,
  PhoneCallEventDetail,
  RequestEventDetail,
  FileUploadDetail,
  BrandDetail,
  UserNoteDetail,
  HumanSummary,
  ProjectDetail,
  ProjectDetailHq,
  ExpertSummary,
} from '../../utils/gql';
import TextareaAutosize from 'react-autosize-textarea';

const matchDetailsQuery = gql`
  query MatchDetails($matchId: ID!) {
    matchDetails(matchId: $matchId) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const meetingActiveForMatchQuery = gql`
  query MeetingActiveForMatch($matchId: ID!) {
    meetingActiveForMatch(matchId: $matchId) {
      ...MeetingDetail
    }
  }
  ${MeetingDetail}
`;

const meetingAllForMatchQuery = gql`
  query MeetingAllForMatch($matchId: ID!) {
    meetingAllForMatch(matchId: $matchId) {
      ...MeetingDetail
    }
  }
  ${MeetingDetail}
`;

const fileActiveForMatchQuery = gql`
  query FileActiveForMatch($matchId: ID) {
    fileActiveForThread(matchId: $matchId) {
      ...FileUploadDetail
    }
  }
  ${FileUploadDetail}
`;

const meetingActiveForExpertCacheOnlyQuery = gql`
  query MeetingActiveForExpertCacheOnly {
    meetingActiveForExpert {
      ...MeetingDetail
    }
  }
  ${MeetingDetail}
`;

const projectPastForMatchQuery = gql`
  query ProjectPastForMatch($matchId: ID!) {
    projectPastForMatch(matchId: $matchId) {
      ...ProjectDetail
    }
  }
  ${ProjectDetail}
`;

// @connection(key: "thread", filter: ["matchId"])
const matchThreadPaginatedQuery = gql`
  query MatchThreadPaginated(
    $matchId: ID
    $direction: String!
    $fromDate: Date
    $limit: Int!
  ) {
    threadPaginated(
      matchId: $matchId
      direction: $direction
      fromDate: $fromDate
      limit: $limit
    ) {
      id
      edges {
        id
        cursor
        node {
          ... on TextMessageEvent {
            ...TextMessageEventDetail
          }
          ... on EmailMessageEvent {
            ...EmailMessageEventDetail
          }
          ... on RequestEvent {
            ...RequestEventDetail
          }
          ... on MessageEvent {
            ...MessageEventDetail
          }
          ... on MeetingEvent {
            ...MeetingEventDetail
          }
          ... on Quote {
            ...QuoteDetail
          }
          ... on PhoneCallEvent {
            ...PhoneCallEventDetail
          }
        }
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const matchThreadSearchMutation = gql`
  mutation MatchThreadSearch(
    $searchQuery: String!
    $matchId: ID
    $limit: Int!
  ) {
    threadSearch(searchQuery: $searchQuery, matchId: $matchId, limit: $limit) {
      id
      highlightsContent
      highlightsSubject
      node {
        ... on TextMessageEvent {
          ...TextMessageEventDetail
        }
        ... on EmailMessageEvent {
          ...EmailMessageEventDetail
        }
        ... on RequestEvent {
          ...RequestEventDetail
        }
        ... on MessageEvent {
          ...MessageEventDetail
        }
        ... on MeetingEvent {
          ...MeetingEventDetail
        }
        ... on Quote {
          ...QuoteDetail
        }
        ... on PhoneCallEvent {
          ...PhoneCallEventDetail
        }
      }
      plainTextContent
      plainTextSubject
      score
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const matchMarkAsReadUnreadMutation = gql`
  mutation MatchMarkAsReadUnread($matchId: ID!, $unreadReason: String) {
    matchMarkAsReadUnread(matchId: $matchId, unreadReason: $unreadReason) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;
const matchExpertPinnedMutation = gql`
  mutation MatchExpertPinned($matchId: ID!, $doPin: Boolean!) {
    matchExpertPinned(matchId: $matchId, doPin: $doPin) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;
const matchExpertTaggedMutation = gql`
  mutation MatchExpertTagged($matchId: ID!, $tagged: String!) {
    matchExpertTagged(matchId: $matchId, tagged: $tagged) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const matchAnotherExpertMutation = gql`
  mutation MatchAnotherExpert(
    $matchId: ID!
    $reason: String!
    $moreDetail: String!
  ) {
    matchAnotherExpert(
      matchId: $matchId
      reason: $reason
      moreDetail: $moreDetail
    )
  }
`;

const matchSetDangerZoneMutation = gql`
  mutation MatchSetDangerZone(
    $matchId: ID!
    $putInDangerZone: Boolean!
    $reason: String!
    $dangerZoneAdditionalDescription: String
  ) {
    matchSetDangerZone(
      matchId: $matchId
      putInDangerZone: $putInDangerZone
      reason: $reason
      dangerZoneAdditionalDescription: $dangerZoneAdditionalDescription
    ) {
      id
    }
  }
`;

const matchThreadSendTextMessageMutation = gql`
  mutation MatchThreadSendTextMessage($input: ThreadSendTextMessageInput!) {
    threadSendTextMessage(input: $input) {
      ...TextMessageEventDetail
    }
  }
  ${TextMessageEventDetail}
`;

const matchThreadSendEmailMessageMutation = gql`
  mutation MatchThreadSendEmailMessage($input: ThreadSendEmailMessageInput!) {
    threadSendEmailMessage(input: $input) {
      ...EmailMessageEventDetail
    }
  }
  ${EmailMessageEventDetail}
`;

const matchThreadOutboundPhoneCallMutation = gql`
  mutation MatchThreadOutboundPhoneCall($recipientHuman: ID!, $matchId: ID) {
    threadOutboundPhoneCall(
      recipientHuman: $recipientHuman
      matchId: $matchId
    ) {
      ...PhoneCallEventDetail
    }
  }
  ${PhoneCallEventDetail}
`;

const matchArchiveOldLeadMutation = gql`
  mutation MatchArchiveOldLead(
    $matchId: ID!
    $reason: String
    $moreDetail: String
  ) {
    matchArchiveOldLead(
      matchId: $matchId
      reason: $reason
      moreDetail: $moreDetail
    ) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const matchSnoozeExpertMutation = gql`
  mutation MatchSnoozeExpert($matchId: ID!, $untilDate: Date!) {
    matchSnoozeExpert(matchId: $matchId, untilDate: $untilDate) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const requestUnmatchByExpertMutation = gql`
  mutation RequestUnmatchByExpert(
    $requestId: ID!
    $reason: String!
    $isCancel: Boolean
    $moreDetail: String
  ) {
    requestUnmatchByExpert(
      requestId: $requestId
      reason: $reason
      isCancel: $isCancel
      moreDetail: $moreDetail
    ) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const meetingInviteResponseMatchMutation = gql`
  mutation MeetingInviteResponseMatch($meetingId: ID!, $response: String!) {
    meetingInviteResponse(meetingId: $meetingId, response: $response) {
      ...MeetingEventDetail
    }
  }
  ${MeetingEventDetail}
`;

const meetingInviteCreateMatchMutation = gql`
  mutation MeetingInviteCreateMatch(
    $matchId: ID
    $title: String!
    $location: String
    $description: String
    $recipients: [ID!]!
    $timeStart: Date!
    $timeEnd: Date!
    $timezone: String!
  ) {
    meetingInviteCreate(
      matchId: $matchId
      title: $title
      location: $location
      description: $description
      recipients: $recipients
      timeStart: $timeStart
      timeEnd: $timeEnd
      timezone: $timezone
    ) {
      ...MeetingEventDetail
    }
  }
  ${MeetingEventDetail}
`;

const meetingInviteUpdateMatchMutation = gql`
  mutation MeetingInviteUpdateMatch(
    $meetingId: ID!
    $title: String!
    $location: String
    $description: String
    $recipients: [ID!]!
    $timeStart: Date!
    $timeEnd: Date!
    $timezone: String!
  ) {
    meetingInviteUpdate(
      meetingId: $meetingId
      title: $title
      location: $location
      description: $description
      recipients: $recipients
      timeStart: $timeStart
      timeEnd: $timeEnd
      timezone: $timezone
    ) {
      ...MeetingEventDetail
    }
  }
  ${MeetingEventDetail}
`;

const meetingInviteCancelMatchMutation = gql`
  mutation MeetingInviteCancelMatch($meetingId: ID!) {
    meetingInviteCancel(meetingId: $meetingId) {
      ...MeetingEventDetail
    }
  }
  ${MeetingEventDetail}
`;

const projectCancelMutation = gql`
  mutation ProjectCancel($quoteId: ID!, $matchId: ID!, $cancelReason: String) {
    projectCancel(
      quoteId: $quoteId
      matchId: $matchId
      cancelReason: $cancelReason
    ) {
      ...ProjectDetail
    }
  }
  ${ProjectDetail}
`;

const projectUndoMarkCompleteMutation = gql`
  mutation ProjectUndoMarkComplete($quoteId: ID!, $matchId: ID!) {
    projectUndoMarkComplete(quoteId: $quoteId, matchId: $matchId) {
      ...ProjectDetail
    }
  }
  ${ProjectDetail}
`;

const fileArchiveMutation = gql`
  mutation FileArchive($fileUploadId: ID!, $matchId: ID!) {
    fileArchive(fileUploadId: $fileUploadId, matchId: $matchId) {
      ...FileUploadDetail
    }
  }
  ${FileUploadDetail}
`;

const brandEditByExpertMutation = gql`
  mutation BrandEditByExpert(
    $brandId: ID!
    $name: String!
    $description: String
    $url: String!
    $shopifyAdminURL: String
  ) {
    brandEditByExpert(
      brandId: $brandId
      name: $name
      description: $description
      url: $url
      shopifyAdminURL: $shopifyAdminURL
    ) {
      ...BrandDetail
    }
  }
  ${BrandDetail}
`;

const brandAddHumanMutation = gql`
  mutation BrandAddHuman($brandId: ID!, $humanId: ID!) {
    brandAddHuman(brandId: $brandId, humanId: $humanId) {
      ...BrandDetail
    }
  }
  ${BrandDetail}
`;

const projectPaginateBrandDetailQuery = gql`
  query ProjectPaginateBrandDetail(
    $brandId: ID!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    projectPaginateBrand(
      brandId: $brandId
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...ProjectDetailHq
    }
  }
  ${ProjectDetailHq}
`;

const brandCreateHumanMutation = gql`
  mutation BrandCreateHuman(
    $brandId: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
  ) {
    brandCreateHuman(
      brandId: $brandId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
    ) {
      brand {
        ...BrandDetail
      }
      contactError {
        human
        name
      }
    }
  }
  ${BrandDetail}
`;

const humanEditByExpertMutation = gql`
  mutation HumanEditByExpert(
    $humanId: ID!
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $secondaryEmails: [String!]!
  ) {
    humanEditByExpert(
      humanId: $humanId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      secondaryEmails: $secondaryEmails
    ) {
      contactError {
        human
        name
      }
      human {
        ...HumanSummary
      }
    }
  }
  ${HumanSummary}
`;

const brandIgnoreHumanMutation = gql`
  mutation BrandIgnoreHuman($brandId: ID!, $humanId: ID!, $isUndo: Boolean) {
    brandIgnoreHuman(brandId: $brandId, humanId: $humanId, isUndo: $isUndo) {
      ...BrandDetail
    }
  }
  ${BrandDetail}
`;

const brandMergeHumanMutation = gql`
  mutation BrandMergeHuman(
    $brandId: ID!
    $parentHumanId: ID!
    $childHumanId: ID!
  ) {
    brandMergeHuman(
      brandId: $brandId
      parentHumanId: $parentHumanId
      childHumanId: $childHumanId
    ) {
      id
    }
  }
`;

const orphanUnthreadEmailFromMatchMutation = gql`
  mutation OrphanUnthreadEmailFromMatch(
    $matchId: ID!
    $emailMessageEventId: ID!
  ) {
    orphanUnthreadEmailFromMatch(
      matchId: $matchId
      emailMessageEventId: $emailMessageEventId
    ) {
      id
    }
  }
`;

const orphanUnthreadTextFromMatchMutation = gql`
  mutation OrphanUnthreadTextFromMatch(
    $matchId: ID!
    $textMessageEventId: ID!
  ) {
    orphanUnthreadTextFromMatch(
      matchId: $matchId
      textMessageEventId: $textMessageEventId
    ) {
      id
    }
  }
`;

const orphanUnthreadPhoneCallFromMatchMutation = gql`
  mutation OrphanUnthreadPhoneCallFromMatch(
    $matchId: ID!
    $phoneCallEventId: ID!
  ) {
    orphanUnthreadPhoneCallFromMatch(
      matchId: $matchId
      phoneCallEventId: $phoneCallEventId
    ) {
      id
    }
  }
`;

const orphanUnthreadMeetingFromMatchMutation = gql`
  mutation OrphanUnthreadMeetingFromMatch($matchId: ID!, $meetingEventId: ID!) {
    orphanUnthreadMeetingFromMatch(
      matchId: $matchId
      meetingEventId: $meetingEventId
    ) {
      id
    }
  }
`;

const matchThreadEventUpdatedSubscription = gql`
  subscription MatchThreadEventUpdated($threadId: ID!) {
    threadEventUpdated(threadId: $threadId) {
      ... on TextMessageEvent {
        ...TextMessageEventDetail
      }
      ... on EmailMessageEvent {
        ...EmailMessageEventDetail
      }
      ... on RequestEvent {
        ...RequestEventDetail
      }
      ... on MessageEvent {
        ...MessageEventDetail
      }
      ... on MeetingEvent {
        ...MeetingEventDetail
      }
      ... on Quote {
        ...QuoteDetail
      }
      ... on PhoneCallEvent {
        ...PhoneCallEventDetail
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const userNoteQuery = gql`
  query UserNoteClient($matchId: ID, $supportChannelId: ID, $humanId: ID) {
    userNoteForTarget(
      matchId: $matchId
      supportChannelId: $supportChannelId
      humanId: $humanId
    ) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const userNoteEditMutation = gql`
  mutation UserNoteEditClient(
    $body: String!
    $userNoteId: ID
    $matchId: ID
    $supportChannelId: ID
    $humanId: ID
  ) {
    userNoteEdit(
      body: $body
      userNoteId: $userNoteId
      matchId: $matchId
      supportChannelId: $supportChannelId
      humanId: $humanId
    ) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const expertShareClientMutation = gql`
  mutation ExpertShareClient($matchId: ID!, $expertId: ID!) {
    expertShareClient(matchId: $matchId, expertId: $expertId)
  }
`;

const userNoteUpdatedSubscription = gql`
  subscription UserNoteUpdatedClient($targetId: ID!) {
    userNoteUpdated(targetId: $targetId) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const matchFileUploadUpdatedSubscription = gql`
  subscription MatchFileUploadUpdated($threadId: ID!) {
    fileUploadUpdated(threadId: $threadId) {
      ...FileUploadDetail
    }
  }
  ${FileUploadDetail}
`;

const expertSearchForExpertMutation = gql`
  mutation ExpertSearchForExpert($searchQuery: String!) {
    expertSearchForExpert(searchQuery: $searchQuery) {
      ...ExpertSummary
    }
  }
  ${ExpertSummary}
`;

interface ExpertClientDetailProps {
  expertDetails?: ExpertDetailsQuery['expertDetails'];
  emailTemplates: TemplatesQuery['templates'];
  expertId: string;
  matchId: string;
  quoteTemplates: TemplatesQuery['templates'];
  socketClient: ClientWithOnReconnected;
}

interface IGroupedEvents {
  group: MatchThreadPaginatedQuery['threadPaginated']['edges'];
}

let scrollDownQuickTimeout: NodeJS.Timeout | undefined;
let isLoadingEvents = false;
const PAGE_LIMIT = 20;
const ACTIVITY_PAGE_LIMIT = 3;
const initTime = new Date().getTime();

const ExpertClientDetail = ({
  expertDetails,
  emailTemplates,
  expertId,
  matchId,
  quoteTemplates,
  socketClient,
}: ExpertClientDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const threadEventsWindow = useRef<HTMLDivElement>(null);
  const [initDate] = useState(() => new Date());
  const [actionLoading, setActionLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  const [dateSnoozeFocused, setDateSnoozeFocused] = useState(false);
  const [unclaimSuccess, setUnclaimSuccess] = useState(false);
  const [unthreadLoading, setUnthreadLoading] = useState(false);
  const [leadExpanded, setLeadExpanded] = useState(true);
  const [projectExpanded, setProjectExpanded] = useState('');
  const [meetingExpanded, setMeetingExpanded] = useState('');
  const [pastLoaded, setPastLoaded] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const [checkOne, setCheckOne] = useState(false);
  const [checkTwo, setCheckTwo] = useState(false);
  const [checkThree, setCheckThree] = useState(false);
  const [emailDraft, setEmailDraft] = useState(false);
  const [emailStatus, setEmailStatus] = useState('');
  const [emailFiles, setEmailFiles] = useState([] as IFilestackFileUpload[]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [emailRecipients, setEmailRecipients] = useState(
    [] as MatchDetailsQuery['matchDetails']['brand']['team'],
  );
  const [messageHqContent, setMessageHqContent] = useState('');
  const [textDraft, setTextDraft] = useState(false);
  const [textStatus, setTextStatus] = useState('');
  const [textFiles, setTextFiles] = useState([] as IFilestackFileUpload[]);
  const [textContent, setTextContent] = useState('');
  const [textRecipients, setTextRecipients] = useState(
    [] as MatchDetailsQuery['matchDetails']['brand']['team'],
  );
  const [meetingEditorTimePickerOpen, setMeetingEditorTimePickerOpen] =
    useState(false);
  const [meetingDraft, setMeetingDraft] = useState(false);
  const [meetingStatus, setMeetingStatus] = useState('');
  const [meetingActionId, setMeetingActionId] = useState('');
  const [meetingTitle, setMeetingTitle] = useState('');
  const [meetingLocation, setMeetingLocation] = useState('');
  const [meetingDescription, setMeetingDescription] = useState('');
  const [meetingRecipients, setMeetingRecipients] = useState(
    [] as MatchDetailsQuery['matchDetails']['brand']['team'],
  );
  const [meetingTimeStart, setMeetingTimeStart] = useState(() => new Date());
  const [meetingTimeEnd, setMeetingTimeEnd] = useState(() => new Date());
  const [meetingTimezone, setMeetingTimezone] = useState('');
  const [quoteDraft, setQuoteDraft] = useState(false);
  const [quoteActionType, setQuoteActionType] = useState('');
  const [quoteActionProject, setQuoteActionProject] = useState('');
  const [quoteActionQuote, setQuoteActionQuote] = useState('');
  const [quoteStatus, setQuoteStatus] = useState('');
  const [quoteTitle, setQuoteTitle] = useState('');
  const [quoteClientFee, setQuoteClientFee] = useState('');
  const [quoteDiscountCode, setQuoteDiscountCode] = useState('');
  const [quotePaymentPrice, setQuotePaymentPrice] = useState('');
  const [quotePaymentType, setQuotePaymentType] = useState('');
  const [quoteTimelineDate, setQuoteTimelineDate] = useState(() =>
    moment.utc().endOf('day'),
  );
  const [quoteTimelineDays, setQuoteTimelineDays] = useState('');
  const [quoteTimelineType, setQuoteTimelineType] = useState('');
  const [quoteContent, setQuoteContent] = useState('');
  const [quoteEmailDraft, setQuoteEmailDraft] = useState(false);
  const [quoteEmailStatus, setQuoteEmailStatus] = useState('');
  const [quoteEmailFiles, setQuoteEmailFiles] = useState(
    [] as IFilestackFileUpload[],
  );
  const [quoteEmailSubject, setQuoteEmailSubject] = useState('');
  const [quoteEmailContent, setQuoteEmailContent] = useState('');
  const [quoteEmailRecipients, setQuoteEmailRecipients] = useState(
    [] as MatchDetailsQuery['matchDetails']['brand']['team'],
  );
  const [quoteTextDraft, setQuoteTextDraft] = useState(false);
  const [quoteTextStatus, setQuoteTextStatus] = useState('');
  const [quoteTextFiles, setQuoteTextFiles] = useState(
    [] as IFilestackFileUpload[],
  );
  const [quoteTextContent, setQuoteTextContent] = useState('');
  const [quoteTextRecipients, setQuoteTextRecipients] = useState(
    [] as MatchDetailsQuery['matchDetails']['brand']['team'],
  );
  const [showSidebar, setShowSidebar] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedSearchResult, setSelectedSearchResult] = useState<
    MatchThreadSearchMutation['threadSearch'][0] | null
  >(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    MatchThreadSearchMutation['threadSearch'] | null
  >(null);
  const [searchExpertInput, setSearchExpertInput] = useState('');
  const [searchExpertResults, setSearchExpertResults] = useState<
    ExpertSearchForExpertMutation['expertSearchForExpert'] | null
  >(null);
  const [cancelProjectShow, setCancelProjectShow] = useState<
    MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0] | null
  >(null);
  const [cancelMeetingShow, setCancelMeetingShow] = useState<
    MeetingActiveForMatchQuery['meetingActiveForMatch'][0] | null
  >(null);
  const [shortQuoteConfirm, setShortQuoteConfirm] = useState(false);
  const [undoProjectShow, setUndoProjectShow] = useState<
    MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0] | null
  >(null);
  const [existingHumanId, setExistingHumanId] = useState<string | null>(null);
  const [existingHumanName, setExistingHumanName] = useState<string | null>(
    null,
  );
  const [viewProjectShow, setViewProjectShow] = useState<
    MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0] | null
  >(null);
  const [markCompleteChecklistShow, setMarkCompleteChecklistShow] = useState<
    MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0] | null
  >(null);
  const [ignoreShow, setIgnoreShow] = useState(false);
  const [allClientsMoveShow, setAllClientsMoveShow] = useState(false);
  const [allClientsMoveReason, setAllClientsMoveReason] = useState('');
  const [allClientsMoveMoreDetail, setAllClientsMoveMoreDetail] = useState('');
  const [anotherAnytimeShow, setAnotherAnytimeShow] = useState(false);
  const [anotherAnytimeReason, setAnotherAnytimeReason] = useState('');
  const [anotherAnytimeMoreDetail, setAnotherAnytimeMoreDetail] = useState('');
  const [brandEditing, setBrandEditing] = useState(false);
  const [humanEditing, setHumanEditing] = useState('');
  const [unclaimShow, setUnclaimShow] = useState('');
  const [primaryPanel, setPrimaryPanel] = useState('Up Next');
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [tempEdges, setTempEdges] = useState(
    [] as MatchThreadPaginatedQuery['threadPaginated']['edges'],
  );
  // remoteEventIds tracks threadEventUpdated things that might cause pagination errors
  const [remoteEventIds, setRemoteEventIds] = useState([] as string[]);
  const [tempTeamIgnores, setTempTeamIgnores] = useState([] as string[]);
  const [showLoadMoreProjects, setShowLoadMoreProjects] = useState(true);
  const {
    data: dataMatch,
    error: errorMatch,
    loading: loadingMatch,
    refetch: refetchMatch,
  } = useQuery<MatchDetailsQuery, MatchDetailsQueryVariables>(
    matchDetailsQuery,
    {
      returnPartialData: true,
      variables: {
        matchId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorMatch) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorMatch) || 'Could not load match details';
  }
  const matchDetails =
    dataMatch && dataMatch.matchDetails && dataMatch.matchDetails.id
      ? dataMatch.matchDetails
      : undefined;
  if (
    matchDetails &&
    ((matchDetails.isBlocked && !modalLoading && !unclaimSuccess) ||
      (matchDetails.expertStr && matchDetails.expertStr !== expertId))
  ) {
    fullErrorCover = 'This match is no longer available';
  }
  const brandTeam =
    (matchDetails && matchDetails.brand && matchDetails.brand.team) || [];
  const ignoreTeam =
    (matchDetails && matchDetails.brand && matchDetails.brand.ignoreHumans) ||
    [];
  const emailBrandTeam = brandTeam.filter((h) => h.primaryEmail);
  const phoneBrandTeam = brandTeam.filter((h) => h.primaryPhone);
  const {
    data: dataThread,
    error: errorThread,
    loading: loadingThread,
    fetchMore: fetchMoreThread,
    subscribeToMore: subscribeToMoreThread,
  } = useQuery<MatchThreadPaginatedQuery, MatchThreadPaginatedQueryVariables>(
    matchThreadPaginatedQuery,
    {
      onCompleted: () => {
        scrollDown();
      },
      variables: {
        direction: 'before',
        fromDate: initDate.getTime(),
        limit: PAGE_LIMIT,
        matchId,
      },
    },
  );
  if (errorThread) {
    fullErrorCover =
      'Error loading this thread: ' + errorUtils.getErrorMessage(errorThread);
  }
  const cleanThread =
    (dataThread &&
      dataThread.threadPaginated &&
      dataThread.threadPaginated.edges) ||
    [];
  useEffect(() => {
    setTempEdges([]);
    setTempTeamIgnores([]);
    setRemoteEventIds([]);
    setPastLoaded(false);
  }, [matchId]);
  useEffect(() => {
    const unsub = subscribeToMoreThread<
      MatchThreadEventUpdatedSubscription,
      MatchThreadEventUpdatedSubscriptionVariables
    >({
      document: matchThreadEventUpdatedSubscription,
      onError: (err) => {
        addNotification(
          'Error loading thread. Please refresh and try again: ' + err.message,
        );
        console.error('onError: subscribeToMoreThread', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { threadEventUpdated },
          },
        },
      ) => {
        console.log('threadEventUpdated', prev, threadEventUpdated);
        if (!threadEventUpdated || !prev.threadPaginated) {
          return prev;
        }
        if (prev.threadPaginated.id !== threadEventUpdated.matchStr) {
          return prev;
        }
        setTempEdges((prevTempEdges) =>
          prevTempEdges.filter(
            (t) =>
              t.id !== threadEventUpdated.threadEvent &&
              [
                'tempPhoneCallThreadEventId',
                'tempEmailMessageThreadEventId',
                'tempTextMessageThreadEventId',
                'tempMeetingThreadEventId',
              ].indexOf(t.id) === -1,
          ),
        );
        setRemoteEventIds((prevRemoteEventIds) =>
          prevRemoteEventIds.concat(threadEventUpdated.id),
        );
        const updatingIndex = (prev.threadPaginated.edges || []).findIndex(
          (e) => e.id === threadEventUpdated.threadEvent,
        );
        if (updatingIndex >= 0) {
          // update existing
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: prev.threadPaginated.edges
                .slice(0, updatingIndex)
                .concat({
                  __typename:
                    prev.threadPaginated.edges[updatingIndex].__typename,
                  cursor: prev.threadPaginated.edges[updatingIndex].cursor,
                  id: prev.threadPaginated.edges[updatingIndex].id,
                  node: {
                    ...prev.threadPaginated.edges[updatingIndex].node,
                    ...threadEventUpdated,
                  } as MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
                })
                .concat(prev.threadPaginated.edges.slice(updatingIndex + 1)),
              id: prev.threadPaginated.id,
            },
          };
        }
        // add new
        scrollDown();
        return {
          threadPaginated: {
            __typename: prev.threadPaginated.__typename,
            edges: (prev.threadPaginated.edges || []).concat({
              __typename: 'ThreadEventEdge',
              cursor: threadEventUpdated.createdAt,
              id: threadEventUpdated.threadEvent || '',
              node: threadEventUpdated,
            }),
            id: prev.threadPaginated.id,
          },
        };
      },
      variables: {
        threadId: matchId,
      },
    });
    return () => {
      unsub();
    };
  }, [subscribeToMoreThread, matchId, addNotification]);
  const brandId =
    matchDetails && matchDetails.brand ? matchDetails.brand.id : '';
  const [
    getProjectPaginated,
    {
      called: calledProjectPaginated,
      data: dataProjectPaginated,
      error: errorProjectPaginated,
      loading: loadingProjectPaginated,
      fetchMore: fetchMoreProjectPaginated,
    },
  ] = useLazyQuery<
    ProjectPaginateBrandDetailQuery,
    ProjectPaginateBrandDetailQueryVariables
  >(projectPaginateBrandDetailQuery, {
    variables: {
      brandId,
      direction: 'BACKWARD',
      fromDate: initDate.getTime(),
      limit: ACTIVITY_PAGE_LIMIT,
    },
  });
  const allProjectsForBrand = _.uniqBy(
    [
      ...((dataProjectPaginated && dataProjectPaginated.projectPaginateBrand) ||
        []),
    ].filter((r) => r.brandStr === brandId),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  const oldestCreatedProject =
    allProjectsForBrand[allProjectsForBrand.length - 1];
  function loadMoreProjects() {
    if (fetchMoreProjectPaginated) {
      fetchMoreProjectPaginated({
        updateQuery: (prev, { fetchMoreResult }) => {
          console.log('fetchMoreResult', fetchMoreResult);
          if (!fetchMoreResult || !fetchMoreResult.projectPaginateBrand)
            return prev;
          if (
            fetchMoreResult.projectPaginateBrand.length < ACTIVITY_PAGE_LIMIT
          ) {
            setShowLoadMoreProjects(false);
          }
          return {
            projectPaginateBrand: (prev.projectPaginateBrand || []).concat(
              fetchMoreResult.projectPaginateBrand,
            ),
          };
        },
        variables: {
          direction: 'BACKWARD',
          fromDate: oldestCreatedProject.createdAt,
          limit: ACTIVITY_PAGE_LIMIT,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'loadMoreProjects',
        });
      });
    }
  }
  const [
    getUserNotes,
    {
      called: calledUserNotes,
      data: dataUserNotes,
      error: errorUserNotes,
      loading: loadingUserNotes,
      subscribeToMore: subscribeToMoreUserNotes,
    },
  ] = useLazyQuery<UserNoteClientQuery, UserNoteClientQueryVariables>(
    userNoteQuery,
    {
      variables: {
        matchId,
      },
    },
  );
  const allUserNotes = (dataUserNotes && dataUserNotes.userNoteForTarget) || [];
  useEffect(() => {
    let unsub: () => void;
    if (matchId && calledUserNotes && subscribeToMoreUserNotes) {
      unsub = subscribeToMoreUserNotes<
        UserNoteUpdatedClientSubscription,
        UserNoteUpdatedClientSubscriptionVariables
      >({
        document: userNoteUpdatedSubscription,
        onError: (err) => {
          addNotification(
            'Error loading notes. Please refresh and try again: ' + err.message,
          );
          console.error('onError: subscribeToMoreUserNotes', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { userNoteUpdated },
            },
          },
        ) => {
          console.log('userNoteUpdated', prev, userNoteUpdated);
          if (!userNoteUpdated || !userNoteUpdated.matchStr) {
            return prev;
          }
          if (matchId !== userNoteUpdated.matchStr) {
            return prev;
          }
          const updatingIndex = (prev.userNoteForTarget || []).findIndex(
            (un) => un.id === userNoteUpdated.id,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              userNoteForTarget: prev.userNoteForTarget
                .slice(0, updatingIndex)
                .concat({
                  ...prev.userNoteForTarget[updatingIndex],
                  ...userNoteUpdated,
                })
                .concat(prev.userNoteForTarget.slice(updatingIndex + 1)),
            };
          }
          return {
            userNoteForTarget: (prev.userNoteForTarget || []).concat(
              userNoteUpdated,
            ),
          };
        },
        variables: {
          targetId: matchId,
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [subscribeToMoreUserNotes, matchId, calledUserNotes, addNotification]);
  const [tryEditNote] = useMutation<
    UserNoteEditClientMutation,
    UserNoteEditClientMutationVariables
  >(userNoteEditMutation);
  function onSaveNote(body: string, userNoteId?: string) {
    if (noteLoading || !matchId) return;
    setNoteLoading(true);
    tryEditNote({
      variables: {
        body,
        matchId,
        userNoteId,
      },
    })
      .then(() => {
        setNoteLoading(false);
      })
      .catch((err: ApolloError) => {
        setNoteLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Edit Note Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'onSaveNote',
        });
      });
  }
  const {
    data: dataMeetingActive,
    error: errorMeetingActive,
    loading: loadingMeetingActive,
    refetch: refetchMeetingActive,
  } = useQuery<MeetingActiveForMatchQuery, MeetingActiveForMatchQueryVariables>(
    meetingActiveForMatchQuery,
    {
      returnPartialData: true,
      variables: {
        matchId,
      },
    },
  );
  const [
    getPastMeetings,
    {
      called: calledMeetingAll,
      data: dataMeetingAll,
      error: errorMeetingAll,
      loading: loadingMeetingAll,
    },
  ] = useLazyQuery<MeetingAllForMatchQuery, MeetingAllForMatchQueryVariables>(
    meetingAllForMatchQuery,
    {
      returnPartialData: true,
      variables: {
        matchId,
      },
    },
  );
  const [
    getPastProjects,
    {
      called: calledProjectPast,
      data: dataProjectPast,
      error: errorProjectPast,
      loading: loadingProjectPast,
    },
  ] = useLazyQuery<ProjectPastForMatchQuery, ProjectPastForMatchQueryVariables>(
    projectPastForMatchQuery,
    {
      returnPartialData: true,
      variables: {
        matchId,
      },
    },
  );
  const { data: dataMeetingForExpert } =
    useQuery<MeetingActiveForExpertCacheOnlyQuery>(
      meetingActiveForExpertCacheOnlyQuery,
      {
        fetchPolicy: 'cache-only',
        returnPartialData: true,
      },
    );
  useEffect(() => {
    // check for drafts
    const hasEmailDraft = tokenUtils.readLocalStorage(`match-email-${matchId}`);
    setEmailDraft(!!hasEmailDraft);
    if (hasEmailDraft) {
      setEmailSubject(
        tokenUtils.readLocalStorage(`match-email-${matchId}-subject`) || '',
      );
    } else {
      setEmailSubject('');
    }
    const hasTextDraft = tokenUtils.readLocalStorage(`match-text-${matchId}`);
    setTextDraft(!!hasTextDraft);
    if (hasTextDraft) {
      setTextContent(
        tokenUtils.readLocalStorage(`match-text-${matchId}-content`) || '',
      );
    } else {
      setTextContent('');
    }
    const hasMeetingDraft = tokenUtils.readLocalStorage(
      `match-meeting-${matchId}`,
    );
    setMeetingDraft(!!hasMeetingDraft);
    if (hasMeetingDraft) {
      setMeetingTitle(
        tokenUtils.readLocalStorage(`match-meeting-${matchId}-title`) || '',
      );
    } else {
      setMeetingTitle('');
    }
    const hasQuoteDraft = tokenUtils.readLocalStorage(`quote-${matchId}`);
    setQuoteDraft(!!hasQuoteDraft);
    if (hasQuoteDraft) {
      setQuoteActionType(
        tokenUtils.readLocalStorage(`quote-${matchId}-actionType`) || '',
      );
      setQuoteTitle(
        tokenUtils.readLocalStorage(`quote-${matchId}-title`) || '',
      );
    } else {
      setQuoteTitle('');
      setQuoteActionType('');
    }
    const hasQuoteEmailDraft = tokenUtils.readLocalStorage(
      `quoteEmail-${matchId}`,
    );
    setQuoteEmailDraft(!!hasQuoteEmailDraft);
    const hasQuoteTextDraft = tokenUtils.readLocalStorage(
      `quoteText-${matchId}`,
    );
    setQuoteTextDraft(!!hasQuoteTextDraft);
  }, [matchId]);
  const {
    data: dataFileActive,
    error: errorFileActive,
    loading: loadingFileActive,
    subscribeToMore: subscribeToMoreFile,
  } = useQuery<FileActiveForMatchQuery, FileActiveForMatchQueryVariables>(
    fileActiveForMatchQuery,
    {
      returnPartialData: true,
      variables: {
        matchId,
      },
    },
  );
  const meetingsForMatch = _.uniqBy(
    [
      ...((dataMeetingActive && dataMeetingActive.meetingActiveForMatch) || []),
      ...((calledMeetingAll &&
        dataMeetingAll &&
        dataMeetingAll.meetingAllForMatch) ||
        []),
      ...((dataMeetingForExpert &&
        dataMeetingForExpert.meetingActiveForExpert) ||
        []),
    ].filter(
      (m) =>
        m.matchStr === matchId &&
        m.eventStart &&
        m.eventEnd &&
        ((m.organizerExpert && m.organizerExpert.id === expertId) ||
          (m.participantExpert && m.participantExpert.id === expertId)),
    ),
    'id',
  ).sort((a, b) => (a.eventStart || Infinity) - (b.eventStart || Infinity));
  const upNextMeetingsForMatch = meetingsForMatch.filter(
    (m) =>
      m.eventEnd >= initTime &&
      m.eventStatus === 'ACTIVE' &&
      m.expertResponse !== 'DECLINED',
  );
  const pastMeetingsForMatch = meetingsForMatch
    .filter(
      (m) =>
        m.eventEnd < initTime ||
        m.eventStatus !== 'ACTIVE' ||
        m.expertResponse === 'DECLINED',
    )
    .reverse();
  const activeFiles = [
    ...((dataFileActive && dataFileActive.fileActiveForThread) || []),
  ]
    .filter(
      (f) =>
        f.matchStr === matchId &&
        !!f.url &&
        !f.isHiddenByExpert &&
        !f.isInlineEmail,
    )
    .sort((a, b) => b.createdAt - a.createdAt);
  useEffect(() => {
    const unsub = subscribeToMoreFile<
      MatchFileUploadUpdatedSubscription,
      MatchFileUploadUpdatedSubscriptionVariables
    >({
      document: matchFileUploadUpdatedSubscription,
      onError: (err) => {
        // ignore error
        console.error('onError: subscribeToMoreFile', err);
      },
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: { fileUploadUpdated },
          },
        },
      ) => {
        console.log('fileUploadUpdated', prev, fileUploadUpdated);
        if (!fileUploadUpdated) {
          return prev;
        }
        if (matchId !== fileUploadUpdated.matchStr) {
          return prev;
        }
        const updatingIndex = (prev.fileActiveForThread || []).findIndex(
          (f) => f.id === fileUploadUpdated.id,
        );
        if (updatingIndex >= 0) {
          // update existing
          return {
            fileActiveForThread: prev.fileActiveForThread
              .slice(0, updatingIndex)
              .concat({
                ...prev.fileActiveForThread[updatingIndex],
                ...fileUploadUpdated,
              })
              .concat(prev.fileActiveForThread.slice(updatingIndex + 1)),
          };
        }
        return {
          fileActiveForThread: (prev.fileActiveForThread || []).concat(
            fileUploadUpdated,
          ),
        };
      },
      variables: {
        threadId: matchId,
      },
    });
    return () => {
      unsub();
    };
  }, [subscribeToMoreFile, matchId]);
  useEffect(() => {
    if (!searchResults) {
      ReactTooltip.hide();
    }
    ReactTooltip.rebuild();
  }, [
    searchLoading,
    searchResults,
    primaryPanel,
    activeFiles,
    upNextMeetingsForMatch,
    pastMeetingsForMatch,
    meetingExpanded,
  ]);
  const [tryFileArchive] = useMutation<
    FileArchiveMutation,
    FileArchiveMutationVariables
  >(fileArchiveMutation);
  function onFileArchive(
    fileUpload: FileActiveForMatchQuery['fileActiveForThread'][0],
  ) {
    tryFileArchive({
      optimisticResponse: {
        fileArchive: {
          ...fileUpload,
          isHiddenByExpert: true,
        },
      },
      variables: {
        fileUploadId: fileUpload.id,
        matchId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'File Archive Error');
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'tryFileArchive',
      });
    });
  }
  const [trySnooze] = useMutation<
    MatchSnoozeExpertMutation,
    MatchSnoozeExpertMutationVariables
  >(matchSnoozeExpertMutation);
  const [tryMarkReadUnread] = useMutation<
    MatchMarkAsReadUnreadMutation,
    MatchMarkAsReadUnreadMutationVariables
  >(matchMarkAsReadUnreadMutation);
  function markAsReadUnread(unreadReason?: string) {
    if (!matchDetails) {
      return;
    }
    alertUtils.removeUnread(matchId);
    tryMarkReadUnread({
      optimisticResponse: {
        matchMarkAsReadUnread: {
          ...matchDetails,
          expertUnread: unreadReason || null,
        },
      },
      variables: {
        matchId,
        ...(unreadReason && { unreadReason }),
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Mark Read/Unread Error',
      );
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'markAsReadUnread',
      });
    });
  }
  function onSnooze(snoozeDate?: moment.Moment | null) {
    if (!matchDetails) {
      return;
    }
    alertUtils.removeUnread(matchId);
    let untilDate: Date;
    let expertUnread = null;
    if (snoozeDate && snoozeDate.isAfter()) {
      untilDate = snoozeDate
        .clone()
        .hour(moment().hour())
        .minute(moment().minute())
        .toDate();
      console.log(snoozeDate.toDate(), moment().toDate(), untilDate);
    } else {
      untilDate = moment().subtract(1, 'days').toDate();
      expertUnread = 'MANUAL_UNSNOOZE';
    }
    trySnooze({
      optimisticResponse: {
        matchSnoozeExpert: {
          ...matchDetails,
          expertSnoozedUntil: untilDate.getTime(),
          expertUnread,
        },
      },
      variables: {
        matchId,
        untilDate: untilDate.getTime(),
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Snooze Error');
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'trySnooze',
      });
    });
  }
  const [tryOutboundPhoneCall] = useMutation<
    MatchThreadOutboundPhoneCallMutation,
    MatchThreadOutboundPhoneCallMutationVariables
  >(matchThreadOutboundPhoneCallMutation);
  function makePhoneCall(
    humanToCall?: MatchDetailsQuery['matchDetails']['brand']['team'][0],
  ) {
    if (sendLoading || !expertDetails) return;
    if (!humanToCall) {
      addNotification(
        'An error occured. Please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    tryOutboundPhoneCall({
      optimisticResponse: {
        threadOutboundPhoneCall: {
          __typename: 'PhoneCallEvent',
          createdAt: Date.now(),
          id: 'tempPhoneCallEventId',
          matchStr: matchId,
          orphanStr: null,
          ownerExpert: {
            ...expertDetails,
            id: expertId,
          },
          ownerHuman: null,
          recipientExpert: null,
          recipientHumans: [humanToCall],
          resultStatus: null,
          supportChannelStr: null,
          threadEvent: 'tempPhoneCallThreadEventId',
          voicemailRecordingUrl: null,
        },
      },
      update: (_cache, { data: dataPhoneCallMutation }) => {
        if (
          dataPhoneCallMutation &&
          dataPhoneCallMutation.threadOutboundPhoneCall
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempPhoneCallThreadEventId' &&
              e.id !==
                dataPhoneCallMutation.threadOutboundPhoneCall.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataPhoneCallMutation.threadOutboundPhoneCall.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor: dataPhoneCallMutation.threadOutboundPhoneCall.createdAt,
              id: dataPhoneCallMutation.threadOutboundPhoneCall.threadEvent,
              node: dataPhoneCallMutation.threadOutboundPhoneCall,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        matchId,
        recipientHuman: humanToCall.id,
      },
    })
      .then(() => {
        setCurrentAction('');
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempPhoneCallThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Phone Call Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'makePhoneCall',
        });
      });
  }
  const [trySendEmailMessage] = useMutation<
    MatchThreadSendEmailMessageMutation,
    MatchThreadSendEmailMessageMutationVariables
  >(matchThreadSendEmailMessageMutation);
  function onSendEmail(drafHtml: string) {
    if (sendLoading || !expertDetails) return;
    if (!emailSubject.trim() || !emailRecipients.length || !drafHtml) {
      addNotification(
        'You need a subject, recipients, and an email body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    console.log('sendEmailMessage', emailSubject, drafHtml);
    trySendEmailMessage({
      optimisticResponse: {
        threadSendEmailMessage: {
          __typename: 'EmailMessageEvent',
          content: drafHtml,
          contentExpanded: null,
          createdAt: Date.now(),
          files: emailFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempEmailMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: matchId,
            orphanStr: null,
            supportChannelStr: null,
          })),
          id: 'tempEmailMessageEventId',
          isTransactional: false,
          matchStr: matchId,
          noExpertRecipientsFound: [],
          orphanStr: null,
          ownerExpert: {
            ...expertDetails,
            id: expertId,
          },
          ownerHuman: null,
          quote: null,
          recipientCopiedExperts: [],
          recipientExpert: null,
          recipientHumans: emailRecipients,
          sendingError: null,
          sendingStatus: 'SENT',
          subject: emailSubject,
          supportChannelStr: null,
          threadEvent: 'tempEmailMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentEmailMutation }) => {
        if (
          dataSentEmailMutation &&
          dataSentEmailMutation.threadSendEmailMessage
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempEmailMessageThreadEventId' &&
              e.id !== dataSentEmailMutation.threadSendEmailMessage.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataSentEmailMutation.threadSendEmailMessage.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor: dataSentEmailMutation.threadSendEmailMessage.createdAt,
              id: dataSentEmailMutation.threadSendEmailMessage.threadEvent,
              node: dataSentEmailMutation.threadSendEmailMessage,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          files: emailFiles,
          html: drafHtml,
          matchId,
          recipientHumans: emailRecipients.map((r) => r.id),
          subject: emailSubject,
        },
      },
    })
      .then(() => {
        onDeleteEmailDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempEmailMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Send Email Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'sendEmailMessage',
        });
      });
  }
  const [trySendTextMessage] = useMutation<
    MatchThreadSendTextMessageMutation,
    MatchThreadSendTextMessageMutationVariables
  >(matchThreadSendTextMessageMutation);
  function onSendText() {
    if (sendLoading || !expertDetails) return;
    if (!textRecipients.length || !textContent) {
      addNotification(
        'You need recipients and a message body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    trySendTextMessage({
      optimisticResponse: {
        threadSendTextMessage: {
          __typename: 'TextMessageEvent',
          content: textContent,
          createdAt: Date.now(),
          files: textFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempTextMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: matchId,
            orphanStr: null,
            supportChannelStr: null,
          })),
          id: 'tempTextMessageEventId',
          isTransactional: false,
          matchStr: matchId,
          orphanStr: null,
          ownerExpert: {
            ...expertDetails,
            id: expertId,
          },
          ownerHuman: null,
          quote: null,
          recipientExpert: null,
          recipientHumans: textRecipients,
          sendingError: null,
          supportChannelStr: null,
          threadEvent: 'tempTextMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentTextMutation }) => {
        if (
          dataSentTextMutation &&
          dataSentTextMutation.threadSendTextMessage
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempTextMessageThreadEventId' &&
              e.id !== dataSentTextMutation.threadSendTextMessage.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id === dataSentTextMutation.threadSendTextMessage.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor: dataSentTextMutation.threadSendTextMessage.createdAt,
              id: dataSentTextMutation.threadSendTextMessage.threadEvent,
              node: dataSentTextMutation.threadSendTextMessage,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          content: textContent,
          files: textFiles,
          matchId,
          recipientHuman: textRecipients[0].id,
        },
      },
    })
      .then(() => {
        onDeleteTextDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempTextMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Send Text Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'sendTextMessage',
        });
      });
  }
  const [tryCreateMeeting] = useMutation<
    MeetingInviteCreateMatchMutation,
    MeetingInviteCreateMatchMutationVariables
  >(meetingInviteCreateMatchMutation);
  const [tryUpdateMeeting] = useMutation<
    MeetingInviteUpdateMatchMutation,
    MeetingInviteUpdateMatchMutationVariables
  >(meetingInviteUpdateMatchMutation);
  function onSendMeeting() {
    if (sendLoading || !expertDetails) return;
    const meetingToUpdate = meetingActionId
      ? meetingsForMatch.find((m) => m.id === meetingActionId)
      : undefined;
    if (meetingActionId && !meetingToUpdate) {
      addNotification(
        'An error occured. Please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    if (
      !meetingRecipients.length ||
      !meetingTitle ||
      !meetingTimeStart ||
      !meetingTimeEnd ||
      !meetingTimezone
    ) {
      addNotification(
        'You need recipients, a title, start/end times, and a timezone set.',
        undefined,
        5000,
      );
      return;
    }
    // test time start and end for validity
    if (meetingTimeStart >= meetingTimeEnd) {
      addNotification('Meeting must start before it ends.', undefined, 5000);
      return;
    }
    // convert timezone/dates on frontend
    const translatedStart = moment(meetingTimeStart)
      .tz(meetingTimezone, true)
      .toDate();
    const translatedEnd = moment(meetingTimeEnd)
      .tz(meetingTimezone, true)
      .toDate();
    if (translatedStart >= translatedEnd) {
      addNotification('Meeting must start before it ends.', undefined, 5000);
      return;
    }
    if (translatedStart <= new Date() || translatedEnd <= new Date()) {
      addNotification('Meeting must be in the future.', undefined, 5000);
      return;
    }
    setSendLoading(true);
    if (meetingToUpdate) {
      tryUpdateMeeting({
        optimisticResponse: {
          meetingInviteUpdate: {
            __typename: 'MeetingEvent',
            action: 'UPDATE',
            createdAt: Date.now(),
            id: 'tempMeetingEventId',
            matchStr: matchId,
            meeting: {
              ...meetingToUpdate,
              eventEnd: translatedEnd.getTime(),
              eventLocation: meetingLocation,
              eventNotes: meetingDescription,
              eventStart: translatedStart.getTime(),
              eventTimezone: meetingTimezone,
              eventTitle: meetingTitle,
              participantHumansStatus: meetingRecipients.map((r) => ({
                __typename: 'ParticipantHumanStatus',
                human: {
                  __typename: 'Human',
                  firstName: '',
                  id: r.id,
                  lastName: '',
                  primaryEmail: '',
                  primaryPhone: '',
                },
                humanStr: r.id,
                status: 'NEEDS-ACTION',
              })),
              requestedByHuman: null,
            },
            orphanStr: null,
            ownerExpert: {
              ...expertDetails,
              id: expertId,
            },
            ownerHuman: null,
            recipientExpert: null,
            recipientHumans: meetingRecipients.map((r) => ({
              __typename: 'Human',
              firstName: '',
              id: r.id,
              lastName: '',
              primaryEmail: '',
              primaryPhone: '',
            })),
            response: null,
            supportChannelStr: null,
            threadEvent: 'tempMeetingThreadEventId',
          },
        },
        update: (_cache, { data: dataMeetingUpdateMutation }) => {
          if (
            dataMeetingUpdateMutation &&
            dataMeetingUpdateMutation.meetingInviteUpdate
          ) {
            const nextTempEdges = tempEdges.filter(
              (e) =>
                e.id !== 'tempMeetingThreadEventId' &&
                e.id !==
                  dataMeetingUpdateMutation.meetingInviteUpdate.threadEvent,
            );
            if (
              !cleanThread.find(
                (e) =>
                  e.id ===
                  dataMeetingUpdateMutation.meetingInviteUpdate.threadEvent,
              )
            ) {
              nextTempEdges.push({
                __typename: 'ThreadEventEdge',
                cursor: dataMeetingUpdateMutation.meetingInviteUpdate.createdAt,
                id: dataMeetingUpdateMutation.meetingInviteUpdate.threadEvent,
                node: dataMeetingUpdateMutation.meetingInviteUpdate,
              });
            }
            setTempEdges(_.uniqBy(nextTempEdges, 'id'));
          }
        },
        variables: {
          description: meetingDescription.trim(),
          location: meetingLocation.trim(),
          meetingId: meetingToUpdate.id,
          recipients: meetingRecipients.map((r) => r.id),
          timeEnd: translatedEnd.getTime(),
          timeStart: translatedStart.getTime(),
          timezone: meetingTimezone,
          title: meetingTitle.trim(),
        },
      })
        .then(() => {
          onDeleteMeetingDraft();
          setSendLoading(false);
        })
        .catch((err: ApolloError) => {
          setTempEdges(
            tempEdges.filter((e) => e.id !== 'tempMeetingThreadEventId'),
          );
          setSendLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Meeting Update Error',
          );
          logError(err, {
            component: 'ExpertClientDetail',
            func: 'tryUpdateMeeting',
          });
        });
    } else {
      tryCreateMeeting({
        optimisticResponse: {
          meetingInviteCreate: {
            __typename: 'MeetingEvent',
            action: 'CREATE',
            createdAt: Date.now(),
            id: 'tempMeetingEventId',
            matchStr: matchId,
            meeting: {
              __typename: 'Meeting',
              createdAt: Date.now(),
              eventAllDay: null,
              eventEnd: translatedEnd.getTime(),
              eventLocation: meetingLocation,
              eventNotes: meetingDescription,
              eventStart: translatedStart.getTime(),
              eventStatus: 'ACTIVE',
              eventTimezone: meetingTimezone,
              eventTitle: meetingTitle,
              expertResponse: 'ACCEPTED',
              id: 'tempMeetingId',
              matchStr: matchId,
              organizerExpert: {
                ...expertDetails,
                id: expertId,
              },
              organizerHuman: null,
              orphanStr: null,
              participantExpert: null,
              participantHumansStatus: meetingRecipients.map((r) => ({
                __typename: 'ParticipantHumanStatus',
                human: {
                  __typename: 'Human',
                  firstName: '',
                  id: r.id,
                  lastName: '',
                  primaryEmail: '',
                  primaryPhone: '',
                },
                humanStr: r.id,
                status: 'NEEDS-ACTION',
              })),
              requestedByHuman: null,
              supportChannelStr: null,
            },
            orphanStr: null,
            ownerExpert: {
              ...expertDetails,
              id: expertId,
            },
            ownerHuman: null,
            recipientExpert: null,
            recipientHumans: meetingRecipients.map((r) => ({
              __typename: 'Human',
              firstName: '',
              id: r.id,
              lastName: '',
              primaryEmail: '',
              primaryPhone: '',
            })),
            response: null,
            supportChannelStr: null,
            threadEvent: 'tempMeetingThreadEventId',
          },
        },
        update: (_cache, { data: dataMeetingCreateMutation }) => {
          if (
            dataMeetingCreateMutation &&
            dataMeetingCreateMutation.meetingInviteCreate
          ) {
            const nextTempEdges = tempEdges.filter(
              (e) =>
                e.id !== 'tempMeetingThreadEventId' &&
                e.id !==
                  dataMeetingCreateMutation.meetingInviteCreate.threadEvent,
            );
            if (
              !cleanThread.find(
                (e) =>
                  e.id ===
                  dataMeetingCreateMutation.meetingInviteCreate.threadEvent,
              )
            ) {
              nextTempEdges.push({
                __typename: 'ThreadEventEdge',
                cursor: dataMeetingCreateMutation.meetingInviteCreate.createdAt,
                id: dataMeetingCreateMutation.meetingInviteCreate.threadEvent,
                node: dataMeetingCreateMutation.meetingInviteCreate,
              });
            }
            setTempEdges(_.uniqBy(nextTempEdges, 'id'));
          }
        },
        variables: {
          description: meetingDescription.trim(),
          location: meetingLocation.trim(),
          matchId,
          recipients: meetingRecipients.map((r) => r.id),
          timeEnd: translatedEnd.getTime(),
          timeStart: translatedStart.getTime(),
          timezone: meetingTimezone,
          title: meetingTitle.trim(),
        },
      })
        .then(() => {
          onDeleteMeetingDraft();
          setSendLoading(false);
        })
        .catch((err: ApolloError) => {
          setTempEdges(
            tempEdges.filter((e) => e.id !== 'tempMeetingThreadEventId'),
          );
          setSendLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Meeting Create Error',
          );
          logError(err, {
            component: 'ExpertClientDetail',
            func: 'tryCreateMeeting',
          });
        });
    }
  }
  const [tryMessageHq] = useMutation<
    MatchSetDangerZoneMutation,
    MatchSetDangerZoneMutationVariables
  >(matchSetDangerZoneMutation);
  function onSendMessageHq() {
    if (sendLoading) return;
    if (!messageHqContent.replace(/\s/gi, '').trim()) {
      addNotification(
        'Please fill out your message details before submitting.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    console.log('onSendMessageHq', messageHqContent);
    tryMessageHq({
      variables: {
        dangerZoneAdditionalDescription: messageHqContent,
        matchId,
        putInDangerZone: true,
        reason: 'SET_BY_EXPERT',
      },
    })
      .then(() => {
        onDeleteMessageHq();
        setSendLoading(false);
        addNotification(
          'Storetasker Support was notified and will look into this for you shortly',
          'GOOD',
          2000,
        );
      })
      .catch((err: ApolloError) => {
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Message HQ Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'onSendMessageHq',
        });
      });
  }
  function onSendQuoteEmail(drafHtml: string) {
    if (sendLoading || !expertDetails || !quoteActionType) return;
    if (inactiveQuoteEditingError) {
      addNotification(
        'This project has been updated. Please delete this draft and start over.',
        undefined,
        5000,
      );
      return;
    }
    if (
      !quoteEmailSubject.trim() ||
      !quoteEmailRecipients.length ||
      !drafHtml
    ) {
      addNotification(
        'You need a subject, recipients, and an email body.',
        undefined,
        5000,
      );
      return;
    }
    const cents =
      Math.max(
        0,
        Math.round(parseFloat((quotePaymentPrice || '').replace('$', '')) || 0),
      ) * 100;
    if (!cents || cents <= 0) {
      addNotification(
        'You need a valid price set for your quote.',
        undefined,
        5000,
      );
      return;
    }
    const estimatedCompletionDays = Math.max(
      0,
      Math.round(parseFloat(quoteTimelineDays || '') || 0),
    );
    if (
      quotePaymentType === 'PROJECT' &&
      quoteTimelineType === 'DAYS' &&
      estimatedCompletionDays <= 0
    ) {
      addNotification(
        'You need a valid timeline set for your quote.',
        undefined,
        5000,
      );
      return;
    }
    const estimatedCompletionDate = quoteTimelineDate
      .utc()
      .endOf('day')
      .valueOf();
    if (
      quotePaymentType === 'PROJECT' &&
      quoteTimelineType === 'DATE' &&
      !estimatedCompletionDate
    ) {
      addNotification(
        'You need a valid timeline set for your quote.',
        undefined,
        5000,
      );
      return;
    }
    let quoteNextStatus = QuoteStatus.Created;
    if (quoteActionType === 'COMPLETE') {
      quoteNextStatus = QuoteStatus.Completed;
    } else if (quoteActionType === 'UPDATE') {
      quoteNextStatus = QuoteStatus.Updated;
    }
    setSendLoading(true);
    console.log('onSendQuoteEmail', emailSubject, drafHtml);
    trySendEmailMessage({
      optimisticResponse: {
        threadSendEmailMessage: {
          __typename: 'EmailMessageEvent',
          content: drafHtml,
          contentExpanded: null,
          createdAt: Date.now(),
          files: quoteEmailFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempEmailMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: matchId,
            orphanStr: null,
            supportChannelStr: null,
          })),
          id: 'tempEmailMessageEventId',
          isTransactional: false,
          matchStr: matchId,
          noExpertRecipientsFound: [],
          orphanStr: null,
          ownerExpert: {
            ...expertDetails,
            id: expertId,
          },
          ownerHuman: null,
          quote: {
            __typename: 'Quote',
            cents,
            createdAt: Date.now(),
            description: quoteContent,
            estimatedCompletionDate:
              quotePaymentType === 'PROJECT' && quoteTimelineType === 'DATE'
                ? estimatedCompletionDate
                : null,
            estimatedCompletionDays:
              quotePaymentType === 'PROJECT' && quoteTimelineType === 'DAYS'
                ? estimatedCompletionDays
                : null,
            id: 'tempEmailMessageQuoteId',
            matchStr: matchId,
            ownerExpert: {
              ...expertDetails,
              id: expertId,
            },
            ownerHuman: null,
            paymentType: quotePaymentType,
            revisionsRequested: null,
            status: quoteNextStatus,
            threadEvent: 'tempEmailMessageQuoteThreadEventId',
            title: quoteTitle,
          },
          recipientCopiedExperts: [],
          recipientExpert: null,
          recipientHumans: quoteEmailRecipients,
          sendingError: null,
          sendingStatus: 'SENT',
          subject: quoteEmailSubject,
          supportChannelStr: null,
          threadEvent: 'tempEmailMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentEmailMutation }) => {
        if (
          dataSentEmailMutation &&
          dataSentEmailMutation.threadSendEmailMessage
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempEmailMessageThreadEventId' &&
              e.id !== dataSentEmailMutation.threadSendEmailMessage.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataSentEmailMutation.threadSendEmailMessage.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor: dataSentEmailMutation.threadSendEmailMessage.createdAt,
              id: dataSentEmailMutation.threadSendEmailMessage.threadEvent,
              node: dataSentEmailMutation.threadSendEmailMessage,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          files: quoteEmailFiles,
          html: drafHtml,
          matchId,
          ...(quoteActionType !== 'COMPLETE' && {
            quote: {
              cents,
              description: quoteContent,
              ...(quotePaymentType === 'PROJECT' &&
                quoteTimelineType === 'DATE' && { estimatedCompletionDate }),
              ...(quotePaymentType === 'PROJECT' &&
                quoteTimelineType === 'DAYS' && { estimatedCompletionDays }),
              paymentType: quotePaymentType,
              title: quoteTitle,
            },
          }),
          quoteAction: quoteActionType,
          quoteId: quoteActionQuote,
          recipientHumans: quoteEmailRecipients.map((r) => r.id),
          subject: quoteEmailSubject,
        },
      },
    })
      .then(() => {
        onDeleteQuoteDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempEmailMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Send Quote Email Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'onSendQuoteEmail',
        });
      });
  }
  function onSendQuoteText() {
    if (sendLoading || !expertDetails || !quoteActionType) return;
    if (inactiveQuoteEditingError) {
      addNotification(
        'This project has been updated. Please delete this draft and start over.',
        undefined,
        5000,
      );
      return;
    }
    if (!quoteTextRecipients.length || !quoteTextContent) {
      addNotification(
        'You need recipients and a message body.',
        undefined,
        5000,
      );
      return;
    }
    const cents =
      Math.max(
        0,
        Math.round(parseFloat((quotePaymentPrice || '').replace('$', '')) || 0),
      ) * 100;
    if (!cents || cents <= 0) {
      addNotification(
        'You need a valid price set for your quote.',
        undefined,
        5000,
      );
      return;
    }
    const estimatedCompletionDays = Math.max(
      0,
      Math.round(parseFloat(quoteTimelineDays || '') || 0),
    );
    if (
      quotePaymentType === 'PROJECT' &&
      quoteTimelineType === 'DAYS' &&
      estimatedCompletionDays <= 0
    ) {
      addNotification(
        'You need a valid timeline set for your quote.',
        undefined,
        5000,
      );
      return;
    }
    const estimatedCompletionDate = quoteTimelineDate
      .utc()
      .endOf('day')
      .valueOf();
    if (
      quotePaymentType === 'PROJECT' &&
      quoteTimelineType === 'DATE' &&
      !estimatedCompletionDate
    ) {
      addNotification(
        'You need a valid timeline set for your quote.',
        undefined,
        5000,
      );
      return;
    }
    let quoteNextStatus = QuoteStatus.Created;
    if (quoteActionType === 'COMPLETE') {
      quoteNextStatus = QuoteStatus.Completed;
    } else if (quoteActionType === 'UPDATE') {
      quoteNextStatus = QuoteStatus.Updated;
    }
    setSendLoading(true);
    console.log('onSendQuoteText');
    trySendTextMessage({
      optimisticResponse: {
        threadSendTextMessage: {
          __typename: 'TextMessageEvent',
          content: quoteTextContent,
          createdAt: Date.now(),
          files: quoteTextFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempTextMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: matchId,
            orphanStr: null,
            supportChannelStr: null,
          })),
          id: 'tempTextMessageEventId',
          isTransactional: false,
          matchStr: matchId,
          orphanStr: null,
          ownerExpert: {
            ...expertDetails,
            id: expertId,
          },
          ownerHuman: null,
          quote: {
            __typename: 'Quote',
            cents,
            createdAt: Date.now(),
            description: quoteContent,
            estimatedCompletionDate:
              quotePaymentType === 'PROJECT' && quoteTimelineType === 'DATE'
                ? estimatedCompletionDate
                : null,
            estimatedCompletionDays:
              quotePaymentType === 'PROJECT' && quoteTimelineType === 'DAYS'
                ? estimatedCompletionDays
                : null,
            id: 'tempTextMessageQuoteId',
            matchStr: matchId,
            ownerExpert: {
              ...expertDetails,
              id: expertId,
            },
            ownerHuman: null,
            paymentType: quotePaymentType,
            revisionsRequested: null,
            status: quoteNextStatus,
            threadEvent: 'tempTextMessageQuoteThreadEventId',
            title: quoteTitle,
          },
          recipientExpert: null,
          recipientHumans: quoteTextRecipients,
          sendingError: null,
          supportChannelStr: null,
          threadEvent: 'tempTextMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentTextMutation }) => {
        if (
          dataSentTextMutation &&
          dataSentTextMutation.threadSendTextMessage
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempTextMessageThreadEventId' &&
              e.id !== dataSentTextMutation.threadSendTextMessage.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id === dataSentTextMutation.threadSendTextMessage.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor: dataSentTextMutation.threadSendTextMessage.createdAt,
              id: dataSentTextMutation.threadSendTextMessage.threadEvent,
              node: dataSentTextMutation.threadSendTextMessage,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          content: quoteTextContent,
          files: quoteTextFiles,
          matchId,
          ...(quoteActionType !== 'COMPLETE' && {
            quote: {
              cents,
              description: quoteContent,
              ...(quotePaymentType === 'PROJECT' &&
                quoteTimelineType === 'DATE' && { estimatedCompletionDate }),
              ...(quotePaymentType === 'PROJECT' &&
                quoteTimelineType === 'DAYS' && { estimatedCompletionDays }),
              paymentType: quotePaymentType,
              title: quoteTitle,
            },
          }),
          quoteAction: quoteActionType,
          quoteId: quoteActionQuote,
          recipientHuman: quoteTextRecipients[0].id,
        },
      },
    })
      .then(() => {
        onDeleteQuoteDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempTextMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Send Text Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'onSendQuoteText',
        });
      });
  }
  const [tryMeetingResponse] = useMutation<
    MeetingInviteResponseMatchMutation,
    MeetingInviteResponseMatchMutationVariables
  >(meetingInviteResponseMatchMutation);
  function tryInviteResponse(
    meeting: Extract<
      MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
      { __typename?: 'MeetingEvent' | undefined }
    >['meeting'],
    response: string,
  ) {
    if (!expertDetails) return;
    tryMeetingResponse({
      optimisticResponse: {
        meetingInviteResponse: {
          __typename: 'MeetingEvent',
          action: null,
          createdAt: Date.now(),
          id: 'tempMeetingEventId',
          matchStr: matchId,
          meeting: {
            ...meeting,
            expertResponse: response,
          },
          orphanStr: null,
          ownerExpert: {
            ...expertDetails,
            id: expertId,
          },
          ownerHuman: null,
          recipientExpert: null,
          recipientHumans: [
            {
              __typename: 'Human',
              firstName: '',
              id:
                (meeting.organizerHuman && meeting.organizerHuman.id) ||
                'tempMeetingEventHumanId',
              lastName: '',
              primaryEmail: '',
              primaryPhone: '',
            },
          ],
          response,
          supportChannelStr: null,
          threadEvent: 'tempMeetingThreadEventId',
        },
      },
      update: (_cache, { data: dataMeetingResponseMutation }) => {
        if (
          dataMeetingResponseMutation &&
          dataMeetingResponseMutation.meetingInviteResponse
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempMeetingThreadEventId' &&
              e.id !==
                dataMeetingResponseMutation.meetingInviteResponse.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataMeetingResponseMutation.meetingInviteResponse.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor:
                dataMeetingResponseMutation.meetingInviteResponse.createdAt,
              id: dataMeetingResponseMutation.meetingInviteResponse.threadEvent,
              node: dataMeetingResponseMutation.meetingInviteResponse,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        meetingId: meeting.id,
        response,
      },
    }).catch((err: ApolloError) => {
      setTempEdges(
        tempEdges.filter((e) => e.id !== 'tempMeetingThreadEventId'),
      );
      addNotification(
        errorUtils.getErrorMessage(err) || 'Meeting Response Error',
      );
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'tryMeetingResponse',
      });
    });
  }
  function startAllClientsMove() {
    setAllClientsMoveShow(true);
    setShowSidebar(false);
  }
  function cancelAllClientsMove() {
    setAllClientsMoveShow(false);
  }
  const [tryArchiveOldLead] = useMutation<
    MatchArchiveOldLeadMutation,
    MatchArchiveOldLeadMutationVariables
  >(matchArchiveOldLeadMutation);
  function archiveOldLead() {
    if (modalLoading || !allClientsMoveShow) {
      return;
    }
    if (!matchDetails) {
      addNotification(
        'An error occured. Please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    if (!allClientsMoveReason) {
      addNotification('Please pick a reason.', undefined, 5000);
      return;
    }
    setModalLoading(true);
    tryArchiveOldLead({
      optimisticResponse: {
        matchArchiveOldLead: {
          ...matchDetails,
          expertUnread: null,
          isLead: null,
          leadRequest: null,
        },
      },
      variables: {
        matchId,
        moreDetail: allClientsMoveMoreDetail,
        reason: allClientsMoveReason,
      },
    })
      .then(() => {
        setAllClientsMoveShow(false);
        setModalLoading(false);
        setAllClientsMoveMoreDetail('');
        setAllClientsMoveReason('');
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Move All CLients Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryArchiveOldLead',
        });
      });
  }
  const [tryExpertSearch] = useMutation<
    ExpertSearchForExpertMutation,
    ExpertSearchForExpertMutationVariables
  >(expertSearchForExpertMutation);
  function onSearchExpertStart() {
    console.log('onSearchExpertStart', searchExpertInput);
    if (!searchExpertInput || searchExpertInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    tryExpertSearch({
      update: (_cache, { data: dataExpertSearch }) => {
        if (dataExpertSearch && dataExpertSearch.expertSearchForExpert) {
          console.log(
            'tryExpertSearch',
            dataExpertSearch.expertSearchForExpert,
          );
          setSearchExpertResults(dataExpertSearch.expertSearchForExpert);
        }
      },
      variables: {
        searchQuery: searchExpertInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Expert Search Error');
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'tryExpertSearch',
      });
      setSearchExpertResults(null);
    });
  }
  function handleSearchExpertInputKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchExpertStart();
    }
  }
  const [tryShareClient] = useMutation<
    ExpertShareClientMutation,
    ExpertShareClientMutationVariables
  >(expertShareClientMutation);
  function doShareClient(
    whichExpert: ExpertSearchForExpertMutation['expertSearchForExpert'][0],
  ) {
    tryShareClient({
      variables: {
        expertId: whichExpert.id,
        matchId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Share Client Error');
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'tryShareClient',
      });
    });
    addNotification(`Client shared with ${whichExpert.firstName}`, 'GOOD');
    setSearchExpertInput('');
    setSearchExpertResults(null);
  }
  function startAnotherAnytime() {
    setAnotherAnytimeShow(true);
    setShowSidebar(false);
  }
  function cancelAnotherAnytime() {
    setAnotherAnytimeShow(false);
  }
  const [tryAnotherExpert] = useMutation<
    MatchAnotherExpertMutation,
    MatchAnotherExpertMutationVariables
  >(matchAnotherExpertMutation);
  function anotherAnytimeSubmit() {
    if (modalLoading || !anotherAnytimeShow) {
      return;
    }
    if (!matchDetails || matchDetails.isLead) {
      addNotification(
        'An error occured. Please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    if (!anotherAnytimeReason || !anotherAnytimeMoreDetail) {
      addNotification('Please fill out both fields.', undefined, 5000);
      return;
    }
    setModalLoading(true);
    tryAnotherExpert({
      variables: {
        matchId,
        moreDetail: anotherAnytimeMoreDetail,
        reason: anotherAnytimeReason,
      },
    })
      .then(() => {
        setAnotherAnytimeShow(false);
        setModalLoading(false);
        setAnotherAnytimeMoreDetail('');
        setAnotherAnytimeReason('');
        addNotification(
          'The Marketplace Success team has been notified. Thank you!',
          'GOOD',
          2000,
        );
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Another Expert Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryAnotherExpert',
        });
      });
  }
  const [tryUnmatchRequest] = useMutation<
    RequestUnmatchByExpertMutation,
    RequestUnmatchByExpertMutationVariables
  >(requestUnmatchByExpertMutation);
  function startCancelFlow() {
    setUnclaimShow('CANCEL');
    setShowSidebar(false);
    setLeadExpanded(false);
  }
  function startUnclaimFlow() {
    setUnclaimShow('SWITCH');
    setShowSidebar(false);
    setLeadExpanded(false);
  }
  function cancelUnclaimFlow() {
    setUnclaimShow('');
  }
  function onUnclaim(reason: string, isCancel: boolean, moreDetail?: string) {
    if (modalLoading || unclaimSuccess || !unclaimShow) {
      return;
    }
    if (!matchDetails || !matchDetails.leadRequest) {
      addNotification(
        'An error occured. Please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    setModalLoading(true);
    console.log('onUnclaim', reason, isCancel, moreDetail);
    tryUnmatchRequest({
      optimisticResponse: {
        requestUnmatchByExpert: {
          ...matchDetails,
          expertUnread: null,
          isBlocked: new Date().getTime(), // TODO-sam not if cancel
          wasBlocked: true,
        },
      },
      variables: {
        isCancel,
        moreDetail,
        reason,
        requestId: matchDetails.leadRequest.id,
      },
    })
      .then(() => {
        setUnclaimSuccess(true);
        setModalLoading(false);
        setUnclaimShow('');
        history.push('/clients');
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Unclaim Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryUnmatchRequest',
        });
      });
  }
  const [tryProjectCancel] = useMutation<
    ProjectCancelMutation,
    ProjectCancelMutationVariables
  >(projectCancelMutation);
  function startCancelProjectFlow(
    project: MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0],
  ) {
    setCancelProjectShow(project);
    setShowSidebar(false);
    setProjectExpanded('');
  }
  function closeCancelProjectFlow() {
    setCancelProjectShow(null);
  }
  function cancelQuote(cancelReason: string, hasPaid: boolean) {
    if (modalLoading || !cancelProjectShow) {
      return;
    }
    if (!expertDetails || !matchDetails || !matchDetails.brand) {
      addNotification(
        'An error occured. Please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    setModalLoading(true);
    tryProjectCancel({
      optimisticResponse: {
        projectCancel: {
          ...cancelProjectShow,
          brandStr: matchDetails.brand.id,
          createdAt: 0,
          expert: {
            ...expertDetails,
          },
          expertStr: expertDetails.id,
          isActive: false,
          matchStr: matchId,
          quote: {
            ...cancelProjectShow.quote,
            status: QuoteStatus.Canceled,
          },
          quoteStr: cancelProjectShow.quote.id,
        },
      },
      variables: {
        cancelReason,
        matchId,
        quoteId: cancelProjectShow.quote.id,
      },
    })
      .then(() => {
        setModalLoading(false);
        setCancelProjectShow(null);
        if (hasPaid && threadEventEdges[threadEventEdges.length - 1]) {
          onReplyClick(threadEventEdges[threadEventEdges.length - 1], true);
        }
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Cancel Project Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryProjectCancel',
        });
      });
  }
  const [tryCancelMeeting] = useMutation<
    MeetingInviteCancelMatchMutation,
    MeetingInviteCancelMatchMutationVariables
  >(meetingInviteCancelMatchMutation);
  function startCancelMeetingFlow(
    meeting: MeetingActiveForMatchQuery['meetingActiveForMatch'][0],
  ) {
    setCancelMeetingShow(meeting);
    setShowSidebar(false);
    setMeetingExpanded('');
  }
  function closeCancelMeetingFlow() {
    setCancelMeetingShow(null);
  }
  function cancelMeeting() {
    if (modalLoading || !cancelMeetingShow || !expertDetails) {
      return;
    }
    onDeleteMeetingDraft();
    setModalLoading(true);
    tryCancelMeeting({
      optimisticResponse: {
        meetingInviteCancel: {
          __typename: 'MeetingEvent',
          action: 'CANCEL',
          createdAt: Date.now(),
          id: 'tempMeetingEventId',
          matchStr: matchId,
          meeting: {
            ...cancelMeetingShow,
            eventStatus: 'CANCELLED',
          },
          orphanStr: null,
          ownerExpert: {
            ...expertDetails,
            id: expertId,
          },
          ownerHuman: null,
          recipientExpert: null,
          recipientHumans: meetingRecipients.map((r) => ({
            __typename: 'Human',
            firstName: '',
            id: r.id,
            lastName: '',
            primaryEmail: '',
            primaryPhone: '',
          })),
          response: null,
          supportChannelStr: null,
          threadEvent: 'tempMeetingThreadEventId',
        },
      },
      update: (_cache, { data: dataMeetingCancelMutation }) => {
        if (
          dataMeetingCancelMutation &&
          dataMeetingCancelMutation.meetingInviteCancel
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempMeetingThreadEventId' &&
              e.id !==
                dataMeetingCancelMutation.meetingInviteCancel.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataMeetingCancelMutation.meetingInviteCancel.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor: dataMeetingCancelMutation.meetingInviteCancel.createdAt,
              id: dataMeetingCancelMutation.meetingInviteCancel.threadEvent,
              node: dataMeetingCancelMutation.meetingInviteCancel,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        meetingId: cancelMeetingShow.id,
      },
    })
      .then(() => {
        setModalLoading(false);
        setCancelMeetingShow(null);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempMeetingThreadEventId'),
        );
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Meeting Cancel Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryCancelMeeting',
        });
      });
  }
  function startViewProjectFlow(
    project: MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0],
  ) {
    setViewProjectShow(project);
    setMarkCompleteChecklistShow(null);
    setShowSidebar(false);
    setProjectExpanded('');
  }
  function closeViewProjectFlow() {
    setViewProjectShow(null);
  }
  let nextPaymentStr = '';
  if (
    viewProjectShow &&
    viewProjectShow.quote &&
    (viewProjectShow.quote.status === QuoteStatus.SubscriptionPaymentMade ||
      viewProjectShow.quote.status === QuoteStatus.Accepted) &&
    (viewProjectShow.quote.paymentType === 'WEEKLY_SUBSCRIPTION' ||
      viewProjectShow.quote.paymentType === 'MONTHLY_SUBSCRIPTION')
  ) {
    nextPaymentStr = getNextPaymentDate(
      new Date(viewProjectShow.quote.createdAt),
      viewProjectShow.quote.paymentType,
    );
  }
  const [tryProjectUndoMarkComplete] = useMutation<
    ProjectUndoMarkCompleteMutation,
    ProjectUndoMarkCompleteMutationVariables
  >(projectUndoMarkCompleteMutation);
  function startUndoProjectFlow(
    project: MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0],
  ) {
    setUndoProjectShow(project);
    setShowSidebar(false);
    setProjectExpanded('');
  }
  function closeUndoProjectFlow() {
    setUndoProjectShow(null);
  }
  function undoMarkCompleteQuote() {
    if (modalLoading || !undoProjectShow) {
      return;
    }
    if (!expertDetails || !matchDetails || !matchDetails.brand) {
      addNotification(
        'An error occured. Please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    setModalLoading(true);
    tryProjectUndoMarkComplete({
      optimisticResponse: {
        projectUndoMarkComplete: {
          ...undoProjectShow,
          brandStr: matchDetails.brand.id,
          createdAt: 0,
          expert: {
            ...expertDetails,
          },
          expertStr: expertDetails.id,
          isActive: false,
          matchStr: matchId,
          quote: {
            ...undoProjectShow.quote,
            status: QuoteStatus.InProgress,
          },
          quoteStr: undoProjectShow.quote.id,
        },
      },
      variables: {
        matchId,
        quoteId: undoProjectShow.quote.id,
      },
    })
      .then(() => {
        setModalLoading(false);
        setUndoProjectShow(null);
        if (threadEventEdges[threadEventEdges.length - 1]) {
          onReplyClick(threadEventEdges[threadEventEdges.length - 1]);
        }
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Cancel Project Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryProjectUndoMarkComplete',
        });
      });
  }
  function startExistingHumanFlow(humanId: string, humanName: string) {
    setExistingHumanId(humanId);
    setExistingHumanName(humanName);
    setShowSidebar(false);
  }
  function closeExistingHumanFlow() {
    setExistingHumanId(null);
    setExistingHumanName(null);
  }
  const [tryAddHuman] = useMutation<
    BrandAddHumanMutation,
    BrandAddHumanMutationVariables
  >(brandAddHumanMutation);
  function onAddHuman(
    human: Exclude<
      Extract<
        MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
        { __typename?: 'EmailMessageEvent' | undefined }
      >['ownerHuman'],
      null | undefined
    >,
  ) {
    if (!matchDetails || !matchDetails.brand || !matchDetails.brand.id) {
      return;
    }
    tryAddHuman({
      optimisticResponse: {
        brandAddHuman: {
          ...matchDetails.brand,
          team: brandTeam.concat([
            {
              __typename: 'Human',
              clientFee: '',
              discountCode: '',
              estimateSpend: 0,
              expertReferralStr: null,
              firstName: human.firstName || '',
              id: human.id,
              lastName: human.lastName || '',
              location: null,
              partnerReferral: null,
              primaryEmail: human.primaryEmail || '',
              primaryPhone: human.primaryPhone || '',
              proStart: null,
              secondaryEmails: human.primaryEmail ? [human.primaryEmail] : [],
            },
          ]),
        },
      },
      variables: {
        brandId: matchDetails.brand.id,
        humanId: human.id,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Brand Add Error');
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'tryAddHuman',
      });
    });
    setPrimaryPanel('About');
  }
  function addExistingToTeam() {
    if (
      !matchDetails ||
      !matchDetails.brand ||
      !matchDetails.brand.id ||
      !existingHumanId
    ) {
      return;
    }
    setModalLoading(true);
    tryAddHuman({
      optimisticResponse: {
        brandAddHuman: {
          ...matchDetails.brand,
          team: brandTeam.concat([
            {
              __typename: 'Human',
              clientFee: '',
              discountCode: '',
              estimateSpend: 0,
              expertReferralStr: null,
              firstName: existingHumanName || '',
              id: existingHumanId,
              lastName: '',
              location: null,
              partnerReferral: null,
              primaryEmail: '',
              primaryPhone: '',
              proStart: null,
              secondaryEmails: [],
            },
          ]),
        },
      },
      variables: {
        brandId: matchDetails.brand.id,
        humanId: existingHumanId,
      },
    })
      .then(() => {
        setModalLoading(false);
        setExistingHumanId(null);
        setExistingHumanName(null);
        setShowSidebar(true);
        setPrimaryPanel('About');
        setHumanEditing('');
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Brand Add Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryAddHuman',
        });
      });
  }
  const [tryIgnoreHuman] = useMutation<
    BrandIgnoreHumanMutation,
    BrandIgnoreHumanMutationVariables
  >(brandIgnoreHumanMutation);
  function onIgnoreHuman(humanId: string) {
    if (!matchDetails || !matchDetails.brand || !matchDetails.brand.id) {
      return;
    }
    tryIgnoreHuman({
      optimisticResponse: {
        brandIgnoreHuman: {
          ...matchDetails.brand,
          ignoreHumansStr: (matchDetails.brand.ignoreHumansStr || []).concat(
            humanId,
          ),
        },
      },
      variables: {
        brandId: matchDetails.brand.id,
        humanId,
        isUndo: false,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Brand Ignore Error');
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'tryIgnoreHuman',
      });
    });
    setPrimaryPanel('About');
    setIgnoreShow(true);
  }
  const [tryExpertPinned] = useMutation<
    MatchExpertPinnedMutation,
    MatchExpertPinnedMutationVariables
  >(matchExpertPinnedMutation);
  function onPinToggle(doPin: boolean) {
    if (!matchDetails) {
      return;
    }
    alertUtils.removeUnread(matchId);
    tryExpertPinned({
      optimisticResponse: {
        matchExpertPinned: {
          ...matchDetails,
          expertPinned: doPin,
        },
      },
      variables: {
        doPin,
        matchId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Pin Error');
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'expertPinned',
      });
    });
  }
  const [tryExpertTagged] = useMutation<
    MatchExpertTaggedMutation,
    MatchExpertTaggedMutationVariables
  >(matchExpertTaggedMutation);
  function onTagged(tagged: string) {
    if (!matchDetails) {
      return;
    }
    alertUtils.removeUnread(matchId);
    tryExpertTagged({
      optimisticResponse: {
        matchExpertTagged: {
          ...matchDetails,
          expertTagged: tagged,
        },
      },
      variables: {
        matchId,
        tagged,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Tag Error');
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'expertTagged',
      });
    });
  }
  function onUndoIgnoreHuman(humanId: string) {
    if (!matchDetails || !matchDetails.brand || !matchDetails.brand.id) {
      return;
    }
    tryIgnoreHuman({
      optimisticResponse: {
        brandIgnoreHuman: {
          ...matchDetails.brand,
          ignoreHumansStr: (matchDetails.brand.ignoreHumansStr || []).filter(
            (hId) => hId !== humanId,
          ),
        },
      },
      variables: {
        brandId: matchDetails.brand.id,
        humanId,
        isUndo: true,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Brand Ignore Error');
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'tryIgnoreHuman - undo',
      });
    });
    setIgnoreShow(false);
  }
  const [tryMergeHuman] = useMutation<
    BrandMergeHumanMutation,
    BrandMergeHumanMutationVariables
  >(brandMergeHumanMutation);
  function onMergeHuman(parentHumanId: string, childHumanId: string) {
    if (!matchDetails || !matchDetails.brand || !matchDetails.brand.id) {
      return;
    }
    tryMergeHuman({
      variables: {
        brandId: matchDetails.brand.id,
        childHumanId,
        parentHumanId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Brand Merge Error');
      setTempTeamIgnores(tempTeamIgnores.filter((ig) => ig !== childHumanId));
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'tryMergeHuman',
      });
    });
    setTempTeamIgnores(tempTeamIgnores.concat(childHumanId));
    setPrimaryPanel('About');
  }
  const [tryUnthreadEmail] = useMutation<
    OrphanUnthreadEmailFromMatchMutation,
    OrphanUnthreadEmailFromMatchMutationVariables
  >(orphanUnthreadEmailFromMatchMutation);
  function onUnthreadEmail(emailMessageEventId: string) {
    if (unthreadLoading || !dataThread) {
      return;
    }
    setUnthreadLoading(true);
    tryUnthreadEmail({
      update: (cache) => {
        cache.writeFragment({
          data: {
            ...dataThread.threadPaginated,
            edges: dataThread.threadPaginated.edges.filter(
              (e) => e.node.id !== emailMessageEventId,
            ),
          },
          fragment: gql`
            fragment UnthreadEmail on Thread {
              id
              edges {
                id
                cursor
                node {
                  ... on TextMessageEvent {
                    ...TextMessageEventDetail
                  }
                  ... on EmailMessageEvent {
                    ...EmailMessageEventDetail
                  }
                  ... on RequestEvent {
                    ...RequestEventDetail
                  }
                  ... on MessageEvent {
                    ...MessageEventDetail
                  }
                  ... on MeetingEvent {
                    ...MeetingEventDetail
                  }
                  ... on Quote {
                    ...QuoteDetail
                  }
                  ... on PhoneCallEvent {
                    ...PhoneCallEventDetail
                  }
                }
              }
            }
            ${TextMessageEventDetail}
            ${EmailMessageEventDetail}
            ${RequestEventDetail}
            ${MessageEventDetail}
            ${MeetingEventDetail}
            ${QuoteDetail}
            ${PhoneCallEventDetail}
          `,
          fragmentName: 'UnthreadEmail',
          id: '',
        });
      },
      variables: {
        emailMessageEventId,
        matchId,
      },
    })
      .then(({ data: mutationData }) => {
        setUnthreadLoading(false);
        if (mutationData && mutationData.orphanUnthreadEmailFromMatch) {
          history.push(
            '/inbound/' + mutationData.orphanUnthreadEmailFromMatch.id,
          );
        }
      })
      .catch((err: ApolloError) => {
        setUnthreadLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Unthread Email Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryUnthreadEmail',
        });
      });
  }
  const [tryUnthreadText] = useMutation<
    OrphanUnthreadTextFromMatchMutation,
    OrphanUnthreadTextFromMatchMutationVariables
  >(orphanUnthreadTextFromMatchMutation);
  function onUnthreadText(textMessageEventId: string) {
    if (unthreadLoading || !dataThread) {
      return;
    }
    setUnthreadLoading(true);
    tryUnthreadText({
      update: (cache) => {
        cache.writeFragment({
          data: {
            ...dataThread.threadPaginated,
            edges: dataThread.threadPaginated.edges.filter(
              (e) => e.node.id !== textMessageEventId,
            ),
          },
          fragment: gql`
            fragment UnthreadText on Thread {
              id
              edges {
                id
                cursor
                node {
                  ... on TextMessageEvent {
                    ...TextMessageEventDetail
                  }
                  ... on EmailMessageEvent {
                    ...EmailMessageEventDetail
                  }
                  ... on RequestEvent {
                    ...RequestEventDetail
                  }
                  ... on MessageEvent {
                    ...MessageEventDetail
                  }
                  ... on MeetingEvent {
                    ...MeetingEventDetail
                  }
                  ... on Quote {
                    ...QuoteDetail
                  }
                  ... on PhoneCallEvent {
                    ...PhoneCallEventDetail
                  }
                }
              }
            }
            ${TextMessageEventDetail}
            ${EmailMessageEventDetail}
            ${RequestEventDetail}
            ${MessageEventDetail}
            ${MeetingEventDetail}
            ${QuoteDetail}
            ${PhoneCallEventDetail}
          `,
          fragmentName: 'UnthreadText',
          id: '',
        });
      },
      variables: {
        matchId,
        textMessageEventId,
      },
    })
      .then(({ data: mutationData }) => {
        setUnthreadLoading(false);
        if (mutationData && mutationData.orphanUnthreadTextFromMatch) {
          history.push(
            '/inbound/' + mutationData.orphanUnthreadTextFromMatch.id,
          );
        }
      })
      .catch((err: ApolloError) => {
        setUnthreadLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Unthread Text Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryUnthreadText',
        });
      });
  }
  const [tryUnthreadPhoneCall] = useMutation<
    OrphanUnthreadPhoneCallFromMatchMutation,
    OrphanUnthreadPhoneCallFromMatchMutationVariables
  >(orphanUnthreadPhoneCallFromMatchMutation);
  function onUnthreadPhoneCall(phoneCallEventId: string) {
    if (unthreadLoading || !dataThread) {
      return;
    }
    setUnthreadLoading(true);
    tryUnthreadPhoneCall({
      update: (cache) => {
        cache.writeFragment({
          data: {
            ...dataThread.threadPaginated,
            edges: dataThread.threadPaginated.edges.filter(
              (e) => e.node.id !== phoneCallEventId,
            ),
          },
          fragment: gql`
            fragment UnthreadCall on Thread {
              id
              edges {
                id
                cursor
                node {
                  ... on TextMessageEvent {
                    ...TextMessageEventDetail
                  }
                  ... on EmailMessageEvent {
                    ...EmailMessageEventDetail
                  }
                  ... on RequestEvent {
                    ...RequestEventDetail
                  }
                  ... on MessageEvent {
                    ...MessageEventDetail
                  }
                  ... on MeetingEvent {
                    ...MeetingEventDetail
                  }
                  ... on Quote {
                    ...QuoteDetail
                  }
                  ... on PhoneCallEvent {
                    ...PhoneCallEventDetail
                  }
                }
              }
            }
            ${TextMessageEventDetail}
            ${EmailMessageEventDetail}
            ${RequestEventDetail}
            ${MessageEventDetail}
            ${MeetingEventDetail}
            ${QuoteDetail}
            ${PhoneCallEventDetail}
          `,
          fragmentName: 'UnthreadCall',
          id: '',
        });
      },
      variables: {
        matchId,
        phoneCallEventId,
      },
    })
      .then(({ data: mutationData }) => {
        setUnthreadLoading(false);
        if (mutationData && mutationData.orphanUnthreadPhoneCallFromMatch) {
          history.push(
            '/inbound/' + mutationData.orphanUnthreadPhoneCallFromMatch.id,
          );
        }
      })
      .catch((err: ApolloError) => {
        setUnthreadLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Unthread Phone Call Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryUnthreadPhoneCall',
        });
      });
  }
  const [tryUnthreadMeeting] = useMutation<
    OrphanUnthreadMeetingFromMatchMutation,
    OrphanUnthreadMeetingFromMatchMutationVariables
  >(orphanUnthreadMeetingFromMatchMutation);
  function onUnthreadMeeting(meetingEventId: string) {
    if (unthreadLoading || !dataThread) {
      return;
    }
    setUnthreadLoading(true);
    tryUnthreadMeeting({
      update: (cache) => {
        cache.writeFragment({
          data: {
            ...dataThread.threadPaginated,
            edges: dataThread.threadPaginated.edges.filter(
              (e) => e.node.id !== meetingEventId,
            ),
          },
          fragment: gql`
            fragment UnthreadMeeting on Thread {
              id
              edges {
                id
                cursor
                node {
                  ... on TextMessageEvent {
                    ...TextMessageEventDetail
                  }
                  ... on EmailMessageEvent {
                    ...EmailMessageEventDetail
                  }
                  ... on RequestEvent {
                    ...RequestEventDetail
                  }
                  ... on MessageEvent {
                    ...MessageEventDetail
                  }
                  ... on MeetingEvent {
                    ...MeetingEventDetail
                  }
                  ... on Quote {
                    ...QuoteDetail
                  }
                  ... on PhoneCallEvent {
                    ...PhoneCallEventDetail
                  }
                }
              }
            }
            ${TextMessageEventDetail}
            ${EmailMessageEventDetail}
            ${RequestEventDetail}
            ${MessageEventDetail}
            ${MeetingEventDetail}
            ${QuoteDetail}
            ${PhoneCallEventDetail}
          `,
          fragmentName: 'UnthreadMeeting',
          id: '',
        });
      },
      variables: {
        matchId,
        meetingEventId,
      },
    })
      .then(({ data: mutationData }) => {
        setUnthreadLoading(false);
        if (mutationData && mutationData.orphanUnthreadMeetingFromMatch) {
          history.push(
            '/inbound/' + mutationData.orphanUnthreadMeetingFromMatch.id,
          );
        }
      })
      .catch((err: ApolloError) => {
        setUnthreadLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Unthread Meeting Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryUnthreadMeeting',
        });
      });
  }
  function onReplyClick(
    edge: MatchThreadPaginatedQuery['threadPaginated']['edges'][0],
    hasPaid?: boolean,
  ) {
    console.log('onReplyClick', edge.id);
    if (edge.node.__typename === 'EmailMessageEvent') {
      const newSubject = replyToSubject(edge.node.subject);
      const recipients = [...edge.node.recipientHumans];
      if (edge.node.ownerHuman) {
        recipients.push(edge.node.ownerHuman);
      }
      openEmailEditor(
        newSubject,
        recipients.map((r) => r.id),
      );
    } else if (edge.node.__typename === 'MeetingEvent') {
      const newSubject = replyToSubject(
        edge.node.meeting && edge.node.meeting.eventTitle,
      );
      const recipients = [...edge.node.recipientHumans];
      if (edge.node.ownerHuman) {
        recipients.push(edge.node.ownerHuman);
      }
      openEmailEditor(
        newSubject,
        recipients.map((r) => r.id),
      );
    } else if (edge.node.__typename === 'TextMessageEvent') {
      const recipients = [...edge.node.recipientHumans];
      if (edge.node.ownerHuman) {
        recipients.push(edge.node.ownerHuman);
      }
      openTextEditor(recipients.map((r) => r.id));
    } else if (edge.node.__typename === 'PhoneCallEvent') {
      const recipients = [...edge.node.recipientHumans];
      if (edge.node.ownerHuman) {
        recipients.push(edge.node.ownerHuman);
      }
      openTextEditor(recipients.map((r) => r.id));
    } else if (edge.node.__typename === 'RequestEvent') {
      if (!edge.node.ownerHuman) {
        openEmailEditor();
      } else if (edge.node.ownerHuman.primaryPhone) {
        openTextEditor([edge.node.ownerHuman.id]);
      } else {
        openEmailEditor(undefined, [edge.node.ownerHuman.id]);
      }
    } else if (edge.node.__typename === 'Quote') {
      const newSubject = replyToSubject(edge.node.title);
      if (!edge.node.ownerHuman) {
        openEmailEditor(
          newSubject,
          emailBrandTeam[0] ? [emailBrandTeam[0].id] : undefined,
          'QUOTE_' + edge.node.status + (hasPaid ? '_PAID' : ''),
        );
      } else if (edge.node.ownerHuman.primaryPhone) {
        openTextEditor(
          [edge.node.ownerHuman.id],
          'QUOTE_' + edge.node.status + (hasPaid ? '_PAID' : ''),
        );
      } else {
        openEmailEditor(
          newSubject,
          [edge.node.ownerHuman.id],
          'QUOTE_' + edge.node.status + (hasPaid ? '_PAID' : ''),
        );
      }
    } else if (edge.node.__typename === 'MessageEvent') {
      const recipient =
        (edge.node.ownerHuman && edge.node.ownerHuman.id) ||
        (emailBrandTeam[0] && emailBrandTeam[0].id);
      openEmailEditor(undefined, recipient ? [recipient] : undefined);
    } else {
      scrollDown();
    }
  }
  function groupThreadEvents(
    events: MatchThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    const groupedEvents: IGroupedEvents[] = [];
    events.forEach((ev) => {
      let isSameEventType = false;
      let isSameSenderSide = false;
      let isSameHuman = false;
      let isCurrGroupable = false;
      let isLastGroupable = false;
      const isFirstGroup = !groupedEvents.length;
      if (
        !isFirstGroup &&
        (ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'MessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent')
      ) {
        isCurrGroupable = true;
        isLastGroupable = true;
        const lastGroup = groupedEvents[groupedEvents.length - 1];
        const lastGroupEvent = lastGroup.group[0];
        // is same event type as last
        if (lastGroupEvent.node.__typename === ev.node.__typename) {
          isSameEventType = true;
          // is both sent from expert or both from Human
          isSameSenderSide =
            !!(ev.node.ownerHuman && lastGroupEvent.node.ownerHuman) ||
            !!(ev.node.ownerExpert && lastGroupEvent.node.ownerExpert);
          // is human interacting same
          isSameHuman =
            (ev.node.ownerHuman &&
              lastGroupEvent.node.ownerHuman &&
              ev.node.ownerHuman.id === lastGroupEvent.node.ownerHuman.id) ||
            (ev.node.recipientHumans[0] &&
              lastGroupEvent.node.recipientHumans[0] &&
              ev.node.recipientHumans[0].id ===
                lastGroupEvent.node.recipientHumans[0].id);
          // don't group texts with quotes attached
          if (ev.node.__typename === 'TextMessageEvent') {
            if (ev.node.quote) {
              isCurrGroupable = false;
            }
          }
          if (lastGroupEvent.node.__typename === 'TextMessageEvent') {
            if (lastGroupEvent.node.quote) {
              isLastGroupable = false;
            }
          }
        }
      }
      if (
        isFirstGroup ||
        !isCurrGroupable ||
        !isLastGroupable ||
        !isSameEventType ||
        !isSameSenderSide ||
        !isSameHuman
      ) {
        groupedEvents.push({
          group: [ev],
        });
      } else {
        groupedEvents[groupedEvents.length - 1].group.push(ev);
      }
    });
    return groupedEvents;
  }
  const totalEdges = cleanThread.concat(tempEdges);
  const overPageLimit = totalEdges.length >= PAGE_LIMIT;
  const threadEventEdges = _.uniqBy(totalEdges, 'id')
    .sort((a, b) => a.cursor - b.cursor)
    .filter((ev) => {
      if (ev.node.__typename === 'Quote') {
        // filter out quote accepts on subscriptions
        return (
          ev.node.paymentType === 'PROJECT' ||
          ev.node.paymentType === 'BILL' ||
          ev.node.status !== QuoteStatus.Accepted
        );
      }
      return true;
    });
  const unknownBrandMembers: { [key: string]: string } = {};
  if (
    matchDetails &&
    matchDetails.brand &&
    matchDetails.brand.ignoreHumansStr &&
    brandTeam.length
  ) {
    threadEventEdges.forEach((ev) => {
      if (
        ev.node.ownerHuman &&
        (ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent' ||
          ev.node.__typename === 'MeetingEvent' ||
          ev.node.__typename === 'EmailMessageEvent')
      ) {
        const unknownHumanIds = [];
        if (ev.node.ownerHuman) {
          unknownHumanIds.push(ev.node.ownerHuman.id.toString());
        }
        if (ev.node.recipientHumans) {
          ev.node.recipientHumans.forEach((h) =>
            unknownHumanIds.push(h.id.toString()),
          );
        }
        unknownHumanIds
          .filter(
            (hId) =>
              hId &&
              matchDetails.brand.ignoreHumansStr.indexOf(hId) === -1 &&
              tempTeamIgnores.indexOf(hId) === -1 &&
              !brandTeam.find((t) => t.id === hId),
          )
          .forEach((hId) => {
            unknownBrandMembers[hId] = ev.id;
          });
      }
    });
  }
  const threadEventsForUnknowns: { [key: string]: string[] } = {};
  Object.keys(unknownBrandMembers).forEach((hId) => {
    const threadEventKey = unknownBrandMembers[hId];
    threadEventsForUnknowns[threadEventKey] = threadEventsForUnknowns[
      threadEventKey
    ]
      ? threadEventsForUnknowns[threadEventKey].concat(hId)
      : [hId];
  });
  function scrollDown() {
    scrollDownQuickTimeout = setTimeout(() => {
      if (threadEventsWindow && threadEventsWindow.current) {
        threadEventsWindow.current.scrollTop =
          threadEventsWindow.current.scrollHeight;
      }
    }, 5);
  }
  useEffect(() => {
    function onScroll() {
      if (threadEventsWindow && threadEventsWindow.current) {
        const scrollTemp = threadEventsWindow.current.scrollTop;
        if (
          scrollTemp < 500 &&
          overPageLimit &&
          canLoadMore &&
          !isLoadingEvents
        ) {
          isLoadingEvents = true;
          const heightTemp = threadEventsWindow.current.scrollHeight;
          fetchMoreThread({
            updateQuery: (prev, { fetchMoreResult }) => {
              if (
                !fetchMoreResult ||
                !prev.threadPaginated ||
                !fetchMoreResult.threadPaginated
              )
                return prev;
              if (fetchMoreResult.threadPaginated.edges.length < PAGE_LIMIT) {
                setCanLoadMore(false);
              }
              return {
                threadPaginated: {
                  __typename: prev.threadPaginated.__typename,
                  edges: _.uniqBy(
                    fetchMoreResult.threadPaginated.edges.concat(
                      prev.threadPaginated.edges || [],
                    ),
                    'id',
                  ),
                  id: prev.threadPaginated.id,
                },
              };
            },
            variables: {
              direction: 'before',
              fromDate: threadEventEdges.filter(
                (ev) => ev.node.id && remoteEventIds.indexOf(ev.node.id) === -1,
              )[0].cursor,
              limit: PAGE_LIMIT,
              matchId,
            },
          })
            .catch((err: ApolloError) => {
              addNotification(
                errorUtils.getErrorMessage(err) || 'Load More Error',
              );
              logError(err, {
                component: 'ExpertClientDetail',
                func: 'fetchMoreThread',
              });
            })
            .finally(() => {
              isLoadingEvents = false;
              if (threadEventsWindow && threadEventsWindow.current) {
                threadEventsWindow.current.scrollTop =
                  Math.max(
                    threadEventsWindow.current.scrollHeight - heightTemp,
                    0,
                  ) + scrollTemp;
              }
            });
        }
      }
    }
    if (threadEventsWindow && threadEventsWindow.current) {
      threadEventsWindow.current.addEventListener('scroll', onScroll);
    }
    const tempWindow = threadEventsWindow.current;
    return () => {
      if (tempWindow) {
        tempWindow.removeEventListener('scroll', onScroll);
      }
    };
  }, [
    canLoadMore,
    overPageLimit,
    addNotification,
    fetchMoreThread,
    matchId,
    threadEventEdges,
    remoteEventIds,
  ]);
  const hasDiscountCode =
    matchDetails && matchDetails.brand && matchDetails.brand.teamLeader
      ? validDiscountCode(matchDetails.brand.teamLeader.discountCode)
      : '';
  const hasClientFee =
    matchDetails &&
    matchDetails.brand &&
    matchDetails.brand.teamLeader &&
    !matchDetails.brand.teamLeader.proStart &&
    !hasDiscountCode
      ? validClientFee(matchDetails.brand.teamLeader.clientFee)
      : '';
  useEffect(() => {
    scrollDown();
    const scrollDownEffectTimeout = setTimeout(() => {
      scrollDown();
    }, 500);
    return () => {
      if (scrollDownEffectTimeout) {
        clearTimeout(scrollDownEffectTimeout);
      }
      if (scrollDownQuickTimeout) {
        clearTimeout(scrollDownQuickTimeout);
      }
    };
  }, [matchId]);
  useEffect(() => {
    const loadTime = new Date();
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('ExpertClientDetail socketClient onReconnected');
      refetchMatch().catch(() => {});
      refetchMeetingActive().catch(() => {});
      fetchMoreThread({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !prev.threadPaginated ||
            !fetchMoreResult.threadPaginated
          )
            return prev;
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: _.uniqBy(
                fetchMoreResult.threadPaginated.edges.concat(
                  prev.threadPaginated.edges || [],
                ),
                'id',
              ),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          direction: 'after',
          fromDate: loadTime.getTime(),
          limit: 50,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'reconnect fetchMoreThread',
        });
      });
    });
    return () => {
      reconnectedListener();
    };
  }, [
    socketClient,
    refetchMatch,
    refetchMeetingActive,
    fetchMoreThread,
    addNotification,
  ]);
  const threadEventGroups = groupThreadEvents(threadEventEdges);
  function generateComponentsForGroup(
    groupedEvents: MatchThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    return groupedEvents
      .map((ev, i) => {
        if (ev.node.__typename === 'RequestEvent') {
          return (
            <RequestThreadEvent
              key={ev.id}
              requestEvent={ev.node}
              showProTip
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        if (ev.node.__typename === 'MessageEvent') {
          return (
            <MessageThreadEvent
              key={ev.id}
              expertId={expertId}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              messageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        if (ev.node.__typename === 'Quote') {
          return (
            <QuoteThreadEvent
              key={ev.id}
              quote={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              showProTip
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        const unknownHumans = threadEventsForUnknowns[ev.id] || [];
        const unknowns = unknownHumans || [];
        let unknownHuman:
          | Extract<
              MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
              { __typename?: 'EmailMessageEvent' | undefined }
            >['ownerHuman']
          | undefined;
        if (
          (ev.node.__typename === 'EmailMessageEvent' ||
            ev.node.__typename === 'TextMessageEvent' ||
            ev.node.__typename === 'MeetingEvent' ||
            ev.node.__typename === 'PhoneCallEvent') &&
          unknowns.length
        ) {
          const unknownHumanId = unknowns[0];
          const humansToCheck = (ev.node.recipientHumans || []).concat();
          if (ev.node.ownerHuman) {
            humansToCheck.push(ev.node.ownerHuman);
          }
          unknownHuman = humansToCheck.find(
            (h) => h && h.id === unknownHumanId,
          );
        }
        if (ev.node.__typename === 'EmailMessageEvent') {
          return (
            <Fragment key={ev.id}>
              <EmailMessageThreadEvent
                key={ev.id}
                expertId={expertId}
                emailMessageEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
                onReplyClick={() => onReplyClick(ev)}
                onUnthreadEmail={onUnthreadEmail}
                isUnthreadLoading={unthreadLoading}
                hasUnknownHuman={!!unknownHuman}
              />
              {unknownHuman && (
                <UnknownHuman
                  className="UnknownHumanEmail"
                  unknownType="email"
                  unknownHuman={unknownHuman}
                  brandTeam={brandTeam}
                  unknownCount={unknowns.length}
                  onMergeHuman={onMergeHuman}
                  onIgnoreHuman={onIgnoreHuman}
                  onAddHuman={onAddHuman}
                />
              )}
            </Fragment>
          );
        }
        if (ev.node.__typename === 'TextMessageEvent') {
          return (
            <Fragment key={ev.id}>
              <TextMessageThreadEvent
                key={ev.id}
                expertId={expertId}
                isGroupFirst={!i}
                isGroupLast={i === groupedEvents.length - 1}
                textMessageEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
                onReplyClick={() => onReplyClick(ev)}
                onUnthreadText={onUnthreadText}
                isUnthreadLoading={unthreadLoading}
                hasUnknownHuman={!!unknownHuman}
              />
              {unknownHuman && (
                <UnknownHuman
                  className="UnknownHumanText"
                  unknownType="text"
                  unknownHuman={unknownHuman}
                  brandTeam={brandTeam}
                  unknownCount={unknowns.length}
                  onMergeHuman={onMergeHuman}
                  onIgnoreHuman={onIgnoreHuman}
                  onAddHuman={onAddHuman}
                />
              )}
            </Fragment>
          );
        }
        if (ev.node.__typename === 'PhoneCallEvent') {
          return (
            <Fragment key={ev.id}>
              <PhoneCallThreadEvent
                key={ev.id}
                isGroupFirst={!i}
                isGroupLast={i === groupedEvents.length - 1}
                phoneCallEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
                onReplyClick={() => onReplyClick(ev)}
                onUnthreadPhoneCall={onUnthreadPhoneCall}
                isUnthreadLoading={unthreadLoading}
                hasUnknownHuman={!!unknownHuman}
              />
              {unknownHuman && (
                <UnknownHuman
                  className="UnknownHumanPhone"
                  unknownType="text"
                  unknownHuman={unknownHuman}
                  brandTeam={brandTeam}
                  unknownCount={unknowns.length}
                  onMergeHuman={onMergeHuman}
                  onIgnoreHuman={onIgnoreHuman}
                  onAddHuman={onAddHuman}
                />
              )}
            </Fragment>
          );
        }
        if (ev.node.__typename === 'MeetingEvent') {
          return (
            <Fragment key={ev.id}>
              <MeetingThreadEvent
                key={ev.id}
                expertDetails={expertDetails}
                meetingEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
                onReplyClick={() => onReplyClick(ev)}
                onUnthreadMeeting={onUnthreadMeeting}
                isUnthreadLoading={unthreadLoading}
                onInviteResponse={tryInviteResponse}
                hasUnknownHuman={!!unknownHuman}
              />
              {unknownHuman && (
                <UnknownHuman
                  className="UnknownHumanMeeting"
                  unknownType="email"
                  unknownHuman={unknownHuman}
                  brandTeam={brandTeam}
                  unknownCount={unknowns.length}
                  onMergeHuman={onMergeHuman}
                  onIgnoreHuman={onIgnoreHuman}
                  onAddHuman={onAddHuman}
                />
              )}
            </Fragment>
          );
        }
        console.log('Missing ThreadEvent type');
        return null;
      })
      .filter((c) => c);
  }
  function closeCurrentAction() {
    setCurrentAction('');
    scrollDown();
  }
  function openPhoneCallEditor() {
    // check currentAction
    if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    setCurrentAction('PHONE');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
  }
  function openMessageHqEditor() {
    // check currentAction
    if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    setMessageHqContent('');
    setCurrentAction('MESSAGE-HQ');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
  }
  function onDeleteMessageHq() {
    setMessageHqContent('');
    setCurrentAction('');
  }
  function openEmailEditor(
    subject?: string,
    recipients?: string[],
    scenario?: string,
    content?: string,
    isSwitchFromText?: boolean,
  ) {
    // check currentAction
    if (isSwitchFromText) {
      onDeleteTextDraft();
    } else if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    // check draft
    if (emailDraft) {
      subject =
        tokenUtils.readLocalStorage(`match-email-${matchId}-subject`) || '';
      recipients = (
        tokenUtils.readLocalStorage(`match-email-${matchId}-recipients`) || ''
      ).split(',');
      content =
        tokenUtils.readLocalStorage(`match-email-${matchId}-content`) || '';
    }
    if (
      subject === undefined &&
      recipients === undefined &&
      content === undefined
    ) {
      // look for the latest email
      const lastEmail = _.findLast(
        threadEventEdges,
        (ev) => ev.node.__typename === 'EmailMessageEvent',
      );
      if (scenario === 'REFERRAL') {
        subject =
          lastEmail && lastEmail.node.__typename === 'EmailMessageEvent'
            ? replyToSubject(lastEmail.node.subject)
            : 'Thanks again!';
        recipients =
          lastEmail && lastEmail.node.__typename === 'EmailMessageEvent'
            ? [...lastEmail.node.recipientHumans.map((r) => r.id)]
            : [];
        if (
          lastEmail &&
          lastEmail.node.__typename === 'EmailMessageEvent' &&
          lastEmail.node.ownerHuman
        ) {
          recipients.push(lastEmail.node.ownerHuman.id);
        }
      } else if (lastEmail) {
        // simulate as if they clicked that email
        onReplyClick(lastEmail);
        return;
      }
      // or assign primaryContact
      if (matchDetails && matchDetails.brand.teamLeader) {
        recipients = [matchDetails.brand.teamLeader.id];
      }
    }
    const plannedRecipients = (recipients || [])
      .map((rId) => emailBrandTeam.find((h) => rId && h && h.id === rId))
      .filter((h) => !!h) as MatchDetailsQuery['matchDetails']['brand']['team'];
    if (!content) {
      const plannedRecipientNames = plannedRecipients.map((r) => ({
        firstName: r.firstName,
        lastName: r.lastName,
      }));
      const expertName = expertDetails
        ? {
            firstName: expertDetails.firstName,
            lastName: expertDetails.lastName,
          }
        : undefined;
      // FUTURE followup templates for quote sent, updated, mark complete
      if (
        scenario === 'QUOTE_ACCEPTED' ||
        scenario === 'QUOTE_SUBSCRIPTION_PAYMENT_MADE'
      ) {
        // content = templateUtils.getQuoteAcceptedEmail(
        //   plannedRecipientNames,
        //   expertName,
        // );
      } else if (scenario === 'QUOTE_APPROVED') {
        // content = templateUtils.getQuoteApprovedEmail(
        //   plannedRecipientNames,
        //   expertName,
        // );
      } else if (scenario === 'QUOTE_BILL_PAID') {
        // FUTURE
      } else if (scenario === 'QUOTE_CANCELED') {
        // content = templateUtils.getQuoteCanceledEmail(
        //   plannedRecipientNames,
        //   expertName,
        // );
      } else if (scenario === 'QUOTE_CANCELED_PAID') {
        // content = templateUtils.getQuotePaidCanceledEmail(
        //   plannedRecipientNames,
        //   expertName,
        // );
      } else if (scenario === 'QUOTE_REVISIONS_REQUESTED') {
        // content = templateUtils.getQuoteRevisionsEmail(
        //   plannedRecipientNames,
        //   expertName,
        // );
      } else if (scenario === 'QUOTE_IN_PROGRESS') {
        // content = templateUtils.getQuoteUndoEmail(
        //   plannedRecipientNames,
        //   expertName,
        // );
      } else if (scenario === 'REFERRAL') {
        content = templateUtils.getReferralEmail(
          plannedRecipientNames,
          expertName,
        );
      } else {
        content = templateUtils.getDefaultEmail(
          plannedRecipientNames,
          expertName,
        );
      }
    }
    setCurrentAction('EMAIL');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    setEmailSubject(subject || '');
    setEmailContent(content || '');
    setEmailRecipients(plannedRecipients);
    setEmailFiles([]);
    setEmailStatus(emailDraft ? 'Saved' : '');
  }
  function askForReferral() {
    markAsReadUnread();
    openEmailEditor(undefined, undefined, 'REFERRAL');
  }
  const debouncedEmailDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(`match-email-${matchId}`, 'true');
    tokenUtils.writeLocalStorage(
      `match-email-${matchId}-subject`,
      emailSubject,
    );
    tokenUtils.writeLocalStorage(
      `match-email-${matchId}-recipients`,
      emailRecipients.map((r) => r.id).join(','),
    );
    tokenUtils.writeLocalStorage(
      `match-email-${matchId}-content`,
      emailContent,
    );
    setEmailDraft(true);
    setEmailStatus('Saved');
  }, 1000);
  function onDeleteEmailDraft() {
    debouncedEmailDraft.cancel();
    tokenUtils.removeLocalStorage(`match-email-${matchId}`);
    tokenUtils.removeLocalStorage(`match-email-${matchId}-subject`);
    tokenUtils.removeLocalStorage(`match-email-${matchId}-recipients`);
    tokenUtils.removeLocalStorage(`match-email-${matchId}-content`);
    setEmailDraft(false);
    setEmailStatus('');
    setEmailSubject('');
    setEmailContent('');
    setEmailRecipients([]);
    setEmailFiles([]);
    setCurrentAction('');
  }
  function onSaveEmailDraft(
    subject: string,
    draftContent: string | null,
    files: IFilestackFileUpload[],
    recipients: MatchDetailsQuery['matchDetails']['brand']['team'],
  ) {
    let saveDraft = false;
    if (emailSubject !== subject) {
      saveDraft = true;
      setEmailSubject(subject || '');
    }
    if (emailRecipients !== recipients) {
      setEmailRecipients(recipients || []);
      saveDraft = true;
    }
    if (emailFiles !== files) {
      setEmailFiles(files || []);
    }
    if (draftContent !== null && emailContent !== draftContent) {
      saveDraft = true;
      setEmailContent(draftContent);
    }
    if (saveDraft) {
      setEmailStatus('Saving');
      debouncedEmailDraft.callback();
    }
  }
  function startMeetingUpdate(
    m: MeetingActiveForMatchQuery['meetingActiveForMatch'][0],
  ) {
    setMeetingExpanded('');
    openMeetingEditor(
      m.id,
      m.eventTitle,
      m.eventLocation || undefined,
      m.eventNotes || undefined,
      (m.participantHumansStatus || []).map((h) => h.humanStr),
      m.eventStart ? new Date(m.eventStart) : new Date(),
      m.eventEnd ? new Date(m.eventEnd) : new Date(),
    );
  }
  function openMeetingEditor(
    actionId?: string,
    title?: string,
    location?: string,
    description?: string,
    recipients?: string[],
    timeStart?: Date,
    timeEnd?: Date,
  ) {
    const timezone = moment.tz.guess();
    // check currentAction
    if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    // check draft
    if (meetingDraft) {
      actionId =
        tokenUtils.readLocalStorage(`match-meeting-${matchId}-actionId`) || '';
      title =
        tokenUtils.readLocalStorage(`match-meeting-${matchId}-title`) || '';
      location =
        tokenUtils.readLocalStorage(`match-meeting-${matchId}-location`) || '';
      description =
        tokenUtils.readLocalStorage(`match-meeting-${matchId}-description`) ||
        '';
      recipients = (
        tokenUtils.readLocalStorage(`match-meeting-${matchId}-recipients`) || ''
      ).split(',');
      const timeStartStr =
        tokenUtils.readLocalStorage(`match-meeting-${matchId}-timeStart`) || '';
      if (timeStartStr && parseInt(timeStartStr, 10)) {
        timeStart = new Date(parseInt(timeStartStr, 10));
      }
      const timeEndStr =
        tokenUtils.readLocalStorage(`match-meeting-${matchId}-timeEnd`) || '';
      if (timeEndStr && parseInt(timeEndStr, 10)) {
        timeEnd = new Date(parseInt(timeEndStr, 10));
      }
    }
    if (
      !title &&
      expertDetails &&
      expertDetails.firstName &&
      matchDetails &&
      matchDetails.brand &&
      matchDetails.brand.name
    ) {
      title = `${matchDetails.brand.name} <> ${expertDetails.firstName} (Storetasker)`;
    }
    if (
      recipients === undefined &&
      matchDetails &&
      matchDetails.brand.teamLeader
    ) {
      recipients = [matchDetails.brand.teamLeader.id];
    }
    if (!timeStart || !timeEnd) {
      // set default times to next hour for 30min
      const startMoment = moment().tz(timezone).startOf('hour').add(2, 'hour');
      timeStart = startMoment.toDate();
      timeEnd = startMoment.add(30, 'minutes').toDate();
    }
    if (
      !location &&
      expertDetails &&
      expertDetails.scheduleTimeDefaultLocation
    ) {
      location = expertDetails.scheduleTimeDefaultLocation;
    }
    if (
      !description &&
      expertDetails &&
      expertDetails.scheduleTimeDefaultDescription
    ) {
      description = expertDetails.scheduleTimeDefaultDescription;
    }
    const plannedRecipients = (recipients || [])
      .map((rId) => emailBrandTeam.find((h) => rId && h && h.id === rId))
      .filter((h) => !!h) as MatchDetailsQuery['matchDetails']['brand']['team'];
    setCurrentAction('MEETING');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    setMeetingActionId(actionId || '');
    setMeetingTitle(title || '');
    setMeetingLocation(location || '');
    setMeetingDescription(description || '');
    setMeetingRecipients(plannedRecipients);
    setMeetingTimeStart(timeStart);
    setMeetingTimeEnd(timeEnd);
    setMeetingTimezone(timezone);
  }
  const debouncedMeetingDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(`match-meeting-${matchId}`, 'true');
    tokenUtils.writeLocalStorage(
      `match-meeting-${matchId}-actionId`,
      meetingActionId,
    );
    tokenUtils.writeLocalStorage(
      `match-meeting-${matchId}-title`,
      meetingTitle,
    );
    tokenUtils.writeLocalStorage(
      `match-meeting-${matchId}-location`,
      meetingLocation,
    );
    tokenUtils.writeLocalStorage(
      `match-meeting-${matchId}-description`,
      meetingDescription,
    );
    tokenUtils.writeLocalStorage(
      `match-meeting-${matchId}-recipients`,
      meetingRecipients.map((r) => r.id).join(','),
    );
    tokenUtils.writeLocalStorage(
      `match-meeting-${matchId}-timeStart`,
      moment(meetingTimeStart)
        .tz(meetingTimezone, true)
        .toDate()
        .getTime()
        .toString(),
    );
    tokenUtils.writeLocalStorage(
      `match-meeting-${matchId}-timeEnd`,
      moment(meetingTimeEnd)
        .tz(meetingTimezone, true)
        .toDate()
        .getTime()
        .toString(),
    );
    setMeetingDraft(true);
    setMeetingStatus('Saved');
  }, 1000);
  function onDeleteMeetingDraft() {
    debouncedQuoteDraft.cancel();
    tokenUtils.removeLocalStorage(`match-meeting-${matchId}`);
    tokenUtils.removeLocalStorage(`match-meeting-${matchId}-actionId`);
    tokenUtils.removeLocalStorage(`match-meeting-${matchId}-title`);
    tokenUtils.removeLocalStorage(`match-meeting-${matchId}-location`);
    tokenUtils.removeLocalStorage(`match-meeting-${matchId}-description`);
    tokenUtils.removeLocalStorage(`match-meeting-${matchId}-recipients`);
    tokenUtils.removeLocalStorage(`match-meeting-${matchId}-timeStart`);
    tokenUtils.removeLocalStorage(`match-meeting-${matchId}-timeEnd`);
    setMeetingDraft(false);
    setMeetingStatus('');
    setMeetingActionId('');
    setMeetingTitle('');
    setMeetingLocation('');
    setMeetingDescription('');
    setMeetingRecipients([]);
    setMeetingTimeStart(new Date());
    setMeetingTimeEnd(new Date());
    setMeetingTimezone('');
    setCurrentAction('');
  }
  function onSaveMeetingDraft(
    title: string,
    location: string,
    description: string,
    recipients: MatchDetailsQuery['matchDetails']['brand']['team'],
    timeStart: Date,
    timeEnd: Date,
    timezone: string,
  ) {
    let saveDraft = false;
    if (meetingTitle !== title) {
      saveDraft = true;
      setMeetingTitle(title || '');
    }
    if (meetingLocation !== location) {
      saveDraft = true;
      setMeetingLocation(location || '');
    }
    if (meetingDescription !== description) {
      saveDraft = true;
      setMeetingDescription(description || '');
    }
    if (meetingRecipients !== recipients) {
      setMeetingRecipients(recipients || []);
      saveDraft = true;
    }
    if (meetingTimeStart !== timeStart) {
      saveDraft = true;
      setMeetingTimeStart(timeStart || '');
    }
    if (meetingTimeEnd !== timeEnd) {
      saveDraft = true;
      setMeetingTimeEnd(timeEnd || '');
    }
    if (meetingTimezone !== timezone) {
      saveDraft = true;
      setMeetingTimezone(timezone || '');
    }
    if (saveDraft) {
      setMeetingStatus('Saving');
      debouncedMeetingDraft.callback();
    }
  }
  function startQuoteUpdate(
    p: MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0],
  ) {
    setProjectExpanded('');
    openQuoteEditor(
      'UPDATE',
      p.id,
      p.quote.id,
      p.quote.title,
      centsDollarsRounded(p.quote.cents),
      p.quote.paymentType,
      p.quote.description,
      p.quote.estimatedCompletionDate
        ? moment.utc(p.quote.estimatedCompletionDate).format()
        : undefined,
      p.quote.estimatedCompletionDays
        ? p.quote.estimatedCompletionDays.toString()
        : undefined,
      p.quote.estimatedCompletionDate ? 'DATE' : undefined,
      p.discountCode || '',
      p.clientFee || '',
    );
  }
  function startMarkCompleteChecklistFlow(
    p: MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0],
  ) {
    setMarkCompleteChecklistShow(p);
    setViewProjectShow(null);
    setShowSidebar(false);
    setProjectExpanded('');
    setCheckOne(false);
    setCheckTwo(false);
    setCheckThree(false);
  }

  function closeMarkCompleteChecklistFlow() {
    setMarkCompleteChecklistShow(null);
  }

  function startMarkComplete(
    p: MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0],
  ) {
    if (!checkOne || !checkTwo || !checkThree) {
      return;
    }
    setMarkCompleteChecklistShow(null);
    setProjectExpanded('');

    openQuoteEditor(
      'COMPLETE',
      p.id,
      p.quote.id,
      p.quote.title,
      centsDollarsRounded(p.quote.cents),
      p.quote.paymentType,
      p.quote.description,
      p.quote.estimatedCompletionDate
        ? moment.utc(p.quote.estimatedCompletionDate).format()
        : undefined,
      p.quote.estimatedCompletionDays
        ? p.quote.estimatedCompletionDays.toString()
        : undefined,
      p.quote.estimatedCompletionDate ? 'DATE' : undefined,
      p.discountCode || '',
      p.clientFee || '',
    );
  }

  function openQuoteEditor(
    actionType: string,
    actionProject?: string,
    actionQuote?: string,
    title?: string,
    paymentPrice?: string,
    paymentType?: string,
    content?: string,
    timelineDate?: string,
    timelineDays?: string,
    timelineType?: string,
    projectDiscountCode?: string,
    projectClientFee?: string,
  ) {
    // check currentAction
    if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    // check draft
    if (quoteDraft) {
      actionType =
        tokenUtils.readLocalStorage(`quote-${matchId}-actionType`) || '';
      actionProject =
        tokenUtils.readLocalStorage(`quote-${matchId}-actionProject`) || '';
      actionQuote =
        tokenUtils.readLocalStorage(`quote-${matchId}-actionQuote`) || '';
      title = tokenUtils.readLocalStorage(`quote-${matchId}-title`) || '';
      paymentPrice =
        tokenUtils.readLocalStorage(`quote-${matchId}-paymentPrice`) || '';
      paymentType =
        tokenUtils.readLocalStorage(`quote-${matchId}-paymentType`) || '';
      content = tokenUtils.readLocalStorage(`quote-${matchId}-content`) || '';
      timelineDate =
        tokenUtils.readLocalStorage(`quote-${matchId}-timelineDate`) || '';
      timelineDays =
        tokenUtils.readLocalStorage(`quote-${matchId}-timelineDays`) || '';
      timelineType =
        tokenUtils.readLocalStorage(`quote-${matchId}-timelineType`) || '';
    }
    setCurrentAction('QUOTE');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    let actionTypeToSet = actionType || 'CREATE';
    if (actionTypeToSet !== 'CREATE' && (!actionProject || !actionQuote)) {
      actionTypeToSet = 'CREATE';
    }
    setQuoteActionType(actionTypeToSet);
    setQuoteActionProject(actionProject || '');
    setQuoteActionQuote(actionQuote || '');
    setQuoteTitle(title || '');
    setQuoteDiscountCode(projectDiscountCode || '');
    setQuoteClientFee(projectClientFee || '');
    setQuotePaymentPrice(
      '$' +
        (
          Math.max(0, Math.round(parseFloat(paymentPrice || '') || 0)) || 250
        ).toString(),
    );
    setQuotePaymentType(paymentType || 'PROJECT');
    setQuoteContent(content || '');
    let momentDateStr = moment.utc(timelineDate || '');
    if (!momentDateStr || !momentDateStr.isValid()) {
      momentDateStr = moment.utc().add(3, 'days');
    }
    setQuoteTimelineDate(momentDateStr.endOf('day'));
    setQuoteTimelineDays(
      (
        Math.max(0, Math.round(parseFloat(timelineDays || '') || 0)) || 3
      ).toString(),
    );
    setQuoteTimelineType(timelineType || 'DAYS');
    setQuoteStatus(quoteDraft ? 'Saved' : '');
    if (actionTypeToSet === 'COMPLETE') {
      onQuoteNextStep(content || '', actionTypeToSet, actionProject);
    }
  }
  const debouncedQuoteDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(`quote-${matchId}`, 'true');
    tokenUtils.writeLocalStorage(
      `quote-${matchId}-actionType`,
      quoteActionType,
    );
    tokenUtils.writeLocalStorage(
      `quote-${matchId}-actionProject`,
      quoteActionProject,
    );
    tokenUtils.writeLocalStorage(
      `quote-${matchId}-actionQuote`,
      quoteActionQuote,
    );
    tokenUtils.writeLocalStorage(`quote-${matchId}-title`, quoteTitle);
    tokenUtils.writeLocalStorage(
      `quote-${matchId}-paymentType`,
      quotePaymentType,
    );
    tokenUtils.writeLocalStorage(
      `quote-${matchId}-paymentPrice`,
      (
        Math.max(
          0,
          Math.round(
            parseFloat((quotePaymentPrice || '').replace('$', '')) || 0,
          ),
        ) || ''
      ).toString(),
    );
    tokenUtils.writeLocalStorage(`quote-${matchId}-content`, quoteContent);
    tokenUtils.writeLocalStorage(
      `quote-${matchId}-timelineDate`,
      quoteTimelineDate ? quoteTimelineDate.utc().format() : '',
    );
    tokenUtils.writeLocalStorage(
      `quote-${matchId}-timelineDays`,
      (
        Math.max(0, Math.round(parseFloat(quoteTimelineDays || '') || 0)) || ''
      ).toString(),
    );
    tokenUtils.writeLocalStorage(
      `quote-${matchId}-timelineType`,
      quoteTimelineType,
    );
    setQuoteDraft(true);
    setQuoteStatus('Saved');
  }, 1000);
  function onDeleteQuoteDraft() {
    debouncedQuoteDraft.cancel();
    tokenUtils.removeLocalStorage(`quote-${matchId}`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-actionType`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-actionProject`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-actionQuote`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-via`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-title`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-paymentPrice`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-paymentType`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-content`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-timelineDate`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-timelineDays`);
    tokenUtils.removeLocalStorage(`quote-${matchId}-timelineType`);
    setQuoteDraft(false);
    setQuoteStatus('');
    setQuoteActionType('');
    setQuoteActionProject('');
    setQuoteActionQuote('');
    setQuoteTitle('');
    setQuotePaymentPrice('');
    setQuoteDiscountCode('');
    setQuoteClientFee('');
    setQuotePaymentType('');
    setQuoteContent('');
    setQuoteTimelineDate(moment.utc().endOf('day'));
    setQuoteTimelineDays('');
    setQuoteTimelineType('');
    onDeleteQuoteEmailDraft();
    onDeleteQuoteTextDraft();
    setCurrentAction('');
  }
  function onSaveQuoteDraft(
    title: string,
    paymentPrice: string,
    paymentType: string,
    draftContent: string | null,
    timelineDate: moment.Moment,
    timelineDays: string,
    timelineType: string,
  ) {
    let saveDraft = false;
    if (quoteTitle !== title) {
      saveDraft = true;
      setQuoteTitle(title || '');
    }
    if (quotePaymentType !== paymentType) {
      saveDraft = true;
      setQuotePaymentType(paymentType || '');
    }
    if (quotePaymentPrice !== paymentPrice) {
      saveDraft = true;
      setQuotePaymentPrice(paymentPrice || '');
    }
    if (draftContent !== null && quoteContent !== draftContent) {
      saveDraft = true;
      setQuoteContent(draftContent);
    }
    if (!quoteTimelineDate || !timelineDate.isSame(quoteTimelineDate)) {
      saveDraft = true;
      setQuoteTimelineDate(timelineDate.utc().endOf('day'));
    }
    if (quoteTimelineDays !== timelineDays) {
      saveDraft = true;
      setQuoteTimelineDays(timelineDays || '');
    }
    if (quoteTimelineType !== timelineType) {
      saveDraft = true;
      setQuoteTimelineType(timelineType || '');
    }
    if (saveDraft) {
      setQuoteStatus('Saving');
      debouncedQuoteDraft.callback();
    }
  }
  function onQuoteCheckLength(draftContent: string, contentLength: number) {
    if (inactiveQuoteEditingError) {
      addNotification(
        'This project has been updated. Please delete this draft and start over.',
        undefined,
        5000,
      );
      return;
    }
    if (!quoteTitle.trim() || !draftContent) {
      addNotification(
        'You need a title and a description for your quote.',
        undefined,
        5000,
      );
      return;
    }
    if (!quotePaymentPrice) {
      addNotification(
        'You need a valid price set for your quote.',
        undefined,
        5000,
      );
      return;
    }
    if (
      quotePaymentType === 'PROJECT' &&
      (!quoteTimelineType ||
        (quoteTimelineType === 'DAYS' && !quoteTimelineDays) ||
        (quoteTimelineType === 'DATE' &&
          (!quoteTimelineDate || !quoteTimelineDate.isAfter())))
    ) {
      addNotification(
        'You need a valid timeline set for your quote.',
        undefined,
        5000,
      );
      return;
    }
    if (contentLength < 250) {
      setShortQuoteConfirm(true);
      setQuoteContent(draftContent);
    } else {
      onQuoteNextStep(draftContent);
    }
  }
  function onQuoteNextStep(
    draftContent: string,
    tempActionType?: string,
    tempActionProject?: string,
  ) {
    setShortQuoteConfirm(false);
    setQuoteContent(draftContent);
    setQuoteStatus('Saving');
    debouncedQuoteDraft.callback();
    // check drafts first
    if (quoteEmailDraft && quoteTextDraft) {
      const via = tokenUtils.readLocalStorage(`quote-${matchId}-via`) || '';
      if (via === 'email') {
        openQuoteEmailEditor(
          undefined,
          undefined,
          undefined,
          tempActionType,
          tempActionProject,
        );
        return;
      }
      if (via === 'text') {
        openQuoteTextEditor(
          undefined,
          undefined,
          tempActionType,
          tempActionProject,
        );
        return;
      }
    }
    if (quoteEmailDraft) {
      openQuoteEmailEditor(
        undefined,
        undefined,
        undefined,
        tempActionType,
        tempActionProject,
      );
      return;
    }
    if (quoteTextDraft) {
      openQuoteTextEditor(
        undefined,
        undefined,
        tempActionType,
        tempActionProject,
      );
      return;
    }
    // otherwise decide on text vs email
    const lastQuoteNode = _.findLast(
      threadEventEdges,
      (ev) =>
        (ev.node.__typename === 'EmailMessageEvent' ||
          ev.node.__typename === 'TextMessageEvent') &&
        !!ev.node.quote,
    );
    if (
      lastQuoteNode &&
      lastQuoteNode.node.__typename === 'EmailMessageEvent'
    ) {
      const newSubject = replyToSubject(lastQuoteNode.node.subject);
      const recipients = [...lastQuoteNode.node.recipientHumans];
      if (lastQuoteNode.node.ownerHuman) {
        recipients.push(lastQuoteNode.node.ownerHuman);
      }
      openQuoteEmailEditor(
        newSubject,
        recipients.map((r) => r.id),
        undefined,
        tempActionType,
        tempActionProject,
      );
      return;
    }
    if (lastQuoteNode && lastQuoteNode.node.__typename === 'TextMessageEvent') {
      const recipients = [...lastQuoteNode.node.recipientHumans];
      if (lastQuoteNode.node.ownerHuman) {
        recipients.push(lastQuoteNode.node.ownerHuman);
      }
      openQuoteTextEditor(
        recipients.map((r) => r.id),
        undefined,
        tempActionType,
        tempActionProject,
      );
      return;
    }
    const lastNode = _.findLast(
      threadEventEdges,
      (ev) =>
        ev.node.__typename === 'EmailMessageEvent' ||
        ev.node.__typename === 'TextMessageEvent',
    );
    if (lastNode && lastNode.node.__typename === 'EmailMessageEvent') {
      const newSubject = replyToSubject(lastNode.node.subject);
      const recipients = [...lastNode.node.recipientHumans];
      if (lastNode.node.ownerHuman) {
        recipients.push(lastNode.node.ownerHuman);
      }
      openQuoteEmailEditor(
        newSubject,
        recipients.map((r) => r.id),
        undefined,
        tempActionType,
        tempActionProject,
      );
      return;
    }
    if (lastNode && lastNode.node.__typename === 'TextMessageEvent') {
      const recipients = [...lastNode.node.recipientHumans];
      if (lastNode.node.ownerHuman) {
        recipients.push(lastNode.node.ownerHuman);
      }
      openQuoteTextEditor(
        recipients.map((r) => r.id),
        undefined,
        tempActionType,
        tempActionProject,
      );
      return;
    }
    // default is email
    openQuoteEmailEditor(
      undefined,
      undefined,
      undefined,
      tempActionType,
      tempActionProject,
    );
  }
  const projectsForMatch = _.uniqBy(
    [
      ...((matchDetails && matchDetails.hasActiveQuotes) || []),
      ...((matchDetails && matchDetails.hasActiveProjects) || []),
      ...((calledProjectPast &&
        dataProjectPast &&
        dataProjectPast.projectPastForMatch) ||
        []),
    ].filter((p) => p.quote && p.quote.status),
    'id',
  ).sort((a, b) => {
    let aSortTime = a.quote.estimatedCompletionDate || 0;
    if (['UPDATED', 'CREATED'].indexOf(a.quote.status) >= 0) {
      aSortTime = 0;
    } else if (
      ['APPROVED', 'BILL_PAID', 'CANCELED'].indexOf(b.quote.status) >= 0
    ) {
      aSortTime = a.quote.createdAt;
    } else if (a.quote.paymentType !== 'PROJECT') {
      aSortTime = Infinity;
    }
    let bSortTime = b.quote.estimatedCompletionDate || 0;
    if (['UPDATED', 'CREATED'].indexOf(b.quote.status) >= 0) {
      bSortTime = 0;
    } else if (
      ['APPROVED', 'BILL_PAID', 'CANCELED'].indexOf(b.quote.status) >= 0
    ) {
      bSortTime = b.quote.createdAt;
    } else if (b.quote.paymentType !== 'PROJECT') {
      bSortTime = Infinity;
    }
    return aSortTime - bSortTime;
  });
  const activeProjects = projectsForMatch.filter(
    (p) =>
      p.isActive &&
      ['APPROVED', 'BILL_PAID', 'CANCELED'].indexOf(p.quote.status) === -1,
  );
  const pastProjects = projectsForMatch
    .filter(
      (p) =>
        !p.isActive ||
        ['APPROVED', 'BILL_PAID', 'CANCELED'].indexOf(p.quote.status) !== -1,
    )
    .reverse();
  let leadProjectWithQuoteSent:
    | MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0]
    | undefined;
  let leadCloseTimelinePast = true;
  let leadCloseDateStr = '';
  let leadMatchedDaysAgo = 10;
  if (matchDetails && matchDetails.isLead) {
    leadMatchedDaysAgo = Math.abs(
      (initTime - matchDetails.createdAt) / (24 * 60 * 60 * 1000),
    );
    leadProjectWithQuoteSent = (matchDetails.hasActiveQuotes || []).find(
      (p) =>
        p.quote &&
        p.quote.cents &&
        p.quote.status &&
        ['CREATED', 'UPDATED'].indexOf(p.quote.status) >= 0,
    );
    if (matchDetails.leadCloseTimeline) {
      const nowMoment = moment.tz(initTime, moment.tz.guess());
      const closeMoment = moment.tz(
        matchDetails.leadCloseTimeline,
        moment.tz.guess(),
      );
      leadCloseTimelinePast = false;
      if (nowMoment.isSame(closeMoment, 'day')) {
        leadCloseDateStr = 'Today';
        leadCloseTimelinePast = true;
      } else if (matchDetails.leadCloseTimeline < initTime) {
        leadCloseTimelinePast = true;
        leadCloseDateStr = '';
      } else if (nowMoment.clone().add(1, 'day').isSame(closeMoment, 'day')) {
        leadCloseDateStr = 'Tomorrow';
      } else if (nowMoment.isSame(closeMoment, 'week')) {
        leadCloseDateStr = closeMoment.format('dddd');
      } else {
        leadCloseDateStr = closeMoment.format('MMMM Do');
      }
    }
  }
  let inactiveQuoteEditingError = false;
  if (quoteActionProject && quoteActionQuote) {
    const editingProject = activeProjects.find(
      (p) => p.id === quoteActionProject,
    );
    if (
      !editingProject ||
      !editingProject.quote ||
      editingProject.quote.id !== quoteActionQuote
    ) {
      inactiveQuoteEditingError = true;
    }
  }
  function openQuoteEmailEditor(
    subject?: string,
    recipients?: string[],
    content?: string,
    tempActionType?: string,
    tempActionProject?: string,
  ) {
    // check draft
    if (quoteEmailDraft) {
      subject =
        tokenUtils.readLocalStorage(`quoteEmail-${matchId}-subject`) || '';
      recipients = (
        tokenUtils.readLocalStorage(`quoteEmail-${matchId}-recipients`) || ''
      ).split(',');
      content =
        tokenUtils.readLocalStorage(`quoteEmail-${matchId}-content`) || '';
    }
    if (
      subject === undefined &&
      recipients === undefined &&
      content === undefined
    ) {
      // default: use project title + assign primaryContact
      subject = quoteTitle;
      if (matchDetails && matchDetails.brand.teamLeader) {
        recipients = [matchDetails.brand.teamLeader.id];
      }
    }
    const plannedRecipients = (recipients || [])
      .map((rId) => emailBrandTeam.find((h) => rId && h && h.id === rId))
      .filter((h) => !!h) as MatchDetailsQuery['matchDetails']['brand']['team'];
    if (!content) {
      const plannedRecipientNames = plannedRecipients.map((r) => ({
        firstName: r.firstName,
        lastName: r.lastName,
      }));
      const expertName = expertDetails
        ? {
            firstName: expertDetails.firstName,
            lastName: expertDetails.lastName,
          }
        : undefined;
      if ((tempActionType || quoteActionType) === 'CREATE') {
        content = templateUtils.getQuoteSendEmail(
          plannedRecipientNames,
          expertName,
          quotePaymentType,
          hasDiscountCode,
        );
      } else {
        const existingProject = activeProjects.find(
          (p) => p.id === (tempActionProject || quoteActionProject),
        );
        if ((tempActionType || quoteActionType) === 'UPDATE') {
          // FUTURE price lower or higher if previously accepted
          content = templateUtils.getQuoteUpdateEmail(
            plannedRecipientNames,
            expertName,
            quotePaymentType,
            (existingProject && existingProject.discountCode) ||
              hasDiscountCode ||
              '',
          );
        } else if ((tempActionType || quoteActionType) === 'COMPLETE') {
          content = templateUtils.getQuoteMarkCompleteEmail(
            plannedRecipientNames,
            !!(existingProject && existingProject.quote.revisionsRequested),
            expertName,
          );
        }
      }
    }
    setCurrentAction('QUOTE-EMAIL');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    setQuoteEmailSubject(subject || '');
    setQuoteEmailContent(content || '');
    setQuoteEmailRecipients(plannedRecipients);
    setQuoteEmailFiles([]);
    setQuoteEmailStatus(quoteEmailDraft ? 'Saved' : '');
  }
  const debouncedQuoteEmailDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(`quoteEmail-${matchId}`, 'true');
    tokenUtils.writeLocalStorage(
      `quoteEmail-${matchId}-subject`,
      quoteEmailSubject,
    );
    tokenUtils.writeLocalStorage(
      `quoteEmail-${matchId}-recipients`,
      quoteEmailRecipients.map((r) => r.id).join(','),
    );
    tokenUtils.writeLocalStorage(
      `quoteEmail-${matchId}-content`,
      quoteEmailContent,
    );
    tokenUtils.writeLocalStorage(`quote-${matchId}-via`, 'email');
    setQuoteEmailDraft(true);
    setQuoteEmailStatus('Saved');
  }, 1000);
  function onDeleteQuoteEmailDraft() {
    debouncedQuoteEmailDraft.cancel();
    tokenUtils.removeLocalStorage(`quoteEmail-${matchId}`);
    tokenUtils.removeLocalStorage(`quoteEmail-${matchId}-subject`);
    tokenUtils.removeLocalStorage(`quoteEmail-${matchId}-recipients`);
    tokenUtils.removeLocalStorage(`quoteEmail-${matchId}-content`);
    setQuoteEmailDraft(false);
    setQuoteEmailStatus('');
    setQuoteEmailSubject('');
    setQuoteEmailContent('');
    setQuoteEmailRecipients([]);
    setQuoteEmailFiles([]);
    setCurrentAction('QUOTE');
  }
  function onSaveQuoteEmailDraft(
    subject: string,
    draftContent: string | null,
    files: IFilestackFileUpload[],
    recipients: MatchDetailsQuery['matchDetails']['brand']['team'],
  ) {
    let saveDraft = false;
    if (quoteEmailSubject !== subject) {
      saveDraft = true;
      setQuoteEmailSubject(subject || '');
    }
    if (quoteEmailRecipients !== recipients) {
      setQuoteEmailRecipients(recipients || []);
      saveDraft = true;
    }
    if (quoteEmailFiles !== files) {
      setQuoteEmailFiles(files || []);
    }
    if (draftContent !== null && quoteEmailContent !== draftContent) {
      saveDraft = true;
      setQuoteEmailContent(draftContent);
    }
    if (saveDraft) {
      setQuoteEmailStatus('Saving');
      debouncedQuoteEmailDraft.callback();
    }
  }
  function openQuoteTextEditor(
    recipients?: string[],
    content?: string,
    tempActionType?: string,
    tempActionProject?: string,
  ) {
    // check draft
    if (quoteTextDraft) {
      recipients = (
        tokenUtils.readLocalStorage(`quoteText-${matchId}-recipients`) || ''
      ).split(',');
      content =
        tokenUtils.readLocalStorage(`quoteText-${matchId}-content`) || '';
    }
    if (recipients === undefined && content === undefined) {
      // default: assign primaryContact
      if (matchDetails && matchDetails.brand.teamLeader) {
        recipients = [matchDetails.brand.teamLeader.id];
      }
    }
    const plannedRecipients = (recipients || [])
      .map((rId) => phoneBrandTeam.find((h) => rId && h && h.id === rId))
      .filter((h) => !!h) as MatchDetailsQuery['matchDetails']['brand']['team'];
    if (!content) {
      const plannedRecipientNames = plannedRecipients.map((r) => ({
        firstName: r.firstName,
        lastName: r.lastName,
      }));
      if ((tempActionType || quoteActionType) === 'CREATE') {
        content = templateUtils.getQuoteSendText(
          plannedRecipientNames,
          quotePaymentType,
        );
      } else {
        const existingProject = activeProjects.find(
          (p) => p.id === (tempActionProject || quoteActionProject),
        );
        if ((tempActionType || quoteActionType) === 'UPDATE') {
          // FUTURE price lower or higher if previously accepted
          content = templateUtils.getQuoteUpdateText(
            plannedRecipientNames,
            quotePaymentType,
          );
        } else if ((tempActionType || quoteActionType) === 'COMPLETE') {
          content = templateUtils.getQuoteMarkCompleteText(
            plannedRecipientNames,
            !!(existingProject && existingProject.quote.revisionsRequested),
          );
        }
      }
    }
    setCurrentAction('QUOTE-TEXT');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    setQuoteTextContent(content || '');
    setQuoteTextRecipients(plannedRecipients);
    setQuoteTextFiles([]);
    setQuoteTextStatus(quoteTextDraft ? 'Saved' : '');
  }
  const debouncedQuoteTextDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(`quoteText-${matchId}`, 'true');
    tokenUtils.writeLocalStorage(
      `quoteText-${matchId}-recipients`,
      quoteTextRecipients.map((r) => r.id).join(','),
    );
    tokenUtils.writeLocalStorage(
      `quoteText-${matchId}-content`,
      quoteTextContent,
    );
    tokenUtils.writeLocalStorage(`quote-${matchId}-via`, 'text');
    setQuoteTextDraft(true);
    setQuoteTextStatus('Saved');
  }, 1000);
  function onDeleteQuoteTextDraft() {
    debouncedQuoteTextDraft.cancel();
    tokenUtils.removeLocalStorage(`quoteText-${matchId}`);
    tokenUtils.removeLocalStorage(`quoteText-${matchId}-recipients`);
    tokenUtils.removeLocalStorage(`quoteText-${matchId}-content`);
    setQuoteTextDraft(false);
    setQuoteTextStatus('');
    setQuoteTextContent('');
    setQuoteTextRecipients([]);
    setQuoteTextFiles([]);
    setCurrentAction('QUOTE');
  }
  function onSaveQuoteTextDraft(
    draftContent: string | null,
    files: IFilestackFileUpload[],
    recipients: MatchDetailsQuery['matchDetails']['brand']['team'],
  ) {
    let saveDraft = false;
    if (quoteTextRecipients !== recipients) {
      setQuoteTextRecipients(recipients || []);
      saveDraft = true;
    }
    if (quoteTextFiles !== files) {
      setQuoteTextFiles(files || []);
    }
    if (draftContent !== null && quoteTextContent !== draftContent) {
      saveDraft = true;
      setQuoteTextContent(draftContent);
    }
    if (saveDraft) {
      setQuoteTextStatus('Saving');
      debouncedQuoteTextDraft.callback();
    }
  }
  function openTextEditor(
    recipients?: string[],
    scenario?: string,
    content?: string,
    isSwitchFromEmail?: boolean,
  ) {
    // check currentAction
    if (isSwitchFromEmail) {
      onDeleteEmailDraft();
    } else if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    // check draft
    if (textDraft) {
      recipients = (
        tokenUtils.readLocalStorage(`match-text-${matchId}-recipients`) || ''
      ).split(',');
      content =
        tokenUtils.readLocalStorage(`match-text-${matchId}-content`) || '';
    }
    if (recipients === undefined && content === undefined) {
      const lastText = _.findLast(
        threadEventEdges,
        (ev) =>
          ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent',
      );
      // look for the latest text/phone
      if (lastText) {
        // simulate as if they clicked it
        onReplyClick(lastText);
        return;
      }
      // or assign primaryContact
      if (matchDetails && matchDetails.brand.teamLeader) {
        recipients = [matchDetails.brand.teamLeader.id];
      }
    }
    const plannedRecipients = (recipients || [])
      .map((rId) => phoneBrandTeam.find((h) => rId && h && h.id === rId))
      .filter((h) => !!h) as MatchDetailsQuery['matchDetails']['brand']['team'];
    if (!content) {
      // FUTURE followup templates for quote sent, updated, mark complete
      if (
        scenario === 'QUOTE_ACCEPTED' ||
        scenario === 'QUOTE_SUBSCRIPTION_PAYMENT_MADE'
      ) {
        // content = templateUtils.getQuoteAcceptedText();
      } else if (scenario === 'QUOTE_APPROVED') {
        // content = templateUtils.getQuoteApprovedText();
      } else if (scenario === 'QUOTE_BILL_PAID') {
        // FUTURE
      } else if (scenario === 'QUOTE_CANCELED') {
        // content = templateUtils.getQuoteCanceledText();
      } else if (scenario === 'QUOTE_CANCELED_PAID') {
        // content = templateUtils.getQuotePaidCanceledText();
      } else if (scenario === 'QUOTE_REVISIONS_REQUESTED') {
        // content = templateUtils.getQuoteRevisionsText();
      } else if (scenario === 'QUOTE_IN_PROGRESS') {
        // content = templateUtils.getQuoteUndoText();
      } else {
        content = templateUtils.getDefaultText();
      }
    }
    setCurrentAction('TEXT');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    setTextContent(content || '');
    setTextRecipients(plannedRecipients);
    setTextFiles([]);
    setTextStatus(textDraft ? 'Saved' : '');
  }
  const debouncedTextDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(`match-text-${matchId}`, 'true');
    tokenUtils.writeLocalStorage(
      `match-text-${matchId}-recipients`,
      textRecipients.map((r) => r.id).join(','),
    );
    tokenUtils.writeLocalStorage(`match-text-${matchId}-content`, textContent);
    setTextDraft(true);
    setTextStatus('Saved');
  }, 1000);
  function onDeleteTextDraft() {
    debouncedTextDraft.cancel();
    tokenUtils.removeLocalStorage(`match-text-${matchId}`);
    tokenUtils.removeLocalStorage(`match-text-${matchId}-recipients`);
    tokenUtils.removeLocalStorage(`match-text-${matchId}-content`);
    setTextDraft(false);
    setTextStatus('');
    setTextContent('');
    setTextRecipients([]);
    setTextFiles([]);
    setCurrentAction('');
  }
  function onSaveTextDraft(
    content: string,
    files: IFilestackFileUpload[],
    recipients: MatchDetailsQuery['matchDetails']['brand']['team'],
  ) {
    let saveDraft = false;
    if (textRecipients !== recipients) {
      setTextRecipients(recipients || []);
      saveDraft = true;
    }
    if (textFiles !== files) {
      setTextFiles(files || []);
    }
    if (textContent !== content) {
      saveDraft = true;
      setTextContent(content);
    }
    if (saveDraft) {
      setTextStatus('Saving');
      debouncedTextDraft.callback();
    }
  }
  function projectSidebarCard(
    p: MatchDetailsQuery['matchDetails']['hasActiveQuotes'][0],
  ) {
    let estimatedCompletionStr = '';
    if (p.quote.paymentType === 'MONTHLY_SUBSCRIPTION') {
      estimatedCompletionStr = 'Monthly Subscription';
    } else if (p.quote.paymentType === 'WEEKLY_SUBSCRIPTION') {
      estimatedCompletionStr = 'Weekly Subscription';
    } else if (p.quote.paymentType === 'BILL') {
      estimatedCompletionStr = 'Bill';
    } else if (['APPROVED', 'CANCELED'].indexOf(p.quote.status) >= 0) {
      estimatedCompletionStr =
        (p.quote.status === QuoteStatus.Approved ? 'Approved ' : 'Canceled ') +
        moment.utc(p.quote.createdAt).tz('UTC').format('MMMM Do, YYYY');
    } else if (['UPDATED', 'CREATED'].indexOf(p.quote.status) >= 0) {
      estimatedCompletionStr = 'Waiting for quote accept';
    } else if (p.quote.estimatedCompletionDate) {
      const nowMoment = moment.tz(initTime, moment.tz.guess());
      const dueMoment = moment.utc(p.quote.estimatedCompletionDate).tz('UTC');
      if (nowMoment.isSame(dueMoment, 'day')) {
        estimatedCompletionStr = 'Due Today';
      } else if (dueMoment.valueOf() < nowMoment.valueOf()) {
        estimatedCompletionStr =
          'Past Deadline: ' + dueMoment.format('MMMM Do');
      } else if (nowMoment.clone().add(1, 'day').isSame(dueMoment, 'day')) {
        estimatedCompletionStr = 'Due Tomorrow';
      } else if (nowMoment.isSame(dueMoment, 'week')) {
        estimatedCompletionStr = 'Due by ' + dueMoment.format('dddd');
      } else if (nowMoment.isSame(dueMoment, 'year')) {
        estimatedCompletionStr = 'Due by ' + dueMoment.format('MMMM Do');
      } else {
        estimatedCompletionStr = 'Due by ' + dueMoment.format('MMMM Do, YYYY');
      }
    }
    return (
      <div key={p.id} className="ThreadDetailSidebarUpNextItem">
        <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardProject">
          <div
            className="ThreadDetailSidebarUpNextCardHeader"
            onClick={() =>
              setProjectExpanded(projectExpanded === p.id ? '' : p.id)
            }
          >
            <div className="ThreadDetailSidebarUpNextCardType">Project</div>
            <div className="ThreadDetailSidebarUpNextCardTitle">
              {p.quote.title}
            </div>
            <div className="ThreadDetailSidebarUpNextCardSubtitle">
              ${formatNumberWithCommas(centsDollarsRounded(p.quote.cents))}
            </div>
            <div
              className={
                'ThreadDetailSidebarUpNextCardStatusLine CardStatusLine' +
                p.quote.status
              }
            />
            <div className="ThreadDetailSidebarUpNextCardStatus">
              {quoteStatusFormatted(p.quote.status)}
            </div>
          </div>
          {!!p.discountCode &&
            !!validDiscountCode(p.discountCode) &&
            !!p.quote &&
            p.quote.status !== QuoteStatus.Canceled && (
              <div
                className="ThreadDetailSidebarUpNextCardMiddle"
                onClick={() =>
                  setProjectExpanded(projectExpanded === p.id ? '' : p.id)
                }
              >
                Client used a {amountDiscountCode(p.discountCode)}% off discount
                code. You will still get paid based on the full amount.
              </div>
            )}
          {projectExpanded === p.id && (
            <div className="ThreadDetailSidebarUpNextCardExpanded">
              <div className="ThreadDetailSidebarUpNextCardActions">
                {['ACCEPTED', 'REVISIONS_REQUESTED', 'IN_PROGRESS'].indexOf(
                  p.quote.status,
                ) >= 0 && (
                  <div
                    className="ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionGood"
                    onClick={() => startMarkCompleteChecklistFlow(p)}
                  >
                    Mark Complete
                  </div>
                )}
                {expertDetails &&
                  expertDetails.status !== 'no-match-quote' &&
                  [
                    'UPDATED',
                    'CREATED',
                    'ACCEPTED',
                    'REVISIONS_REQUESTED',
                    'IN_PROGRESS',
                  ].indexOf(p.quote.status) >= 0 && (
                    <div
                      className="ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionNeutral"
                      onClick={() => startQuoteUpdate(p)}
                    >
                      Update Quote
                    </div>
                  )}
                {[
                  'UPDATED',
                  'CREATED',
                  'ACCEPTED',
                  'SUBSCRIPTION_PAYMENT_MADE',
                  'REVISIONS_REQUESTED',
                  'IN_PROGRESS',
                ].indexOf(p.quote.status) >= 0 && (
                  <div
                    className="ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionBad"
                    onClick={() => startCancelProjectFlow(p)}
                  >
                    Cancel Project
                  </div>
                )}
                {p.quote.status === QuoteStatus.Completed && (
                  <div
                    className="ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionBad"
                    onClick={() => startUndoProjectFlow(p)}
                  >
                    Undo Mark Complete
                  </div>
                )}
                <div
                  className="ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionNeutral"
                  onClick={() => startViewProjectFlow(p)}
                >
                  View Project Details
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="ThreadDetailSidebarUpNextItemFooter">
          {estimatedCompletionStr}
          <div
            onClick={() => {
              copyToClipboard(
                `${envUtils.pickWebAppUrlForUserType('human')}/projects/${
                  p.id
                }`,
              ).catch(() => {});
            }}
            className="ThreadDetailSidebarUpNextItemFooterRight"
          >
            copy link
          </div>
        </div>
      </div>
    );
  }
  function meetingSidebarCard(
    m: MeetingActiveForMatchQuery['meetingActiveForMatch'][0],
  ) {
    const nowMoment = moment.tz(initTime, m.eventTimezone || moment.tz.guess());
    const meetingStartMoment = moment.tz(
      m.eventStart,
      m.eventTimezone || moment.tz.guess(),
    );
    const meetingEndMoment = moment.tz(
      m.eventEnd,
      m.eventTimezone || moment.tz.guess(),
    );
    let dayStr = meetingStartMoment.format('MMMM Do');
    if (nowMoment.isSame(meetingStartMoment, 'day')) {
      dayStr = 'Today';
    } else if (
      nowMoment.clone().add(1, 'day').isSame(meetingStartMoment, 'day')
    ) {
      dayStr = 'Tomorrow';
    } else if (
      nowMoment.isSame(meetingStartMoment, 'week') &&
      m.eventStart > initTime
    ) {
      dayStr = meetingStartMoment.format('dddd');
    }
    const timeStr = m.eventAllDay
      ? ', all day'
      : ' at ' + meetingStartMoment.format('h:mma z');
    const eventSummaryDateStr = dayStr + timeStr;
    let eventRangeDateStr = '';
    let eventRangeDateFull = '';
    if (m.eventAllDay) {
      eventRangeDateFull = `All Day: ${meetingStartMoment.format(
        'M/D/YY',
      )} - ${meetingEndMoment.format('M/D/YY')}`;
      if (meetingStartMoment.isSame(meetingEndMoment, 'day')) {
        if (nowMoment.isSame(meetingStartMoment, 'year')) {
          eventRangeDateStr = `All Day: ${meetingStartMoment.format(
            'MMMM Do',
          )}`;
        } else {
          eventRangeDateStr = `All Day: ${meetingStartMoment.format('M/D/YY')}`;
        }
      }
    } else {
      const startTimezone = meetingStartMoment.format('z');
      eventRangeDateFull = `${meetingStartMoment.format(
        'M/D/YY',
      )} at ${meetingStartMoment.format('h:mma')} - ${meetingEndMoment.format(
        'M/D/YY',
      )} at ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
      if (meetingStartMoment.isSame(meetingEndMoment, 'day')) {
        if (nowMoment.isSame(meetingStartMoment, 'day')) {
          eventRangeDateStr = `Today: ${meetingStartMoment.format(
            'h:mma',
          )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
        } else if (meetingStartMoment.isBefore(nowMoment)) {
          if (
            nowMoment
              .clone()
              .subtract(1, 'day')
              .isSame(meetingStartMoment, 'day')
          ) {
            eventRangeDateStr = `Yesterday: ${meetingStartMoment.format(
              'h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          } else if (nowMoment.isSame(meetingStartMoment, 'year')) {
            eventRangeDateStr = `${meetingStartMoment.format(
              'MMMM Do: h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          } else {
            eventRangeDateStr = `${meetingStartMoment.format(
              'M/D/YY: h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          }
        } else if (meetingStartMoment.isAfter(nowMoment)) {
          if (nowMoment.isSame(meetingStartMoment, 'week')) {
            eventRangeDateStr = `${meetingStartMoment.format(
              'dddd: h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          } else if (nowMoment.isSame(meetingStartMoment, 'year')) {
            eventRangeDateStr = `${meetingStartMoment.format(
              'MMMM Do: h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          } else {
            eventRangeDateStr = `${meetingStartMoment.format(
              'M/D/YY: h:mma',
            )} - ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
          }
        }
      } else if (
        nowMoment.isSame(meetingStartMoment, 'year') &&
        nowMoment.isSame(meetingEndMoment, 'year')
      ) {
        eventRangeDateStr = `${meetingStartMoment.format(
          'MMMM Do',
        )} at ${meetingStartMoment.format('h:mma')} - ${meetingEndMoment.format(
          'MMMM Do',
        )} at ${meetingEndMoment.format('h:mma')} ${startTimezone}`;
      }
    }
    if (!eventRangeDateStr) {
      eventRangeDateStr = eventRangeDateFull;
    }
    const organizerStr = m.organizerHuman
      ? (
          (m.organizerHuman.firstName || '') +
          ' ' +
          (m.organizerHuman.lastName || '')
        ).trim()
      : 'You';
    const participantsStr = (m.participantHumansStatus || [])
      .filter((p) => p.human)
      .map(
        (p) =>
          (`${(p.human.firstName || '').trim()} ${(
            p.human.lastName || ''
          ).trim()}`.trim() ||
            (p.human.primaryEmail || '').trim() ||
            '') +
          (m.organizerExpert
            ? ` (${meetingResponseFormatted(p.status, true)})`
            : ''),
      )
      .join(', ');
    let highLevelStatus =
      m.eventStatus === 'ACTIVE' ? m.expertResponse : 'CANCELED';
    if (m.organizerExpert) {
      if (m.participantHumansStatus.find((p) => p.status === 'ACCEPTED')) {
        highLevelStatus = 'ACCEPTED';
      } else if (
        m.participantHumansStatus.find((p) => p.status === 'DECLINED')
      ) {
        highLevelStatus = 'DECLINED';
      } else {
        highLevelStatus = 'NEEDS-ACTION';
      }
    }
    return (
      <div key={m.id} className="ThreadDetailSidebarUpNextItem">
        <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardMeeting">
          <div
            className="ThreadDetailSidebarUpNextCardHeader"
            onClick={() =>
              setMeetingExpanded(meetingExpanded === m.id ? '' : m.id)
            }
          >
            <div className="ThreadDetailSidebarUpNextCardType">Meeting</div>
            <div className="ThreadDetailSidebarUpNextCardTitle">
              {m.eventTitle}
            </div>
            <div
              className={
                'ThreadDetailSidebarUpNextCardStatusLine CardStatusLine' +
                highLevelStatus
              }
            />
            <div className="ThreadDetailSidebarUpNextCardStatus">
              {meetingResponseFormatted(highLevelStatus, !!m.organizerExpert)}
            </div>
          </div>
          {meetingExpanded === m.id && (
            <div className="ThreadDetailSidebarUpNextCardExpanded">
              <div className="ThreadDetailSidebarUpNextCardDetails">
                {!!eventRangeDateStr && (
                  <div className="ThreadDetailSidebarUpNextCardDetail">
                    <div className="ThreadDetailSidebarUpNextCardDetailTitle">
                      Date
                    </div>
                    <div
                      className="ThreadDetailSidebarUpNextCardDetailValue"
                      data-tip={eventRangeDateFull}
                    >
                      {eventRangeDateStr}
                    </div>
                  </div>
                )}
                {!!m.eventLocation && (
                  <div className="ThreadDetailSidebarUpNextCardDetail">
                    <div className="ThreadDetailSidebarUpNextCardDetailTitle">
                      Location
                    </div>
                    <div className="ThreadDetailSidebarUpNextCardDetailValue">
                      {m.eventLocation}
                    </div>
                  </div>
                )}
                {!!m.eventNotes && (
                  <div className="ThreadDetailSidebarUpNextCardDetail">
                    <div className="ThreadDetailSidebarUpNextCardDetailTitle">
                      Notes
                    </div>
                    <div className="ThreadDetailSidebarUpNextCardDetailValue">
                      {m.eventNotes}
                    </div>
                  </div>
                )}
                <div className="ThreadDetailSidebarUpNextCardDetail">
                  <div className="ThreadDetailSidebarUpNextCardDetailTitle">
                    Organizer
                  </div>
                  <div className="ThreadDetailSidebarUpNextCardDetailValue">
                    {organizerStr}
                  </div>
                </div>
                {!!participantsStr && (
                  <div className="ThreadDetailSidebarUpNextCardDetail">
                    <div className="ThreadDetailSidebarUpNextCardDetailTitle">
                      Participants
                    </div>
                    <div className="ThreadDetailSidebarUpNextCardDetailValue">
                      {participantsStr}
                    </div>
                  </div>
                )}
              </div>
              {m.eventStatus === 'ACTIVE' &&
                m.organizerExpert &&
                m.eventEnd >= initTime &&
                m.organizerExpert.id === expertId && (
                  <div className="ThreadDetailSidebarUpNextCardActions">
                    <div
                      className="ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionGood"
                      onClick={() => startMeetingUpdate(m)}
                    >
                      Update Meeting
                    </div>
                    <div
                      className="ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionBad"
                      onClick={() => startCancelMeetingFlow(m)}
                    >
                      Cancel Meeting
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
        <div className="ThreadDetailSidebarUpNextItemFooter">
          {eventSummaryDateStr}
        </div>
      </div>
    );
  }
  function loadPastHistory() {
    getPastMeetings().catch((err: ApolloError) => {
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'getPastMeetings',
      });
    });
    getPastProjects().catch((err: ApolloError) => {
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'getPastProjects',
      });
    });
    setPastLoaded(true);
  }
  const emptyCurrent =
    (!matchDetails || (!matchDetails.expertUnread && !matchDetails.isLead)) &&
    !emailDraft &&
    !textDraft &&
    !quoteDraft &&
    !meetingDraft &&
    !activeProjects.length &&
    !upNextMeetingsForMatch.length;
  const emptyPast = !pastProjects.length && !pastMeetingsForMatch.length;
  function goEditContacts() {
    setPrimaryPanel('About');
    setShowSidebar(true);
  }
  function renderCurrentActionEditor() {
    if (currentAction === 'EMAIL') {
      return (
        <div
          className={
            'ThreadActionEmail ' +
            (sendLoading ? ' ThreadActionEmailSending ' : '')
          }
        >
          <EmailEditor
            emailTemplates={emailTemplates}
            expertId={expertId}
            expertEmailAddress={
              expertDetails && expertDetails.primaryPublicEmail
                ? expertDetails.primaryPublicEmail
                : ''
            }
            expertPhoneNumber={
              expertDetails && expertDetails.primaryPublicPhone
                ? expertDetails.primaryPublicPhone
                : ''
            }
            expertSchedulePath={
              expertDetails &&
              expertDetails.scheduleTimeEnabled &&
              expertDetails.profilePath
                ? expertDetails.profilePath
                : ''
            }
            files={emailFiles}
            goEditContacts={goEditContacts}
            initContent={emailContent}
            onDeleteDraft={onDeleteEmailDraft}
            onSaveDraft={onSaveEmailDraft}
            onSendEmail={onSendEmail}
            recipients={emailRecipients}
            subject={emailSubject}
            status={emailStatus}
            switchToTextEditor={(contentStr?: string) =>
              openTextEditor(
                emailRecipients.map((r) => r.id),
                undefined,
                contentStr,
                true,
              )
            }
            team={emailBrandTeam}
          />
        </div>
      );
    }
    if (currentAction === 'TEXT') {
      return (
        <div
          className={
            'ThreadActionText ' +
            (sendLoading ? ' ThreadActionTextSending ' : '')
          }
        >
          <TextEditor
            expertId={expertId}
            expertEmailAddress={
              expertDetails && expertDetails.primaryPublicEmail
                ? expertDetails.primaryPublicEmail
                : ''
            }
            expertPhoneNumber={
              expertDetails && expertDetails.primaryPublicPhone
                ? expertDetails.primaryPublicPhone
                : ''
            }
            expertSchedulePath={
              expertDetails &&
              expertDetails.scheduleTimeEnabled &&
              expertDetails.profilePath
                ? expertDetails.profilePath
                : ''
            }
            files={textFiles}
            goEditContacts={goEditContacts}
            content={textContent}
            onDeleteDraft={onDeleteTextDraft}
            onSaveDraft={onSaveTextDraft}
            onSendText={onSendText}
            recipients={textRecipients}
            status={textStatus}
            switchToEmailEditor={(contentStr?: string) =>
              openEmailEditor(
                undefined,
                textRecipients.map((r) => r.id),
                undefined,
                contentStr,
                true,
              )
            }
            team={phoneBrandTeam}
          />
        </div>
      );
    }
    if (currentAction === 'QUOTE') {
      return (
        <div className="ThreadActionQuote">
          <div className="ThreadActionQuoteTipWrapper">
            <div className="ThreadActionQuoteTip">
              <div className="ThreadActionQuoteTipTitle">Pro Tip!</div>
              <a
                href="https://kb.storetasker.com/Writing-a-Great-Quote-6e4bc885e285437a997a154b6ca8c49c"
                target="_blank"
                rel="noopener noreferrer"
                className="ThreadActionQuoteTipLearn"
              >
                Learn More
              </a>
              <div className="ThreadActionQuoteTipBody">
                Writing a detailed quote (with a thorough scope!) improves
                conversion rate and dramatically reduces issues down the line:
                scope creep, miscommunication and refunds.
              </div>
            </div>
          </div>
          <QuoteEditor
            actionType={quoteActionType}
            hasClientFee={
              quoteActionType === 'CREATE' ? hasClientFee : quoteClientFee
            }
            expertBroughtClient={
              !!(
                matchDetails &&
                matchDetails.brand &&
                matchDetails.brand.teamLeader &&
                matchDetails.brand.teamLeader.expertReferralStr &&
                matchDetails.brand.teamLeader.expertReferralStr === expertId
              )
            }
            expertCreatedAt={expertDetails ? expertDetails.createdAt : 0}
            hasDiscountCode={quoteDiscountCode || hasDiscountCode}
            inactiveQuoteEditingError={inactiveQuoteEditingError}
            initContent={quoteContent}
            levelEstimate={expertDetails ? expertDetails.levelEstimate : ''}
            onDeleteDraft={onDeleteQuoteDraft}
            onNextStep={onQuoteCheckLength}
            onSaveDraft={onSaveQuoteDraft}
            paymentPrice={quotePaymentPrice}
            paymentType={quotePaymentType}
            quoteTemplates={quoteTemplates}
            status={quoteStatus}
            timelineDate={quoteTimelineDate}
            timelineDays={quoteTimelineDays}
            timelineType={quoteTimelineType}
            title={quoteTitle}
          />
        </div>
      );
    }
    if (currentAction === 'QUOTE-EMAIL') {
      return (
        <div
          className={
            'ThreadActionEmail ' +
            (sendLoading ? ' ThreadActionEmailSending ' : '')
          }
        >
          <EmailEditor
            backToQuoteEditor={() => setCurrentAction('QUOTE')}
            emailTemplates={emailTemplates}
            expertId={expertId}
            expertEmailAddress={
              expertDetails && expertDetails.primaryPublicEmail
                ? expertDetails.primaryPublicEmail
                : ''
            }
            expertPhoneNumber={
              expertDetails && expertDetails.primaryPublicPhone
                ? expertDetails.primaryPublicPhone
                : ''
            }
            expertSchedulePath={
              expertDetails &&
              expertDetails.scheduleTimeEnabled &&
              expertDetails.profilePath
                ? expertDetails.profilePath
                : ''
            }
            files={quoteEmailFiles}
            goEditContacts={goEditContacts}
            inactiveQuoteEditingError={inactiveQuoteEditingError}
            initContent={quoteEmailContent}
            onDeleteDraft={
              quoteActionType === 'COMPLETE'
                ? onDeleteQuoteDraft
                : onDeleteQuoteEmailDraft
            }
            onSaveDraft={onSaveQuoteEmailDraft}
            onSendEmail={onSendQuoteEmail}
            quoteActionType={quoteActionType}
            quotePaymentPrice={quotePaymentPrice}
            quotePaymentType={quotePaymentType}
            quoteTitle={quoteTitle}
            recipients={quoteEmailRecipients}
            subject={quoteEmailSubject}
            status={quoteEmailStatus}
            switchToTextEditor={(contentStr?: string) =>
              openQuoteTextEditor(
                quoteEmailRecipients.map((r) => r.id),
                contentStr,
              )
            }
            team={emailBrandTeam}
          />
        </div>
      );
    }
    if (currentAction === 'QUOTE-TEXT') {
      return (
        <div
          className={
            'ThreadActionText ' +
            (sendLoading ? ' ThreadActionTextSending ' : '')
          }
        >
          <TextEditor
            backToQuoteEditor={() => setCurrentAction('QUOTE')}
            expertId={expertId}
            expertEmailAddress={
              expertDetails && expertDetails.primaryPublicEmail
                ? expertDetails.primaryPublicEmail
                : ''
            }
            expertPhoneNumber={
              expertDetails && expertDetails.primaryPublicPhone
                ? expertDetails.primaryPublicPhone
                : ''
            }
            expertSchedulePath={
              expertDetails &&
              expertDetails.scheduleTimeEnabled &&
              expertDetails.profilePath
                ? expertDetails.profilePath
                : ''
            }
            files={quoteTextFiles}
            goEditContacts={goEditContacts}
            inactiveQuoteEditingError={inactiveQuoteEditingError}
            content={quoteTextContent}
            onDeleteDraft={
              quoteActionType === 'COMPLETE'
                ? onDeleteQuoteDraft
                : onDeleteQuoteTextDraft
            }
            onSaveDraft={onSaveQuoteTextDraft}
            onSendText={onSendQuoteText}
            quoteActionType={quoteActionType}
            quotePaymentPrice={quotePaymentPrice}
            quotePaymentType={quotePaymentType}
            quoteTitle={quoteTitle}
            recipients={quoteTextRecipients}
            status={quoteTextStatus}
            switchToEmailEditor={(contentStr?: string) =>
              openQuoteEmailEditor(
                undefined,
                quoteTextRecipients.map((r) => r.id),
                contentStr,
              )
            }
            team={phoneBrandTeam}
          />
        </div>
      );
    }
    if (currentAction === 'MEETING') {
      return (
        <div
          className={
            'ThreadActionMeeting ' +
            (sendLoading ? ' ThreadActionMeetingSending ' : '')
          }
        >
          <MeetingEditor
            actionMeetingId={meetingActionId}
            description={meetingDescription}
            expertDetails={expertDetails}
            goEditContacts={goEditContacts}
            location={meetingLocation}
            onDeleteDraft={onDeleteMeetingDraft}
            onSaveDraft={onSaveMeetingDraft}
            onSendMeeting={onSendMeeting}
            onTimePickerOpenClose={setMeetingEditorTimePickerOpen}
            recipients={meetingRecipients}
            status={meetingStatus}
            team={emailBrandTeam}
            timeEnd={meetingTimeEnd}
            timeStart={meetingTimeStart}
            timezone={meetingTimezone}
            title={meetingTitle}
          />
        </div>
      );
    }
    if (currentAction === 'MESSAGE-HQ') {
      return (
        <div
          className={
            'ThreadActionMessageHq ' +
            (sendLoading ? ' ThreadActionMessageHqSending ' : '')
          }
        >
          <MessageHqEditor
            content={messageHqContent}
            onDeleteContent={onDeleteMessageHq}
            onSendContent={onSendMessageHq}
            onChangeContent={(newContent: string) =>
              setMessageHqContent(newContent)
            }
          />
        </div>
      );
    }
    if (currentAction === 'PHONE') {
      return (
        <div
          className={
            'ThreadActionPhone ' +
            (sendLoading ? ' ThreadActionPhoneSending ' : '')
          }
        >
          <PhoneCallEditor
            onCancel={() => setCurrentAction('')}
            goEditContacts={goEditContacts}
            onMakeCall={makePhoneCall}
            team={phoneBrandTeam}
          />
        </div>
      );
    }
    return null;
  }
  const [tryThreadSearch] = useMutation<
    MatchThreadSearchMutation,
    MatchThreadSearchMutationVariables
  >(matchThreadSearchMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!searchInput) {
      toggleSearch();
      return;
    }
    if (searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchResults([]);
    tryThreadSearch({
      update: (_cache, { data: dataThreadSearch }) => {
        setSearchLoading(false);
        if (dataThreadSearch && dataThreadSearch.threadSearch) {
          console.log('tryThreadSearch', dataThreadSearch.threadSearch);
          setSearchResults(
            dataThreadSearch.threadSearch
              .slice()
              .sort((a, b) => b.score - a.score),
          );
        }
      },
      variables: {
        limit: 5,
        matchId,
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Thread Search Error');
      logError(err, {
        component: 'ExpertClientDetail',
        func: 'tryThreadSearch',
      });
      setSearchLoading(false);
      setSearchResults(null);
    });
  }
  function onSelectSearchResult(
    s: MatchThreadSearchMutation['threadSearch'][0],
  ) {
    setSelectedSearchResult(s);
    setShowSidebar(false);
  }
  function blockClickPropagation(ev: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  function renderSearchResult(s: MatchThreadSearchMutation['threadSearch'][0]) {
    const nowMoment = moment.tz(initTime, moment.tz.guess());
    const eventMoment = moment.tz(s.node.createdAt, moment.tz.guess());
    const eventDateFull =
      eventMoment.format('MMMM Do, YYYY') +
      ' at ' +
      eventMoment.format('h:mma z');
    let eventDateSummary = eventMoment.format('M/D/YY');
    if (nowMoment.isSame(eventMoment, 'day')) {
      eventDateSummary = eventMoment.format('h:mma');
    } else if (
      nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')
    ) {
      eventDateSummary = 'Yesterday';
    } else if (nowMoment.isSame(eventMoment, 'week')) {
      eventDateSummary = eventMoment.format('dddd');
    } else if (nowMoment.isSame(eventMoment, 'year')) {
      eventDateSummary = eventMoment.format('MMMM Do');
    }
    const isFromExpert = !s.node.ownerHuman;
    const primaryHuman:
      | Extract<
          MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
          { __typename?: 'EmailMessageEvent' | undefined }
        >['ownerHuman']
      | undefined = s.node.ownerHuman || undefined;
    let humanRecipients: Exclude<
      Extract<
        MatchThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
        { __typename?: 'EmailMessageEvent' | undefined }
      >['ownerHuman'],
      null | undefined
    >[] = [];
    if (
      s.node.__typename === 'MessageEvent' ||
      s.node.__typename === 'EmailMessageEvent' ||
      s.node.__typename === 'TextMessageEvent' ||
      s.node.__typename === 'MeetingEvent' ||
      s.node.__typename === 'PhoneCallEvent'
    ) {
      humanRecipients = s.node.recipientHumans || [];
    }
    // find highlights in text
    let beforeHighlight = '';
    let inHighlight = '';
    let afterHighlight = '';
    const charsBeforeAfter = 70;
    const highlightContent = s.highlightsContent[0] || '';
    if (highlightContent && s.plainTextContent) {
      const highlightIndex = s.plainTextContent.indexOf(highlightContent);
      if (highlightIndex >= 0) {
        const beforeIndex = Math.max(0, highlightIndex - charsBeforeAfter);
        const afterIndex =
          highlightIndex + highlightContent.length + charsBeforeAfter;
        const textAroundHighlight =
          (beforeIndex > 0 ? '...' : '') +
          s.plainTextContent.slice(beforeIndex, afterIndex) +
          (afterIndex < s.plainTextContent.length - 1 ? '...' : '');
        const newHighlightIndex = textAroundHighlight.indexOf(highlightContent);
        if (newHighlightIndex >= 0) {
          beforeHighlight = textAroundHighlight.slice(0, newHighlightIndex);
          inHighlight = highlightContent;
          afterHighlight = textAroundHighlight.slice(
            newHighlightIndex + highlightContent.length,
          );
        }
      }
    }
    return (
      <div
        className={
          'ThreadDetailSidebarSearchResult ' +
          (selectedSearchResult && selectedSearchResult.id === s.id
            ? ' ThreadDetailSidebarSearchResultSelected '
            : '')
        }
        key={s.id}
      >
        <div
          className="ThreadDetailSidebarSearchResultCard"
          onClick={() => onSelectSearchResult(s)}
        >
          <div className="ThreadDetailSidebarSearchResultCardRecipients">
            {primaryHuman && (
              <HumanUserBubble
                firstName={primaryHuman.firstName}
                lastName={primaryHuman.lastName}
                fallbackEmail={primaryHuman.primaryEmail}
                fallbackPhone={primaryHuman.primaryPhone}
                initialsOnly
                primary
              />
            )}
            {humanRecipients.map((r) => (
              <HumanUserBubble
                key={r.id}
                firstName={r.firstName}
                lastName={r.lastName}
                fallbackEmail={r.primaryEmail}
                fallbackPhone={r.primaryPhone}
                initialsOnly
              />
            ))}
          </div>
          {!!isFromExpert && (
            <div className="ThreadDetailSidebarSearchResultCardFromExpert">
              <ExpertUserBubble
                expertId={expertId}
                expertDetails={expertDetails}
                primary
              />
            </div>
          )}
          <div className="ThreadDetailSidebarSearchResultCardSubject">
            {s.plainTextSubject || ''}
          </div>
          {!inHighlight && (
            <div className="ThreadDetailSidebarSearchResultCardText">
              {s.plainTextContent.length > 2 * charsBeforeAfter
                ? s.plainTextContent.slice(0, 2 * charsBeforeAfter) + '...'
                : s.plainTextContent}
            </div>
          )}
          {inHighlight && (
            <div className="ThreadDetailSidebarSearchResultCardText">
              {beforeHighlight}
              <span>{inHighlight}</span>
              {afterHighlight}
            </div>
          )}
        </div>
        <div className="ThreadDetailSidebarSearchResultBottom">
          <div className="ThreadDetailSidebarSearchResultBottomLeft">
            {searchResultType(s.node.__typename!)}
          </div>
          <div
            className="ThreadDetailSidebarSearchResultBottomRight"
            data-tip={eventDateFull}
          >
            {eventDateSummary}
          </div>
        </div>
      </div>
    );
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      toggleSearch();
    }
  }
  function toggleSearch() {
    const nextShowSearch = !showSearch;
    setSearchInput('');
    setSearchResults(null);
    setSelectedSearchResult(null);
    setSearchLoading(false);
    setShowSearch(nextShowSearch);
    if (nextShowSearch) {
      setTimeout(() => {
        if (searchRef && searchRef.current) {
          searchRef.current.focus();
        }
      });
    }
  }
  const [tryEditBrand] = useMutation<
    BrandEditByExpertMutation,
    BrandEditByExpertMutationVariables
  >(brandEditByExpertMutation);
  function onSaveBrandEdits(
    name: string,
    url: string,
    description?: string,
    shopifyAdminURL?: string,
  ) {
    if (actionLoading || !matchDetails || !matchDetails.brand) {
      return;
    }
    if (!(name || '').trim() || !(url || '').trim()) {
      addNotification(
        'Please submit a brand name and url at least.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryEditBrand({
      optimisticResponse: {
        brandEditByExpert: {
          ...matchDetails.brand,
          ...(description && { description }),
          name,
          ...(shopifyAdminURL && { shopifyAdminURL }),
          url,
        },
      },
      variables: {
        brandId: matchDetails.brand.id,
        description,
        name,
        shopifyAdminURL,
        url,
      },
    })
      .then(() => {
        setActionLoading(false);
        setBrandEditing(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Brand Edit Error');
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryEditBrand',
        });
      });
  }
  const [tryEditHuman] = useMutation<
    HumanEditByExpertMutation,
    HumanEditByExpertMutationVariables
  >(humanEditByExpertMutation);
  function onEditHuman(
    human: MatchDetailsQuery['matchDetails']['brand']['team'][0],
    phone?: string,
    email?: string,
    firstName?: string,
    lastName?: string,
    secondaryEmails?: string[],
  ) {
    if (actionLoading) return;
    if (!firstName && !lastName) {
      addNotification('Please submit a first or last name', undefined, 5000);
      return;
    }
    if (phone && phone.indexOf('+44') === 0) {
      addNotification(
        'Due to country restrictions, we are not able to use UK phone numbers.',
        undefined,
        5000,
      );
    }
    setActionLoading(true);
    tryEditHuman({
      optimisticResponse: {
        humanEditByExpert: {
          __typename: 'HumanWithContactError',
          contactError: null,
          human: {
            ...human,
            ...(firstName && { firstName }),
            ...(lastName && { lastName }),
            ...(email && { primaryEmail: email }),
            ...(phone && { primaryPhone: phone }),
            ...(secondaryEmails && { secondaryEmails }),
          },
        },
      },
      variables: {
        email,
        firstName,
        humanId: human.id,
        lastName,
        phone,
        secondaryEmails: (secondaryEmails || []).filter(
          (se) => !!se && !!se.trim(),
        ),
      },
    })
      .then(({ data: dataMutation }) => {
        setActionLoading(false);
        if (
          dataMutation &&
          dataMutation.humanEditByExpert &&
          dataMutation.humanEditByExpert.human
        ) {
          setHumanEditing('');
        } else if (
          dataMutation &&
          dataMutation.humanEditByExpert &&
          dataMutation.humanEditByExpert.contactError &&
          dataMutation.humanEditByExpert.contactError.human
        ) {
          if (
            brandTeam.find(
              (h) =>
                h.id === dataMutation.humanEditByExpert.contactError!.human,
            )
          ) {
            // if the human is already on this brand, just show an error
            addNotification(
              `Someone on this brand's team already has this contact info: ${dataMutation.humanEditByExpert.contactError.name}`,
              undefined,
              5000,
            );
          } else {
            // otherwise do a popup and give option to do nothing or add to the team
            startExistingHumanFlow(
              dataMutation.humanEditByExpert.contactError.human,
              dataMutation.humanEditByExpert.contactError.name,
            );
          }
        } else {
          addNotification(
            'An error occured trying to edit this human. Please contact support.',
            undefined,
            5000,
          );
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Team Member Edit Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryEditHuman',
        });
      });
  }
  const [tryCreateHuman] = useMutation<
    BrandCreateHumanMutation,
    BrandCreateHumanMutationVariables
  >(brandCreateHumanMutation);
  function onCreateHuman(
    phone?: string,
    email?: string,
    firstName?: string,
    lastName?: string,
  ) {
    if (!matchDetails || !matchDetails.brand || !matchDetails.brand.id) {
      return;
    }
    if (!firstName && !lastName) {
      addNotification('Please submit a first or last name', undefined, 5000);
      return;
    }
    if (!phone && !email) {
      addNotification(
        'Please submit a valid phone or email at least',
        undefined,
        5000,
      );
      return;
    }
    if (phone && phone.indexOf('+44') === 0) {
      addNotification(
        'Due to country restrictions, we are not able to use UK phone numbers.',
        undefined,
        5000,
      );
    }
    setActionLoading(true);
    tryCreateHuman({
      optimisticResponse: {
        brandCreateHuman: {
          __typename: 'BrandWithContactError',
          brand: {
            ...matchDetails.brand,
            team: brandTeam.concat([
              {
                __typename: 'Human',
                clientFee: '',
                discountCode: '',
                estimateSpend: 0,
                expertReferralStr: null,
                firstName: firstName || '',
                id: 'tempHumanId',
                lastName: lastName || '',
                location: null,
                partnerReferral: null,
                primaryEmail: email || '',
                primaryPhone: phone || '',
                proStart: null,
                secondaryEmails: [],
              },
            ]),
          },
          contactError: null,
        },
      },
      variables: {
        brandId: matchDetails.brand.id,
        email,
        firstName,
        lastName,
        phone,
      },
    })
      .then(({ data: dataMutation }) => {
        setActionLoading(false);
        if (
          dataMutation &&
          dataMutation.brandCreateHuman &&
          dataMutation.brandCreateHuman.brand
        ) {
          setHumanEditing('');
        } else if (
          dataMutation &&
          dataMutation.brandCreateHuman &&
          dataMutation.brandCreateHuman.contactError &&
          dataMutation.brandCreateHuman.contactError.human
        ) {
          if (
            brandTeam.find(
              (h) => h.id === dataMutation.brandCreateHuman.contactError!.human,
            )
          ) {
            // if the human is already on this brand, just show an error
            addNotification(
              `Someone on this brand's team already has this contact info: ${dataMutation.brandCreateHuman.contactError.name}`,
              undefined,
              5000,
            );
          } else {
            // otherwise do a popup and give option to do nothing or add to the team
            startExistingHumanFlow(
              dataMutation.brandCreateHuman.contactError.human,
              dataMutation.brandCreateHuman.contactError.name,
            );
          }
        } else {
          addNotification(
            'An error occured trying to edit this human. Please contact support.',
            undefined,
            5000,
          );
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Add Team Member Error',
        );
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'tryCreateHuman',
        });
      });
  }
  function onSaveHumanBrandEditor(
    human?: MatchDetailsQuery['matchDetails']['brand']['team'][0],
    phone?: string,
    email?: string,
    firstName?: string,
    lastName?: string,
    secondaryEmails?: string[],
  ) {
    if (human) {
      onEditHuman(human, phone, email, firstName, lastName, secondaryEmails);
    } else {
      onCreateHuman(phone, email, firstName, lastName);
    }
  }
  const secondaryPanels = ['Up Next', 'About', 'Files', 'Notes'].filter(
    (p) => p !== primaryPanel,
  );
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
    if (panelName === 'Notes' && !calledUserNotes && matchId) {
      getUserNotes().catch((err: ApolloError) => {
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'getUserNotes',
        });
      });
    }
    if (panelName === 'About' && !calledProjectPaginated && brandId) {
      getProjectPaginated().catch((err: ApolloError) => {
        logError(err, {
          component: 'ExpertClientDetail',
          func: 'getProjectPaginated',
        });
      });
    }
  }
  let isSnoozeAllowed = !!matchDetails;
  if (
    matchDetails &&
    (matchDetails.isBlocked ||
      matchDetails.dangerZoneEventExpert ||
      (matchDetails.hasActiveProjectsStr &&
        matchDetails.hasActiveProjectsStr.length) ||
      (matchDetails.hasActiveQuotesStr &&
        matchDetails.hasActiveQuotesStr.length) ||
      (matchDetails.dangerZoneProjectsStr &&
        matchDetails.dangerZoneProjectsStr.length) ||
      (matchDetails.expertSnoozedUntil &&
        matchDetails.expertSnoozedUntil > initTime))
  ) {
    isSnoozeAllowed = false;
  }
  const suggestSnooze = !!(
    matchDetails &&
    isSnoozeAllowed &&
    matchDetails.expertUnread &&
    [
      'BRAND_BILL_PAID',
      'BRAND_BILL_PAID_REF',
      'BRAND_PROJECT_APPROVE',
      'BRAND_PROJECT_APPROVE_REF',
    ].indexOf(matchDetails.expertUnread) >= 0
  );
  const suggestReferral = !!(
    matchDetails &&
    matchDetails.expertUnread &&
    [
      'BRAND_BILL_PAID_REF',
      'BRAND_PROJECT_APPROVE_REF',
      'BRAND_SUBSCRIPTION_PAID_REF',
    ].indexOf(matchDetails.expertUnread) >= 0
  );
  let spendMax = 0;
  if (matchDetails && matchDetails.brand && matchDetails.brand.estimateSpend) {
    spendMax = matchDetails.brand.estimateSpend;
  }
  if (matchDetails && matchDetails.brand && matchDetails.brand.team) {
    matchDetails.brand.team.forEach((t) => {
      if (t.estimateSpend && t.estimateSpend > spendMax) {
        spendMax = t.estimateSpend;
      }
    });
  }
  if (
    loadingMatch &&
    loadingThread &&
    loadingMeetingActive &&
    loadingUserNotes &&
    loadingMeetingAll &&
    loadingProjectPast &&
    loadingFileActive &&
    loadingProjectPaginated &&
    errorProjectPaginated
  ) {
    // ignore these
  }
  return (
    <div className="DashboardModal ClientDetailModal">
      <div className="DashboardModalTopCircle" />
      <div className="DashboardModalBottomCircle" />
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '') +
          (selectedSearchResult ||
          unclaimShow ||
          cancelProjectShow ||
          cancelMeetingShow ||
          shortQuoteConfirm ||
          undoProjectShow ||
          anotherAnytimeShow ||
          allClientsMoveShow ||
          existingHumanId ||
          viewProjectShow ||
          markCompleteChecklistShow
            ? ' ThreadDetailViewWithModal '
            : '')
        }
      >
        <div
          className={
            'ThreadDetailMain ' +
            (meetingEditorTimePickerOpen ? 'ThreadDetailMainIncreaseZ' : '')
          }
        >
          <div className="ThreadDetailMainHeader">
            <Link to="/clients" className="ThreadDetailMainHeaderBack">
              {matchDetails && matchDetails.brand
                ? matchDetails.brand.name
                : 'Clients'}
            </Link>
            <div
              className={
                'ThreadDetailMainHeaderPin ' +
                (matchDetails?.expertPinned
                  ? ' ThreadDetailMainHeaderPinYes '
                  : '')
              }
              onClick={() => onPinToggle(!matchDetails?.expertPinned)}
            >
              {matchDetails?.expertPinned ? 'pinned' : 'pin'}
            </div>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div className="ThreadDetailMainBody" ref={threadEventsWindow}>
            <div
              className={
                'ThreadEvents ' +
                (currentAction ? ' ThreadEventsHasAction ' : '')
              }
            >
              {threadEventGroups.map((evGroup) => (
                <div className="ThreadEventGroup" key={evGroup.group[0].id}>
                  {generateComponentsForGroup(evGroup.group)}
                </div>
              ))}
            </div>
            {!!currentAction && !!matchDetails && (
              <div className="ThreadCurrentAction">
                {renderCurrentActionEditor()}
              </div>
            )}
          </div>
          <div
            className={
              'ThreadDetailActions ' +
              (openActions ? ' ThreadDetailActionsOpen ' : '') +
              (currentAction ? ' ThreadDetailActionsCurrent ' : '')
            }
          >
            <div className="ThreadDetailActionRow">
              <div
                className="ThreadDetailActionItem"
                onClick={() => openTextEditor()}
              >
                <span>send a </span>text
              </div>
              <div
                className="ThreadDetailActionItem"
                onClick={() => openEmailEditor()}
              >
                <span>send an </span>email
              </div>
              {expertDetails && expertDetails.status !== 'no-match-quote' && (
                <div
                  className="ThreadDetailActionItem"
                  onClick={() => openQuoteEditor('CREATE')}
                >
                  <span>send a </span>quote
                </div>
              )}
            </div>
            <div className="ThreadDetailActionRow">
              <div
                className="ThreadDetailActionTrigger"
                onClick={() =>
                  currentAction
                    ? closeCurrentAction()
                    : setOpenActions(!openActions)
                }
              >
                <div className="ThreadDetailActionBtn">+</div>
                <div className="ThreadDetailActionText">actions</div>
              </div>
              <div
                className="ThreadDetailActionItem"
                onClick={() => openMessageHqEditor()}
              >
                <span>get </span>help<span> with client</span>
              </div>
              <div
                className="ThreadDetailActionItem"
                onClick={() => openPhoneCallEditor()}
              >
                <span>make a </span>call
              </div>
              <div
                className="ThreadDetailActionItem"
                onClick={() => openMeetingEditor()}
              >
                meeting<span> invite</span>
              </div>
            </div>
          </div>
          {!!selectedSearchResult && (
            <div className="ThreadDetailMainFocusModal" onClick={toggleSearch}>
              <div
                className="ThreadDetailMainFocusModalContainer ThreadDetailMainFocusModalContainerSearch"
                onClick={blockClickPropagation}
              >
                <ThreadSearchResults
                  expertId={expertId}
                  matchId={matchId}
                  searchResultFound={selectedSearchResult}
                />
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={toggleSearch}
                >
                  close
                </div>
              </div>
            </div>
          )}
          {!!unclaimShow && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelUnclaimFlow}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <UnclaimActions
                      onUnclaim={onUnclaim}
                      isSwitch={unclaimShow === 'SWITCH'}
                      isLeadCloseTimelinePast={leadCloseTimelinePast}
                      hasActiveProjects={!!activeProjects.length}
                      leadMatchedDaysAgo={leadMatchedDaysAgo}
                    />
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelUnclaimFlow}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!cancelProjectShow && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={closeCancelProjectFlow}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <ProjectCancelActions
                      onCancel={cancelQuote}
                      project={cancelProjectShow}
                    />
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={closeCancelProjectFlow}
                    >
                      close
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!shortQuoteConfirm && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={() => setShortQuoteConfirm(false)}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Looks like a very short quote...
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        Writing a detailed quote (with a thorough scope) has
                        been shown to improve conversion rates and dramatically
                        reduce support issues down the line: scope creep,
                        miscommunication, refunds, etc.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to send this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() => onQuoteNextStep(quoteContent)}
                          >
                            Yes, continue
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={() => setShortQuoteConfirm(false)}
                          >
                            No, add more detail
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={() => setShortQuoteConfirm(false)}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!anotherAnytimeShow && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelAnotherAnytime}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyAnotherAnytime">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Help them meet another expert
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        This will alert the Marketplace Success team who will
                        personally help them meet another member in the network.
                        Don&apos;t worry, you&apos;ll still be able to work with
                        them in the future.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Reason
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (anotherAnytimeReason === 'SKILLS_FIT'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() =>
                              setAnotherAnytimeReason('SKILLS_FIT')
                            }
                          >
                            skills required
                          </div>
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (anotherAnytimeReason === 'TIMEZONE_FIT'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() =>
                              setAnotherAnytimeReason('TIMEZONE_FIT')
                            }
                          >
                            time zone issue
                          </div>
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (anotherAnytimeReason === 'BUDGET_LOW'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() =>
                              setAnotherAnytimeReason('BUDGET_LOW')
                            }
                          >
                            client&apos;s budget is too low
                          </div>
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (anotherAnytimeReason === 'TOO_BUSY'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() => setAnotherAnytimeReason('TOO_BUSY')}
                          >
                            I&apos;m too busy at the moment
                          </div>
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (anotherAnytimeReason === 'CULTURE_FIT'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() =>
                              setAnotherAnytimeReason('CULTURE_FIT')
                            }
                          >
                            culture fit
                          </div>
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (anotherAnytimeReason === 'OTHER'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() => setAnotherAnytimeReason('OTHER')}
                          >
                            other
                          </div>
                        </div>
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Can you tell us a bit more?
                        </div>
                        <div className="ThreadDetailMainFocusModalSummarizeInput">
                          <TextareaAutosize
                            type="text"
                            rows={5}
                            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                            spellCheck="true"
                            className="ThreadDetailMainFocusModalSummarizeTextarea"
                            value={anotherAnytimeMoreDetail}
                            onChange={(e) => {
                              setAnotherAnytimeMoreDetail(
                                e.currentTarget.value,
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="BasicFormFooter">
                        <div
                          className={
                            'BasicFormAction ' +
                            (!anotherAnytimeMoreDetail || !anotherAnytimeReason
                              ? ' BasicFormActionInvalid '
                              : '')
                          }
                          onClick={anotherAnytimeSubmit}
                        >
                          Submit
                        </div>
                        <div
                          className="BasicFormActionInstead"
                          onClick={cancelAnotherAnytime}
                        >
                          never mind
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelAnotherAnytime}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!allClientsMoveShow && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelAllClientsMove}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyAllClientsMove">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Not Moving Forward? Move To All Clients
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        Don&apos;t worry - it happens! We&apos;ll adjust the tag
                        to lost for now, and move them into your all clients
                        list for later
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Reason
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (allClientsMoveReason === 'CLIENT_UNRESPONSIVE'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() =>
                              setAllClientsMoveReason('CLIENT_UNRESPONSIVE')
                            }
                          >
                            client never replied
                          </div>
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (allClientsMoveReason === 'SKILLS_FIT'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() =>
                              setAllClientsMoveReason('SKILLS_FIT')
                            }
                          >
                            skills required
                          </div>
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (allClientsMoveReason === 'BUDGET_LOW'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() =>
                              setAllClientsMoveReason('BUDGET_LOW')
                            }
                          >
                            client&apos;s budget is too low
                          </div>
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (allClientsMoveReason === 'FOUND_SOMEONE_ELSE'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() =>
                              setAllClientsMoveReason('FOUND_SOMEONE_ELSE')
                            }
                          >
                            client hired someone else
                          </div>
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (allClientsMoveReason ===
                              'NO_LONGER_PURSUING_THIS_PROJECT'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() =>
                              setAllClientsMoveReason(
                                'NO_LONGER_PURSUING_THIS_PROJECT',
                              )
                            }
                          >
                            client put the project on hold
                          </div>
                          <div
                            className={
                              'ThreadDetailMainFocusModalChoicesOption ' +
                              (allClientsMoveReason === 'OTHER'
                                ? 'ThreadDetailMainFocusModalChoicesOptionActive'
                                : '')
                            }
                            onClick={() => setAllClientsMoveReason('OTHER')}
                          >
                            other
                          </div>
                        </div>
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Another other notes?
                        </div>
                        <div className="ThreadDetailMainFocusModalSummarizeInput">
                          <TextareaAutosize
                            type="text"
                            rows={5}
                            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                            spellCheck="true"
                            className="ThreadDetailMainFocusModalSummarizeTextarea"
                            value={allClientsMoveMoreDetail}
                            onChange={(e) => {
                              setAllClientsMoveMoreDetail(
                                e.currentTarget.value,
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="BasicFormFooter">
                        <div
                          className={
                            'BasicFormAction ' +
                            (!allClientsMoveReason
                              ? ' BasicFormActionInvalid '
                              : '')
                          }
                          onClick={archiveOldLead}
                        >
                          Submit
                        </div>
                        <div
                          className="BasicFormActionInstead"
                          onClick={cancelAllClientsMove}
                        >
                          never mind
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelAllClientsMove}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!cancelMeetingShow && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={closeCancelMeetingFlow}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Cancel Meeting
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        All the meeting participants will receive a canceled
                        notification to remove it from their calendars.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelMeeting}
                          >
                            Yes, cancel this meeting
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={closeCancelMeetingFlow}
                          >
                            No, never mind, don&apos;t cancel
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={closeCancelMeetingFlow}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!undoProjectShow && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={closeUndoProjectFlow}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Undo Mark Complete
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        Your client will NOT receive an automated notification
                        about this. Please make sure to let them know that you
                        are still actively working on this project.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={undoMarkCompleteQuote}
                          >
                            Yes, undo mark complete for this project
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={closeUndoProjectFlow}
                          >
                            No, keep this project marked complete
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={closeUndoProjectFlow}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!existingHumanId && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={closeExistingHumanFlow}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Another Person Found
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        We found another client with the same contact info:{' '}
                        {existingHumanName}
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          What should we do?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={addExistingToTeam}
                          >
                            Add {existingHumanName} as another member on this
                            brand&apos;s team
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={closeExistingHumanFlow}
                          >
                            Ignore, I&apos;ll use different contact info for
                            this person
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={closeExistingHumanFlow}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!viewProjectShow && !!viewProjectShow.quote && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={closeViewProjectFlow}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                  <div className="ThreadDetailMainFocusModalContainerTitle">
                    {viewProjectShow.quote.title || 'Project Details'}
                  </div>
                  {!!nextPaymentStr && (
                    <div className="ThreadDetailMainFocusModalContainerSubtitle ThreadDetailMainFocusModalContainerSubtitleLowMargin">
                      Next payment: {nextPaymentStr}
                    </div>
                  )}
                  <div className="ThreadDetailMainFocusModalProject">
                    <div className="ThreadDetailMainFocusModalProjectSection">
                      <div className="ThreadDetailMainFocusModalProjectSectionTitle">
                        Description
                      </div>
                      {!!viewProjectShow.quote.description && (
                        <ReadOnlyEditor
                          className="ThreadDetailMainFocusModalProjectSectionContent"
                          content={viewProjectShow.quote.description}
                        />
                      )}
                    </div>
                    {!!viewProjectShow.quote.revisionsRequested && (
                      <div className="ThreadDetailMainFocusModalProjectSection">
                        <div className="ThreadDetailMainFocusModalProjectSectionTitle">
                          Revisions Requested
                        </div>
                        <ReadOnlyEditor
                          className="ThreadDetailMainFocusModalProjectSectionContent"
                          content={viewProjectShow.quote.revisionsRequested}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={closeViewProjectFlow}
                >
                  close
                </div>
              </div>
            </div>
          )}
          {!!markCompleteChecklistShow && !!markCompleteChecklistShow.quote && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={closeMarkCompleteChecklistFlow}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                  <div className="ThreadDetailMainFocusModalContainerDetailedTitle">
                    {markCompleteChecklistShow.quote.title || 'Project Details'}
                    <div className="TitleRight">
                      <div className="TitlePrice">Price</div>
                      <div className="TitlePriceValue">
                        $
                        {formatNumberWithCommas(
                          centsDollarsRounded(
                            markCompleteChecklistShow.quote.cents,
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="ThreadDetailMainFocusModalProject">
                    <div className="ThreadDetailMainFocusModalProjectSection">
                      <div className="ThreadDetailMainFocusModalProjectSectionTitle">
                        Description
                      </div>
                      {!!markCompleteChecklistShow.quote.description && (
                        <ReadOnlyEditor
                          className="ThreadDetailMainFocusModalProjectSectionContent ProjectSectionContentScrollable"
                          content={markCompleteChecklistShow.quote.description}
                        />
                      )}
                    </div>
                    <div className="ThreadDetailMainFocusModalProjectSection LastProjectSection">
                      <div className="ThreadDetailMainFocusModalProjectSectionTitle">
                        Deadline
                      </div>
                      <div className="ThreadDetailMainFocusModalProjectSectionContent ProjectSectionContentMedium">
                        {moment
                          .utc(
                            markCompleteChecklistShow.quote
                              .estimatedCompletionDate,
                          )
                          .format('MMMM Do')}
                      </div>
                    </div>
                    <div className="ThreadDetailMainFocusModalProjectChecklist">
                      <div className="ThreadDetailMainFocusModalProjectChecklistItem">
                        Did you double check the scope?
                        <input
                          type="checkbox"
                          key={'checkOne-' + checkOne.toString()}
                          checked={checkOne}
                          onChange={() => setCheckOne((prev) => !prev)}
                        />
                      </div>
                      <div className="ThreadDetailMainFocusModalProjectChecklistItem">
                        Did you QA the work?
                        <input
                          type="checkbox"
                          key={'checkTwo-' + checkTwo.toString()}
                          checked={checkTwo}
                          onChange={() => setCheckTwo((prev) => !prev)}
                        />
                      </div>
                      <div className="ThreadDetailMainFocusModalProjectChecklistItem">
                        {"Did your client already confirm that they're happy?"}
                        <input
                          type="checkbox"
                          key={'checkThree-' + checkThree.toString()}
                          checked={checkThree}
                          onChange={() => setCheckThree((prev) => !prev)}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        'ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionGood ThreadDetailMainFocusModalChecklistAction ' +
                        (!checkOne || !checkTwo || !checkThree
                          ? ' ThreadDetailMainFocusModalChecklistActionInvalid '
                          : ' ')
                      }
                      onClick={() =>
                        startMarkComplete(markCompleteChecklistShow)
                      }
                    >
                      Mark Complete
                    </div>
                  </div>
                </div>
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={closeMarkCompleteChecklistFlow}
                >
                  close
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div className="ThreadDetailSidebar">
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
            <div
              className={
                'ThreadDetailSidebarHeaderSearch ThreadDetailSidebarHeaderSearch' +
                (showSearch ? 'Active ' : '')
              }
            >
              <div className="ThreadDetailSidebarHeaderSearchBar">
                <input
                  type="text"
                  ref={searchRef}
                  className="ThreadDetailSidebarHeaderSearchBarInput"
                  placeholder="Search"
                  autoComplete="new-off"
                  spellCheck="false"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={handleSearchInputKeyDown}
                />
                <div
                  className="ThreadDetailSidebarHeaderSearchToggle"
                  onClick={toggleSearch}
                />
              </div>
            </div>
          </div>
          <div className="ThreadDetailSidebarBody">
            {!searchLoading && searchResults === null ? (
              <div className="ThreadDetailSidebarBodyPanels">
                {primaryPanel === 'Up Next' && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelUpNext">
                    <div className="ThreadDetailSidebarUpNextCurrent">
                      {matchDetails && matchDetails.expertUnread && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          {suggestReferral ? (
                            <div className="ThreadDetailSidebarUpNextReferral">
                              <div className="ThreadDetailSidebarUpNextReferralSummary">
                                They just gave you great feedback. Now could be
                                the perfect time to ask for a client referral.
                              </div>
                              <div
                                className="ThreadDetailSidebarUpNextReferralBtn"
                                onClick={() => askForReferral()}
                              >
                                ask for a referral
                              </div>
                              <div
                                className="ThreadDetailSidebarUpNextReferralClose"
                                onClick={() => markAsReadUnread()}
                              />
                            </div>
                          ) : (
                            <div
                              className="ThreadDetailSidebarUpNextUnread"
                              onClick={() => markAsReadUnread()}
                            >
                              <div className="ThreadDetailSidebarUpNextUnreadSummary">
                                {unreadReasonFormatted(
                                  matchDetails.expertUnread,
                                )}
                              </div>
                              <div className="ThreadDetailSidebarUpNextUnreadClose" />
                            </div>
                          )}
                        </div>
                      )}
                      {matchDetails && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div className="ThreadDetailSidebarTagger">
                            <div className="ThreadDetailSidebarTaggerTitle">
                              Client Tag
                            </div>
                            <div
                              className={
                                'ThreadDetailSidebarTaggerCurrent ThreadDetailSidebarTaggerCurrent' +
                                (matchDetails.expertTagged || '')
                              }
                            >
                              {expertTaggedName(matchDetails.expertTagged)}
                            </div>
                            <div className="ThreadDetailSidebarTaggerSelect">
                              <select
                                className="ThreadDetailSidebarTaggerSelectInput"
                                required
                                value={matchDetails.expertTagged || ''}
                                onChange={(e) =>
                                  onTagged(e.currentTarget.value)
                                }
                              >
                                <option value="">Active</option>
                                <option value="WARM">Keep Warm</option>
                                <option value="COLD">Cold</option>
                                <option value="LOST">Lost</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {matchDetails &&
                        matchDetails.expertSnoozedUntil &&
                        matchDetails.expertSnoozedUntil > initTime && (
                          <div className="ThreadDetailSidebarUpNextItem">
                            <div
                              className="ThreadDetailSidebarUpNextUnread ThreadDetailSidebarUpNextSnooze"
                              onClick={() => onSnooze()}
                            >
                              <div className="ThreadDetailSidebarUpNextUnreadSummary">
                                Snoozed until:{' '}
                                {moment(matchDetails.expertSnoozedUntil).format(
                                  'MMMM Do',
                                )}
                              </div>
                              <div className="ThreadDetailSidebarUpNextUnreadClose" />
                            </div>
                          </div>
                        )}
                      {!!suggestSnooze && (
                        <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                          <div className="ThreadDetailSidebarBodyPanelEmpty">
                            <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                              Set a reminder to follow up!
                            </div>
                            <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                              Set a reminder below to follow up with this client
                              in a few weeks and work with them again!
                            </div>
                          </div>
                        </div>
                      )}
                      {!!emailDraft && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardDraft ThreadDetailSidebarUpNextCardDraftEmail">
                            <div
                              className="ThreadDetailSidebarUpNextCardHeader"
                              onClick={() => openEmailEditor()}
                            >
                              <div className="ThreadDetailSidebarUpNextCardType">
                                Draft Email
                              </div>
                              <div className="ThreadDetailSidebarUpNextCardSubtitle">
                                {emailSubject}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!!textDraft && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardDraft ThreadDetailSidebarUpNextCardDraftText">
                            <div
                              className="ThreadDetailSidebarUpNextCardHeader"
                              onClick={() => openTextEditor()}
                            >
                              <div className="ThreadDetailSidebarUpNextCardType">
                                Draft Text
                              </div>
                              <div className="ThreadDetailSidebarUpNextCardSubtitle">
                                {textContent}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!!meetingDraft && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardDraft ThreadDetailSidebarUpNextCardDraftMeeting">
                            <div
                              className="ThreadDetailSidebarUpNextCardHeader"
                              onClick={() => openMeetingEditor()}
                            >
                              <div className="ThreadDetailSidebarUpNextCardType">
                                Draft Meeting
                              </div>
                              <div className="ThreadDetailSidebarUpNextCardSubtitle">
                                {meetingTitle}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!!quoteDraft && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardDraft ThreadDetailSidebarUpNextCardDraftQuote">
                            <div
                              className="ThreadDetailSidebarUpNextCardHeader"
                              onClick={() =>
                                openQuoteEditor(quoteActionType || 'CREATE')
                              }
                            >
                              <div className="ThreadDetailSidebarUpNextCardType">
                                {quoteActionType === 'CREATE'
                                  ? 'Draft Quote'
                                  : 'Project Update'}
                              </div>
                              <div className="ThreadDetailSidebarUpNextCardSubtitle">
                                {quoteTitle}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {matchDetails && !!matchDetails.isLead && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardLead">
                            <div
                              className="ThreadDetailSidebarUpNextCardHeader"
                              onClick={() => setLeadExpanded(!leadExpanded)}
                            >
                              <div className="ThreadDetailSidebarUpNextCardType">
                                Lead To Close
                              </div>
                              <div className="ThreadDetailSidebarUpNextCardTitle">
                                {matchDetails && matchDetails.brand
                                  ? matchDetails.brand.name
                                  : ''}
                              </div>
                              <div
                                className={
                                  'ThreadDetailSidebarUpNextCardStatusLine CardStatusLine' +
                                  (leadProjectWithQuoteSent
                                    ? 'CREATED'
                                    : 'CANCELED')
                                }
                              />
                              <div className="ThreadDetailSidebarUpNextCardStatus">
                                {leadProjectWithQuoteSent
                                  ? 'Quote Sent'
                                  : 'Needs A Quote'}
                              </div>
                            </div>
                            {!!leadExpanded && (
                              <div className="ThreadDetailSidebarUpNextCardExpanded">
                                <div className="ThreadDetailSidebarUpNextCardActions">
                                  {(!matchDetails.leadRequest ||
                                    !matchDetails.leadRequest.isActive) &&
                                    (!matchDetails.hasActiveQuotes ||
                                      !matchDetails.hasActiveQuotes.length) &&
                                    (!matchDetails.hasActiveProjects ||
                                      !matchDetails.hasActiveProjects
                                        .length) && (
                                      <div
                                        className="ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionBorder"
                                        onClick={startAllClientsMove}
                                      >
                                        Move to All Clients
                                      </div>
                                    )}
                                  {!matchDetails.wasBlocked &&
                                    matchDetails.leadRequest &&
                                    !matchDetails.leadRequest.isMultipleMatch &&
                                    !matchDetails.leadRequest.cancelledAt &&
                                    !matchDetails.leadRequest.inReview &&
                                    !!matchDetails.leadRequest.isActive &&
                                    matchDetails.leadRequest.currentMatchStr ===
                                      matchId && (
                                      <div
                                        className="ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionBorder"
                                        onClick={startUnclaimFlow}
                                      >
                                        Help Them Find Another Expert
                                      </div>
                                    )}
                                  {!matchDetails.wasBlocked &&
                                    matchDetails.leadRequest &&
                                    !matchDetails.leadRequest.isMultipleMatch &&
                                    !matchDetails.leadRequest.cancelledAt &&
                                    !matchDetails.leadRequest.inReview &&
                                    !!matchDetails.leadRequest.isActive &&
                                    matchDetails.leadRequest.currentMatchStr ===
                                      matchId && (
                                      <div
                                        className="ThreadDetailSidebarUpNextCardAction ThreadDetailSidebarUpNextCardActionBorder"
                                        onClick={startCancelFlow}
                                      >
                                        Move to All Clients
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}
                          </div>
                          {leadCloseDateStr && !matchDetails.wasBlocked && (
                            <div className="ThreadDetailSidebarUpNextItemFooter">
                              {leadCloseDateStr}
                            </div>
                          )}
                        </div>
                      )}
                      {activeProjects.map(projectSidebarCard)}
                      {upNextMeetingsForMatch.map(meetingSidebarCard)}
                      {!!emptyCurrent && (
                        <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                          <div className="ThreadDetailSidebarBodyPanelEmpty">
                            <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                              Looking good
                            </div>
                            <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                              New to-do items for this client will show up here
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {pastLoaded ? (
                      <div className="ThreadDetailSidebarUpNextPastDivide">
                        <div className="ThreadDetailSidebarUpNextPastDivideLine" />
                        <div className="ThreadDetailSidebarUpNextPastDivideText">
                          Past
                        </div>
                      </div>
                    ) : (
                      <div
                        className="ThreadDetailSidebarUpNextExpandPast"
                        onClick={loadPastHistory}
                      >
                        View Past
                      </div>
                    )}
                    {!!pastLoaded && (
                      <div className="ThreadDetailSidebarUpNextPast">
                        {pastProjects.map(projectSidebarCard)}
                        {pastMeetingsForMatch.map(meetingSidebarCard)}
                        {!!emptyPast && (
                          <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                            <div className="ThreadDetailSidebarBodyPanelEmpty">
                              <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                                No history found
                              </div>
                              <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                                {errorMeetingActive ||
                                errorMeetingAll ||
                                errorProjectPast
                                  ? errorUtils.getErrorMessage(
                                      errorMeetingActive ||
                                        errorMeetingAll ||
                                        errorProjectPast!,
                                    )
                                  : 'Past projects and meetings with this client will show up here.'}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {!!hasDiscountCode && matchDetails && (
                      <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                        <div className="ThreadDetailSidebarBodyPanelEmpty">
                          <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                            Discount Code
                          </div>
                          <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                            {pitchDiscountCode(
                              matchDetails.brand.teamLeader.discountCode,
                            )}{' '}
                            You will still get paid based on the full amount you
                            quote, don&apos;t worry.
                          </div>
                        </div>
                      </div>
                    )}
                    {!!matchDetails &&
                      !!matchDetails.dangerZoneEventExpert &&
                      !!matchDetails.dangerZoneEventExpert.createdAt &&
                      !!matchDetails.dangerZoneEventExpert.isSupportCase && (
                        <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                          <div className="ThreadDetailSidebarBodyPanelEmpty ThreadDetailSidebarBodyPanelEmptySupportCase">
                            <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                              Active Support Case
                            </div>
                            <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                              Last updated on{' '}
                              {moment(
                                matchDetails.dangerZoneEventExpert.createdAt,
                              ).format('MMMM Do')}
                            </div>
                            <div className="ThreadDetailSidebarBodyPanelEmptyCaseDesc">
                              {matchDetails.dangerZoneEventExpert
                                .dangerZoneAdditionalDescription ||
                                'Reach out to our Support Team for more details.'}
                            </div>
                          </div>
                        </div>
                      )}
                    {matchDetails &&
                      matchDetails.brand &&
                      matchDetails.brand.teamLeader &&
                      matchDetails.brand.teamLeader.expertReferralStr &&
                      matchDetails.brand.teamLeader.expertReferralStr ===
                        expertId && (
                        <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                          <div className="ThreadDetailSidebarBodyPanelEmpty">
                            <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                              You brought this client
                            </div>
                            <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                              Storetasker only takes a 3% cut on clients you
                              bring to the platform and you&apos;re welcome to
                              charge them any hourly rate you like.
                            </div>
                          </div>
                        </div>
                      )}
                    {!!matchDetails &&
                      (!matchDetails.expertUnread ||
                        !!isSnoozeAllowed ||
                        !matchDetails.isLead) && (
                        <div className="ThreadDetailSidebarSection">
                          {!matchDetails.expertUnread && (
                            <div
                              className="ThreadDetailSidebarAdminAction"
                              onClick={() =>
                                markAsReadUnread('MANUAL_MARKED_UNREAD')
                              }
                            >
                              mark as unread
                            </div>
                          )}
                          {!!isSnoozeAllowed && (
                            <div className="ThreadDetailSidebarAdminAction">
                              set a reminder to follow up
                              <div className="ThreadDetailSidebarSnoozePicker">
                                <SingleDatePicker
                                  date={null}
                                  onDateChange={onSnooze}
                                  focused={dateSnoozeFocused}
                                  onFocusChange={({ focused }) =>
                                    setDateSnoozeFocused(focused || false)
                                  }
                                  showClearDate={false}
                                  displayFormat=""
                                  noBorder
                                  hideKeyboardShortcutsPanel
                                  numberOfMonths={1}
                                  isOutsideRange={(day) =>
                                    day.isSame(moment(), 'day') ||
                                    day.isBefore(moment())
                                  }
                                  withPortal
                                  id="uniqueClientId"
                                  placeholder="Snooze"
                                  block
                                />
                              </div>
                            </div>
                          )}
                          {!matchDetails.isLead && (
                            <div
                              className="ThreadDetailSidebarAdminAction"
                              onClick={startAnotherAnytime}
                            >
                              help them find another expert
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                )}
                {primaryPanel === 'About' && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                    {!!humanEditing && (
                      <HumanBrandSidebarEditor
                        human={
                          humanEditing === 'new'
                            ? undefined
                            : brandTeam.find((h) => h.id === humanEditing)
                        }
                        isActionLoading={actionLoading}
                        onCancel={() => setHumanEditing('')}
                        onSave={onSaveHumanBrandEditor}
                      />
                    )}
                    {!!brandEditing && matchDetails && matchDetails.brand && (
                      <BrandSidebarEditor
                        brand={matchDetails.brand}
                        isActionLoading={actionLoading}
                        onCancel={() => setBrandEditing(false)}
                        onSave={onSaveBrandEdits}
                      />
                    )}
                    {!humanEditing && !brandEditing && (
                      <Fragment>
                        <div className="ThreadDetailSidebarAboutSection">
                          <div className="ThreadDetailSidebarAboutSectionTitle">
                            Brand
                          </div>
                          <div className="ThreadDetailSidebarAboutTitle">
                            {matchDetails && matchDetails.brand
                              ? matchDetails.brand.name
                              : ''}
                          </div>
                          {matchDetails &&
                            matchDetails.brand &&
                            matchDetails.brand.createdAt && (
                              <div className="ThreadDetailSidebarAboutSubtitle">
                                Signed up:{' '}
                                {moment(matchDetails.brand.createdAt).format(
                                  'MMMM Do, YYYY',
                                )}
                              </div>
                            )}
                          <div className="ThreadDetailSidebarAboutDescription">
                            {(matchDetails &&
                              matchDetails.brand &&
                              matchDetails.brand.description) ||
                              'No description found.'}
                          </div>
                          {!!hasDiscountCode && matchDetails && (
                            <Fragment>
                              <div className="ThreadDetailSidebarAboutSectionTitle">
                                Discount Code
                              </div>
                              <div className="ThreadDetailSidebarAboutDescription">
                                {pitchDiscountCode(
                                  matchDetails.brand.teamLeader.discountCode,
                                )}{' '}
                                You will still get paid based on the full amount
                                you quote, don&apos;t worry.
                              </div>
                            </Fragment>
                          )}
                          <div className="ThreadDetailSidebarAboutSectionTitle">
                            Website
                          </div>
                          {matchDetails &&
                            matchDetails.brand &&
                            matchDetails.brand.shopifyAdminURL && (
                              <div className="ThreadDetailSidebarAboutWebsite">
                                <a
                                  className="ThreadDetailSidebarAboutWebsiteLink"
                                  href={
                                    'http://' +
                                    matchDetails.brand.shopifyAdminURL
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {matchDetails.brand.shopifyAdminURL}
                                </a>
                                <div
                                  className="ThreadDetailSidebarAboutWebsiteLinkCopy"
                                  onClick={() => {
                                    copyToClipboard(
                                      matchDetails.brand.shopifyAdminURL || '',
                                    ).catch(() => {});
                                  }}
                                />
                              </div>
                            )}
                          {matchDetails &&
                            matchDetails.brand &&
                            matchDetails.brand.url && (
                              <div className="ThreadDetailSidebarAboutWebsite">
                                <a
                                  className="ThreadDetailSidebarAboutWebsiteLink"
                                  href={'http://' + matchDetails.brand.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {matchDetails.brand.url}
                                </a>
                                <div
                                  className="ThreadDetailSidebarAboutWebsiteLinkCopy"
                                  onClick={() => {
                                    copyToClipboard(
                                      matchDetails.brand.url || '',
                                    ).catch(() => {});
                                  }}
                                />
                              </div>
                            )}
                          {matchDetails &&
                            matchDetails.brand &&
                            matchDetails.brand.shopifyStorefrontPassword &&
                            matchDetails.brand.shopifyStorefrontPassword !==
                              'has-secret-password' && (
                              <div className="ThreadDetailSidebarAboutDescription">
                                Storefront Password:{' '}
                                {matchDetails.brand.shopifyStorefrontPassword}
                              </div>
                            )}
                          {!!matchDetails &&
                            !!matchDetails.brand &&
                            !!(
                              matchDetails.brand.categoryType ||
                              matchDetails.brand.estimateEmployees ||
                              matchDetails.brand.estimateRevenue ||
                              matchDetails.brand.estimateTheme ||
                              matchDetails.brand.instagramFollowers ||
                              matchDetails.brand.isShopifyPlus
                            ) && (
                              <Fragment>
                                <div className="ThreadDetailSidebarAboutSectionTitle ThreadDetailSidebarAboutSectionTitleExtraTop">
                                  Details
                                </div>
                                {!!matchDetails.brand.isShopifyPlus && (
                                  <div className="ThreadDetailSidebarAboutSubtitle">
                                    Plan: Shopify Plus
                                  </div>
                                )}
                                {!!matchDetails.brand.categoryType && (
                                  <div className="ThreadDetailSidebarAboutSubtitle">
                                    Category: {matchDetails.brand.categoryType}
                                  </div>
                                )}
                                {!!matchDetails.brand.estimateEmployees && (
                                  <div className="ThreadDetailSidebarAboutSubtitle">
                                    Est. Employees:{' '}
                                    {kpiNumber(
                                      matchDetails.brand.estimateEmployees,
                                    )}
                                  </div>
                                )}
                                {!!matchDetails.brand.estimateRevenue && (
                                  <div className="ThreadDetailSidebarAboutSubtitle">
                                    Est. Monthly Revenue:{' '}
                                    {kpiDollars(
                                      matchDetails.brand.estimateRevenue,
                                    )}
                                  </div>
                                )}
                                {!!matchDetails.brand.estimateTheme && (
                                  <div className="ThreadDetailSidebarAboutSubtitle">
                                    Theme: {matchDetails.brand.estimateTheme}
                                  </div>
                                )}
                                {!!matchDetails.brand.instagramFollowers && (
                                  <div className="ThreadDetailSidebarAboutSubtitle">
                                    Est. Instagram followers:{' '}
                                    {kpiNumber(
                                      matchDetails.brand.instagramFollowers,
                                    )}
                                  </div>
                                )}
                              </Fragment>
                            )}
                          <div
                            className="ThreadDetailSidebarAboutEdit"
                            onClick={() => setBrandEditing(true)}
                          >
                            edit brand
                          </div>
                        </div>
                        <div className="ThreadDetailSidebarAboutSection">
                          <div className="ThreadDetailSidebarAboutSectionTitle">
                            Team
                          </div>
                          {brandTeam.map((h) => {
                            let emailPart = '';
                            if (h.primaryEmail) {
                              emailPart = h.primaryPhone
                                ? 'Email '
                                : 'Email address ';
                            }
                            let phonePart = '';
                            if (h.primaryPhone) {
                              phonePart = h.primaryEmail
                                ? '+ phone '
                                : 'Phone number ';
                            }
                            return (
                              <div
                                key={h.id}
                                className="ThreadDetailSidebarAboutHuman"
                              >
                                <div className="ThreadDetailSidebarAboutHumanName">
                                  {`${(h.firstName || '').trim()} ${(
                                    h.lastName || ''
                                  ).trim()}`.trim() || 'Name is missing!'}
                                </div>
                                {!!matchDetails &&
                                  !!matchDetails.brand &&
                                  !!matchDetails.brand.teamLeader &&
                                  h.id === matchDetails.brand.teamLeader.id && (
                                    <div className="ThreadDetailSidebarAboutHumanLocation">
                                      Team Leader
                                    </div>
                                  )}
                                <div className="ThreadDetailSidebarAboutHumanLocation">
                                  <LocationTimestamp
                                    locationCity={h.location && h.location.city}
                                    locationRegionCode={
                                      h.location && h.location.regionCode
                                    }
                                    locationTimezone={
                                      h.location && h.location.timezone
                                    }
                                  />
                                </div>
                                <div className="ThreadDetailSidebarAboutHumanInfo">{`${emailPart}${phonePart} saved`}</div>
                                {!!h.proStart && (
                                  <div className="ThreadDetailSidebarAboutHumanInfo">
                                    Storetasker Pro
                                  </div>
                                )}
                                {h.partnerReferral &&
                                  h.partnerReferral.partner && (
                                    <a
                                      className="RequestDetailViewSectionReferral"
                                      href={
                                        h.partnerReferral.partner.partnerUrl ||
                                        ''
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Referred by:{' '}
                                      {h.partnerReferral.partner.partnerName ||
                                        ''}
                                    </a>
                                  )}
                                <div
                                  className="ThreadDetailSidebarAboutHumanEdit"
                                  onClick={() => setHumanEditing(h.id)}
                                >
                                  edit
                                </div>
                              </div>
                            );
                          })}
                          <div
                            className="ThreadDetailSidebarAboutAdd"
                            onClick={() => setHumanEditing('new')}
                          >
                            Create A New Contact
                          </div>
                        </div>
                        {!!ignoreTeam.length && (
                          <div className="ThreadDetailSidebarAboutSection">
                            <div className="ThreadDetailSidebarAboutSectionTitle">
                              Participants Ignored
                            </div>
                            {!!ignoreShow &&
                              ignoreTeam.map((h) => {
                                const nameStr = `${(
                                  h.firstName || ''
                                ).trim()} ${(h.lastName || '').trim()}`.trim();
                                let detailsStr = '';
                                if (h.primaryEmail) {
                                  detailsStr += `${h.primaryEmail} `;
                                }
                                if (h.primaryPhone) {
                                  detailsStr +=
                                    formatValidPhoneNumber(h.primaryPhone) ||
                                    h.primaryPhone ||
                                    '';
                                }
                                return (
                                  <div
                                    key={h.id}
                                    className="ThreadDetailSidebarAboutHuman"
                                  >
                                    <div className="ThreadDetailSidebarAboutHumanName">
                                      {nameStr || detailsStr}
                                    </div>
                                    {!!nameStr && (
                                      <div className="ThreadDetailSidebarAboutHumanInfo">
                                        {detailsStr}
                                      </div>
                                    )}
                                    <div
                                      className="ThreadDetailSidebarAboutHumanEdit"
                                      onClick={() => onUndoIgnoreHuman(h.id)}
                                    >
                                      undo
                                    </div>
                                  </div>
                                );
                              })}
                            <div
                              className="ThreadDetailSidebarAboutEdit"
                              onClick={() => setIgnoreShow(!ignoreShow)}
                            >
                              {ignoreShow ? 'hide' : 'show all'}
                            </div>
                          </div>
                        )}
                        {!!allProjectsForBrand.length && !!spendMax && (
                          <div className="ThreadDetailSidebarAboutSection">
                            <div className="ThreadDetailSidebarAboutSectionTitle">
                              Total Spent: {kpiDollars(spendMax)}
                            </div>
                            <div className="RequestDetailViewSectionProjects">
                              {allProjectsForBrand.map((p) => (
                                <div key={p.id} className="PreviousProjectItem">
                                  <div className="PreviousProjectCard">
                                    <div className="PreviousProjectCardTitle">
                                      {p.quote.title}
                                    </div>
                                    <div className="PreviousProjectCardSubtitle">
                                      <ExpertUserBubble
                                        expertId={p.expert.id}
                                        expertDetails={p.expert}
                                        primary
                                      />
                                      {p.expert.firstName || ''}
                                    </div>
                                    <div className="PreviousProjectCardRight">
                                      $
                                      {formatNumberWithCommas(
                                        centsDollarsRounded(p.quote.cents),
                                      )}
                                    </div>
                                    <div
                                      className={
                                        'PreviousProjectCardStatusLine CardStatusLine' +
                                        p.quote.status
                                      }
                                    />
                                    <div className="PreviousProjectCardStatus">
                                      {quoteStatusFormatted(p.quote.status)}
                                    </div>
                                  </div>
                                  <div className="PreviousProjectFooter">
                                    {moment(p.createdAt).format(
                                      'MMMM Do, YYYY',
                                    )}
                                  </div>
                                </div>
                              ))}
                              {allProjectsForBrand.length >=
                                ACTIVITY_PAGE_LIMIT &&
                                oldestCreatedProject &&
                                showLoadMoreProjects && (
                                  <div
                                    className="PreviousProjectItemLoader"
                                    onClick={loadMoreProjects}
                                  >
                                    load more projects
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                        <div className="ThreadDetailSidebarAdminSection">
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Share Client With Another Expert?
                          </div>
                          <div className="ThreadDetailSidebarAdminSearch">
                            <div className="ThreadDetailSidebarAdminSearchBar">
                              <input
                                type="text"
                                className="ThreadDetailSidebarAdminSearchBarInput"
                                autoComplete="new-off"
                                spellCheck="false"
                                placeholder="Search Experts"
                                value={searchExpertInput}
                                onChange={(e) =>
                                  setSearchExpertInput(e.target.value)
                                }
                                onKeyDown={handleSearchExpertInputKeyDown}
                              />
                            </div>
                            <div className="ThreadDetailSidebarAdminSearchResults">
                              {searchExpertResults &&
                                !searchExpertResults.length && (
                                  <div className="ThreadDetailSidebarAdminEmpty">
                                    No experts found. Try another search query
                                    to find who you&apos;re looking for.
                                  </div>
                                )}
                              {searchExpertResults &&
                                searchExpertResults.map((expertResult) => (
                                  <div
                                    key={expertResult.id}
                                    className="ThreadDetailSidebarAdminSearchResult"
                                    onClick={() => doShareClient(expertResult)}
                                  >
                                    <div className="ThreadDetailSidebarAdminSearchResultTitle">
                                      {(
                                        (expertResult.firstName || '') +
                                        ' ' +
                                        (expertResult.lastName || '')
                                      ).trim()}
                                    </div>
                                    <div className="ThreadDetailSidebarAdminSearchResultSubtitle">
                                      {expertResult.primaryPublicEmail || ''}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                )}
                {primaryPanel === 'Files' && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelFiles">
                    {activeFiles.map((f) => {
                      const fileMoment = moment.tz(
                        f.createdAt,
                        moment.tz.guess(),
                      );
                      const fileDateFull =
                        fileMoment.format('MMMM Do, YYYY') +
                        ' at ' +
                        fileMoment.format('h:mma z');
                      return (
                        <div key={f.id} className="ThreadDetailSidebarFileItem">
                          <a
                            className="ThreadDetailSidebarFileItemTitle"
                            href={f.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-tip={fileDateFull}
                          >
                            {f.filename}
                          </a>
                          <div
                            className="ThreadDetailSidebarFileItemArchive"
                            onClick={() => onFileArchive(f)}
                          >
                            Archive
                          </div>
                        </div>
                      );
                    })}
                    {!activeFiles.length && (
                      <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                        <div className="ThreadDetailSidebarBodyPanelEmpty">
                          <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                            No files found
                          </div>
                          <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                            {errorFileActive
                              ? errorUtils.getErrorMessage(errorFileActive)
                              : 'When you send or receive files, they will be saved here.'}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {primaryPanel === 'Notes' && matchDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelTeam">
                    <div className="ThreadDetailSidebarAdminSection">
                      <UserNoteEditor
                        onSave={onSaveNote}
                        isLoading={noteLoading}
                        isExpert
                        errorFound={errorUserNotes}
                        userNotes={allUserNotes}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="ThreadDetailSidebarBodySearch">
                {!searchLoading && searchResults && !searchResults.length && (
                  <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                    <div className="ThreadDetailSidebarBodyPanelEmpty">
                      <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                        No search results found
                      </div>
                      <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                        Please try again with another search.
                      </div>
                    </div>
                  </div>
                )}
                {!searchLoading && searchResults && !!searchResults.length && (
                  <div className="ThreadDetailSidebarSearchResults">
                    {searchResults.map(renderSearchResult)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <Link to="/clients" className="DashboardErrorCoverNav">
              back
            </Link>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

ExpertClientDetail.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  expertDetails: PropTypes.object,
  expertId: PropTypes.string.isRequired,
  matchId: PropTypes.string.isRequired,
  quoteTemplates: PropTypes.array.isRequired,
  socketClient: PropTypes.object.isRequired,
};

ExpertClientDetail.defaultProps = {
  expertDetails: null,
};

export default ExpertClientDetail;
