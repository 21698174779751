import _ from 'lodash';
import moment from 'moment-timezone';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  RequestActiveForExpertQuery,
  ExpertDetailsQuery,
} from '../gql/graphql';
import {
  EXPERT_LEVEL_SWITCH,
  EXPERIENCE_OPTIONS,
  FOCUS_OPTIONS,
  SKILL_OPTIONS,
  TOOL_OPTIONS,
  TIMELINE_OPTIONS,
  PROBLEM_OPTIONS,
  INDUSTRY_OPTIONS,
} from './constants';
import { IPastClients } from './props';

export function formatValidPhoneNumber(
  phoneNumber?: string | null,
  isNationalSpecial?: boolean,
) {
  if (!phoneNumber) {
    return '';
  }
  const foundNumber = parsePhoneNumberFromString(phoneNumber || '');
  if (foundNumber) {
    if (isNationalSpecial) {
      return '+1 ' + foundNumber.formatNational();
    }
    return foundNumber.formatInternational();
  }
  return '';
}
export function phoneErrorTitle(errorType: string) {
  if (errorType === 'NOT_MOBILE') {
    return 'Mobile Phones Only';
  }
  if (errorType === 'PRE_REGISTER') {
    return 'Unsupported Phone Number';
  }
  return '';
}
export function phoneErrorSubtitle(errorType: string) {
  if (errorType === 'NOT_MOBILE') {
    return "We're only able to text you on a mobile phone, so either submit another number or we can just stick with email for now. Please contact support@storetasker.com if you're having any issues.";
  }
  if (errorType === 'PRE_REGISTER') {
    return "Unfortunately, we're not able to text you at this number due to country restrictions, so let's just stick with email for now. Please contact support@storetasker.com if you're having any issues.";
  }
  return '';
}
export function profileBandwidthValue(profileBandwidth: string) {
  if (profileBandwidth === '0_25') {
    return '0-25%';
  }
  if (profileBandwidth === '25_50') {
    return '25-50%';
  }
  if (profileBandwidth === '50_75') {
    return '50-75%';
  }
  if (profileBandwidth === '75_100') {
    return '75-100%';
  }
  return '';
}
export function replyToSubject(theSubject?: string) {
  const rawSubject = (theSubject || '').trim();
  const subject = rawSubject
    .replace(/(^|\s)(fw: )/gi, ' Fwd: ')
    .replace(/(^|\s)(fwd: )+/gi, ' Fwd: ')
    .replace(/(^|\s)(re\[(.*)\]: )/gi, ' Re: ')
    .replace(/(^|\s)(reply: )/gi, ' Re: ')
    .replace(/(^|\s)(re: )+/gi, ' Re: ')
    .trim();
  const newSubject =
    !subject || subject.indexOf('Re: ') >= 0 || subject.indexOf('Fwd: ') >= 0
      ? subject
      : 'Re: ' + subject;
  return newSubject;
}
export function formatNumberWithCommas(dollars: string) {
  let formatted = '';
  const [beforeDecimal, afterDecimal] = dollars.split('.');
  if (afterDecimal) {
    formatted += `.${afterDecimal}`;
  }

  const digits = beforeDecimal.split('');
  let counter = 0;
  while (digits.length > 0) {
    if (counter % 3 === 0 && counter !== 0) {
      formatted = `,${formatted}`;
    }
    formatted = `${digits.pop()}${formatted}`;
    counter += 1;
  }
  return formatted;
}
export function centsDollarsRounded(cents: number) {
  return Math.round(cents / 100).toString();
}
export function centsDollarsDecimal(cents: number) {
  return (cents / 100).toFixed(2);
}
export function unreadReasonFormatted(status?: string | null) {
  if (status === 'BRAND_SLOW_RESPONSE_FOLLOWUP') {
    return 'Reminder: No Response Yet';
  }
  if (status === 'BRAND_SLOW_QUOTE_ACCEPT_FOLLOWUP') {
    return 'Reminder: Quote Sent';
  }
  if (status === 'PROJECT_DEADLINE_APPROACHING') {
    return 'Upcoming Project Deadline';
  }
  if (status === 'PROJECT_DEADLINE_MISSED') {
    return 'Missed Project Deadline';
  }
  if (status === 'LEAD_CLOSE_DEADLINE') {
    return 'Reminder: Close Lead';
  }
  if (status === 'BRAND_QUOTE_ACCEPT') {
    return 'Quote Accepted';
  }
  if (status === 'BRAND_REQUEST_REVISIONS') {
    return 'Revisions Requested';
  }
  if (status === 'BRAND_PROJECT_APPROVE') {
    return 'Project Approved';
  }
  if (status === 'BRAND_PROJECT_APPROVE_REF') {
    return 'Project Approved: Ask for referral?';
  }
  if (status === 'BRAND_BILL_PAID') {
    return 'Bill Paid';
  }
  if (status === 'BRAND_BILL_PAID_REF') {
    return 'Bill Paid: Ask for referral?';
  }
  if (status === 'HQ_QUOTE_CANCEL') {
    return 'Project Canceled By HQ';
  }
  if (status === 'BRAND_SUBSCRIPTION_PAID') {
    return 'Subscription Paid';
  }
  if (status === 'BRAND_SUBSCRIPTION_PAID_REF') {
    return 'Subscription Paid: Ask for referral?';
  }
  if (status === 'NEW_MISSED_MESSAGE') {
    return 'Missed Message';
  }
  if (status === 'NEW_BRAND_MATCH') {
    return 'Matched With Brand';
  }
  if (status === 'CLIENT_SHARED') {
    return 'Client Shared With You';
  }
  if (status === 'CLIENT_CREATED') {
    return 'Client Created By You';
  }
  if (status === 'MANUAL_UNSNOOZE') {
    return 'Unsnoozed';
  }
  if (status === 'SNOOZE_WAKEUP') {
    return 'Snooze Alert';
  }
  if (status === 'MANUAL_MARKED_UNREAD') {
    return 'Marked Unread';
  }
  if (status === 'THREADED_ORPHAN') {
    return 'Threaded From Inbound';
  }
  if (status === 'UNTHREADED_MESSAGE') {
    return 'Unthreaded From Match';
  }
  if (status === 'ADMIN_ASSIGNED') {
    return 'Admin Assigned';
  }
  if (status === 'TEXT_SEND_ERROR') {
    return 'Error Sending Text';
  }
  if (status === 'EMAIL_SEND_ERROR') {
    return 'Error Sending Email';
  }
  if (status === 'MOVE_ALL_CLIENTS') {
    return 'Move to All Clients?';
  }
  return status || '';
}
export function shouldAlert(status?: string | null) {
  if (!status) return false;
  if (status === 'BRAND_QUOTE_ACCEPT') {
    return true;
  }
  if (status === 'BRAND_REQUEST_REVISIONS') {
    return true;
  }
  if (status === 'BRAND_PROJECT_APPROVE') {
    return true;
  }
  if (status === 'BRAND_PROJECT_APPROVE_REF') {
    return true;
  }
  if (status === 'BRAND_BILL_PAID') {
    return true;
  }
  if (status === 'BRAND_BILL_PAID_REF') {
    return true;
  }
  if (status === 'BRAND_SUBSCRIPTION_PAID') {
    return true;
  }
  if (status === 'BRAND_SUBSCRIPTION_PAID_REF') {
    return true;
  }
  if (status === 'NEW_MISSED_MESSAGE') {
    return true;
  }
  if (status === 'NEW_BRAND_MATCH') {
    return true;
  }
  if (status === 'CLIENT_SHARED') {
    return true;
  }
  if (status === 'CLIENT_CREATED') {
    return true;
  }
  if (status === 'TEXT_SEND_ERROR') {
    return true;
  }
  if (status === 'EMAIL_SEND_ERROR') {
    return true;
  }
  return false;
}
export function sendingErrorFormatted(status?: string | null) {
  if (status === 'NOT_DELIVERED') {
    return 'Not delivered';
  }
  if (status === 'FILE_SIZE') {
    return 'Files too big';
  }
  return status || '';
}
export function sendingStatusFormatted(status?: string | null) {
  if (status === 'SENT') {
    return 'Sent';
  }
  if (status === 'DELIVERED') {
    return 'Delivered';
  }
  if (status === 'OPENED') {
    return 'Opened';
  }
  if (status === 'FAILED') {
    return 'Failed';
  }
  return status || '';
}
export function meetingResponseFormatted(
  status?: string,
  fromExpert?: boolean,
) {
  if (status === 'NEEDS-ACTION') {
    return fromExpert ? 'Waiting For Reply' : 'Need To Reply';
  }
  if (status === 'ACCEPTED') {
    return 'Accepted';
  }
  if (status === 'DECLINED') {
    return 'Declined';
  }
  if (status === 'TENTATIVE') {
    return 'Tentative';
  }
  // CANCELED added for convenience
  if (status === 'CANCELED') {
    return 'Canceled';
  }
  return status || '';
}
export function quoteStatusFormatted(status?: string) {
  if (status === 'ACCEPTED') {
    return 'Quote Accepted';
  }
  if (status === 'APPROVED') {
    return 'Approved';
  }
  if (status === 'BILL_PAID') {
    return 'Bill Paid';
  }
  if (status === 'CANCELED') {
    return 'Canceled';
  }
  if (status === 'CREATED') {
    return 'Quote Sent';
  }
  if (status === 'COMPLETED') {
    return 'Marked Complete';
  }
  if (status === 'REVISIONS_REQUESTED') {
    return 'Revisions Requested';
  }
  if (status === 'SUBSCRIPTION_PAYMENT_MADE') {
    return 'Subscription Paid';
  }
  if (status === 'UPDATED') {
    return 'Quote Updated';
  }
  if (status === 'IN_PROGRESS') {
    return 'In Progress';
  }
  return '';
}
export function quoteStatusFormattedHuman(
  status?: string,
  paymentType?: string,
) {
  if (status === 'ACCEPTED') {
    return 'In Progress';
  }
  if (status === 'APPROVED') {
    return 'Completed';
  }
  if (status === 'BILL_PAID') {
    return 'Paid';
  }
  if (status === 'CANCELED') {
    return 'Canceled';
  }
  if (status === 'CREATED') {
    if (paymentType === 'BILL') return 'Bill';
    return 'Quote';
  }
  if (status === 'COMPLETED') {
    return 'Review';
  }
  if (status === 'REVISIONS_REQUESTED') {
    return 'Revisions';
  }
  if (status === 'SUBSCRIPTION_PAYMENT_MADE') {
    return 'Active';
  }
  if (status === 'UPDATED') {
    return 'Updated';
  }
  if (status === 'IN_PROGRESS') {
    return 'In Progress';
  }
  return '';
}
export function quoteEventStatus(status?: string) {
  if (status === 'ACCEPTED') {
    return 'Quote Accepted';
  }
  if (status === 'APPROVED') {
    return 'Project Approved';
  }
  if (status === 'BILL_PAID') {
    return 'Bill Paid';
  }
  if (status === 'CANCELED') {
    return 'Project Canceled';
  }
  if (status === 'CREATED') {
    return 'Quote Sent';
  }
  if (status === 'COMPLETED') {
    return 'Marked Complete';
  }
  if (status === 'REVISIONS_REQUESTED') {
    return 'Revisions Requested';
  }
  if (status === 'SUBSCRIPTION_PAYMENT_MADE') {
    return 'Subscription Paid';
  }
  if (status === 'UPDATED') {
    return 'Quote Updated';
  }
  if (status === 'IN_PROGRESS') {
    return 'Undo Mark Complete';
  }
  return '';
}
export function quoteStatusExpertAction(status?: string) {
  if (status === 'CREATED') {
    return 'Quote Sent';
  }
  if (status === 'UPDATED') {
    return 'Quote Updated';
  }
  if (status === 'COMPLETED') {
    return 'Marked Complete';
  }
  if (status === 'CANCELED') {
    return 'Project Canceled';
  }
  if (status === 'IN_PROGRESS') {
    return 'Undo Mark Complete';
  }
  return '';
}
export function projectNotificationStatus(
  status?: string,
  paymentType?: string,
) {
  if (status === 'ACCEPTED') {
    return 'in progress';
  }
  if (status === 'APPROVED') {
    return 'project completed';
  }
  if (status === 'BILL_PAID') {
    return 'bill paid';
  }
  if (status === 'CANCELED') {
    return 'project canceled';
  }
  if (status === 'CREATED') {
    if (paymentType === 'BILL') return 'new bill';
    return 'new quote';
  }
  if (status === 'COMPLETED') {
    return 'ready for review';
  }
  if (status === 'REVISIONS_REQUESTED') {
    return 'in progress';
  }
  if (status === 'SUBSCRIPTION_PAYMENT_MADE') {
    return 'active';
  }
  if (status === 'UPDATED') {
    if (paymentType === 'BILL') return 'updated bill';
    return 'quote updated';
  }
  if (status === 'IN_PROGRESS') {
    return 'in progress';
  }
  return '';
}
export function paymentTypeFormatted(paymentType?: string) {
  if (paymentType === 'WEEKLY_SUBSCRIPTION') {
    return 'Weekly Subscription';
  }
  if (paymentType === 'MONTHLY_SUBSCRIPTION') {
    return 'Monthly Subscription';
  }
  if (paymentType === 'BILL') {
    return 'Bill';
  }
  return 'Project';
}
export function prefabPriceStr(priceType: string, cents?: number | null) {
  if (!cents) return '';
  const dollars = formatNumberWithCommas(centsDollarsRounded(cents));
  switch (priceType) {
    case 'STARTING_AT':
      return `starting at $${dollars}`;
    case 'EXACTLY':
      return `$${dollars}`;
    case 'NONE':
      return '';
    default:
      return null;
  }
}
export function searchResultType(threadEventTypename: string) {
  if (threadEventTypename === 'MessageEvent') {
    return 'Message';
  }
  if (threadEventTypename === 'EmailMessageEvent') {
    return 'Email';
  }
  if (threadEventTypename === 'RequestEvent') {
    return 'Request';
  }
  if (threadEventTypename === 'TextMessageEvent') {
    return 'Text Message';
  }
  if (threadEventTypename === 'MeetingEvent') {
    return 'Meeting';
  }
  if (threadEventTypename === 'PhoneCallEvent') {
    return 'Phone Call';
  }
  if (threadEventTypename === 'Quote') {
    return 'Project';
  }
  return '';
}

export function unclaimedReason(unclaimedReasonEnum?: string) {
  if (unclaimedReasonEnum === 'SKILLS_MISMATCH') {
    return 'Skills Required';
  }
  if (unclaimedReasonEnum === 'LOCATION_NOT_A_FIT') {
    return 'Location/Timezone Mismatch';
  }
  if (unclaimedReasonEnum === 'SKILLS_NOT_A_FIT') {
    return 'Skills Required';
  }
  if (unclaimedReasonEnum === 'PORTFOLIO_NOT_A_FIT') {
    return 'Portfolio/Experience Mismatch';
  }
  if (unclaimedReasonEnum === 'TIMELINE_NOT_A_FIT') {
    return 'Timeline/Deadline Mismatch';
  }
  if (unclaimedReasonEnum === 'QUOTE_NOT_A_FIT') {
    return 'Quote/Budget Mismatch';
  }
  if (unclaimedReasonEnum === 'EXPERT_IS_UNRESPONSIVE') {
    return 'Expert Is Unresponsive';
  }
  if (unclaimedReasonEnum === 'EXPERT_DIFFICULT_TO_WORK_WITH') {
    return 'Expert Is Difficult To Work With';
  }
  return '';
}

export function expertFocusType(tagFocus?: string[]) {
  if (!tagFocus || !tagFocus[0]) return '';
  if (tagFocus[0] === 'FOCUS_WEB_DEVELOPMENT') {
    return 'Shopify Developer';
  }
  if (tagFocus[0] === 'FOCUS_DESIGN') {
    return 'Designer';
  }
  if (tagFocus[0] === 'FOCUS_MARKETING') {
    return 'Marketer';
  }
  if (tagFocus[0] === 'FOCUS_MANAGER') {
    return 'eComm Manager';
  }
  return '';
}

export function expertFocusWhat(tagFocus?: string[]) {
  if (!tagFocus || !tagFocus[0]) return '';
  const focusOption = FOCUS_OPTIONS.find((o) => o.value === tagFocus[0]);
  if (!focusOption) return '';
  return focusOption.what;
}
export function expertFocusPosition(tagFocus?: string[]) {
  if (!tagFocus || !tagFocus[0]) return '';
  if (tagFocus[0] === 'FOCUS_WEB_DEVELOPMENT') {
    return 'Developer';
  }
  if (tagFocus[0] === 'FOCUS_DESIGN') {
    return 'Designer';
  }
  if (tagFocus[0] === 'FOCUS_MARKETING') {
    return 'Marketer';
  }
  if (tagFocus[0] === 'FOCUS_MANAGER') {
    return 'eComm Manager';
  }
  return '';
}
export function expertClientsBrag(pastClients?: IPastClients[]) {
  const clientsArr = (pastClients || []).slice(0, 3);
  console.log(clientsArr, 'clientsArr');
  if (!clientsArr.length) {
    return '.';
  }
  let clientListStr = clientsArr[0].name.trim();
  if (clientsArr.length === 2) {
    clientListStr =
      clientsArr[0].name.trim() + ' and ' + clientsArr[1].name.trim();
  } else if (clientsArr.length === 3) {
    clientListStr =
      clientsArr[0].name.trim() +
      ', ' +
      clientsArr[1].name.trim() +
      ', and ' +
      clientsArr[2].name.trim();
  }
  return (
    ' and have worked with clients like ' + clientListStr + ' among others.'
  );
}
export function expertSkillsBrag(tagSkillsRaw?: string[]) {
  const tagSkills = (tagSkillsRaw || [])
    .map(tagLabelSkill)
    .filter((s) => s.trim())
    .slice(0, 3);
  if (!tagSkills.length) {
    return '';
  }
  let skillList = tagSkills[0].trim();
  if (tagSkills.length === 2) {
    skillList = tagSkills[0].trim() + ' and ' + tagSkills[1].trim();
  } else if (tagSkills.length === 3) {
    skillList =
      tagSkills[0].trim() +
      ', ' +
      tagSkills[1].trim() +
      ', and ' +
      tagSkills[2].trim();
  }
  return skillList;
}

export function kpiPercentage(decimal: number) {
  return Math.floor(decimal * 100.0).toString() + '%';
}

export function kpiRating(rating: number) {
  return _.round(rating, 1).toFixed(1);
}

export function kpiDollars(cents: number) {
  const dollars = Math.round(cents / 100);
  if (dollars < 1000) {
    return '$' + dollars.toString();
  }
  if (dollars < 100000) {
    const thousands = _.round(dollars / 1000, 1);
    return (
      '$' +
      (thousands.toString().indexOf('.') === -1
        ? thousands.toString()
        : thousands.toFixed(1)) +
      'k'
    );
  }
  if (dollars < 1000000) {
    return '$' + Math.round(dollars / 1000).toString() + 'k';
  }
  if (dollars < 100000000) {
    const millions = _.round(dollars / 1000, 1);
    return (
      '$' +
      (millions.toString().indexOf('.') === -1
        ? millions.toString()
        : millions.toFixed(1)) +
      'm'
    );
  }
  return '$' + Math.round(dollars / 1000000).toString() + 'm';
}

export function kpiNumber(raw: number) {
  if (raw < 1000) {
    return raw.toString();
  }
  if (raw < 100000) {
    const thousands = _.round(raw / 1000, 1);
    return (
      (thousands.toString().indexOf('.') === -1
        ? thousands.toString()
        : thousands.toFixed(1)) + 'k'
    );
  }
  if (raw < 1000000) {
    return Math.round(raw / 1000).toString() + 'k';
  }
  if (raw < 100000000) {
    const millions = _.round(raw / 1000, 1);
    return (
      (millions.toString().indexOf('.') === -1
        ? millions.toString()
        : millions.toFixed(1)) + 'm'
    );
  }
  return Math.round(raw / 1000000).toString() + 'm';
}

export function getExpertLevelStr(levelName: string, createdAt?: number) {
  const beforeSwitch = !createdAt || createdAt < EXPERT_LEVEL_SWITCH;
  if (!levelName || levelName === 'level-1') {
    return {
      info: beforeSwitch
        ? "Currently you're at Level 1 with 25% commission. Once you hit $10k in sales, you'll reach Level 2 with 20% commission."
        : "Currently you're at Level 1 with 20% commission. Once you hit $150k in sales, you'll reach the next level with 15% commission.",
      level: 1,
      name: 'Level 1',
    };
  }
  if (levelName === 'level-2') {
    return {
      info: beforeSwitch
        ? "Currently you're at Level 2 with 20% commission. Once you hit $20k in sales, you'll reach Level 3 with 15% commission."
        : "Currently you're at Level 2 with 20% commission. Once you hit $150k in sales, you'll reach the next level with 15% commission.",
      level: 2,
      name: 'Level 2',
    };
  }
  if (levelName === 'level-3') {
    return {
      info: beforeSwitch
        ? "Currently you're at Level 3 with 15% commission. Once you hit $150k in sales, you'll reach Level 4 with 13% commission."
        : "Currently you're at Level 3 with 20% commission. Once you hit $150k in sales, you'll reach the next level with 15% commission.",
      level: 3,
      name: 'Level 3',
    };
  }
  if (levelName === 'level-4') {
    return {
      info: beforeSwitch
        ? "Currently you're at Level 4 with 13% commission. Once you hit $400k in sales, you'll reach Level 5 with 10% commission."
        : "Currently you're at Level 4 with 15% commission. Once you hit $400k in sales, you'll reach Level 5 with 10% commission.",
      level: 4,
      name: 'Level 4',
    };
  }
  return {
    info: "You're at Level 5 with 10% commission and have reached the top level of all Storetasker Experts. You are my hero.",
    level: 5,
    name: 'Level 5',
  };
}

export function getPartnerLevelStr(levelName: string) {
  if (!levelName || levelName === 'level-1') {
    return {
      info: 'You get paid a 7.5% cut whenever a referral completes their first paid project.',
      level: 1,
      name: 'Level 1',
    };
  }
  if (levelName === 'level-2') {
    return {
      info: 'You get paid a 10% cut whenever a referral completes their first paid project.',
      level: 2,
      name: 'Level 2',
    };
  }
  if (levelName === 'level-3') {
    return {
      info: 'You get paid a 12.5% cut whenever a referral completes their first paid project.',
      level: 3,
      name: 'Level 3',
    };
  }
  if (levelName === 'level-4') {
    return {
      info: 'You get paid a 15% cut whenever a referral completes their first paid project.',
      level: 4,
      name: 'Level 4',
    };
  }
  if (levelName === 'level-5') {
    return {
      info: 'You get paid a 10% cut whenever a referral completes a project.',
      level: 5,
      name: 'Level 5',
    };
  }
  return {
    info: 'You get paid a 20% cut of our net revenue whenever a referral completes a project.',
    level: 6,
    name: 'Level 6',
  };
}

function getExpertRawCut(levelName: string, beforeSwitch: boolean) {
  if (!levelName || levelName === 'level-1') {
    return beforeSwitch ? 0.75 : 0.8;
  }
  if (levelName === 'level-2') {
    return 0.8;
  }
  if (levelName === 'level-3') {
    return beforeSwitch ? 0.85 : 0.8;
  }
  if (levelName === 'level-4') {
    return beforeSwitch ? 0.87 : 0.85;
  }
  return 0.9;
}

export function getExpertCut(
  levelName: string,
  dollars: number,
  expertBroughtClient: boolean,
  createdAt?: number,
) {
  const beforeSwitch = !createdAt || createdAt < EXPERT_LEVEL_SWITCH;
  const cutDollars = Math.round(
    (expertBroughtClient ? 0.97 : getExpertRawCut(levelName, beforeSwitch)) *
      0.96 *
      dollars,
  );
  return formatNumberWithCommas(cutDollars.toString());
}

export function getNextPaymentDate(lastPaid: Date, paymentType: string) {
  return moment(lastPaid)
    .add(1, paymentType === 'WEEKLY_SUBSCRIPTION' ? 'week' : 'month')
    .format('MMMM Do, YYYY');
}

export function partnerReferralMedium(medium: string) {
  if (medium === 'form') return 'Embedded Form';
  if (medium === 'support') return 'Support Ticket';
  if (medium === 'discount') return 'Discount Code';
  if (medium === 'email') return 'Email Intro';
  if (medium === 'manual') return 'Manual Attribution';
  if (medium === 'marketplace') return 'Marketplace';
  if (medium === 'directory') return 'Directory';
  if (medium === 'directory-link') return 'Directory Link';
  if (medium === 'app-link') return 'App Link';
  return 'Tracking Link';
}

export function getNumericValue(feedbackValue: string) {
  switch (feedbackValue) {
    case 'GOOD':
      return 5;
    case 'FINE':
      return 4;
    case 'AVERAGE':
      return 3;
    case 'BAD':
      return 2;
    case 'HORRIBLE':
      return 1;
    default:
      return 0;
  }
}

export function validDiscountCode(
  discountCode?: string | null,
  includePaid?: boolean,
) {
  if (!discountCode) {
    return '';
  }
  if (discountCode === '10_FIRST_FOREVER') {
    return '10_FIRST_FOREVER';
  }
  if (discountCode === '15_FIRST_FOREVER') {
    return '15_FIRST_FOREVER';
  }
  if (discountCode === '10_NEXT_FOREVER') {
    return '10_NEXT_FOREVER';
  }
  if (discountCode === 'ALREADY_PAID' && includePaid) {
    return 'ALREADY_PAID';
  }
  return '';
}

export function validClientFee(clientFee?: string | null) {
  if (!clientFee) {
    return '';
  }
  if (clientFee === '3_POINT_5') {
    return '3_POINT_5';
  }
  return '';
}

export function explainClientFee(clientFee?: string | null) {
  if (!clientFee) {
    return 'None';
  }
  if (clientFee === '3_POINT_5') {
    return '3.5% client fee';
  }
  return '';
}

export function explainDiscountCode(discountCode?: string | null) {
  if (!discountCode) {
    return 'None - New Customer';
  }
  if (discountCode === '10_FIRST_FOREVER') {
    return '10% off FIRST project';
  }
  if (discountCode === '15_FIRST_FOREVER') {
    return '15% off FIRST project';
  }
  if (discountCode === '10_NEXT_FOREVER') {
    return '10% off NEXT project';
  }
  if (discountCode === 'ALREADY_PAID') {
    return 'None - Already Did A Project';
  }
  return '';
}

export function pitchDiscountCode(discountCode?: string | null) {
  if (!discountCode) {
    return '';
  }
  if (discountCode === '10_FIRST_FOREVER') {
    return 'They get 10% off their first project.';
  }
  if (discountCode === '15_FIRST_FOREVER') {
    return 'They get 15% off their first project.';
  }
  if (discountCode === '10_NEXT_FOREVER') {
    return 'They get 10% off their next project.';
  }
  return '';
}

export function amountDiscountCode(discountCode?: string | null) {
  if (!discountCode) {
    return '';
  }
  if (discountCode === '10_FIRST_FOREVER') {
    return '10';
  }
  if (discountCode === '15_FIRST_FOREVER') {
    return '15';
  }
  if (discountCode === '10_NEXT_FOREVER') {
    return '10';
  }
  return '';
}
export function amountClientFee(clientFee?: string | null) {
  if (!clientFee) {
    return '';
  }
  if (clientFee === '3_POINT_5') {
    return '3.5';
  }
  return '';
}
export function valueDiscountCode(discountCode?: string) {
  if (!discountCode) {
    return 0;
  }
  if (discountCode === '10_FIRST_FOREVER') {
    return 0.1;
  }
  if (discountCode === '15_FIRST_FOREVER') {
    return 0.15;
  }
  if (discountCode === '10_NEXT_FOREVER') {
    return 0.1;
  }
  return 0;
}
export function valueClientFee(clientFee?: string) {
  if (!clientFee) {
    return 0;
  }
  if (clientFee === '3_POINT_5') {
    return 0.035;
  }
  return 0;
}
export function applyDiscountCode(
  isProjectDiscountCode: boolean,
  discountCode?: string,
) {
  if (!discountCode) {
    return '';
  }
  if (discountCode === '10_FIRST_FOREVER') {
    return isProjectDiscountCode
      ? 'Your 10% off discount code is applied to this project.'
      : 'You have 10% off your first project.';
  }
  if (discountCode === '15_FIRST_FOREVER') {
    return isProjectDiscountCode
      ? 'Your 15% off discount code is applied to this project.'
      : 'You have 15% off your first project.';
  }
  if (discountCode === '10_NEXT_FOREVER') {
    return isProjectDiscountCode
      ? 'Your 10% off discount code is applied to this project.'
      : 'You have 10% off your next project.';
  }
  return '';
}
export function notificationDiscountCode(
  isReminder: boolean,
  discountCode?: string,
) {
  if (!discountCode) {
    return '';
  }
  if (discountCode === '10_FIRST_FOREVER') {
    return isReminder
      ? 'As a reminder, you have 10% off your first project!'
      : 'Get started today for 10% off your first project!';
  }
  if (discountCode === '15_FIRST_FOREVER') {
    return isReminder
      ? 'As a reminder, you have 15% off your first project!'
      : 'Get started today for 15% off your first project!';
  }
  if (discountCode === '10_NEXT_FOREVER') {
    return isReminder
      ? 'As a reminder, you have 10% off your next project!'
      : 'Get started today for 10% off your next project!';
  }
  return '';
}

export function isLeadSuggested(
  expertId: string,
  req?: RequestActiveForExpertQuery['requestActiveForExpert'][0],
  expertDetails?: ExpertDetailsQuery['expertDetails'],
  skiVal?: string,
  tagSkillsVal?: string[],
  tagToolsVal?: string[],
) {
  if (!req) return false;
  if (
    req.suggestedMatchesStr &&
    req.suggestedMatchesStr.find((su) => su === expertId)
  )
    return true;
  const checkSkiVal = (expertDetails ? expertDetails.ski : skiVal) || '';
  if (
    req.isKeyAccount &&
    checkSkiVal &&
    (checkSkiVal === 'ALL' || checkSkiVal === 'RIS')
  )
    return true;
  const checkTagSkillsVal =
    (expertDetails ? expertDetails.tagSkills : tagSkillsVal) || [];
  if (
    req.tagSkills &&
    req.tagSkills.find((t) => checkTagSkillsVal.indexOf(t) >= 0)
  )
    return true;
  const checkTagToolsVal =
    (expertDetails ? expertDetails.tagTools : tagToolsVal) || [];
  if (
    req.tagTools &&
    req.tagTools.find((t) => checkTagToolsVal.indexOf(t) >= 0)
  )
    return true;
  return false;
}

export function tagLabelExperience(tagValue: string) {
  const tag = EXPERIENCE_OPTIONS.find((o) => o.value === tagValue);
  if (!tag) return '';
  return tag.label;
}

export function tagLabelFocus(tagValue: string) {
  const tag = FOCUS_OPTIONS.find((o) => o.value === tagValue);
  if (!tag) return '';
  return tag.label;
}

export function tagPersonFocus(tagValue: string) {
  const tag = FOCUS_OPTIONS.find((o) => o.value === tagValue);
  if (!tag) return '';
  return tag.person;
}
export function tagLabelProblem(tagValue: string) {
  const tag = PROBLEM_OPTIONS.find((o) => o.value === tagValue);
  if (!tag) return '';
  return tag.label;
}
export function tagLabelIndustry(tagValue: string) {
  const tag = INDUSTRY_OPTIONS.find((o) => o.value === tagValue);
  if (!tag) return '';
  return tag.label;
}
export function tagLabelSkill(tagValue: string) {
  const tag = SKILL_OPTIONS.find((o) => o.value === tagValue);
  if (!tag) return '';
  return tag.label;
}
export function tagLabelTimeline(tagValue: string) {
  const tag = TIMELINE_OPTIONS.find((o) => o.value === tagValue);
  if (!tag) return '';
  return tag.label;
}
export function tagLabelTool(tagValue: string) {
  const tag = TOOL_OPTIONS.find((o) => o.value === tagValue);
  if (!tag) return '';
  return tag.label;
}
export function tagLabelBudget(tagBudgetMin: string, tagBudgetMax: string) {
  if (tagBudgetMin && tagBudgetMax) {
    return `$${tagBudgetMin} - $${tagBudgetMax}`;
  }
  if (tagBudgetMin) {
    return `At least $${tagBudgetMin}`;
  }
  if (tagBudgetMax) {
    return `Up to $${tagBudgetMax}`;
  }
  return '';
}

export function starValueFormatted(starValue: number) {
  switch (starValue) {
    case 5:
      return 'GOOD';
    case 4:
      return 'FINE';
    case 3:
      return 'BAD';
    case 2:
      return 'AVERAGE';
    case 1:
      return 'HORRIBLE';
    default:
      return '';
  }
}
export function starValueToStr(starValue: number) {
  switch (starValue) {
    case 5:
      return 'excellent';
    case 4:
      return 'fine';
    case 3:
      return 'okay';
    case 2:
      return 'bad';
    case 1:
      return 'horrible';
    default:
      return '';
  }
}

export function expertTaggedName(expertTagged?: string | null) {
  if (expertTagged === 'WARM') {
    return 'Keep Warm';
  }
  if (expertTagged === 'COLD') {
    return 'Cold';
  }
  if (expertTagged === 'LOST') {
    return 'Lost';
  }
  return 'Active';
}

export default {
  amountClientFee,
  amountDiscountCode,
  applyDiscountCode,
  centsDollarsDecimal,
  centsDollarsRounded,
  expertClientsBrag,
  expertFocusPosition,
  expertFocusType,
  expertFocusWhat,
  expertSkillsBrag,
  expertTaggedName,
  explainClientFee,
  explainDiscountCode,
  formatNumberWithCommas,
  formatValidPhoneNumber,
  getExpertCut,
  getExpertLevelStr,
  getNextPaymentDate,
  getNumericValue,
  getPartnerLevelStr,
  isLeadSuggested,
  kpiPercentage,
  kpiRating,
  meetingResponseFormatted,
  notificationDiscountCode,
  partnerReferralMedium,
  paymentTypeFormatted,
  phoneErrorSubtitle,
  phoneErrorTitle,
  pitchDiscountCode,
  prefabPriceStr,
  profileBandwidthValue,
  projectNotificationStatus,
  quoteEventStatus,
  quoteStatusExpertAction,
  quoteStatusFormatted,
  quoteStatusFormattedHuman,
  replyToSubject,
  searchResultType,
  sendingErrorFormatted,
  sendingStatusFormatted,
  shouldAlert,
  starValueFormatted,
  starValueToStr,
  tagLabelBudget,
  tagLabelExperience,
  tagLabelFocus,
  tagLabelIndustry,
  tagLabelProblem,
  tagLabelSkill,
  tagLabelTimeline,
  tagLabelTool,
  tagPersonFocus,
  unclaimedReason,
  unreadReasonFormatted,
  validClientFee,
  validDiscountCode,
  valueClientFee,
  valueDiscountCode,
};
