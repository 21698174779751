import { useState, Fragment } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import PropTypes from 'prop-types';

interface UnclaimActionsProps {
  hasActiveProjects: boolean;
  isLeadCloseTimelinePast: boolean;
  isSwitch: boolean;
  leadMatchedDaysAgo: number;
  onUnclaim: (reason: string, isCancel: boolean, moreDetail?: string) => void;
}

const unclaimIssueData = {
  budget: {
    reason: 'QUOTE_NOT_A_FIT',
    title: 'Budget Issue',
  },
  location: {
    reason: 'LOCATION_NOT_A_FIT',
    title: 'Location Issue',
  },
  portfolio: {
    reason: 'PORTFOLIO_NOT_A_FIT',
    title: 'Portfolio Issue',
  },
  skills: {
    reason: 'SKILLS_NOT_A_FIT',
    title: 'Skills Issue',
  },
  timeline: {
    reason: 'TIMELINE_NOT_A_FIT',
    title: 'Timeline Issue',
  },
} as { [key: string]: { reason: string; title: string } };

const UnclaimActions = ({
  hasActiveProjects,
  isLeadCloseTimelinePast,
  isSwitch,
  leadMatchedDaysAgo,
  onUnclaim,
}: UnclaimActionsProps) => {
  const [inputReason, setInputReason] = useState('');
  const [moreDetail, setMoreDetail] = useState('');
  return (
    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyUnclaim">
      {!isSwitch && (
        <Fragment>
          <div className="ThreadDetailMainFocusModalContainerTitle">
            Not Moving Forward? Move To All Clients
          </div>
          <div className="ThreadDetailMainFocusModalContainerSubtitle">
            Don&apos;t worry - it happens! We&apos;ll adjust the tag to lost for
            now, and move them into your all clients list for later
          </div>
          <div className="ThreadDetailMainFocusModalChoicesSection">
            <div className="ThreadDetailMainFocusModalChoicesTitle">
              Why did this lead not work out?
            </div>
            <div className="ThreadDetailMainFocusModalChoicesOptions">
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onUnclaim('FIGURED_IT_OUT_MYSELF', true)}
              >
                Client figured it out and doesn&apos;t need our help
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onUnclaim('FOUND_SOMEONE_ELSE', true)}
              >
                Client found someone else to do it for them
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() =>
                  onUnclaim('NO_LONGER_PURSUING_THIS_PROJECT', true)
                }
              >
                Client no longer plans to do this project
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() => onUnclaim('CLIENT_UNRESPONSIVE', true)}
              >
                Client is unresponsive &amp; ghosting me
              </div>
              <div
                className="ThreadDetailMainFocusModalChoicesOption"
                onClick={() =>
                  onUnclaim('CLIENT_IS_DIFFICULT_TO_WORK_WITH', true)
                } // FUTURE ask what the issue was...
              >
                I had an issue with the client
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {!!isSwitch && !inputReason && (
        <Fragment>
          <div className="ThreadDetailMainFocusModalContainerTitle">
            {hasActiveProjects ? 'Are you sure?' : 'Not a good fit?'}
          </div>
          <div className="ThreadDetailMainFocusModalContainerSubtitle">
            {hasActiveProjects
              ? 'Before you can put this project back into the queue, you need to cancel the quote you sent.'
              : 'This information helps the next set of experts decide if they want to work with this client. Do not put this project back in queue unless the customer wants to work with someone else.'}
          </div>
          {!hasActiveProjects &&
            leadMatchedDaysAgo < 5 &&
            !isLeadCloseTimelinePast && (
              <div className="ThreadDetailMainFocusModalContainerSubtitle">
                Are you sure? You only recently matched with them. Before
                putting them back in the queue, consider if it&apos;s still
                possible to convert this lead yourself by chatting with them
                more and better understanding what they need.
              </div>
            )}
          {!hasActiveProjects && (
            <div className="ThreadDetailMainFocusModalChoicesSection">
              <div className="ThreadDetailMainFocusModalChoicesTitle">
                Why not?
              </div>
              <div className="ThreadDetailMainFocusModalChoicesOptions">
                <div
                  className="ThreadDetailMainFocusModalChoicesOption"
                  onClick={() => setInputReason('location')}
                >
                  Location Mismatch
                </div>
                <div
                  className="ThreadDetailMainFocusModalChoicesOption"
                  onClick={() => setInputReason('skills')}
                >
                  Skills Required
                </div>
                <div
                  className="ThreadDetailMainFocusModalChoicesOption"
                  onClick={() => setInputReason('portfolio')}
                >
                  Portfolio / Experience Needed
                </div>
                <div
                  className="ThreadDetailMainFocusModalChoicesOption"
                  onClick={() => setInputReason('timeline')}
                >
                  Timeline / Deadline
                </div>
                <div
                  className="ThreadDetailMainFocusModalChoicesOption"
                  onClick={() => setInputReason('budget')}
                >
                  Quote / Budget
                </div>
                {leadMatchedDaysAgo < 5 && !isLeadCloseTimelinePast && (
                  <div
                    className="ThreadDetailMainFocusModalChoicesOption"
                    onClick={() => setInputReason('unresponsive')}
                  >
                    Unresponsive Client
                  </div>
                )}
              </div>
            </div>
          )}
        </Fragment>
      )}
      {!!isSwitch && !!inputReason && inputReason !== 'unresponsive' && (
        <Fragment>
          <div className="ThreadDetailMainFocusModalContainerTitle">
            {unclaimIssueData[inputReason].title || 'Issue'}
          </div>
          <div className="ThreadDetailMainFocusModalContainerSubtitle">
            Help save your fellow experts time and explain what the client
            wants, so they know if they should pick up the job or not.
          </div>
          <div className="ThreadDetailMainFocusModalSummarize">
            <div className="ThreadDetailMainFocusModalSummarizeInput">
              <TextareaAutosize
                type="text"
                rows={5}
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                spellCheck="true"
                className="ThreadDetailMainFocusModalSummarizeTextarea"
                value={moreDetail}
                onChange={(e) => {
                  setMoreDetail(e.currentTarget.value);
                }}
              />
            </div>
            <div
              className="ThreadDetailMainFocusModalSummarizeBtn"
              onClick={() =>
                !!moreDetail &&
                onUnclaim(
                  unclaimIssueData[inputReason].reason || '',
                  !isSwitch,
                  moreDetail,
                )
              }
            >
              Unclaim Client
            </div>
          </div>
        </Fragment>
      )}
      {!!isSwitch && inputReason === 'unresponsive' && (
        <Fragment>
          <div className="ThreadDetailMainFocusModalContainerTitle">
            Unresponsive client?
          </div>
          <div className="ThreadDetailMainFocusModalContainerSubtitle">
            You recently matched with this client, so they may not have had a
            chance to review or respond to you yet. Please wait for their
            response or keep following up via email and text. You can archive
            this lead after 5 days from match.
          </div>
        </Fragment>
      )}
    </div>
  );
};

UnclaimActions.propTypes = {
  hasActiveProjects: PropTypes.bool.isRequired,
  isLeadCloseTimelinePast: PropTypes.bool.isRequired,
  isSwitch: PropTypes.bool.isRequired,
  leadMatchedDaysAgo: PropTypes.number.isRequired,
  onUnclaim: PropTypes.func.isRequired,
};

export default UnclaimActions;
