import React, { ReactNode, Fragment, useRef, useState, useEffect } from 'react';
import {
  Editor,
  EditorState,
  getDefaultKeyBinding,
  getVisibleSelectionRect,
  KeyBindingUtil,
  RichUtils,
  convertFromRaw,
  ContentState,
  ContentBlock,
  CompositeDecorator,
  SelectionState,
  Modifier,
  RawDraftContentState,
} from 'draft-js';
import PropTypes from 'prop-types';
import '../../styles/feature/RichTextEditor.scss';
const { convertFromHTML } = require('draft-convert'); // eslint-disable-line @typescript-eslint/no-var-requires
const { filterEditorState } = require('draftjs-filters'); // eslint-disable-line @typescript-eslint/no-var-requires

interface RichTextEditorProps {
  initContent: string;
  onUpdated: (draftContent: ContentState) => void;
  placeholder: string;
  readOnly?: boolean;
}

const TOOLBAR_WIDTH = 215;
const TOOLBAR_HEIGHT = 40;

const RichTextEditor = ({
  initContent,
  onUpdated,
  placeholder,
  readOnly = false,
}: RichTextEditorProps) => {
  const [editorState, setEditorState] = useState(() => {
    const firstEditorState = fromJSONEncodedString(initContent);
    setTimeout(() => {
      onUpdated(firstEditorState.getCurrentContent());
    });
    return firstEditorState;
  });
  useEffect(() => {
    if (initContent) {
      setEditorState(fromJSONEncodedString(initContent));
    }
  }, [initContent]);
  const [toolbarUrlInput, setToolbarUrlInput] = useState('');
  const [toolbarUrlValid, setToolbarUrlValid] = useState(true);
  const [toolbarUrlOpen, setToolbarUrlOpen] = useState(false);
  const [toolbarLastPosition, setToolbarLastPosition] = useState({
    left: 0,
    top: 0,
  });
  const editorRef = useRef<Editor>(null);
  const urlInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  }, []);
  const selectionRect = getVisibleSelectionRect(window);
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const hidePlaceholder =
    !contentState.hasText() &&
    contentState.getBlockMap().first().getType() !== 'unstyled';
  const startKey = selection.getStartKey();
  const startKeyBlock = contentState.getBlockForKey(startKey);
  const endKey = selection.getEndKey();
  const endKeyBlock = contentState.getBlockForKey(endKey);
  const currentBlockType = startKeyBlock.getType();
  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const startOffset = selection.getStartOffset();
  const startEntity = startKeyBlock.getEntityAt(startOffset);
  const startEntityObj = startEntity
    ? contentState.getEntity(startEntity)
    : null;
  const endOffset = selection.getEndOffset();
  const endEntity = endKeyBlock.getEntityAt(endOffset);
  // const endEntityObj = endEntity ? contentState.getEntity(endEntity) : null;
  const ignoreCurrLinkEntity = startKey !== endKey || startEntity !== endEntity;
  const noLinkingAllowed =
    RichUtils.currentBlockContainsLink(editorState) || ignoreCurrLinkEntity;
  const currEntityKey = ignoreCurrLinkEntity ? null : startEntity || null;
  const currEntity = ignoreCurrLinkEntity ? null : startEntityObj || null;
  const isRemoval =
    currEntity &&
    currEntity.getData().url &&
    (currEntity.getData().url === toolbarUrlInput || !toolbarUrlInput.trim());
  const toolbarStyleDisplay: 'block' | 'none' =
    toolbarUrlOpen ||
    (currEntityKey && selection.getHasFocus()) ||
    (selectionRect && !selection.isCollapsed() && selection.getHasFocus())
      ? 'block'
      : 'none';
  // FUTURE there is still a weird bug that causes toolbar to flash when clicking out of editor and back in
  let toolbarStyleLeft = selectionRect
    ? selectionRect.left - TOOLBAR_WIDTH / 2 + selectionRect.width / 2
    : 0;
  let toolbarStyleTop = selectionRect
    ? selectionRect.top - TOOLBAR_HEIGHT - 5
    : 0;
  if (
    toolbarUrlOpen ||
    (currEntityKey && !toolbarStyleLeft && !toolbarStyleTop)
  ) {
    toolbarStyleLeft = toolbarLastPosition.left;
    toolbarStyleTop = toolbarLastPosition.top;
  }
  function promptLink(ev?: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
    if (
      currEntity ||
      (!noLinkingAllowed && !editorState.getSelection().isCollapsed())
    ) {
      if (currEntity) {
        setToolbarUrlInput(currEntity.getData().url as string);
      } else {
        const checkSelectionText = startKeyBlock
          .getText()
          .slice(startOffset, endOffset)
          .trim();
        const spaceIndex = checkSelectionText.indexOf(' ');
        const dotIndex = checkSelectionText.indexOf('.');
        const isLinkValid =
          checkSelectionText.length &&
          spaceIndex === -1 &&
          dotIndex >= 1 &&
          dotIndex < checkSelectionText.length - 1;
        if (isLinkValid) {
          setToolbarUrlInput(checkSelectionText);
        }
      }
      setToolbarUrlOpen(true);
      if (toolbarStyleDisplay === 'block') {
        setToolbarLastPosition({
          left: toolbarStyleLeft,
          top: toolbarStyleTop,
        });
      }
      setTimeout(() => {
        if (urlInputRef && urlInputRef.current) {
          urlInputRef.current.focus();
        }
      });
    }
  }
  function handleUrlInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      saveLink();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      const escSelection = editorState.getSelection();
      const collapsedEsc = escSelection.merge({
        anchorKey: escSelection.getEndKey(),
        anchorOffset: escSelection.getEndOffset(),
        focusKey: escSelection.getEndKey(),
        focusOffset: escSelection.getEndOffset(),
      });
      const nextEditorState = EditorState.forceSelection(
        editorState,
        collapsedEsc,
      );
      setEditorState(nextEditorState);
      if (urlInputRef && urlInputRef.current) {
        urlInputRef.current.blur();
      }
      sendUpdate(nextEditorState);
    }
  }
  function urlInputChange(val: string) {
    setToolbarUrlValid(true);
    setToolbarUrlInput(val);
  }
  function blurUrlInput() {
    setToolbarUrlOpen(false);
    setToolbarUrlInput('');
  }
  function saveLink(ev?: React.MouseEvent, specificLink?: string) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
    let cleanLink = (specificLink || toolbarUrlInput).trim();
    const spaceIndex = cleanLink.indexOf(' ');
    const dotIndex = cleanLink.indexOf('.');
    const isLinkValid =
      cleanLink.length &&
      spaceIndex === -1 &&
      dotIndex >= 1 &&
      dotIndex < cleanLink.length - 1;
    if (currEntityKey && !cleanLink.length) {
      // if existing entity and input is empty, then removeLink
      removeLink();
    } else if (!isLinkValid) {
      setToolbarUrlValid(false);
    } else {
      const httpIndex = cleanLink.indexOf('http:');
      const httpsIndex = cleanLink.indexOf('https:');
      const mailtoIndex = cleanLink.indexOf('mailto:');
      // if http(s): not found at the start, then we need to add it
      if (httpIndex !== 0 && httpsIndex !== 0 && mailtoIndex !== 0) {
        if (cleanLink.indexOf('@') === -1) {
          cleanLink = 'http://' + cleanLink;
        } else {
          cleanLink = 'mailto:' + cleanLink;
        }
      }
      if (currEntityKey) {
        const contentStateBeforeEdit = editorState.getCurrentContent();
        const contentStateAfterEdit = contentStateBeforeEdit.replaceEntityData(
          currEntityKey,
          {
            url: cleanLink,
          },
        );
        const editedLink = EditorState.push(
          editorState,
          contentStateAfterEdit,
          'apply-entity',
        );
        const linkSelection = editorState.getSelection();
        const collapsedLink = linkSelection.merge({
          anchorKey: linkSelection.getEndKey(),
          anchorOffset: linkSelection.getEndOffset(),
          focusKey: linkSelection.getEndKey(),
          focusOffset: linkSelection.getEndOffset(),
        });
        const nextEditorState = EditorState.forceSelection(
          editedLink,
          collapsedLink,
        );
        setEditorState(nextEditorState);
        sendUpdate(nextEditorState);
      } else if (!editorState.getSelection().isCollapsed()) {
        const contentStateWithEntity = editorState
          .getCurrentContent()
          .createEntity('LINK', 'MUTABLE', {
            url: cleanLink,
          });
        const createdEntityKey =
          contentStateWithEntity.getLastCreatedEntityKey();
        const withLink = RichUtils.toggleLink(
          editorState,
          editorState.getSelection(),
          createdEntityKey,
        );
        const linkSelection = editorState.getSelection();
        const collapsedLink = linkSelection.merge({
          anchorKey: linkSelection.getEndKey(),
          anchorOffset: linkSelection.getEndOffset(),
          focusKey: linkSelection.getEndKey(),
          focusOffset: linkSelection.getEndOffset(),
        });
        const nextEditorState = EditorState.forceSelection(
          withLink,
          collapsedLink,
        );
        setEditorState(nextEditorState);
        sendUpdate(nextEditorState);
      }
      setToolbarUrlOpen(false);
      setToolbarUrlInput('');
    }
  }
  function removeLink(ev?: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
    let removeSelection = editorState.getSelection();
    if (currEntityKey) {
      const contentForRemove = editorState.getCurrentContent();
      const blockWithRemove = contentForRemove
        .getBlockMap()
        .get(removeSelection.getStartKey());
      let entityRange: { start: number; end: number } | undefined;
      blockWithRemove.findEntityRanges(
        (character) => character.getEntity() === currEntityKey,
        (start, end) => {
          entityRange = {
            end,
            start,
          };
        },
      );
      if (entityRange) {
        removeSelection = removeSelection.merge({
          anchorOffset: removeSelection.getIsBackward()
            ? entityRange.end
            : entityRange.start,
          focusOffset: removeSelection.getIsBackward()
            ? entityRange.start
            : entityRange.end,
        });
      }
    }
    const withoutLink = RichUtils.toggleLink(
      editorState,
      removeSelection,
      null,
    );
    const linkSelection = editorState.getSelection();
    const collapsedLink = linkSelection.merge({
      anchorKey: linkSelection.getEndKey(),
      anchorOffset: linkSelection.getEndOffset(),
      focusKey: linkSelection.getEndKey(),
      focusOffset: linkSelection.getEndOffset(),
    });
    const nextEditorState = EditorState.forceSelection(
      withoutLink,
      collapsedLink,
    );
    setEditorState(nextEditorState);
    sendUpdate(nextEditorState);
    setToolbarUrlOpen(false);
    setToolbarUrlInput('');
  }
  function handleKeyCommand(command: string, theEditorState: EditorState) {
    if (command === 'linkify') {
      promptLink();
      return 'handled';
    }
    if (command === 'unordered-list-item' || command === 'ordered-list-item') {
      toggleBlockType(command);
      return 'handled';
    }
    const newState = RichUtils.handleKeyCommand(theEditorState, command);
    if (newState) {
      setEditorState(newState);
      sendUpdate(newState);
      return 'handled';
    }
    return 'not-handled';
  }
  function blockMouseDown(ev: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  function toggleInlineStyle(theInlineStyle: string, ev?: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
    const editorStateFocused = EditorState.forceSelection(
      editorState,
      editorState.getSelection(),
    );
    const nextEditorState = RichUtils.toggleInlineStyle(
      editorStateFocused,
      theInlineStyle,
    );
    setEditorState(nextEditorState);
    sendUpdate(nextEditorState);
  }
  function toggleBlockType(theBlockType: string, ev?: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
    const editorStateFocused = EditorState.forceSelection(
      editorState,
      editorState.getSelection(),
    );
    const nextEditorState = RichUtils.toggleBlockType(
      editorStateFocused,
      theBlockType,
    );
    setEditorState(nextEditorState);
    sendUpdate(nextEditorState);
  }
  function keyBindingFn(e: React.KeyboardEvent): string | null {
    if (
      e.shiftKey &&
      KeyBindingUtil.hasCommandModifier(e) &&
      e.keyCode === 55
    ) {
      return 'ordered-list-item';
    }
    if (
      e.shiftKey &&
      KeyBindingUtil.hasCommandModifier(e) &&
      e.keyCode === 56
    ) {
      return 'unordered-list-item';
    }
    if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 75) {
      return 'linkify';
    }
    if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 74) {
      // block cmd-J code blocks
      return null;
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      const escSelection = editorState.getSelection();
      const collapsedEsc = escSelection.merge({
        anchorKey: escSelection.getEndKey(),
        anchorOffset: escSelection.getEndOffset(),
        focusKey: escSelection.getEndKey(),
        focusOffset: escSelection.getEndOffset(),
      });
      const nextEditorState = EditorState.forceSelection(
        editorState,
        collapsedEsc,
      );
      setEditorState(nextEditorState);
      sendUpdate(nextEditorState);
      return null;
    }
    return getDefaultKeyBinding(e);
  }
  function handlePastedText(
    textPasted: string,
    htmlPasted: string | undefined,
  ): 'handled' | 'not-handled' {
    const checkPastedText = (textPasted || '').trim();
    const spaceIndex = checkPastedText.indexOf(' ');
    const dotIndex = checkPastedText.indexOf('.');
    const isLinkValid =
      checkPastedText.length &&
      spaceIndex === -1 &&
      dotIndex >= 1 &&
      dotIndex < checkPastedText.length - 1;
    if (
      !currEntityKey &&
      !editorState.getSelection().isCollapsed() &&
      isLinkValid
    ) {
      const currentSelectedText = startKeyBlock
        .getText()
        .slice(startOffset, endOffset)
        .trim();
      if (currentSelectedText) {
        saveLink(undefined, checkPastedText);
        return 'handled';
      }
    }
    // FUTURE still a bug where occassionally if you paste a link it will lose the spaces on both sides of it
    if (htmlPasted) {
      const contentStateForPaste = convertFromHTML({
        htmlToEntity: (
          nodeName: string,
          node: HTMLElement,
          createEntity: (type: string, mutability: string, data: any) => string,
        ) => {
          if (nodeName === 'a') {
            return createEntity('LINK', 'MUTABLE', {
              url: (node as HTMLAnchorElement).href,
            });
          }
          return undefined;
        },
        htmlToStyle: (
          nodeName: string,
          node: HTMLElement,
          currentStyle: any,
        ) => {
          if (
            (nodeName === 'strong' || nodeName === 'b') &&
            node &&
            node.style &&
            (node.style.fontWeight === 'normal' ||
              node.style.fontWeight === '400')
          ) {
            return currentStyle.delete('BOLD');
          }
          if (
            node &&
            node.style &&
            (node.style.fontWeight === 'bold' ||
              node.style.fontWeight === '700')
          ) {
            return currentStyle.add('BOLD');
          }
          return currentStyle;
        },
      })(htmlPasted);
      if (contentStateForPaste) {
        const afterPaste = Modifier.replaceWithFragment(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          contentStateForPaste.getBlockMap(),
        );
        const nextState = EditorState.push(
          editorState,
          afterPaste,
          'insert-fragment',
        );
        const nextEditorState = filterEditorState(
          {
            blocks: ['ordered-list-item', 'unordered-list-item'],
            entities: [
              {
                attributes: ['url'],
                type: 'LINK',
              },
            ],
            maxNesting: 4,
            styles: ['BOLD', 'ITALIC', 'UNDERLINE'],
            whitespacedCharacters: ['\n', '\t'],
          },
          nextState,
        ) as EditorState;
        setEditorState(nextEditorState);
        sendUpdate(nextEditorState);
        return 'handled';
      }
    }
    return 'not-handled';
  }
  function handleBeforeInput(char: string) {
    const selectionBeforeInput = editorState.getSelection();
    if (selectionBeforeInput.isCollapsed()) {
      const contentBeforeInput = editorState.getCurrentContent();
      const blockBeforeInput = contentBeforeInput.getBlockForKey(
        selectionBeforeInput.getStartKey(),
      );
      const startOffsetBeforeInput = selectionBeforeInput.getStartOffset();
      const textBeforeInput = blockBeforeInput.getText();
      const beforeInput = textBeforeInput.slice(0, startOffsetBeforeInput);
      const mark = `${beforeInput}${char}`;
      let newEditorStateBeforeInput = editorState;
      let newBlockTypeBeforeInput = '';
      if (mark === '* ') {
        newBlockTypeBeforeInput = 'unordered-list-item';
      } else if (mark === '+ ') {
        newBlockTypeBeforeInput = 'unordered-list-item';
      } else if (mark === '- ') {
        newBlockTypeBeforeInput = 'unordered-list-item';
      } else if (mark === '1. ') {
        newBlockTypeBeforeInput = 'ordered-list-item';
      }
      if (
        newBlockTypeBeforeInput &&
        blockBeforeInput.getType() === 'unstyled'
      ) {
        const key = selectionBeforeInput.getStartKey();
        newEditorStateBeforeInput = RichUtils.toggleBlockType(
          newEditorStateBeforeInput,
          newBlockTypeBeforeInput,
        );
        let newContentBeforeInput =
          newEditorStateBeforeInput.getCurrentContent();
        let killSelection = SelectionState.createEmpty(key);
        killSelection = killSelection.merge({
          focusKey: key,
          focusOffset: beforeInput.length,
        });
        newContentBeforeInput = Modifier.replaceText(
          newContentBeforeInput,
          killSelection,
          '',
        );
        newEditorStateBeforeInput = EditorState.push(
          newEditorStateBeforeInput,
          newContentBeforeInput,
          'remove-range',
        );
        newEditorStateBeforeInput = EditorState.forceSelection(
          newEditorStateBeforeInput,
          newEditorStateBeforeInput.getSelection(),
        );
      }
      if (newEditorStateBeforeInput !== editorState) {
        setEditorState(newEditorStateBeforeInput);
        sendUpdate(newEditorStateBeforeInput);
        return 'handled';
      }
    }
    return 'not-handled';
  }
  function onChange(nextEditorState: EditorState) {
    let filteredState = nextEditorState;
    const shouldFilterPaste =
      nextEditorState.getCurrentContent() !== editorState.getCurrentContent() &&
      nextEditorState.getLastChangeType() === 'insert-fragment';
    if (shouldFilterPaste) {
      filteredState = filterEditorState(
        {
          blocks: ['ordered-list-item', 'unordered-list-item'],
          entities: [
            {
              attributes: ['url'],
              type: 'LINK',
            },
          ],
          maxNesting: 4,
          styles: ['BOLD', 'ITALIC', 'UNDERLINE'],
          whitespacedCharacters: ['\n', '\t'],
        },
        filteredState,
      );
    }
    setEditorState(filteredState);
    sendUpdate(filteredState);
  }
  function onTab(e: React.KeyboardEvent) {
    const nextEditorState = RichUtils.onTab(e, editorState, 4);
    setEditorState(nextEditorState);
    sendUpdate(nextEditorState);
  }
  function sendUpdate(updatedEditorState: EditorState) {
    onUpdated(updatedEditorState.getCurrentContent());
  }
  return (
    <div className="RichTextEditor">
      <div
        className={
          'RichTextEditorWrap ' +
          (hidePlaceholder ? 'RichTextEditorWrapHidePlaceholder' : '')
        }
        onClick={() => editorRef.current && editorRef.current.focus()}
      >
        <Editor
          editorState={editorState}
          onChange={onChange}
          handleKeyCommand={handleKeyCommand}
          ref={editorRef}
          autoCorrect="off"
          autoComplete="off"
          spellCheck={true}
          onTab={onTab}
          keyBindingFn={keyBindingFn}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          placeholder={placeholder || ''}
          readOnly={readOnly}
        />
      </div>
      <div
        className="RichTextEditorToolbar"
        style={{
          display: toolbarStyleDisplay,
          left: Math.max(toolbarStyleLeft, 5),
          top: Math.max(toolbarStyleTop, 5),
        }}
      >
        {currEntityKey || toolbarUrlOpen ? (
          <Fragment>
            <div className="RichTextEditorToolbarInputWrapper">
              {toolbarUrlOpen ? (
                <input
                  type="text"
                  placeholder="https://"
                  autoComplete="new-off"
                  spellCheck="false"
                  className={
                    'RichTextEditorToolbarUrlFieldInput ' +
                    (toolbarUrlValid
                      ? ''
                      : 'RichTextEditorToolbarUrlFieldInputInvalid')
                  }
                  value={toolbarUrlInput}
                  onChange={(e) => {
                    urlInputChange(e.target.value);
                  }}
                  onBlur={blurUrlInput}
                  onKeyDown={handleUrlInputKeyDown}
                  ref={urlInputRef}
                />
              ) : (
                <div
                  className="RichTextEditorToolbarInputCover"
                  onMouseDown={blockMouseDown}
                  onClick={promptLink}
                >
                  {currEntity ? currEntity.getData().url : ''}
                </div>
              )}
            </div>
            {isRemoval ? (
              <div
                className="RichTextEditorToolbarInputBtn RichTextEditorToolbarInputRemove"
                onMouseDown={blockMouseDown}
                onClick={removeLink}
              >
                Unlink
              </div>
            ) : (
              <div
                className="RichTextEditorToolbarInputBtn"
                onMouseDown={blockMouseDown}
                onClick={saveLink}
              >
                Link
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={
                'RichTextEditorToolbarControl RichTextEditorToolbarControlBold ' +
                (currentInlineStyle.has('BOLD')
                  ? ' RichTextEditorToolbarControlActive '
                  : '')
              }
              onMouseDown={blockMouseDown}
              onClick={(e) => toggleInlineStyle('BOLD', e)}
            />
            <div
              className={
                'RichTextEditorToolbarControl RichTextEditorToolbarControlItalic ' +
                (currentInlineStyle.has('ITALIC')
                  ? ' RichTextEditorToolbarControlActive '
                  : '')
              }
              onMouseDown={blockMouseDown}
              onClick={(e) => toggleInlineStyle('ITALIC', e)}
            />
            <div
              className={
                'RichTextEditorToolbarControl RichTextEditorToolbarControlUnderline ' +
                (currentInlineStyle.has('UNDERLINE')
                  ? ' RichTextEditorToolbarControlActive '
                  : '')
              }
              onMouseDown={blockMouseDown}
              onClick={(e) => toggleInlineStyle('UNDERLINE', e)}
            />
            <div
              className={
                'RichTextEditorToolbarControl RichTextEditorToolbarControlUnordered ' +
                (currentBlockType === 'unordered-list-item'
                  ? ' RichTextEditorToolbarControlActive '
                  : '')
              }
              onMouseDown={blockMouseDown}
              onClick={(e) => toggleBlockType('unordered-list-item', e)}
            />
            <div
              className={
                'RichTextEditorToolbarControl RichTextEditorToolbarControlOrdered ' +
                (currentBlockType === 'ordered-list-item'
                  ? ' RichTextEditorToolbarControlActive '
                  : '')
              }
              onMouseDown={blockMouseDown}
              onClick={(e) => toggleBlockType('ordered-list-item', e)}
            />
            {!noLinkingAllowed && (
              <div
                className="RichTextEditorToolbarControl RichTextEditorToolbarControlLink"
                onMouseDown={blockMouseDown}
                onClick={promptLink}
              />
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

RichTextEditor.propTypes = {
  initContent: PropTypes.string.isRequired,
  onUpdated: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

interface LinkComponentProps {
  contentState: ContentState;
  entityKey: string;
  children: ReactNode | ReactNode[];
}
const Link = ({
  contentState: linkContentState,
  entityKey: linkEntityKey,
  children: linkChildren,
}: LinkComponentProps) => (
  <a
    href={linkContentState.getEntity(linkEntityKey).getData().url}
    className="link-entity"
  >
    {linkChildren}
  </a>
);
const decorator = new CompositeDecorator([
  {
    component: Link,
    strategy: findLinkEntities,
  },
]);
function findLinkEntities(
  findContentBlock: ContentBlock,
  findCallback: (start: number, end: number) => void,
  findContentState: ContentState,
) {
  findContentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      findContentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, findCallback);
}
const fromJSONEncodedString = (jsonString: string) => {
  if (jsonString) {
    try {
      return EditorState.createWithContent(
        convertFromRaw(JSON.parse(jsonString) as RawDraftContentState),
        decorator,
      );
    } catch (_e) {
      return EditorState.createWithContent(
        ContentState.createFromText(jsonString),
        decorator,
      );
    }
  }
  return EditorState.createWithContent(
    ContentState.createFromText(''),
    decorator,
  );
};

export default RichTextEditor;
