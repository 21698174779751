/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  Fragment,
} from 'react';
import ReactTooltip from 'react-tooltip';
import { useDebouncedCallback } from 'use-debounce';
import { ClientWithOnReconnected } from '../../utils/apollo';
import moment from 'moment-timezone';
import { ContentState, convertToRaw } from 'draft-js';
import TextareaAutosize from 'react-autosize-textarea';
import PropTypes from 'prop-types';
import {
  useMutation,
  useQuery,
  useLazyQuery,
  ApolloError,
  gql,
} from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import envUtils from '../../utils/env';
import logError from '../../utils/airbrake';
import errorUtils from '../../utils/error';
import {
  SUPPORT_EXPERT_ID,
  FOCUS_OPTIONS,
  SKILL_OPTIONS,
  TOOL_OPTIONS,
  INDUSTRY_OPTIONS,
} from '../../utils/constants';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import { IFilestackFileUpload } from '../../utils/filestack';
import {
  TextMessageEventDetail,
  EmailMessageEventDetail,
  MessageEventDetail,
  MeetingEventDetail,
  QuoteDetail,
  PhoneCallEventDetail,
  RequestPartialTop,
  RequestEventDetail,
  RequestSummaryHq,
  MatchSummaryHq,
  MatchDetail,
  BrandDetail,
  BrandDetailHq,
  ExpertSummary,
  HumanSummary,
  UserNoteDetail,
  ProjectDetailHq,
} from '../../utils/gql';
import templateUtils from '../../utils/template';
import {
  replyToSubject,
  unreadReasonFormatted,
  centsDollarsRounded,
  quoteStatusFormatted,
  searchResultType,
  amountDiscountCode,
  amountClientFee,
  kpiDollars,
  kpiNumber,
  tagLabelIndustry,
} from '../../utils/format';
import tokenUtils from '../../utils/token';
import ThreadSearchResults from '../feature/ThreadSearchResults';
import RichTextEditor from '../feature/RichTextEditor';
import MultiSelect from '../feature/MultiSelect';
import UserNoteEditor from '../feature/UserNoteEditor';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import HumanUserBubble from '../feature/HumanUserBubble';
import EmailMessageThreadEvent from '../threadEvent/EmailMessageThreadEvent';
import TextMessageThreadEvent from '../threadEvent/TextMessageThreadEvent';
import MessageThreadEvent from '../threadEvent/MessageThreadEvent';
import PhoneCallThreadEvent from '../threadEvent/PhoneCallThreadEvent';
import MeetingThreadEvent from '../threadEvent/MeetingThreadEvent';
import UnknownHuman from '../threadEvent/UnknownHuman';
import EmailEditor from '../feature/EmailEditor';
import TextEditor from '../feature/TextEditor';
import {
  TemplatesQuery,
  BrandDetailsAdminQuery,
  BrandDetailsAdminQueryVariables,
  AdminThreadPaginatedQuery,
  AdminThreadPaginatedQueryVariables,
  AdminThreadEventUpdatedSubscription,
  AdminThreadEventUpdatedSubscriptionVariables,
  UserNoteBrandAdminQuery,
  UserNoteBrandAdminQueryVariables,
  UserNoteBrandEditAdminMutation,
  UserNoteBrandEditAdminMutationVariables,
  UserNoteBrandUpdatedAdminSubscription,
  UserNoteBrandUpdatedAdminSubscriptionVariables,
  RequestPaginateBrandQuery,
  RequestPaginateBrandQueryVariables,
  RequestSubmitByAdminMutation,
  RequestSubmitByAdminMutationVariables,
  MatchSnoozeExpertAdminMutation,
  MatchSnoozeExpertAdminMutationVariables,
  MatchPaginateBrandQuery,
  MatchPaginateBrandQueryVariables,
  ProjectPaginateBrandQuery,
  ProjectPaginateBrandQueryVariables,
  AdminThreadSendTextMessageMutation,
  AdminThreadSendTextMessageMutationVariables,
  AdminThreadSendEmailMessageMutation,
  AdminThreadSendEmailMessageMutationVariables,
  MeetingInviteResponseAdminMutation,
  MeetingInviteResponseAdminMutationVariables,
  BrandEditByAdminMutation,
  BrandEditByAdminMutationVariables,
  BrandAddHumanAdminMutation,
  BrandAddHumanAdminMutationVariables,
  BrandEditTeamByAdminMutation,
  BrandEditTeamByAdminMutationVariables,
  BrandSwitchTeamLeaderByAdminMutation,
  BrandSwitchTeamLeaderByAdminMutationVariables,
  BrandIgnoreHumanAdminMutation,
  BrandIgnoreHumanAdminMutationVariables,
  BrandMergeHumanAdminMutation,
  BrandMergeHumanAdminMutationVariables,
  OrphanUnthreadEmailFromAdminMutation,
  OrphanUnthreadEmailFromAdminMutationVariables,
  OrphanUnthreadTextFromAdminMutation,
  OrphanUnthreadTextFromAdminMutationVariables,
  BrandMergeByAdminMutation,
  BrandMergeByAdminMutationVariables,
  BrandSearchForAdminMutation,
  BrandSearchForAdminMutationVariables,
  OrphanUnthreadPhoneCallFromAdminMutation,
  OrphanUnthreadPhoneCallFromAdminMutationVariables,
  OrphanUnthreadMeetingFromAdminMutation,
  OrphanUnthreadMeetingFromAdminMutationVariables,
  MatchAssignAdminMutation,
  MatchAssignAdminMutationVariables,
  MatchMarkAsReadUnreadAdminMutation,
  MatchMarkAsReadUnreadAdminMutationVariables,
  MatchManualExpertBrandMutation,
  MatchManualExpertBrandMutationVariables,
  ExpertSearchForAdminBrandMutation,
  ExpertSearchForAdminBrandMutationVariables,
  HumanSearchForAdminBrandMutation,
  HumanSearchForAdminBrandMutationVariables,
  MatchThreadSearchBrandMutation,
  MatchThreadSearchBrandMutationVariables,
  QuoteStatus,
} from '../../gql/graphql';
const ReactFilestack = require('filestack-react').default; // eslint-disable-line @typescript-eslint/no-var-requires

const brandDetailsAdminQuery = gql`
  query BrandDetailsAdmin($brandId: ID!) {
    brandDetails(brandId: $brandId) {
      ...BrandDetailHq
    }
  }
  ${BrandDetailHq}
`;

// @connection(key: "thread", filter: ["matchId"])
const adminThreadPaginatedQuery = gql`
  query AdminThreadPaginated(
    $matchId: ID
    $direction: String!
    $fromDate: Date
    $limit: Int!
  ) {
    threadPaginated(
      matchId: $matchId
      direction: $direction
      fromDate: $fromDate
      limit: $limit
    ) {
      id
      edges {
        id
        cursor
        node {
          ... on TextMessageEvent {
            ...TextMessageEventDetail
          }
          ... on EmailMessageEvent {
            ...EmailMessageEventDetail
          }
          ... on RequestEvent {
            ...RequestEventDetail
          }
          ... on MessageEvent {
            ...MessageEventDetail
          }
          ... on MeetingEvent {
            ...MeetingEventDetail
          }
          ... on Quote {
            ...QuoteDetail
          }
          ... on PhoneCallEvent {
            ...PhoneCallEventDetail
          }
        }
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const adminThreadEventUpdatedSubscription = gql`
  subscription AdminThreadEventUpdated($threadId: ID!) {
    threadEventUpdated(threadId: $threadId) {
      ... on TextMessageEvent {
        ...TextMessageEventDetail
      }
      ... on EmailMessageEvent {
        ...EmailMessageEventDetail
      }
      ... on RequestEvent {
        ...RequestEventDetail
      }
      ... on MessageEvent {
        ...MessageEventDetail
      }
      ... on MeetingEvent {
        ...MeetingEventDetail
      }
      ... on Quote {
        ...QuoteDetail
      }
      ... on PhoneCallEvent {
        ...PhoneCallEventDetail
      }
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const userNoteAdminQuery = gql`
  query UserNoteBrandAdmin($matchId: ID, $supportChannelId: ID, $humanId: ID) {
    userNoteForTarget(
      matchId: $matchId
      supportChannelId: $supportChannelId
      humanId: $humanId
    ) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const userNoteEditAdminMutation = gql`
  mutation UserNoteBrandEditAdmin(
    $body: String!
    $userNoteId: ID
    $matchId: ID
    $supportChannelId: ID
    $humanId: ID
  ) {
    userNoteEdit(
      body: $body
      userNoteId: $userNoteId
      matchId: $matchId
      supportChannelId: $supportChannelId
      humanId: $humanId
    ) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const adminUserNoteUpdatedSubscription = gql`
  subscription UserNoteBrandUpdatedAdmin($targetId: ID!) {
    userNoteUpdated(targetId: $targetId) {
      ...UserNoteDetail
    }
  }
  ${UserNoteDetail}
`;

const matchThreadSearchBrandMutation = gql`
  mutation MatchThreadSearchBrand(
    $searchQuery: String!
    $matchId: ID
    $limit: Int!
  ) {
    threadSearch(searchQuery: $searchQuery, matchId: $matchId, limit: $limit) {
      id
      highlightsContent
      highlightsSubject
      node {
        ... on TextMessageEvent {
          ...TextMessageEventDetail
        }
        ... on EmailMessageEvent {
          ...EmailMessageEventDetail
        }
        ... on RequestEvent {
          ...RequestEventDetail
        }
        ... on MessageEvent {
          ...MessageEventDetail
        }
        ... on MeetingEvent {
          ...MeetingEventDetail
        }
        ... on Quote {
          ...QuoteDetail
        }
        ... on PhoneCallEvent {
          ...PhoneCallEventDetail
        }
      }
      plainTextContent
      plainTextSubject
      score
    }
  }
  ${TextMessageEventDetail}
  ${EmailMessageEventDetail}
  ${RequestEventDetail}
  ${MessageEventDetail}
  ${MeetingEventDetail}
  ${QuoteDetail}
  ${PhoneCallEventDetail}
`;

const requestPaginateBrandQuery = gql`
  query RequestPaginateBrand(
    $brandId: ID!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    requestPaginateBrand(
      brandId: $brandId
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...RequestSummaryHq
    }
  }
  ${RequestSummaryHq}
`;

const matchPaginateBrandQuery = gql`
  query MatchPaginateBrand(
    $brandId: ID!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    matchPaginateBrand(
      brandId: $brandId
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...MatchSummaryHq
    }
  }
  ${MatchSummaryHq}
`;

const projectPaginateBrandQuery = gql`
  query ProjectPaginateBrand(
    $brandId: ID!
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    projectPaginateBrand(
      brandId: $brandId
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...ProjectDetailHq
    }
  }
  ${ProjectDetailHq}
`;

const matchAssignAdminMutation = gql`
  mutation MatchAssignAdmin($matchId: ID!, $adminAssignment: String!) {
    matchAssignAdmin(matchId: $matchId, adminAssignment: $adminAssignment) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const requestSubmitByAdminMutation = gql`
  mutation RequestSubmitByAdmin($input: RequestSubmitInput!) {
    requestSubmitByAdmin(input: $input) {
      ...RequestPartialTop
    }
  }
  ${RequestPartialTop}
`;

const adminThreadSendTextMessageMutation = gql`
  mutation AdminThreadSendTextMessage($input: ThreadSendTextMessageInput!) {
    threadSendTextMessageByAdmin(input: $input) {
      ...TextMessageEventDetail
    }
  }
  ${TextMessageEventDetail}
`;

const adminThreadSendEmailMessageMutation = gql`
  mutation AdminThreadSendEmailMessage($input: ThreadSendEmailMessageInput!) {
    threadSendEmailMessageByAdmin(input: $input) {
      ...EmailMessageEventDetail
    }
  }
  ${EmailMessageEventDetail}
`;

const meetingInviteResponseAdminMutation = gql`
  mutation MeetingInviteResponseAdmin($meetingId: ID!, $response: String!) {
    meetingInviteResponseByAdmin(meetingId: $meetingId, response: $response) {
      ...MeetingEventDetail
    }
  }
  ${MeetingEventDetail}
`;

const matchManualExpertBrandMutation = gql`
  mutation MatchManualExpertBrand($brandId: ID!, $expertId: ID!) {
    matchManualExpertBrand(brandId: $brandId, expertId: $expertId) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const expertSearchForAdminBrandMutation = gql`
  mutation ExpertSearchForAdminBrand(
    $searchQuery: String!
    $onlyActives: Boolean
  ) {
    expertSearchForAdmin(searchQuery: $searchQuery, onlyActives: $onlyActives) {
      ...ExpertSummary
    }
  }
  ${ExpertSummary}
`;

const humanSearchForAdminBrandMutation = gql`
  mutation HumanSearchForAdminBrand($searchQuery: String!) {
    humanSearchForAdmin(searchQuery: $searchQuery) {
      ...HumanSummary
    }
  }
  ${HumanSummary}
`;

const matchMarkAsReadUnreadAdminMutation = gql`
  mutation MatchMarkAsReadUnreadAdmin($matchId: ID!, $unreadReason: String) {
    matchMarkAsReadUnread(matchId: $matchId, unreadReason: $unreadReason) {
      id
      expertUnread
    }
  }
`;

const matchSnoozeExpertAdminMutation = gql`
  mutation MatchSnoozeExpertAdmin($matchId: ID!, $untilDate: Date!) {
    matchSnoozeExpert(matchId: $matchId, untilDate: $untilDate) {
      ...MatchDetail
    }
  }
  ${MatchDetail}
`;

const brandEditByAdminMutation = gql`
  mutation BrandEditByAdmin(
    $brandId: ID!
    $name: String!
    $description: String
    $url: String!
    $shopifyAdminURL: String
    $platform: String!
    $accountTier: String!
    $isDelicate: Boolean!
    $isKeyAccount: Boolean
    $isShopifyPlus: Boolean
    $shopifyStorefrontPassword: String
    $invoicingEnabled: Boolean
    $addressLineOne: String
    $addressLineTwo: String
    $legalName: String
    $vatNumber: String
    $shouldExcludeFromKpiReports: Boolean
    $industryTag: String
  ) {
    brandEditByAdmin(
      brandId: $brandId
      name: $name
      description: $description
      url: $url
      shopifyAdminURL: $shopifyAdminURL
      platform: $platform
      accountTier: $accountTier
      isDelicate: $isDelicate
      isKeyAccount: $isKeyAccount
      isShopifyPlus: $isShopifyPlus
      shopifyStorefrontPassword: $shopifyStorefrontPassword
      invoicingEnabled: $invoicingEnabled
      addressLineOne: $addressLineOne
      addressLineTwo: $addressLineTwo
      legalName: $legalName
      vatNumber: $vatNumber
      shouldExcludeFromKpiReports: $shouldExcludeFromKpiReports
      industryTag: $industryTag
    ) {
      ...BrandDetailHq
    }
  }
  ${BrandDetailHq}
`;

const brandEditTeamByAdminMutation = gql`
  mutation BrandEditTeamByAdmin($brandId: ID!, $team: [ID!]!) {
    brandEditTeamByAdmin(brandId: $brandId, team: $team) {
      ...BrandDetailHq
    }
  }
  ${BrandDetailHq}
`;

const brandSwitchTeamLeaderByAdminMutation = gql`
  mutation BrandSwitchTeamLeaderByAdmin($brandId: ID!, $newLeader: ID!) {
    brandSwitchTeamLeaderByAdmin(brandId: $brandId, newLeader: $newLeader) {
      ...BrandDetailHq
    }
  }
  ${BrandDetailHq}
`;

const brandAddHumanAdminMutation = gql`
  mutation BrandAddHumanAdmin($brandId: ID!, $humanId: ID!) {
    brandAddHuman(brandId: $brandId, humanId: $humanId) {
      ...BrandDetailHq
    }
  }
  ${BrandDetailHq}
`;

const brandIgnoreHumanAdminMutation = gql`
  mutation BrandIgnoreHumanAdmin($brandId: ID!, $humanId: ID!) {
    brandIgnoreHuman(brandId: $brandId, humanId: $humanId) {
      ...BrandDetailHq
    }
  }
  ${BrandDetailHq}
`;

const brandMergeHumanAdminMutation = gql`
  mutation BrandMergeHumanAdmin(
    $brandId: ID!
    $parentHumanId: ID!
    $childHumanId: ID!
  ) {
    brandMergeHuman(
      brandId: $brandId
      parentHumanId: $parentHumanId
      childHumanId: $childHumanId
    ) {
      id
    }
  }
`;

const orphanUnthreadEmailFromAdminMutation = gql`
  mutation OrphanUnthreadEmailFromAdmin(
    $matchId: ID!
    $emailMessageEventId: ID!
  ) {
    orphanUnthreadEmailFromMatch(
      matchId: $matchId
      emailMessageEventId: $emailMessageEventId
    ) {
      id
    }
  }
`;

const orphanUnthreadTextFromAdminMutation = gql`
  mutation OrphanUnthreadTextFromAdmin(
    $matchId: ID!
    $textMessageEventId: ID!
  ) {
    orphanUnthreadTextFromMatch(
      matchId: $matchId
      textMessageEventId: $textMessageEventId
    ) {
      id
    }
  }
`;

const orphanUnthreadPhoneCallFromAdminMutation = gql`
  mutation OrphanUnthreadPhoneCallFromAdmin(
    $matchId: ID!
    $phoneCallEventId: ID!
  ) {
    orphanUnthreadPhoneCallFromMatch(
      matchId: $matchId
      phoneCallEventId: $phoneCallEventId
    ) {
      id
    }
  }
`;

const orphanUnthreadMeetingFromAdminMutation = gql`
  mutation OrphanUnthreadMeetingFromAdmin($matchId: ID!, $meetingEventId: ID!) {
    orphanUnthreadMeetingFromMatch(
      matchId: $matchId
      meetingEventId: $meetingEventId
    ) {
      id
    }
  }
`;

const brandSearchForAdminMutation = gql`
  mutation BrandSearchForAdmin($searchQuery: String!) {
    brandSearchForAdmin(searchQuery: $searchQuery) {
      ...BrandDetail
    }
  }
  ${BrandDetail}
`;

const brandMergeByAdminMutation = gql`
  mutation BrandMergeByAdmin($parentBrandId: ID!, $childBrandId: ID!) {
    brandMergeByAdmin(
      parentBrandId: $parentBrandId
      childBrandId: $childBrandId
    ) {
      ...BrandDetailHq
    }
  }
  ${BrandDetailHq}
`;

interface AdminBrandDetailProps {
  brandId: string;
  emailTemplates: TemplatesQuery['templates'];
  socketClient: ClientWithOnReconnected;
}

interface IGroupedEvents {
  group: AdminThreadPaginatedQuery['threadPaginated']['edges'];
}

let scrollDownQuickTimeout: NodeJS.Timeout | undefined;
let isLoadingEvents = false;
const EVENT_PAGE_LIMIT = 20;
const ACTIVITY_PAGE_LIMIT = 5;
const initTime = new Date().getTime();

const AdminBrandDetail = ({
  brandId,
  emailTemplates,
  socketClient,
}: AdminBrandDetailProps) => {
  const history = useHistory();
  const { addNotification } = useContext(GlobalNotificationContext);
  const threadEventsWindow = useRef<HTMLDivElement>(null);
  const [initDate] = useState(() => new Date());
  const [actionLoading, setActionLoading] = useState(false);
  const [adminLoading, setAdminLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [unthreadLoading, setUnthreadLoading] = useState(false);
  const [showLoadMoreRequests, setShowLoadMoreRequests] = useState(true);
  const [showLoadMoreMatches, setShowLoadMoreMatches] = useState(true);
  const [showLoadMoreProjects, setShowLoadMoreProjects] = useState(true);
  const [emailDraft, setEmailDraft] = useState(false);
  const [emailStatus, setEmailStatus] = useState('');
  const [emailFiles, setEmailFiles] = useState([] as IFilestackFileUpload[]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailContent, setEmailContent] = useState('');
  const [emailRecipients, setEmailRecipients] = useState(
    [] as BrandDetailsAdminQuery['brandDetails']['team'],
  );
  const [textDraft, setTextDraft] = useState(false);
  const [textStatus, setTextStatus] = useState('');
  const [textFiles, setTextFiles] = useState([] as IFilestackFileUpload[]);
  const [textContent, setTextContent] = useState('');
  const [textRecipients, setTextRecipients] = useState(
    [] as BrandDetailsAdminQuery['brandDetails']['team'],
  );
  const [openActions, setOpenActions] = useState(false);
  const [currentAction, setCurrentAction] = useState('');
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [tempEdges, setTempEdges] = useState(
    [] as AdminThreadPaginatedQuery['threadPaginated']['edges'],
  );
  // remoteEventIds tracks threadEventUpdated things that might cause pagination errors
  const [remoteEventIds, setRemoteEventIds] = useState([] as string[]);
  const [tempTeamIgnores, setTempTeamIgnores] = useState([] as string[]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [editBrandName, setEditBrandName] = useState('');
  const [editBrandDescription, setEditBrandDescription] = useState('');
  const [editBrandUrl, setEditBrandUrl] = useState('');
  const [editBrandAdminUrl, setEditBrandAdminUrl] = useState('');
  const [editBrandIndustryTag, setEditBrandIndustryTag] = useState('');
  const [editBrandPlatform, setEditBrandPlatform] = useState('');
  const [editBrandAccountTier, setEditBrandAccountTier] = useState('');
  const [editBrandStorefrontPwd, setEditBrandStorefrontPwd] = useState('');
  const [editBrandIsKeyAccount, setEditBrandIsKeyAccount] = useState('false');
  const [editBrandIsDelicate, setEditBrandIsDelicate] = useState('false');
  const [editBrandIsShopifyPlus, setEditBrandIsShopifyPlus] = useState('false');
  const [editBrandInvoicing, setEditBrandInvoicing] = useState('false');
  const [editBrandExcludeKpis, setEditBrandExcludeKpis] = useState('false');
  const [editBrandAddressOne, setEditBrandAddressOne] = useState('');
  const [editBrandAddressTwo, setEditBrandAddressTwo] = useState('');
  const [editBrandVatNumber, setEditBrandVatNumber] = useState('');
  const [editBrandLegalName, setEditBrandLegalName] = useState('');
  const [tagTools, setTagTools] = useState([] as string[]);
  const [tagFocus, setTagFocus] = useState([] as string[]);
  const [tagSkills, setTagSkills] = useState([] as string[]);
  const [requestOnlySuggested, setRequestOnlySuggested] = useState('false');
  const [requestIsHqApprove, setRequestIsHqApprove] = useState('false');
  const [requestIsMultipleMatch, setRequestIsMultipleMatch] = useState('false');
  const [requestFiles, setRequestFiles] = useState(
    [] as IFilestackFileUpload[],
  );
  const [editorLatest, setEditorLatest] = useState<ContentState | null>(null);
  const [primaryPanel, setPrimaryPanel] = useState('About');
  const searchRef = useRef<HTMLInputElement>(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedSearchResult, setSelectedSearchResult] = useState<
    MatchThreadSearchBrandMutation['threadSearch'][0] | null
  >(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<
    MatchThreadSearchBrandMutation['threadSearch'] | null
  >(null);
  const [searchInputExperts, setSearchInputExperts] = useState('');
  const [searchResultsExperts, setSearchResultsExperts] = useState<
    ExpertSearchForAdminBrandMutation['expertSearchForAdmin'] | null
  >(null);
  const [searchInputHumans, setSearchInputHumans] = useState('');
  const [searchResultsHumans, setSearchResultsHumans] = useState<
    HumanSearchForAdminBrandMutation['humanSearchForAdmin'] | null
  >(null);
  const [searchInputBrands, setSearchInputBrands] = useState('');
  const [searchResultsBrands, setSearchResultsBrands] = useState<
    BrandSearchForAdminMutation['brandSearchForAdmin'] | null
  >(null);
  const [mergeSpecific, setMergeSpecific] = useState('');
  const [matchSpecific, setMatchSpecific] = useState('');
  const [removeSpecific, setRemoveSpecific] = useState('');
  const [addSpecific, setAddSpecific] = useState('');
  const [createNewRequest, setCreateNewRequest] = useState(false);
  const [adminSnoozed] = useState('');
  const [adminAssignment] = useState('');
  useEffect(() => {
    if (!searchResults) {
      ReactTooltip.hide();
    }
    ReactTooltip.rebuild();
  }, [searchLoading, searchResults]);
  const {
    data: dataBrand,
    error: errorBrand,
    loading: loadingBrand,
    refetch: refetchBrand,
  } = useQuery<BrandDetailsAdminQuery, BrandDetailsAdminQueryVariables>(
    brandDetailsAdminQuery,
    {
      returnPartialData: true,
      variables: {
        brandId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorBrand) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorBrand) || 'Could not load brand details';
  }
  const brandDetails =
    dataBrand && dataBrand.brandDetails && dataBrand.brandDetails.id
      ? dataBrand.brandDetails
      : undefined;
  const adminMatchId =
    brandDetails && brandDetails.adminMatch ? brandDetails.adminMatch.id : '';
  const brandTeam = (brandDetails && brandDetails.team) || [];
  const emailBrandTeam = brandTeam.filter((h) => h.primaryEmail);
  const phoneBrandTeam = brandTeam.filter((h) => h.primaryPhone);
  const {
    data: dataThread,
    error: errorThread,
    loading: loadingThread,
    fetchMore: fetchMoreThread,
    subscribeToMore: subscribeToMoreThread,
  } = useQuery<AdminThreadPaginatedQuery, AdminThreadPaginatedQueryVariables>(
    adminThreadPaginatedQuery,
    {
      onCompleted: () => {
        scrollDown();
      },
      skip: !adminMatchId,
      variables: {
        direction: 'before',
        fromDate: initDate.getTime(),
        limit: EVENT_PAGE_LIMIT,
        matchId: adminMatchId,
      },
    },
  );
  if (errorThread) {
    fullErrorCover =
      'Error loading this thread: ' + errorUtils.getErrorMessage(errorThread);
  }
  const cleanThread =
    (dataThread &&
      dataThread.threadPaginated &&
      dataThread.threadPaginated.edges) ||
    [];
  useEffect(() => {
    setTempEdges([]);
    setTempTeamIgnores([]);
    setRemoteEventIds([]);
  }, [adminMatchId]);
  useEffect(() => {
    let unsub: () => void;
    if (adminMatchId) {
      unsub = subscribeToMoreThread<
        AdminThreadEventUpdatedSubscription,
        AdminThreadEventUpdatedSubscriptionVariables
      >({
        document: adminThreadEventUpdatedSubscription,
        onError: (err) => {
          addNotification(
            'Error loading thread. Please refresh and try again: ' +
              err.message,
          );
          console.error('onError: subscribeToMoreThread', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { threadEventUpdated },
            },
          },
        ) => {
          console.log('threadEventUpdated', prev, threadEventUpdated);
          if (!threadEventUpdated || !prev.threadPaginated) {
            return prev;
          }
          if (prev.threadPaginated.id !== threadEventUpdated.matchStr) {
            return prev;
          }
          setTempEdges((prevTempEdges) =>
            prevTempEdges.filter(
              (t) =>
                t.id !== threadEventUpdated.threadEvent &&
                [
                  'tempEmailMessageThreadEventId',
                  'tempTextMessageThreadEventId',
                  'tempMeetingThreadEventId',
                ].indexOf(t.id) === -1,
            ),
          );
          setRemoteEventIds((prevRemoteEventIds) =>
            prevRemoteEventIds.concat(threadEventUpdated.id),
          );
          const updatingIndex = (prev.threadPaginated.edges || []).findIndex(
            (e) => e.id === threadEventUpdated.threadEvent,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              threadPaginated: {
                __typename: prev.threadPaginated.__typename,
                edges: prev.threadPaginated.edges
                  .slice(0, updatingIndex)
                  .concat({
                    __typename:
                      prev.threadPaginated.edges[updatingIndex].__typename,
                    cursor: prev.threadPaginated.edges[updatingIndex].cursor,
                    id: prev.threadPaginated.edges[updatingIndex].id,
                    node: {
                      ...prev.threadPaginated.edges[updatingIndex].node,
                      ...threadEventUpdated,
                    } as AdminThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
                  })
                  .concat(prev.threadPaginated.edges.slice(updatingIndex + 1)),
                id: prev.threadPaginated.id,
              },
            };
          }
          // add new
          scrollDown();
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: (prev.threadPaginated.edges || []).concat({
                __typename: 'ThreadEventEdge',
                cursor: threadEventUpdated.createdAt,
                id: threadEventUpdated.threadEvent || '',
                node: threadEventUpdated,
              }),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          threadId: adminMatchId,
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [subscribeToMoreThread, adminMatchId, addNotification]);
  const [
    getUserNotes,
    {
      called: calledUserNotes,
      data: dataUserNotes,
      error: errorUserNotes,
      loading: loadingUserNotes,
      subscribeToMore: subscribeToMoreUserNotes,
    },
  ] = useLazyQuery<UserNoteBrandAdminQuery, UserNoteBrandAdminQueryVariables>(
    userNoteAdminQuery,
    {
      variables: {
        matchId: adminMatchId,
      },
    },
  );
  const allUserNotes = (dataUserNotes && dataUserNotes.userNoteForTarget) || [];
  useEffect(() => {
    let unsub: () => void;
    if (adminMatchId && calledUserNotes && subscribeToMoreUserNotes) {
      unsub = subscribeToMoreUserNotes<
        UserNoteBrandUpdatedAdminSubscription,
        UserNoteBrandUpdatedAdminSubscriptionVariables
      >({
        document: adminUserNoteUpdatedSubscription,
        onError: (err) => {
          addNotification(
            'Error loading notes. Please refresh and try again: ' + err.message,
          );
          console.error('onError: subscribeToMoreUserNotes', err);
        },
        updateQuery: (
          prev,
          {
            subscriptionData: {
              data: { userNoteUpdated },
            },
          },
        ) => {
          console.log('userNoteUpdated', prev, userNoteUpdated);
          if (!userNoteUpdated || !userNoteUpdated.matchStr) {
            return prev;
          }
          if (adminMatchId !== userNoteUpdated.matchStr) {
            return prev;
          }
          const updatingIndex = (prev.userNoteForTarget || []).findIndex(
            (un) => un.id === userNoteUpdated.id,
          );
          if (updatingIndex >= 0) {
            // update existing
            return {
              userNoteForTarget: prev.userNoteForTarget
                .slice(0, updatingIndex)
                .concat({
                  ...prev.userNoteForTarget[updatingIndex],
                  ...userNoteUpdated,
                })
                .concat(prev.userNoteForTarget.slice(updatingIndex + 1)),
            };
          }
          return {
            userNoteForTarget: (prev.userNoteForTarget || []).concat(
              userNoteUpdated,
            ),
          };
        },
        variables: {
          targetId: adminMatchId,
        },
      });
    }
    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [
    subscribeToMoreUserNotes,
    adminMatchId,
    calledUserNotes,
    addNotification,
  ]);
  const [tryEditNote] = useMutation<
    UserNoteBrandEditAdminMutation,
    UserNoteBrandEditAdminMutationVariables
  >(userNoteEditAdminMutation);
  function onSaveNote(body: string, userNoteId?: string) {
    if (noteLoading || !adminMatchId) return;
    setNoteLoading(true);
    tryEditNote({
      variables: {
        body,
        matchId: adminMatchId,
        userNoteId,
      },
    })
      .then(() => {
        setNoteLoading(false);
      })
      .catch((err: ApolloError) => {
        setNoteLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Edit Note Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'onSaveNote',
        });
      });
  }
  useEffect(() => {
    // check for drafts
    const hasEmailDraft = tokenUtils.readLocalStorage(
      `match-email-${adminMatchId}`,
    );
    setEmailDraft(!!hasEmailDraft);
    if (hasEmailDraft) {
      setEmailSubject(
        tokenUtils.readLocalStorage(`match-email-${adminMatchId}-subject`) ||
          '',
      );
    } else {
      setEmailSubject('');
    }
    const hasTextDraft = tokenUtils.readLocalStorage(
      `match-text-${adminMatchId}`,
    );
    setTextDraft(!!hasTextDraft);
    if (hasTextDraft) {
      setTextContent(
        tokenUtils.readLocalStorage(`match-text-${adminMatchId}-content`) || '',
      );
    } else {
      setTextContent('');
    }
  }, [adminMatchId]);
  const [tryEditBrand] = useMutation<
    BrandEditByAdminMutation,
    BrandEditByAdminMutationVariables
  >(brandEditByAdminMutation);
  function onSaveEditingInfo() {
    if (actionLoading) return;
    if (!editBrandName || !editBrandUrl) {
      addNotification(
        'Please submit name and website at least.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryEditBrand({
      variables: {
        accountTier: editBrandAccountTier,
        addressLineOne: editBrandAddressOne,
        addressLineTwo: editBrandAddressTwo,
        brandId,
        description: editBrandDescription,
        industryTag: editBrandIndustryTag,
        invoicingEnabled: editBrandInvoicing === 'true',
        isDelicate: editBrandIsDelicate === 'true',
        isKeyAccount: editBrandIsKeyAccount === 'true',
        isShopifyPlus: editBrandIsShopifyPlus === 'true',
        legalName: editBrandLegalName,
        name: editBrandName,
        platform: editBrandPlatform,
        shopifyAdminURL: editBrandAdminUrl,
        shopifyStorefrontPassword: editBrandStorefrontPwd,
        shouldExcludeFromKpiReports: editBrandExcludeKpis === 'true',
        url: editBrandUrl,
        vatNumber: editBrandVatNumber,
      },
    })
      .then(() => {
        setActionLoading(false);
        setIsEditingInfo(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Edit Brand Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'tryEditBrand',
        });
      });
  }
  function startEditingInfo() {
    if (!brandDetails) {
      return;
    }
    setIsEditingInfo(true);
    setEditBrandName(brandDetails.name || '');
    setEditBrandDescription(brandDetails.description || '');
    setEditBrandUrl(brandDetails.url || '');
    setEditBrandAdminUrl(brandDetails.shopifyAdminURL || '');
    setEditBrandIndustryTag(brandDetails.industryTag || '');
    setEditBrandAccountTier(brandDetails.accountTier || '');
    setEditBrandPlatform(brandDetails.platform || 'shopify');
    setEditBrandStorefrontPwd(brandDetails.shopifyStorefrontPassword || '');
    setEditBrandIsDelicate(brandDetails.isDelicate ? 'true' : 'false');
    setEditBrandIsKeyAccount(brandDetails.isKeyAccount ? 'true' : 'false');
    setEditBrandIsShopifyPlus(brandDetails.isShopifyPlus ? 'true' : 'false');
    setEditBrandInvoicing(brandDetails.invoicingEnabled ? 'true' : 'false');
    setEditBrandExcludeKpis(
      brandDetails.shouldExcludeFromKpiReports ? 'true' : 'false',
    );
    setEditBrandAddressOne(
      (brandDetails.businessInfo && brandDetails.businessInfo.addressLineOne) ||
        '',
    );
    setEditBrandAddressTwo(
      (brandDetails.businessInfo && brandDetails.businessInfo.addressLineTwo) ||
        '',
    );
    setEditBrandVatNumber(
      (brandDetails.businessInfo && brandDetails.businessInfo.vatNumber) || '',
    );
    setEditBrandLegalName(
      (brandDetails.businessInfo && brandDetails.businessInfo.legalName) || '',
    );
  }
  const [tryMarkReadUnread] = useMutation<
    MatchMarkAsReadUnreadAdminMutation,
    MatchMarkAsReadUnreadAdminMutationVariables
  >(matchMarkAsReadUnreadAdminMutation);
  function markAsRead() {
    if (adminLoading || !adminMatchId) return;
    setAdminLoading(true);
    tryMarkReadUnread({
      optimisticResponse: {
        matchMarkAsReadUnread: {
          __typename: 'Match',
          expertUnread: null,
          id: adminMatchId,
        },
      },
      variables: {
        matchId: adminMatchId,
      },
    })
      .then(() => {
        setAdminLoading(false);
      })
      .catch((err: ApolloError) => {
        setAdminLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Mark As Read Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'markAsRead',
        });
      });
  }
  function markAsUnread() {
    if (adminLoading || !adminMatchId) return;
    setAdminLoading(true);
    tryMarkReadUnread({
      optimisticResponse: {
        matchMarkAsReadUnread: {
          __typename: 'Match',
          expertUnread: 'MANUAL_MARKED_UNREAD',
          id: adminMatchId,
        },
      },
      variables: {
        matchId: adminMatchId,
        unreadReason: 'MANUAL_MARKED_UNREAD',
      },
    })
      .then(() => {
        setAdminLoading(false);
      })
      .catch((err: ApolloError) => {
        setAdminLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Mark As Unread Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'markAsUnread',
        });
      });
  }
  const [tryAssignAdmin] = useMutation<
    MatchAssignAdminMutation,
    MatchAssignAdminMutationVariables
  >(matchAssignAdminMutation);
  function onAssignAdmin(theAssignment: string) {
    if (adminLoading || !adminMatchId) return;
    setAdminLoading(true);
    tryAssignAdmin({
      variables: {
        adminAssignment: theAssignment,
        matchId: adminMatchId,
      },
    })
      .then(() => {
        setAdminLoading(false);
      })
      .catch((err: ApolloError) => {
        setAdminLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Assign Admin Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'tryAssignAdmin',
        });
      });
  }
  const [trySnoozeAdmin] = useMutation<
    MatchSnoozeExpertAdminMutation,
    MatchSnoozeExpertAdminMutationVariables
  >(matchSnoozeExpertAdminMutation);
  function onSnoozeAdmin(snoozeDayValue?: string) {
    if (adminLoading || !adminMatchId) return;
    let untilDate: Date;
    if (snoozeDayValue) {
      const days = parseInt(snoozeDayValue, 10);
      untilDate = moment().add(days, 'days').toDate();
    } else {
      untilDate = moment().subtract(1, 'days').toDate();
    }
    setAdminLoading(true);
    trySnoozeAdmin({
      variables: {
        matchId: adminMatchId,
        untilDate: untilDate.getTime(),
      },
    })
      .then(() => {
        setAdminLoading(false);
      })
      .catch((err: ApolloError) => {
        setAdminLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Snooze Admin Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'trySnoozeAdmin',
        });
      });
  }
  const [trySendEmailMessage] = useMutation<
    AdminThreadSendEmailMessageMutation,
    AdminThreadSendEmailMessageMutationVariables
  >(adminThreadSendEmailMessageMutation);
  function onSendEmail(drafHtml: string) {
    if (sendLoading || !adminMatchId) return;
    if (!emailSubject.trim() || !emailRecipients.length || !drafHtml) {
      addNotification(
        'You need a subject, recipients, and an email body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    console.log('sendEmailMessage', emailSubject, drafHtml);
    trySendEmailMessage({
      optimisticResponse: {
        threadSendEmailMessageByAdmin: {
          __typename: 'EmailMessageEvent',
          content: drafHtml,
          contentExpanded: null,
          createdAt: Date.now(),
          files: emailFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempEmailMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: adminMatchId,
            orphanStr: null,
            supportChannelStr: null,
          })),
          id: 'tempEmailMessageEventId',
          isTransactional: false,
          matchStr: adminMatchId,
          noExpertRecipientsFound: [],
          orphanStr: null,
          ownerExpert: {
            __typename: 'Expert',
            firstName: 'Storetasker',
            id: SUPPORT_EXPERT_ID,
            lastName: 'Support',
            photo: '',
          },
          ownerHuman: null,
          quote: null,
          recipientCopiedExperts: [],
          recipientExpert: null,
          recipientHumans: emailRecipients,
          sendingError: null,
          sendingStatus: 'SENT',
          subject: emailSubject,
          supportChannelStr: null,
          threadEvent: 'tempEmailMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentEmailMutation }) => {
        if (
          dataSentEmailMutation &&
          dataSentEmailMutation.threadSendEmailMessageByAdmin
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempEmailMessageThreadEventId' &&
              e.id !==
                dataSentEmailMutation.threadSendEmailMessageByAdmin.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataSentEmailMutation.threadSendEmailMessageByAdmin.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor:
                dataSentEmailMutation.threadSendEmailMessageByAdmin.createdAt,
              id: dataSentEmailMutation.threadSendEmailMessageByAdmin
                .threadEvent,
              node: dataSentEmailMutation.threadSendEmailMessageByAdmin,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          files: emailFiles,
          html: drafHtml,
          matchId: adminMatchId,
          recipientHumans: emailRecipients.map((r) => r.id),
          subject: emailSubject,
        },
      },
    })
      .then(() => {
        onDeleteEmailDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempEmailMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Send Email Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'sendEmailMessage',
        });
      });
  }
  const [trySendTextMessage] = useMutation<
    AdminThreadSendTextMessageMutation,
    AdminThreadSendTextMessageMutationVariables
  >(adminThreadSendTextMessageMutation);
  function onSendText() {
    if (sendLoading || !adminMatchId) return;
    if (!textRecipients.length || !textContent) {
      addNotification(
        'You need recipients and a message body.',
        undefined,
        5000,
      );
      return;
    }
    setSendLoading(true);
    trySendTextMessage({
      optimisticResponse: {
        threadSendTextMessageByAdmin: {
          __typename: 'TextMessageEvent',
          content: textContent,
          createdAt: Date.now(),
          files: textFiles.map((f, i) => ({
            ...f,
            __typename: 'FileUpload',
            createdAt: Date.now(),
            id: 'tempTextMessageFileId' + i.toString(),
            isHiddenByExpert: false,
            isInlineEmail: false,
            matchStr: adminMatchId,
            orphanStr: null,
            supportChannelStr: null,
          })),
          id: 'tempTextMessageEventId',
          isTransactional: false,
          matchStr: adminMatchId,
          orphanStr: null,
          ownerExpert: {
            __typename: 'Expert',
            firstName: 'Storetasker',
            id: SUPPORT_EXPERT_ID,
            lastName: 'Support',
            photo: '',
          },
          ownerHuman: null,
          quote: null,
          recipientExpert: null,
          recipientHumans: textRecipients,
          sendingError: null,
          supportChannelStr: null,
          threadEvent: 'tempTextMessageThreadEventId',
        },
      },
      update: (_cache, { data: dataSentTextMutation }) => {
        if (
          dataSentTextMutation &&
          dataSentTextMutation.threadSendTextMessageByAdmin
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempTextMessageThreadEventId' &&
              e.id !==
                dataSentTextMutation.threadSendTextMessageByAdmin.threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataSentTextMutation.threadSendTextMessageByAdmin.threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor:
                dataSentTextMutation.threadSendTextMessageByAdmin.createdAt,
              id: dataSentTextMutation.threadSendTextMessageByAdmin.threadEvent,
              node: dataSentTextMutation.threadSendTextMessageByAdmin,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        input: {
          content: textContent,
          files: textFiles,
          matchId: adminMatchId,
          recipientHuman: textRecipients[0].id,
        },
      },
    })
      .then(() => {
        onDeleteTextDraft();
        setSendLoading(false);
      })
      .catch((err: ApolloError) => {
        setTempEdges(
          tempEdges.filter((e) => e.id !== 'tempTextMessageThreadEventId'),
        );
        setSendLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Send Text Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'sendTextMessage',
        });
      });
  }
  function groupThreadEvents(
    events: AdminThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    const groupedEvents: IGroupedEvents[] = [];
    events.forEach((ev) => {
      let isSameEventType = false;
      let isSameSenderSide = false;
      let isSameHuman = false;
      let isCurrGroupable = false;
      let isLastGroupable = false;
      const isFirstGroup = !groupedEvents.length;
      if (
        !isFirstGroup &&
        (ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'MessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent')
      ) {
        isCurrGroupable = true;
        isLastGroupable = true;
        const lastGroup = groupedEvents[groupedEvents.length - 1];
        const lastGroupEvent = lastGroup.group[0];
        // is same event type as last
        if (lastGroupEvent.node.__typename === ev.node.__typename) {
          isSameEventType = true;
          // is both sent from expert or both from Human
          isSameSenderSide =
            !!(ev.node.ownerHuman && lastGroupEvent.node.ownerHuman) ||
            !!(ev.node.ownerExpert && lastGroupEvent.node.ownerExpert);
          // is human interacting same
          isSameHuman =
            (ev.node.ownerHuman &&
              lastGroupEvent.node.ownerHuman &&
              ev.node.ownerHuman.id === lastGroupEvent.node.ownerHuman.id) ||
            (ev.node.recipientHumans[0] &&
              lastGroupEvent.node.recipientHumans[0] &&
              ev.node.recipientHumans[0].id ===
                lastGroupEvent.node.recipientHumans[0].id);
          // don't group texts with quotes attached
          if (ev.node.__typename === 'TextMessageEvent') {
            if (ev.node.quote) {
              isCurrGroupable = false;
            }
          }
          if (lastGroupEvent.node.__typename === 'TextMessageEvent') {
            if (lastGroupEvent.node.quote) {
              isLastGroupable = false;
            }
          }
        }
      }
      if (
        isFirstGroup ||
        !isCurrGroupable ||
        !isLastGroupable ||
        !isSameEventType ||
        !isSameSenderSide ||
        !isSameHuman
      ) {
        groupedEvents.push({
          group: [ev],
        });
      } else {
        groupedEvents[groupedEvents.length - 1].group.push(ev);
      }
    });
    return groupedEvents;
  }
  const totalEdges = cleanThread.concat(tempEdges);
  const overPageLimit = totalEdges.length >= EVENT_PAGE_LIMIT;
  const threadEventEdges = _.uniqBy(totalEdges, 'id')
    .sort((a, b) => a.cursor - b.cursor)
    .filter((ev) => {
      if (ev.node.__typename === 'Quote') {
        // filter out quote accepts on subscriptions
        return (
          ev.node.paymentType === 'PROJECT' ||
          ev.node.paymentType === 'BILL' ||
          ev.node.status !== QuoteStatus.Accepted
        );
      }
      return true;
    });
  const unknownBrandMembers: { [key: string]: string } = {};
  if (brandDetails && brandDetails.ignoreHumansStr && brandTeam.length) {
    threadEventEdges.forEach((ev) => {
      if (
        ev.node.ownerHuman &&
        (ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent' ||
          ev.node.__typename === 'MeetingEvent' ||
          ev.node.__typename === 'EmailMessageEvent')
      ) {
        const unknownHumanIds = [];
        if (ev.node.ownerHuman) {
          unknownHumanIds.push(ev.node.ownerHuman.id.toString());
        }
        if (ev.node.recipientHumans) {
          ev.node.recipientHumans.forEach((h) =>
            unknownHumanIds.push(h.id.toString()),
          );
        }
        unknownHumanIds
          .filter(
            (hId) =>
              hId &&
              brandDetails.ignoreHumansStr.indexOf(hId) === -1 &&
              tempTeamIgnores.indexOf(hId) === -1 &&
              !brandTeam.find((t) => t.id === hId),
          )
          .forEach((hId) => {
            unknownBrandMembers[hId] = ev.id;
          });
      }
    });
  }
  const threadEventsForUnknowns: { [key: string]: string[] } = {};
  Object.keys(unknownBrandMembers).forEach((hId) => {
    const threadEventKey = unknownBrandMembers[hId];
    threadEventsForUnknowns[threadEventKey] = threadEventsForUnknowns[
      threadEventKey
    ]
      ? threadEventsForUnknowns[threadEventKey].concat(hId)
      : [hId];
  });
  function scrollDown() {
    scrollDownQuickTimeout = setTimeout(() => {
      if (threadEventsWindow && threadEventsWindow.current) {
        threadEventsWindow.current.scrollTop =
          threadEventsWindow.current.scrollHeight;
      }
    }, 5);
  }
  useEffect(() => {
    function onScroll() {
      if (threadEventsWindow && threadEventsWindow.current) {
        const scrollTemp = threadEventsWindow.current.scrollTop;
        if (
          scrollTemp < 500 &&
          overPageLimit &&
          canLoadMore &&
          !isLoadingEvents
        ) {
          isLoadingEvents = true;
          const heightTemp = threadEventsWindow.current.scrollHeight;
          fetchMoreThread({
            updateQuery: (prev, { fetchMoreResult }) => {
              if (
                !fetchMoreResult ||
                !prev.threadPaginated ||
                !fetchMoreResult.threadPaginated
              )
                return prev;
              if (
                fetchMoreResult.threadPaginated.edges.length < EVENT_PAGE_LIMIT
              ) {
                setCanLoadMore(false);
              }
              return {
                threadPaginated: {
                  __typename: prev.threadPaginated.__typename,
                  edges: _.uniqBy(
                    (fetchMoreResult.threadPaginated.edges || []).concat(
                      prev.threadPaginated.edges,
                    ),
                    'id',
                  ),
                  id: prev.threadPaginated.id,
                },
              };
            },
            variables: {
              direction: 'before',
              fromDate: threadEventEdges.filter(
                (ev) => ev.node.id && remoteEventIds.indexOf(ev.node.id) === -1,
              )[0].cursor,
              limit: EVENT_PAGE_LIMIT,
              matchId: adminMatchId,
            },
          })
            .catch((err: ApolloError) => {
              addNotification(
                errorUtils.getErrorMessage(err) || 'Load More Error',
              );
              logError(err, {
                component: 'AdminBrandDetail',
                func: 'fetchMoreThread',
              });
            })
            .finally(() => {
              isLoadingEvents = false;
              if (threadEventsWindow && threadEventsWindow.current) {
                threadEventsWindow.current.scrollTop =
                  Math.max(
                    threadEventsWindow.current.scrollHeight - heightTemp,
                    0,
                  ) + scrollTemp;
              }
            });
        }
      }
    }
    if (threadEventsWindow && threadEventsWindow.current) {
      threadEventsWindow.current.addEventListener('scroll', onScroll);
    }
    const tempWindow = threadEventsWindow.current;
    return () => {
      if (tempWindow) {
        tempWindow.removeEventListener('scroll', onScroll);
      }
    };
  }, [
    canLoadMore,
    overPageLimit,
    addNotification,
    fetchMoreThread,
    adminMatchId,
    threadEventEdges,
    remoteEventIds,
  ]);
  useEffect(() => {
    scrollDown();
    const scrollDownEffectTimeout = setTimeout(() => {
      scrollDown();
    }, 500);
    return () => {
      if (scrollDownEffectTimeout) {
        clearTimeout(scrollDownEffectTimeout);
      }
      if (scrollDownQuickTimeout) {
        clearTimeout(scrollDownQuickTimeout);
      }
    };
  }, [adminMatchId]);
  const threadEventGroups = groupThreadEvents(threadEventEdges);
  function generateComponentsForGroup(
    groupedEvents: AdminThreadPaginatedQuery['threadPaginated']['edges'],
  ) {
    return groupedEvents
      .map((ev, i) => {
        if (
          ev.node.__typename === 'RequestEvent' ||
          ev.node.__typename === 'Quote'
        ) {
          return <div key={ev.id} />;
        }
        if (ev.node.__typename === 'MessageEvent') {
          return (
            <MessageThreadEvent
              key={ev.id}
              expertId={SUPPORT_EXPERT_ID}
              isGroupFirst={!i}
              isGroupLast={i === groupedEvents.length - 1}
              messageEvent={ev.node}
              threadEventId={ev.id}
              threadEventTimestamp={ev.cursor}
              onReplyClick={() => onReplyClick(ev)}
            />
          );
        }
        const unknownHumans = threadEventsForUnknowns[ev.id] || [];
        const unknowns = unknownHumans || [];
        let unknownHuman:
          | Extract<
              AdminThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
              { __typename?: 'EmailMessageEvent' | undefined }
            >['ownerHuman']
          | undefined;
        if (
          (ev.node.__typename === 'EmailMessageEvent' ||
            ev.node.__typename === 'TextMessageEvent' ||
            ev.node.__typename === 'MeetingEvent' ||
            ev.node.__typename === 'PhoneCallEvent') &&
          unknowns.length
        ) {
          const unknownHumanId = unknowns[0];
          const humansToCheck = (ev.node.recipientHumans || []).concat();
          if (ev.node.ownerHuman) {
            humansToCheck.push(ev.node.ownerHuman);
          }
          unknownHuman = humansToCheck.find(
            (h) => h && h.id === unknownHumanId,
          );
        }
        if (ev.node.__typename === 'EmailMessageEvent') {
          return (
            <Fragment key={ev.id}>
              <EmailMessageThreadEvent
                key={ev.id}
                expertId={SUPPORT_EXPERT_ID}
                emailMessageEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
                onReplyClick={() => onReplyClick(ev)}
                onUnthreadEmail={onUnthreadEmail}
                isUnthreadLoading={unthreadLoading}
                hasUnknownHuman={!!unknownHuman}
                isAdmin
              />
              {unknownHuman && (
                <UnknownHuman
                  className="UnknownHumanEmail"
                  unknownType="email"
                  unknownHuman={unknownHuman}
                  brandTeam={brandTeam}
                  unknownCount={unknowns.length}
                  onMergeHuman={onMergeHuman}
                  onIgnoreHuman={onIgnoreHuman}
                  onAddHuman={onAddHuman}
                />
              )}
            </Fragment>
          );
        }
        if (ev.node.__typename === 'TextMessageEvent') {
          return (
            <Fragment key={ev.id}>
              <TextMessageThreadEvent
                key={ev.id}
                expertId={SUPPORT_EXPERT_ID}
                isGroupFirst={!i}
                isGroupLast={i === groupedEvents.length - 1}
                textMessageEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
                onReplyClick={() => onReplyClick(ev)}
                onUnthreadText={onUnthreadText}
                isUnthreadLoading={unthreadLoading}
                hasUnknownHuman={!!unknownHuman}
              />
              {unknownHuman && (
                <UnknownHuman
                  className="UnknownHumanText"
                  unknownType="text"
                  unknownHuman={unknownHuman}
                  brandTeam={brandTeam}
                  unknownCount={unknowns.length}
                  onMergeHuman={onMergeHuman}
                  onIgnoreHuman={onIgnoreHuman}
                  onAddHuman={onAddHuman}
                />
              )}
            </Fragment>
          );
        }
        if (ev.node.__typename === 'PhoneCallEvent') {
          return (
            <Fragment key={ev.id}>
              <PhoneCallThreadEvent
                key={ev.id}
                isGroupFirst={!i}
                isGroupLast={i === groupedEvents.length - 1}
                phoneCallEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
                onReplyClick={() => onReplyClick(ev)}
                onUnthreadPhoneCall={onUnthreadPhoneCall}
                isUnthreadLoading={unthreadLoading}
                hasUnknownHuman={!!unknownHuman}
              />
              {unknownHuman && (
                <UnknownHuman
                  className="UnknownHumanPhone"
                  unknownType="text"
                  unknownHuman={unknownHuman}
                  brandTeam={brandTeam}
                  unknownCount={unknowns.length}
                  onMergeHuman={onMergeHuman}
                  onIgnoreHuman={onIgnoreHuman}
                  onAddHuman={onAddHuman}
                />
              )}
            </Fragment>
          );
        }
        if (ev.node.__typename === 'MeetingEvent') {
          return (
            <Fragment key={ev.id}>
              <MeetingThreadEvent
                key={ev.id}
                meetingEvent={ev.node}
                threadEventId={ev.id}
                threadEventTimestamp={ev.cursor}
                onReplyClick={() => onReplyClick(ev)}
                onUnthreadMeeting={onUnthreadMeeting}
                isUnthreadLoading={unthreadLoading}
                onInviteResponse={tryInviteResponse}
                hasUnknownHuman={!!unknownHuman}
              />
              {unknownHuman && (
                <UnknownHuman
                  className="UnknownHumanMeeting"
                  unknownType="email"
                  unknownHuman={unknownHuman}
                  brandTeam={brandTeam}
                  unknownCount={unknowns.length}
                  onMergeHuman={onMergeHuman}
                  onIgnoreHuman={onIgnoreHuman}
                  onAddHuman={onAddHuman}
                />
              )}
            </Fragment>
          );
        }
        console.log('Missing ThreadEvent type');
        return null;
      })
      .filter((c) => c);
  }
  const [tryMeetingResponse] = useMutation<
    MeetingInviteResponseAdminMutation,
    MeetingInviteResponseAdminMutationVariables
  >(meetingInviteResponseAdminMutation);
  function tryInviteResponse(
    meeting: Extract<
      AdminThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
      { __typename?: 'MeetingEvent' | undefined }
    >['meeting'],
    response: string,
  ) {
    tryMeetingResponse({
      optimisticResponse: {
        meetingInviteResponseByAdmin: {
          __typename: 'MeetingEvent',
          action: null,
          createdAt: Date.now(),
          id: 'tempMeetingEventId',
          matchStr: adminMatchId,
          meeting: {
            ...meeting,
            expertResponse: response,
          },
          orphanStr: null,
          ownerExpert: {
            __typename: 'Expert',
            firstName: 'Storetasker',
            id: SUPPORT_EXPERT_ID,
            lastName: 'Support',
            photo: '',
          },
          ownerHuman: null,
          recipientExpert: null,
          recipientHumans: [
            {
              __typename: 'Human',
              firstName: '',
              id:
                (meeting.organizerHuman && meeting.organizerHuman.id) ||
                'tempMeetingEventHumanId',
              lastName: '',
              primaryEmail: '',
              primaryPhone: '',
            },
          ],
          response,
          supportChannelStr: null,
          threadEvent: 'tempMeetingThreadEventId',
        },
      },
      update: (_cache, { data: dataMeetingResponseMutation }) => {
        if (
          dataMeetingResponseMutation &&
          dataMeetingResponseMutation.meetingInviteResponseByAdmin
        ) {
          const nextTempEdges = tempEdges.filter(
            (e) =>
              e.id !== 'tempMeetingThreadEventId' &&
              e.id !==
                dataMeetingResponseMutation.meetingInviteResponseByAdmin
                  .threadEvent,
          );
          if (
            !cleanThread.find(
              (e) =>
                e.id ===
                dataMeetingResponseMutation.meetingInviteResponseByAdmin
                  .threadEvent,
            )
          ) {
            nextTempEdges.push({
              __typename: 'ThreadEventEdge',
              cursor:
                dataMeetingResponseMutation.meetingInviteResponseByAdmin
                  .createdAt,
              id: dataMeetingResponseMutation.meetingInviteResponseByAdmin
                .threadEvent,
              node: dataMeetingResponseMutation.meetingInviteResponseByAdmin,
            });
          }
          setTempEdges(_.uniqBy(nextTempEdges, 'id'));
        }
      },
      variables: {
        meetingId: meeting.id,
        response,
      },
    }).catch((err: ApolloError) => {
      setTempEdges(
        tempEdges.filter((e) => e.id !== 'tempMeetingThreadEventId'),
      );
      addNotification(
        errorUtils.getErrorMessage(err) || 'Meeting Response Error',
      );
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'tryMeetingResponse',
      });
    });
  }
  const [tryAddHuman] = useMutation<
    BrandAddHumanAdminMutation,
    BrandAddHumanAdminMutationVariables
  >(brandAddHumanAdminMutation);
  function onAddHuman(
    human: Exclude<
      Extract<
        AdminThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
        { __typename?: 'EmailMessageEvent' | undefined }
      >['ownerHuman'],
      null | undefined
    >,
  ) {
    if (!brandDetails) {
      return;
    }
    tryAddHuman({
      optimisticResponse: {
        brandAddHuman: {
          ...brandDetails,
          team: brandTeam.concat([
            {
              __typename: 'Human',
              clientFee: '',
              discountCode: '',
              estimateSpend: 0,
              expertReferralStr: null,
              firstName: human.firstName || '',
              id: human.id,
              lastName: human.lastName || '',
              location: null,
              partnerReferral: null,
              primaryEmail: human.primaryEmail || '',
              primaryPhone: human.primaryPhone || '',
              proStart: null,
              secondaryEmails: human.primaryEmail ? [human.primaryEmail] : [],
            },
          ]),
        },
      },
      variables: {
        brandId: brandDetails.id,
        humanId: human.id,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Brand Add Error');
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'tryAddHuman',
      });
    });
  }
  const [tryIgnoreHuman] = useMutation<
    BrandIgnoreHumanAdminMutation,
    BrandIgnoreHumanAdminMutationVariables
  >(brandIgnoreHumanAdminMutation);
  function onIgnoreHuman(humanId: string) {
    if (!brandDetails) {
      return;
    }
    tryIgnoreHuman({
      optimisticResponse: {
        brandIgnoreHuman: {
          ...brandDetails,
          ignoreHumansStr: (brandDetails.ignoreHumansStr || []).concat(humanId),
        },
      },
      variables: {
        brandId: brandDetails.id,
        humanId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Brand Ignore Error');
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'tryIgnoreHuman',
      });
    });
  }
  const [tryMergeHuman] = useMutation<
    BrandMergeHumanAdminMutation,
    BrandMergeHumanAdminMutationVariables
  >(brandMergeHumanAdminMutation);
  function onMergeHuman(parentHumanId: string, childHumanId: string) {
    if (!brandDetails) {
      return;
    }
    tryMergeHuman({
      variables: {
        brandId: brandDetails.id,
        childHumanId,
        parentHumanId,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Brand Merge Error');
      setTempTeamIgnores(tempTeamIgnores.filter((ig) => ig !== childHumanId));
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'tryMergeHuman',
      });
    });
    setTempTeamIgnores(tempTeamIgnores.concat(childHumanId));
  }
  const [tryUnthreadEmail] = useMutation<
    OrphanUnthreadEmailFromAdminMutation,
    OrphanUnthreadEmailFromAdminMutationVariables
  >(orphanUnthreadEmailFromAdminMutation);
  function onUnthreadEmail(emailMessageEventId: string) {
    if (unthreadLoading || !dataThread) {
      return;
    }
    setUnthreadLoading(true);
    tryUnthreadEmail({
      update: (cache) => {
        cache.writeFragment({
          data: {
            ...dataThread.threadPaginated,
            edges: dataThread.threadPaginated.edges.filter(
              (e) => e.node.id !== emailMessageEventId,
            ),
          },
          fragment: gql`
            fragment UnthreadEmail on Thread {
              id
              edges {
                id
                cursor
                node {
                  ... on TextMessageEvent {
                    ...TextMessageEventDetail
                  }
                  ... on EmailMessageEvent {
                    ...EmailMessageEventDetail
                  }
                  ... on RequestEvent {
                    ...RequestEventDetail
                  }
                  ... on MessageEvent {
                    ...MessageEventDetail
                  }
                  ... on MeetingEvent {
                    ...MeetingEventDetail
                  }
                  ... on Quote {
                    ...QuoteDetail
                  }
                  ... on PhoneCallEvent {
                    ...PhoneCallEventDetail
                  }
                }
              }
            }
            ${TextMessageEventDetail}
            ${EmailMessageEventDetail}
            ${RequestEventDetail}
            ${MessageEventDetail}
            ${MeetingEventDetail}
            ${QuoteDetail}
            ${PhoneCallEventDetail}
          `,
          fragmentName: 'UnthreadEmail',
          id: '',
        });
      },
      variables: {
        emailMessageEventId,
        matchId: adminMatchId,
      },
    })
      .then(({ data: mutationData }) => {
        setUnthreadLoading(false);
        if (mutationData && mutationData.orphanUnthreadEmailFromMatch) {
          history.push(
            '/inbound/' + mutationData.orphanUnthreadEmailFromMatch.id,
          );
        }
      })
      .catch((err: ApolloError) => {
        setUnthreadLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Unthread Email Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'tryUnthreadEmail',
        });
      });
  }
  const [tryUnthreadText] = useMutation<
    OrphanUnthreadTextFromAdminMutation,
    OrphanUnthreadTextFromAdminMutationVariables
  >(orphanUnthreadTextFromAdminMutation);
  function onUnthreadText(textMessageEventId: string) {
    if (unthreadLoading || !dataThread) {
      return;
    }
    setUnthreadLoading(true);
    tryUnthreadText({
      update: (cache) => {
        cache.writeFragment({
          data: {
            ...dataThread.threadPaginated,
            edges: dataThread.threadPaginated.edges.filter(
              (e) => e.node.id !== textMessageEventId,
            ),
          },
          fragment: gql`
            fragment UnthreadText on Thread {
              id
              edges {
                id
                cursor
                node {
                  ... on TextMessageEvent {
                    ...TextMessageEventDetail
                  }
                  ... on EmailMessageEvent {
                    ...EmailMessageEventDetail
                  }
                  ... on RequestEvent {
                    ...RequestEventDetail
                  }
                  ... on MessageEvent {
                    ...MessageEventDetail
                  }
                  ... on MeetingEvent {
                    ...MeetingEventDetail
                  }
                  ... on Quote {
                    ...QuoteDetail
                  }
                  ... on PhoneCallEvent {
                    ...PhoneCallEventDetail
                  }
                }
              }
            }
            ${TextMessageEventDetail}
            ${EmailMessageEventDetail}
            ${RequestEventDetail}
            ${MessageEventDetail}
            ${MeetingEventDetail}
            ${QuoteDetail}
            ${PhoneCallEventDetail}
          `,
          fragmentName: 'UnthreadText',
          id: '',
        });
      },
      variables: {
        matchId: adminMatchId,
        textMessageEventId,
      },
    })
      .then(({ data: mutationData }) => {
        setUnthreadLoading(false);
        if (mutationData && mutationData.orphanUnthreadTextFromMatch) {
          history.push(
            '/inbound/' + mutationData.orphanUnthreadTextFromMatch.id,
          );
        }
      })
      .catch((err: ApolloError) => {
        setUnthreadLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Unthread Text Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'tryUnthreadText',
        });
      });
  }
  const [tryUnthreadPhoneCall] = useMutation<
    OrphanUnthreadPhoneCallFromAdminMutation,
    OrphanUnthreadPhoneCallFromAdminMutationVariables
  >(orphanUnthreadPhoneCallFromAdminMutation);
  function onUnthreadPhoneCall(phoneCallEventId: string) {
    if (unthreadLoading || !dataThread) {
      return;
    }
    setUnthreadLoading(true);
    tryUnthreadPhoneCall({
      update: (cache) => {
        cache.writeFragment({
          data: {
            ...dataThread.threadPaginated,
            edges: dataThread.threadPaginated.edges.filter(
              (e) => e.node.id !== phoneCallEventId,
            ),
          },
          fragment: gql`
            fragment UnthreadCall on Thread {
              id
              edges {
                id
                cursor
                node {
                  ... on TextMessageEvent {
                    ...TextMessageEventDetail
                  }
                  ... on EmailMessageEvent {
                    ...EmailMessageEventDetail
                  }
                  ... on RequestEvent {
                    ...RequestEventDetail
                  }
                  ... on MessageEvent {
                    ...MessageEventDetail
                  }
                  ... on MeetingEvent {
                    ...MeetingEventDetail
                  }
                  ... on Quote {
                    ...QuoteDetail
                  }
                  ... on PhoneCallEvent {
                    ...PhoneCallEventDetail
                  }
                }
              }
            }
            ${TextMessageEventDetail}
            ${EmailMessageEventDetail}
            ${RequestEventDetail}
            ${MessageEventDetail}
            ${MeetingEventDetail}
            ${QuoteDetail}
            ${PhoneCallEventDetail}
          `,
          fragmentName: 'UnthreadCall',
          id: '',
        });
      },
      variables: {
        matchId: adminMatchId,
        phoneCallEventId,
      },
    })
      .then(({ data: mutationData }) => {
        setUnthreadLoading(false);
        if (mutationData && mutationData.orphanUnthreadPhoneCallFromMatch) {
          history.push(
            '/inbound/' + mutationData.orphanUnthreadPhoneCallFromMatch.id,
          );
        }
      })
      .catch((err: ApolloError) => {
        setUnthreadLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Unthread Phone Call Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'tryUnthreadPhoneCall',
        });
      });
  }
  const [tryUnthreadMeeting] = useMutation<
    OrphanUnthreadMeetingFromAdminMutation,
    OrphanUnthreadMeetingFromAdminMutationVariables
  >(orphanUnthreadMeetingFromAdminMutation);
  function onUnthreadMeeting(meetingEventId: string) {
    if (unthreadLoading || !dataThread) {
      return;
    }
    setUnthreadLoading(true);
    tryUnthreadMeeting({
      update: (cache) => {
        cache.writeFragment({
          data: {
            ...dataThread.threadPaginated,
            edges: dataThread.threadPaginated.edges.filter(
              (e) => e.node.id !== meetingEventId,
            ),
          },
          fragment: gql`
            fragment UnthreadMeeting on Thread {
              id
              edges {
                id
                cursor
                node {
                  ... on TextMessageEvent {
                    ...TextMessageEventDetail
                  }
                  ... on EmailMessageEvent {
                    ...EmailMessageEventDetail
                  }
                  ... on RequestEvent {
                    ...RequestEventDetail
                  }
                  ... on MessageEvent {
                    ...MessageEventDetail
                  }
                  ... on MeetingEvent {
                    ...MeetingEventDetail
                  }
                  ... on Quote {
                    ...QuoteDetail
                  }
                  ... on PhoneCallEvent {
                    ...PhoneCallEventDetail
                  }
                }
              }
            }
            ${TextMessageEventDetail}
            ${EmailMessageEventDetail}
            ${RequestEventDetail}
            ${MessageEventDetail}
            ${MeetingEventDetail}
            ${QuoteDetail}
            ${PhoneCallEventDetail}
          `,
          fragmentName: 'UnthreadMeeting',
          id: '',
        });
      },
      variables: {
        matchId: adminMatchId,
        meetingEventId,
      },
    })
      .then(({ data: mutationData }) => {
        setUnthreadLoading(false);
        if (mutationData && mutationData.orphanUnthreadMeetingFromMatch) {
          history.push(
            '/inbound/' + mutationData.orphanUnthreadMeetingFromMatch.id,
          );
        }
      })
      .catch((err: ApolloError) => {
        setUnthreadLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Unthread Meeting Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'tryUnthreadMeeting',
        });
      });
  }
  function onReplyClick(
    edge: AdminThreadPaginatedQuery['threadPaginated']['edges'][0],
  ) {
    console.log('onReplyClick', edge.id);
    if (edge.node.__typename === 'EmailMessageEvent') {
      const newSubject = replyToSubject(edge.node.subject);
      const recipients = [...edge.node.recipientHumans];
      if (edge.node.ownerHuman) {
        recipients.push(edge.node.ownerHuman);
      }
      openEmailEditor(
        newSubject,
        recipients.map((r) => r.id),
      );
    } else if (edge.node.__typename === 'MeetingEvent') {
      const newSubject = replyToSubject(
        edge.node.meeting && edge.node.meeting.eventTitle,
      );
      const recipients = [...edge.node.recipientHumans];
      if (edge.node.ownerHuman) {
        recipients.push(edge.node.ownerHuman);
      }
      openEmailEditor(
        newSubject,
        recipients.map((r) => r.id),
      );
    } else if (edge.node.__typename === 'TextMessageEvent') {
      const recipients = [...edge.node.recipientHumans];
      if (edge.node.ownerHuman) {
        recipients.push(edge.node.ownerHuman);
      }
      openTextEditor(recipients.map((r) => r.id));
    } else if (edge.node.__typename === 'PhoneCallEvent') {
      const recipients = [...edge.node.recipientHumans];
      if (edge.node.ownerHuman) {
        recipients.push(edge.node.ownerHuman);
      }
      openTextEditor(recipients.map((r) => r.id));
    } else if (edge.node.__typename === 'MessageEvent') {
      const recipient =
        (edge.node.ownerHuman && edge.node.ownerHuman.id) ||
        (emailBrandTeam[0] && emailBrandTeam[0].id);
      openEmailEditor(undefined, recipient ? [recipient] : undefined);
    } else {
      scrollDown();
    }
  }
  function closeCurrentAction() {
    setCurrentAction('');
    scrollDown();
  }
  function openEmailEditor(
    subject?: string,
    recipients?: string[],
    scenario?: string,
    content?: string,
    isSwitchFromText?: boolean,
  ) {
    // check currentAction
    if (isSwitchFromText) {
      onDeleteTextDraft();
    } else if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    // check draft
    if (emailDraft) {
      subject =
        tokenUtils.readLocalStorage(`match-email-${adminMatchId}-subject`) ||
        '';
      recipients = (
        tokenUtils.readLocalStorage(`match-email-${adminMatchId}-recipients`) ||
        ''
      ).split(',');
      content =
        tokenUtils.readLocalStorage(`match-email-${adminMatchId}-content`) ||
        '';
    }
    if (
      subject === undefined &&
      recipients === undefined &&
      content === undefined
    ) {
      const lastEmail = _.findLast(
        threadEventEdges,
        (ev) => ev.node.__typename === 'EmailMessageEvent',
      );
      // look for the latest email
      if (lastEmail) {
        // simulate as if they clicked that email
        onReplyClick(lastEmail);
        return;
      }
      // or assign primaryContact
      if (brandDetails && brandDetails.teamLeader) {
        recipients = [brandDetails.teamLeader.id];
      }
    }
    const plannedRecipients = (recipients || [])
      .map((rId) => emailBrandTeam.find((h) => rId && h && h.id === rId))
      .filter((h) => !!h) as BrandDetailsAdminQuery['brandDetails']['team'];
    if (!content) {
      const plannedRecipientNames = plannedRecipients.map((r) => ({
        firstName: r.firstName,
        lastName: r.lastName,
      }));
      if (scenario) {
        // FUTURE customer support templates
      }
      content = templateUtils.getDefaultEmail(plannedRecipientNames, {
        firstName: 'Storetasker Support',
        lastName: '',
      });
    }
    setCurrentAction('EMAIL');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    setEmailSubject(subject || '');
    setEmailContent(content || '');
    setEmailRecipients(plannedRecipients);
    setEmailFiles([]);
    setEmailStatus(emailDraft ? 'Saved' : '');
  }
  const debouncedEmailDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(`match-email-${adminMatchId}`, 'true');
    tokenUtils.writeLocalStorage(
      `match-email-${adminMatchId}-subject`,
      emailSubject,
    );
    tokenUtils.writeLocalStorage(
      `match-email-${adminMatchId}-recipients`,
      emailRecipients.map((r) => r.id).join(','),
    );
    tokenUtils.writeLocalStorage(
      `match-email-${adminMatchId}-content`,
      emailContent,
    );
    setEmailDraft(true);
    setEmailStatus('Saved');
  }, 1000);
  function onDeleteEmailDraft() {
    debouncedEmailDraft.cancel();
    tokenUtils.removeLocalStorage(`match-email-${adminMatchId}`);
    tokenUtils.removeLocalStorage(`match-email-${adminMatchId}-subject`);
    tokenUtils.removeLocalStorage(`match-email-${adminMatchId}-recipients`);
    tokenUtils.removeLocalStorage(`match-email-${adminMatchId}-content`);
    setEmailDraft(false);
    setEmailStatus('');
    setEmailSubject('');
    setEmailContent('');
    setEmailRecipients([]);
    setEmailFiles([]);
    setCurrentAction('');
  }
  function onSaveEmailDraft(
    subject: string,
    draftContent: string | null,
    files: IFilestackFileUpload[],
    recipients: BrandDetailsAdminQuery['brandDetails']['team'],
  ) {
    let saveDraft = false;
    if (emailSubject !== subject) {
      saveDraft = true;
      setEmailSubject(subject || '');
    }
    if (emailRecipients !== recipients) {
      setEmailRecipients(recipients || []);
      saveDraft = true;
    }
    if (emailFiles !== files) {
      setEmailFiles(files || []);
    }
    if (draftContent !== null && emailContent !== draftContent) {
      saveDraft = true;
      setEmailContent(draftContent);
    }
    if (saveDraft) {
      setEmailStatus('Saving');
      debouncedEmailDraft.callback();
    }
  }
  function openTextEditor(
    recipients?: string[],
    scenario?: string,
    content?: string,
    isSwitchFromEmail?: boolean,
  ) {
    // check currentAction
    if (isSwitchFromEmail) {
      onDeleteEmailDraft();
    } else if (currentAction) {
      scrollDown();
      setShowSidebar(false);
      return;
    }
    // check draft
    if (textDraft) {
      recipients = (
        tokenUtils.readLocalStorage(`match-text-${adminMatchId}-recipients`) ||
        ''
      ).split(',');
      content =
        tokenUtils.readLocalStorage(`match-text-${adminMatchId}-content`) || '';
    }
    if (recipients === undefined && content === undefined) {
      const lastText = _.findLast(
        threadEventEdges,
        (ev) =>
          ev.node.__typename === 'TextMessageEvent' ||
          ev.node.__typename === 'PhoneCallEvent',
      );
      // look for the latest text/phone
      if (lastText) {
        // simulate as if they clicked it
        onReplyClick(lastText);
        return;
      }
      // or assign primaryContact
      if (brandDetails && brandDetails.teamLeader) {
        recipients = [brandDetails.teamLeader.id];
      }
    }
    const plannedRecipients = (recipients || [])
      .map((rId) => phoneBrandTeam.find((h) => rId && h && h.id === rId))
      .filter((h) => !!h) as BrandDetailsAdminQuery['brandDetails']['team'];
    if (!content) {
      if (scenario) {
        // FUTURE customer support templates
      }
      content = templateUtils.getDefaultText();
    }
    setCurrentAction('TEXT');
    setOpenActions(false);
    scrollDown();
    setShowSidebar(false);
    setTextContent(content || '');
    setTextRecipients(plannedRecipients);
    setTextFiles([]);
    setTextStatus(textDraft ? 'Saved' : '');
  }
  const debouncedTextDraft = useDebouncedCallback(() => {
    tokenUtils.writeLocalStorage(`match-text-${adminMatchId}`, 'true');
    tokenUtils.writeLocalStorage(
      `match-text-${adminMatchId}-recipients`,
      textRecipients.map((r) => r.id).join(','),
    );
    tokenUtils.writeLocalStorage(
      `match-text-${adminMatchId}-content`,
      textContent,
    );
    setTextDraft(true);
    setTextStatus('Saved');
  }, 1000);
  function onDeleteTextDraft() {
    debouncedTextDraft.cancel();
    tokenUtils.removeLocalStorage(`match-text-${adminMatchId}`);
    tokenUtils.removeLocalStorage(`match-text-${adminMatchId}-recipients`);
    tokenUtils.removeLocalStorage(`match-text-${adminMatchId}-content`);
    setTextDraft(false);
    setTextStatus('');
    setTextContent('');
    setTextRecipients([]);
    setTextFiles([]);
    setCurrentAction('');
  }
  function onSaveTextDraft(
    content: string,
    files: IFilestackFileUpload[],
    recipients: BrandDetailsAdminQuery['brandDetails']['team'],
  ) {
    let saveDraft = false;
    if (textRecipients !== recipients) {
      setTextRecipients(recipients || []);
      saveDraft = true;
    }
    if (textFiles !== files) {
      setTextFiles(files || []);
    }
    if (textContent !== content) {
      saveDraft = true;
      setTextContent(content);
    }
    if (saveDraft) {
      setTextStatus('Saving');
      debouncedTextDraft.callback();
    }
  }
  function goEditContacts() {
    setPrimaryPanel('Team');
    setShowSidebar(true);
  }
  function renderCurrentActionEditor() {
    if (currentAction === 'EMAIL') {
      return (
        <div
          className={
            'ThreadActionEmail ' +
            (sendLoading ? ' ThreadActionEmailSending ' : '')
          }
        >
          <EmailEditor
            emailTemplates={emailTemplates}
            expertId={SUPPORT_EXPERT_ID}
            files={emailFiles}
            goEditContacts={goEditContacts}
            initContent={emailContent}
            isAdmin
            onDeleteDraft={onDeleteEmailDraft}
            onSaveDraft={onSaveEmailDraft}
            onSendEmail={onSendEmail}
            recipients={emailRecipients}
            subject={emailSubject}
            status={emailStatus}
            switchToTextEditor={(contentStr?: string) =>
              openTextEditor(
                emailRecipients.map((r) => r.id),
                undefined,
                contentStr,
                true,
              )
            }
            team={emailBrandTeam}
          />
        </div>
      );
    }
    if (currentAction === 'TEXT') {
      return (
        <div
          className={
            'ThreadActionText ' +
            (sendLoading ? ' ThreadActionTextSending ' : '')
          }
        >
          <TextEditor
            expertId={SUPPORT_EXPERT_ID}
            files={textFiles}
            goEditContacts={goEditContacts}
            content={textContent}
            onDeleteDraft={onDeleteTextDraft}
            onSaveDraft={onSaveTextDraft}
            onSendText={onSendText}
            recipients={textRecipients}
            status={textStatus}
            switchToEmailEditor={(contentStr?: string) =>
              openEmailEditor(
                undefined,
                textRecipients.map((r) => r.id),
                undefined,
                contentStr,
                true,
              )
            }
            team={phoneBrandTeam}
          />
        </div>
      );
    }
    return null;
  }
  const [tryThreadSearch] = useMutation<
    MatchThreadSearchBrandMutation,
    MatchThreadSearchBrandMutationVariables
  >(matchThreadSearchBrandMutation);
  function onSearchStart() {
    console.log('onSearchStart', searchInput);
    if (!adminMatchId) return;
    if (!searchInput) {
      toggleSearch();
      return;
    }
    if (searchInput.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    setSearchLoading(true);
    setSearchResults([]);
    tryThreadSearch({
      update: (_cache, { data: dataThreadSearch }) => {
        setSearchLoading(false);
        if (dataThreadSearch && dataThreadSearch.threadSearch) {
          console.log('tryThreadSearch', dataThreadSearch.threadSearch);
          setSearchResults(
            dataThreadSearch.threadSearch
              .slice()
              .sort((a, b) => b.score - a.score),
          );
        }
      },
      variables: {
        limit: 5,
        matchId: adminMatchId,
        searchQuery: searchInput,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Thread Search Error');
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'tryThreadSearch',
      });
      setSearchLoading(false);
      setSearchResults(null);
    });
  }
  function onSelectSearchResult(
    s: MatchThreadSearchBrandMutation['threadSearch'][0],
  ) {
    setSelectedSearchResult(s);
    setShowSidebar(false);
  }
  function blockClickPropagation(ev: React.MouseEvent) {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
      ev.nativeEvent.stopImmediatePropagation();
    }
  }
  function renderSearchResult(
    s: MatchThreadSearchBrandMutation['threadSearch'][0],
  ) {
    const nowMoment = moment.tz(initTime, moment.tz.guess());
    const eventMoment = moment.tz(s.node.createdAt, moment.tz.guess());
    const eventDateFull =
      eventMoment.format('MMMM Do, YYYY') +
      ' at ' +
      eventMoment.format('h:mma z');
    let eventDateSummary = eventMoment.format('M/D/YY');
    if (nowMoment.isSame(eventMoment, 'day')) {
      eventDateSummary = eventMoment.format('h:mma');
    } else if (
      nowMoment.clone().subtract(1, 'day').isSame(eventMoment, 'day')
    ) {
      eventDateSummary = 'Yesterday';
    } else if (nowMoment.isSame(eventMoment, 'week')) {
      eventDateSummary = eventMoment.format('dddd');
    } else if (nowMoment.isSame(eventMoment, 'year')) {
      eventDateSummary = eventMoment.format('MMMM Do');
    }
    const isFromExpert = !s.node.ownerHuman;
    const primaryHuman:
      | Extract<
          AdminThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
          { __typename?: 'EmailMessageEvent' | undefined }
        >['ownerHuman']
      | undefined = s.node.ownerHuman || undefined;
    let humanRecipients: Exclude<
      Extract<
        AdminThreadPaginatedQuery['threadPaginated']['edges'][0]['node'],
        { __typename?: 'EmailMessageEvent' | undefined }
      >['ownerHuman'],
      null | undefined
    >[] = [];
    if (
      s.node.__typename === 'MessageEvent' ||
      s.node.__typename === 'EmailMessageEvent' ||
      s.node.__typename === 'TextMessageEvent' ||
      s.node.__typename === 'MeetingEvent' ||
      s.node.__typename === 'PhoneCallEvent'
    ) {
      humanRecipients = s.node.recipientHumans || [];
    }
    // find highlights in text
    let beforeHighlight = '';
    let inHighlight = '';
    let afterHighlight = '';
    const charsBeforeAfter = 70;
    const highlightContent = s.highlightsContent[0] || '';
    if (highlightContent && s.plainTextContent) {
      const highlightIndex = s.plainTextContent.indexOf(highlightContent);
      if (highlightIndex >= 0) {
        const beforeIndex = Math.max(0, highlightIndex - charsBeforeAfter);
        const afterIndex =
          highlightIndex + highlightContent.length + charsBeforeAfter;
        const textAroundHighlight =
          (beforeIndex > 0 ? '...' : '') +
          s.plainTextContent.slice(beforeIndex, afterIndex) +
          (afterIndex < s.plainTextContent.length - 1 ? '...' : '');
        const newHighlightIndex = textAroundHighlight.indexOf(highlightContent);
        if (newHighlightIndex >= 0) {
          beforeHighlight = textAroundHighlight.slice(0, newHighlightIndex);
          inHighlight = highlightContent;
          afterHighlight = textAroundHighlight.slice(
            newHighlightIndex + highlightContent.length,
          );
        }
      }
    }
    return (
      <div
        className={
          'ThreadDetailSidebarSearchResult ' +
          (selectedSearchResult && selectedSearchResult.id === s.id
            ? ' ThreadDetailSidebarSearchResultSelected '
            : '')
        }
        key={s.id}
      >
        <div
          className="ThreadDetailSidebarSearchResultCard"
          onClick={() => onSelectSearchResult(s)}
        >
          <div className="ThreadDetailSidebarSearchResultCardRecipients">
            {primaryHuman && (
              <HumanUserBubble
                firstName={primaryHuman.firstName}
                lastName={primaryHuman.lastName}
                fallbackEmail={primaryHuman.primaryEmail}
                fallbackPhone={primaryHuman.primaryPhone}
                initialsOnly
                primary
              />
            )}
            {humanRecipients.map((r) => (
              <HumanUserBubble
                key={r.id}
                firstName={r.firstName}
                lastName={r.lastName}
                fallbackEmail={r.primaryEmail}
                fallbackPhone={r.primaryPhone}
                initialsOnly
              />
            ))}
          </div>
          {!!isFromExpert &&
            (s.node.__typename === 'MessageEvent' ||
              s.node.__typename === 'EmailMessageEvent' ||
              s.node.__typename === 'TextMessageEvent' ||
              s.node.__typename === 'MeetingEvent' ||
              s.node.__typename === 'PhoneCallEvent' ||
              s.node.__typename === 'Quote') && (
              <div className="ThreadDetailSidebarSearchResultCardFromExpert">
                <ExpertUserBubble
                  expertId={(s.node.ownerExpert && s.node.ownerExpert.id) || ''}
                  expertDetails={s.node.ownerExpert}
                  primary
                />
              </div>
            )}
          <div className="ThreadDetailSidebarSearchResultCardSubject">
            {s.plainTextSubject || ''}
          </div>
          {!inHighlight && (
            <div className="ThreadDetailSidebarSearchResultCardText">
              {s.plainTextContent.length > 2 * charsBeforeAfter
                ? s.plainTextContent.slice(0, 2 * charsBeforeAfter) + '...'
                : s.plainTextContent}
            </div>
          )}
          {inHighlight && (
            <div className="ThreadDetailSidebarSearchResultCardText">
              {beforeHighlight}
              <span>{inHighlight}</span>
              {afterHighlight}
            </div>
          )}
        </div>
        <div className="ThreadDetailSidebarSearchResultBottom">
          <div className="ThreadDetailSidebarSearchResultBottomLeft">
            {searchResultType(s.node.__typename!)}
          </div>
          <div
            className="ThreadDetailSidebarSearchResultBottomRight"
            data-tip={eventDateFull}
          >
            {eventDateSummary}
          </div>
        </div>
      </div>
    );
  }
  function handleSearchInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStart();
    }
    if (e.key === 'Escape' || e.key === 'Esc') {
      e.preventDefault();
      toggleSearch();
    }
  }
  function toggleSearch() {
    const nextShowSearch = !showSearch;
    setSearchInput('');
    setSearchResults(null);
    setSelectedSearchResult(null);
    setSearchLoading(false);
    setShowSearch(nextShowSearch);
    if (nextShowSearch) {
      setTimeout(() => {
        if (searchRef && searchRef.current) {
          searchRef.current.focus();
        }
      });
    }
  }
  const {
    data: dataRequestPaginated,
    error: errorRequestPaginated,
    loading: loadingRequestPaginated,
    fetchMore: fetchMoreRequestPaginated,
  } = useQuery<RequestPaginateBrandQuery, RequestPaginateBrandQueryVariables>(
    requestPaginateBrandQuery,
    {
      variables: {
        brandId,
        direction: 'BACKWARD',
        fromDate: initDate.getTime(),
        limit: ACTIVITY_PAGE_LIMIT,
      },
    },
  );
  const allRequestsForBrand = _.uniqBy(
    [
      ...((dataRequestPaginated && dataRequestPaginated.requestPaginateBrand) ||
        []),
    ].filter((r) => r.brand && r.brand.id === brandId),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  const oldestCreatedRequest =
    allRequestsForBrand[allRequestsForBrand.length - 1];
  function loadMoreRequests() {
    fetchMoreRequestPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.requestPaginateBrand)
          return prev;
        if (fetchMoreResult.requestPaginateBrand.length < ACTIVITY_PAGE_LIMIT) {
          setShowLoadMoreRequests(false);
        }
        return {
          requestPaginateBrand: (prev.requestPaginateBrand || []).concat(
            fetchMoreResult.requestPaginateBrand,
          ),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate: oldestCreatedRequest.createdAt,
        limit: ACTIVITY_PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'loadMoreRequests',
      });
    });
  }
  const {
    data: dataMatchPaginated,
    error: errorMatchPaginated,
    loading: loadingMatchPaginated,
    fetchMore: fetchMoreMatchPaginated,
  } = useQuery<MatchPaginateBrandQuery, MatchPaginateBrandQueryVariables>(
    matchPaginateBrandQuery,
    {
      variables: {
        brandId,
        direction: 'BACKWARD',
        fromDate: initDate.getTime(),
        limit: ACTIVITY_PAGE_LIMIT,
      },
    },
  );
  const allMatchesForBrand = _.uniqBy(
    [
      ...((dataMatchPaginated && dataMatchPaginated.matchPaginateBrand) || []),
    ].filter(
      (r) =>
        r.brand && r.brand.id === brandId && r.expertStr !== SUPPORT_EXPERT_ID,
    ),
    'id',
  ).sort((a, b) => b.lastUpdated - a.lastUpdated);
  const oldestUpdatedMatch = allMatchesForBrand[allMatchesForBrand.length - 1];
  function loadMoreMatches() {
    fetchMoreMatchPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.matchPaginateBrand)
          return prev;
        if (fetchMoreResult.matchPaginateBrand.length < ACTIVITY_PAGE_LIMIT) {
          setShowLoadMoreMatches(false);
        }
        return {
          matchPaginateBrand: (prev.matchPaginateBrand || []).concat(
            fetchMoreResult.matchPaginateBrand,
          ),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate: oldestUpdatedMatch.lastUpdated,
        limit: ACTIVITY_PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'loadMoreMatches',
      });
    });
  }
  const {
    data: dataProjectPaginated,
    error: errorProjectPaginated,
    loading: loadingProjectPaginated,
    fetchMore: fetchMoreProjectPaginated,
  } = useQuery<ProjectPaginateBrandQuery, ProjectPaginateBrandQueryVariables>(
    projectPaginateBrandQuery,
    {
      variables: {
        brandId,
        direction: 'BACKWARD',
        fromDate: initDate.getTime(),
        limit: ACTIVITY_PAGE_LIMIT,
      },
    },
  );
  const allProjectsForBrand = _.uniqBy(
    [
      ...((dataProjectPaginated && dataProjectPaginated.projectPaginateBrand) ||
        []),
    ].filter((r) => r.brandStr === brandId),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  const oldestCreatedProject =
    allProjectsForBrand[allProjectsForBrand.length - 1];
  function loadMoreProjects() {
    fetchMoreProjectPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.projectPaginateBrand)
          return prev;
        if (fetchMoreResult.projectPaginateBrand.length < ACTIVITY_PAGE_LIMIT) {
          setShowLoadMoreProjects(false);
        }
        return {
          projectPaginateBrand: (prev.projectPaginateBrand || []).concat(
            fetchMoreResult.projectPaginateBrand,
          ),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate: oldestCreatedProject.createdAt,
        limit: ACTIVITY_PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'loadMoreProjects',
      });
    });
  }
  useEffect(() => {
    const loadTime = new Date();
    const reconnectedListener = socketClient.onReconnected(() => {
      console.log('AdminBrandDetail socketClient onReconnected');
      refetchBrand().catch(() => {});
      fetchMoreThread({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            !prev.threadPaginated ||
            !fetchMoreResult.threadPaginated
          )
            return prev;
          return {
            threadPaginated: {
              __typename: prev.threadPaginated.__typename,
              edges: _.uniqBy(
                fetchMoreResult.threadPaginated.edges.concat(
                  prev.threadPaginated.edges || [],
                ),
                'id',
              ),
              id: prev.threadPaginated.id,
            },
          };
        },
        variables: {
          direction: 'after',
          fromDate: loadTime.getTime(),
          limit: 50,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'reconnect fetchMoreThread',
        });
      });
      fetchMoreProjectPaginated({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            projectPaginateBrand: (prev.projectPaginateBrand || []).concat(
              fetchMoreResult.projectPaginateBrand,
            ),
          };
        },
        variables: {
          direction: 'FORWARD',
          fromDate: loadTime.getTime(),
          limit: 5,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'reconnect loadMoreProjects',
        });
      });
      fetchMoreRequestPaginated({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            requestPaginateBrand: (prev.requestPaginateBrand || []).concat(
              fetchMoreResult.requestPaginateBrand,
            ),
          };
        },
        variables: {
          direction: 'FORWARD',
          fromDate: loadTime.getTime(),
          limit: 5,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'reconnect loadMoreRequests',
        });
      });
      fetchMoreMatchPaginated({
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            matchPaginateBrand: (prev.matchPaginateBrand || []).concat(
              fetchMoreResult.matchPaginateBrand,
            ),
          };
        },
        variables: {
          direction: 'FORWARD',
          fromDate: loadTime.getTime(),
          limit: 5,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Reconnect Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'reconnect loadMoreMatches',
        });
      });
    });
    return () => {
      reconnectedListener();
    };
  }, [
    socketClient,
    refetchBrand,
    fetchMoreThread,
    fetchMoreRequestPaginated,
    fetchMoreMatchPaginated,
    fetchMoreProjectPaginated,
    addNotification,
  ]);
  function confirmRemoveSpecific(humanId: string) {
    setRemoveSpecific(humanId);
  }
  function cancelRemoveSpecific() {
    setRemoveSpecific('');
  }
  const [trySwitchTeamLeader] = useMutation<
    BrandSwitchTeamLeaderByAdminMutation,
    BrandSwitchTeamLeaderByAdminMutationVariables
  >(brandSwitchTeamLeaderByAdminMutation);
  function switchTeamLeader(humanId: string) {
    if (!brandDetails || !brandDetails.team) {
      return;
    }
    const newLeader = brandDetails.team.find((h) => h.id === humanId);
    if (!newLeader) {
      return;
    }
    trySwitchTeamLeader({
      optimisticResponse: {
        brandSwitchTeamLeaderByAdmin: {
          ...brandDetails,
          teamLeader: newLeader,
        },
      },
      variables: {
        brandId,
        newLeader: humanId,
      },
    })
      .then(() => {})
      .catch((err: ApolloError) => {
        addNotification(
          errorUtils.getErrorMessage(err) || 'Switch Leader Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'switchTeamLeader',
        });
      });
  }
  const [tryEditTeam] = useMutation<
    BrandEditTeamByAdminMutation,
    BrandEditTeamByAdminMutationVariables
  >(brandEditTeamByAdminMutation);
  function onRemoveSpecific() {
    if (modalLoading || !brandDetails || !brandDetails.team) {
      return;
    }
    setModalLoading(true);
    tryEditTeam({
      variables: {
        brandId,
        team: brandDetails.team
          .filter((h) => h.id !== removeSpecific)
          .map((h) => h.id),
      },
    })
      .then(() => {
        setModalLoading(false);
        setRemoveSpecific('');
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Remove Specifc Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'onRemoveSpecific',
        });
      });
  }
  function confirmAddSpecific(humanId: string) {
    setAddSpecific(humanId);
  }
  function cancelAddSpecific() {
    setAddSpecific('');
  }
  function onAddSpecific() {
    if (modalLoading || !brandDetails || !brandDetails.team) {
      return;
    }
    setModalLoading(true);
    tryEditTeam({
      variables: {
        brandId,
        team: brandDetails.team.map((h) => h.id).concat(addSpecific),
      },
    })
      .then(() => {
        setModalLoading(false);
        setAddSpecific('');
        setSearchInputHumans('');
        setSearchResultsHumans(null);
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Add Specifc Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'onAddSpecific',
        });
      });
  }
  function confirmMatchSpecific(expertId: string) {
    setMatchSpecific(expertId);
  }
  function cancelMatchSpecific() {
    setMatchSpecific('');
  }
  const [tryMatchSpecific] = useMutation<
    MatchManualExpertBrandMutation,
    MatchManualExpertBrandMutationVariables
  >(matchManualExpertBrandMutation);
  function onMatchSpecific() {
    if (modalLoading) {
      return;
    }
    setModalLoading(true);
    tryMatchSpecific({
      variables: {
        brandId,
        expertId: matchSpecific,
      },
    })
      .then(({ data: mutationData }) => {
        setMatchSpecific('');
        setModalLoading(false);
        if (mutationData && mutationData.matchManualExpertBrand) {
          history.push('/match/' + mutationData.matchManualExpertBrand.id);
        }
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Match Specifc Error',
        );
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'tryMatchSpecific',
        });
      });
  }
  function fileError(error: Error) {
    console.log('Filestack fileError', error);
    addNotification(
      (error && error.message) || 'File upload error. Please try again.',
      undefined,
      5000,
    );
  }
  function fileUploaded(filestackRes: any) {
    console.log('Filestack fileUploaded', filestackRes);
    if (filestackRes && filestackRes.filesUploaded) {
      setRequestFiles(
        requestFiles.concat(
          filestackRes.filesUploaded.map((f: any) => ({
            container: f.container || '',
            filename: f.filename || '',
            handle: f.handle || '',
            key: f.key || '',
            mimetype: f.mimetype || '',
            size: (f.size || '').toString(),
            source: f.source || '',
            uploadId: f.uploadId || '',
            url: f.url || '',
          })) as IFilestackFileUpload[],
        ),
      );
    }
  }
  function removeFile(filestackUploadUrl: string) {
    console.log('Filestack removeFile', filestackUploadUrl);
    setRequestFiles(requestFiles.filter((f) => f.url !== filestackUploadUrl));
  }
  const [tryRequestSubmitByAdmin] = useMutation<
    RequestSubmitByAdminMutation,
    RequestSubmitByAdminMutationVariables
  >(requestSubmitByAdminMutation);
  function onCreateNewRequest() {
    if (modalLoading) {
      return;
    }
    let draftContent = '';
    let plainTextContent = '';
    if (editorLatest && editorLatest.hasText()) {
      plainTextContent = editorLatest.getPlainText().trim();
      if (plainTextContent.replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(editorLatest));
        } catch (rawError) {
          console.log('email convertToRaw error', rawError);
        }
      }
    }
    if (!draftContent) {
      addNotification('Please submit a description.', undefined, 5000);
      return;
    }
    setModalLoading(true);
    tryRequestSubmitByAdmin({
      variables: {
        input: {
          brandId,
          description: draftContent,
          descriptionPlainText: plainTextContent,
          files: requestFiles,
          isHqApprove: requestIsHqApprove === 'true',
          isMultipleMatch: requestIsMultipleMatch === 'true',
          onlySuggested: requestOnlySuggested === 'true',
          ...(!!tagFocus.length && { tagFocus }),
          ...(!!tagSkills.length && { tagSkills }),
          ...(!!tagTools.length && { tagTools }),
        },
      },
    })
      .then(({ data: mutationData }) => {
        setCreateNewRequest(false);
        setModalLoading(false);
        if (
          mutationData &&
          mutationData.requestSubmitByAdmin &&
          mutationData.requestSubmitByAdmin.id
        ) {
          history.push('/requests/' + mutationData.requestSubmitByAdmin.id);
        }
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'New Request Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'onCreateNewRequest',
        });
      });
  }
  const [tryExpertSearch] = useMutation<
    ExpertSearchForAdminBrandMutation,
    ExpertSearchForAdminBrandMutationVariables
  >(expertSearchForAdminBrandMutation);
  function onSearchStartExperts() {
    console.log('onSearchStartExperts', searchInputExperts);
    if (!searchInputExperts || searchInputExperts.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    tryExpertSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        if (dataMatchSearch && dataMatchSearch.expertSearchForAdmin) {
          console.log('tryExpertSearch', dataMatchSearch.expertSearchForAdmin);
          setSearchResultsExperts(dataMatchSearch.expertSearchForAdmin);
        }
      },
      variables: {
        onlyActives: true,
        searchQuery: searchInputExperts,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Expert Search Error');
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'tryExpertSearch',
      });
      setSearchResultsExperts(null);
    });
  }
  function handleSearchInputExpertsKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStartExperts();
    }
  }
  const [tryHumanSearch] = useMutation<
    HumanSearchForAdminBrandMutation,
    HumanSearchForAdminBrandMutationVariables
  >(humanSearchForAdminBrandMutation);
  function onSearchStartHumans() {
    console.log('onSearchStartHumans', searchInputHumans);
    if (!searchInputHumans || searchInputHumans.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    tryHumanSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        if (dataMatchSearch && dataMatchSearch.humanSearchForAdmin) {
          console.log('tryHumanSearch', dataMatchSearch.humanSearchForAdmin);
          setSearchResultsHumans(dataMatchSearch.humanSearchForAdmin);
        }
      },
      variables: {
        searchQuery: searchInputHumans,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Human Search Error');
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'tryHumanSearch',
      });
      setSearchResultsHumans(null);
    });
  }
  function handleSearchInputHumansKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStartHumans();
    }
  }
  const [tryBrandSearch] = useMutation<
    BrandSearchForAdminMutation,
    BrandSearchForAdminMutationVariables
  >(brandSearchForAdminMutation);
  function onSearchStartBrands() {
    console.log('onSearchStartBrands', searchInputBrands);
    if (!searchInputBrands || searchInputBrands.trim().length <= 2) {
      addNotification('At least 3 characters!', undefined, 5000);
      return;
    }
    tryBrandSearch({
      update: (_cache, { data: dataMatchSearch }) => {
        if (dataMatchSearch && dataMatchSearch.brandSearchForAdmin) {
          console.log('tryBrandSearch', dataMatchSearch.brandSearchForAdmin);
          setSearchResultsBrands(
            dataMatchSearch.brandSearchForAdmin.filter((b) => b.id !== brandId),
          );
        }
      },
      variables: {
        searchQuery: searchInputBrands,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Brand Search Error');
      logError(err, {
        component: 'AdminBrandDetail',
        func: 'tryBrandSearch',
      });
      setSearchResultsBrands(null);
    });
  }
  function handleSearchInputBrandsKeyDown(
    e: React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchStartBrands();
    }
  }
  function confirmMergeSpecific(mergeId: string) {
    setMergeSpecific(mergeId);
  }
  function cancelMergeSpecific() {
    setMergeSpecific('');
  }
  const [tryBrandMerge] = useMutation<
    BrandMergeByAdminMutation,
    BrandMergeByAdminMutationVariables
  >(brandMergeByAdminMutation);
  function onMergeSpecific() {
    if (modalLoading) {
      return;
    }
    setModalLoading(true);
    tryBrandMerge({
      variables: {
        childBrandId: brandId,
        parentBrandId: mergeSpecific,
      },
    })
      .then(() => {
        setModalLoading(false);
        setMergeSpecific('');
        setSearchInputBrands('');
        setSearchResultsBrands(null);
        history.push('/brands/' + mergeSpecific);
      })
      .catch((err: ApolloError) => {
        setModalLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Merge Brand Error');
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'onMergeSpecific',
        });
      });
  }
  const secondaryPanels = ['About', 'Activity', 'Team', 'Notes'].filter(
    (p) => p !== primaryPanel,
  );
  function switchPanel(panelName: string) {
    setPrimaryPanel(panelName);
    if (panelName === 'Notes' && !calledUserNotes && adminMatchId) {
      getUserNotes().catch((err: ApolloError) => {
        logError(err, {
          component: 'AdminBrandDetail',
          func: 'getUserNotes',
        });
      });
    }
  }
  const restOfTeam = ((brandDetails && brandDetails.team) || []).filter(
    (h) =>
      h.id !==
      ((brandDetails &&
        brandDetails.teamLeader &&
        brandDetails.teamLeader.id) ||
        ''),
  );
  if (
    loadingBrand ||
    loadingThread ||
    loadingUserNotes ||
    loadingRequestPaginated ||
    loadingMatchPaginated ||
    loadingProjectPaginated
  ) {
    // ignore these
  }
  return (
    <div className="DashboardModal AdminBrandDetailModal">
      <div
        className={
          'ThreadDetailView ClientDetailView ' +
          (showSidebar ? ' ThreadDetailViewWithSidebar ' : '') +
          (selectedSearchResult ||
          matchSpecific ||
          removeSpecific ||
          addSpecific ||
          createNewRequest ||
          mergeSpecific
            ? ' ThreadDetailViewWithModal '
            : '')
        }
      >
        <div className="ThreadDetailMain">
          <div className="ThreadDetailMainHeader">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/brands')
              }
              className="ThreadDetailMainHeaderBack"
            >
              {brandDetails ? brandDetails.name : 'Back'}
            </div>
            <div
              className="ThreadDetailMainHeaderToggle"
              onClick={() => setShowSidebar(true)}
            />
          </div>
          <div
            className="ThreadDetailMainBody AdminBrandDetailView"
            ref={threadEventsWindow}
          >
            <div
              className={
                'ThreadEvents ' +
                (currentAction ? ' ThreadEventsHasAction ' : '')
              }
            >
              {threadEventGroups.map((evGroup) => (
                <div className="ThreadEventGroup" key={evGroup.group[0].id}>
                  {generateComponentsForGroup(evGroup.group)}
                </div>
              ))}
            </div>
            {!!currentAction && !!brandDetails && (
              <div className="ThreadCurrentAction">
                {renderCurrentActionEditor()}
              </div>
            )}
          </div>
          <div
            className={
              'ThreadDetailActions ' +
              (openActions ? ' ThreadDetailActionsOpen ' : '') +
              (currentAction ? ' ThreadDetailActionsCurrent ' : '')
            }
          >
            <div className="ThreadDetailActionRow">
              <div
                className="ThreadDetailActionItem"
                onClick={() => openTextEditor()}
              >
                <span>send a </span>text
              </div>
              <div
                className="ThreadDetailActionItem"
                onClick={() => openEmailEditor()}
              >
                <span>send an </span>email
              </div>
            </div>
            <div className="ThreadDetailActionRow">
              <div
                className="ThreadDetailActionTrigger"
                onClick={() =>
                  currentAction
                    ? closeCurrentAction()
                    : setOpenActions(!openActions)
                }
              >
                <div className="ThreadDetailActionBtn">+</div>
                <div className="ThreadDetailActionText">actions</div>
              </div>
            </div>
          </div>
          {!!selectedSearchResult && (
            <div className="ThreadDetailMainFocusModal" onClick={toggleSearch}>
              <div
                className="ThreadDetailMainFocusModalContainer ThreadDetailMainFocusModalContainerSearch"
                onClick={blockClickPropagation}
              >
                <ThreadSearchResults
                  expertId={SUPPORT_EXPERT_ID}
                  matchId={adminMatchId}
                  searchResultFound={selectedSearchResult}
                />
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={toggleSearch}
                >
                  close
                </div>
              </div>
            </div>
          )}
          {!!matchSpecific && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelMatchSpecific}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Confirm Match
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        The customer will not be notified about this. You need
                        to follow up.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={onMatchSpecific}
                          >
                            Yes, make the match
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelMatchSpecific}
                          >
                            No, never mind, do nothing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelMatchSpecific}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!removeSpecific && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelRemoveSpecific}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Remove teammate?
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Integer convallis congue sem, ac consectetur magna. In
                        eget malesuada.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={onRemoveSpecific}
                          >
                            Yes, remove from team
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelRemoveSpecific}
                          >
                            No, never mind, do nothing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelRemoveSpecific}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!createNewRequest && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={() => setCreateNewRequest(false)}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                  <div className="ThreadDetailMainFocusModalContainerTitle">
                    Create a new request
                  </div>
                  <div className="ThreadDetailMainFocusModalContainerSubtitle">
                    This mimics exactly what happens if the brand team leader
                    submits the request (notifications, matching algo, etc).
                  </div>
                  <div className="ThreadDetailMainFocusModalFormField">
                    <div className="ThreadDetailMainFocusModalFormFieldTitle">
                      Description
                    </div>
                    <div className="ThreadDetailMainFocusModalSummarizeRich">
                      <RichTextEditor
                        initContent={''}
                        placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                        onUpdated={setEditorLatest}
                      />
                    </div>
                  </div>
                  <div className="ThreadDetailMainFocusModalFormField">
                    <div className="ThreadDetailMainFocusModalFormFieldTitle">
                      Focus
                    </div>
                    <MultiSelect
                      options={FOCUS_OPTIONS}
                      currValues={tagFocus}
                      onChange={setTagFocus}
                      withTags
                    />
                  </div>
                  <div className="ThreadDetailMainFocusModalFormField">
                    <div className="ThreadDetailMainFocusModalFormFieldTitle">
                      Skills
                    </div>
                    <MultiSelect
                      options={SKILL_OPTIONS}
                      currValues={tagSkills}
                      onChange={setTagSkills}
                      withTags
                    />
                  </div>
                  <div className="ThreadDetailMainFocusModalFormField">
                    <div className="ThreadDetailMainFocusModalFormFieldTitle">
                      Tools
                    </div>
                    <MultiSelect
                      options={TOOL_OPTIONS}
                      currValues={tagTools}
                      onChange={setTagTools}
                      withTags
                    />
                  </div>
                  <div className="ThreadDetailMainFocusModalFormField">
                    <div className="ThreadDetailMainFocusModalFormFieldTitle">
                      Only Show To Suggested Experts
                    </div>
                    <select
                      className=""
                      required
                      value={requestOnlySuggested}
                      onChange={(e) =>
                        setRequestOnlySuggested(e.currentTarget.value)
                      }
                    >
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </select>
                  </div>
                  <div className="ThreadDetailMainFocusModalFormField">
                    <div className="ThreadDetailMainFocusModalFormFieldTitle">
                      Is Multiple Match
                    </div>
                    <select
                      className=""
                      required
                      value={requestIsMultipleMatch}
                      onChange={(e) =>
                        setRequestIsMultipleMatch(e.currentTarget.value)
                      }
                    >
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </select>
                  </div>
                  <div className="ThreadDetailMainFocusModalFormField">
                    <div className="ThreadDetailMainFocusModalFormFieldTitle">
                      Is HQ Approve
                    </div>
                    <select
                      className=""
                      required
                      value={requestIsHqApprove}
                      onChange={(e) =>
                        setRequestIsHqApprove(e.currentTarget.value)
                      }
                    >
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </select>
                  </div>
                  <div className="ThreadDetailMainFocusModalFormField">
                    <div className="ThreadDetailMainFocusModalFormFieldTitle">
                      Files
                    </div>
                    {requestFiles.map((f) => (
                      <div
                        key={f.url}
                        className="ThreadEditorFile"
                        onClick={() => removeFile(f.url || '')}
                      >
                        <span className="ThreadEditorFileText">
                          {f.filename}
                        </span>
                        <div className="ThreadEditorFileRemove" />
                      </div>
                    ))}
                    <ReactFilestack
                      apikey="AGxdviGoVRwWPL6lKEdnXz"
                      actionOptions={{
                        maxFiles: 20,
                        storeTo: {
                          container: envUtils.pick(
                            'files.asklorem.com',
                            'files-dev.asklorem.com',
                            'files-dev.asklorem.com',
                          ),
                          location: 's3',
                          path: `${SUPPORT_EXPERT_ID}/`,
                          region: 'us-east-1',
                        },
                      }}
                      onSuccess={fileUploaded}
                      onError={fileError}
                      customRender={({ onPick }: { onPick: any }) => (
                        <div
                          onMouseDown={onPick}
                          className={
                            'ThreadEditorUpload ' +
                            (requestFiles.length
                              ? ' ThreadEditorUploadHasFiles '
                              : '')
                          }
                        >
                          {requestFiles.length ? '+' : 'Attach Files'}
                        </div>
                      )}
                    />
                  </div>
                  {modalLoading ? (
                    <div className="ThreadDetailMainFocusModalLoading" />
                  ) : (
                    <Fragment>
                      <div
                        className="ThreadDetailMainFocusModalSummarizeBtn"
                        onClick={onCreateNewRequest}
                      >
                        Submit
                      </div>
                    </Fragment>
                  )}
                </div>
                <div
                  className="ThreadDetailMainFocusModalClose"
                  onClick={() => setCreateNewRequest(false)}
                >
                  cancel
                </div>
              </div>
            </div>
          )}
          {!!addSpecific && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelAddSpecific}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Add teammate?
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        No notifications will be sent. Up to you to follow up.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={onAddSpecific}
                          >
                            Yes, add to the team
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelAddSpecific}
                          >
                            No, never mind, do nothing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelAddSpecific}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
          {!!mergeSpecific && (
            <div
              className="ThreadDetailMainFocusModal"
              onClick={cancelMergeSpecific}
            >
              <div
                className="ThreadDetailMainFocusModalContainer"
                onClick={blockClickPropagation}
              >
                {modalLoading ? (
                  <div className="ThreadDetailMainFocusModalLoading" />
                ) : (
                  <Fragment>
                    <div className="ThreadDetailMainFocusModalContainerBody ThreadDetailMainFocusModalContainerBodyCancelProject">
                      <div className="ThreadDetailMainFocusModalContainerTitle">
                        Merge into another brand?
                      </div>
                      <div className="ThreadDetailMainFocusModalContainerSubtitle">
                        You are merging this brand, into the parent brand you
                        just selected. We can&apos;t undo this action. Please
                        double check this.
                      </div>
                      <div className="ThreadDetailMainFocusModalChoicesSection">
                        <div className="ThreadDetailMainFocusModalChoicesTitle">
                          Are you sure you want to do this?
                        </div>
                        <div className="ThreadDetailMainFocusModalChoicesOptions">
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={onMergeSpecific}
                          >
                            Yes, merge this child into the selected parent brand
                          </div>
                          <div
                            className="ThreadDetailMainFocusModalChoicesOption"
                            onClick={cancelMergeSpecific}
                          >
                            No, never mind, do nothing
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ThreadDetailMainFocusModalClose"
                      onClick={cancelMergeSpecific}
                    >
                      cancel
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className="ThreadDetailMainBodyCover"
          onClick={() => setShowSidebar(false)}
        />
        <div className="ThreadDetailSidebar">
          <div className="ThreadDetailSidebarHeader">
            <div
              className="ThreadDetailSidebarHeaderBack"
              onClick={() => setShowSidebar(false)}
            />
            <div className="ThreadDetailSidebarHeaderPrimary">
              {primaryPanel}
            </div>
            {secondaryPanels.map((panelName) => (
              <div
                key={panelName}
                className="ThreadDetailSidebarHeaderSecondarySwitch"
                onClick={() => switchPanel(panelName)}
              >
                {panelName}
              </div>
            ))}
            <div
              className={
                'ThreadDetailSidebarHeaderSearch ThreadDetailSidebarHeaderSearch' +
                (showSearch ? 'Active ' : '')
              }
            >
              <div className="ThreadDetailSidebarHeaderSearchBar">
                <input
                  type="text"
                  ref={searchRef}
                  className="ThreadDetailSidebarHeaderSearchBarInput"
                  placeholder="Search"
                  autoComplete="new-off"
                  spellCheck="false"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={handleSearchInputKeyDown}
                />
                <div
                  className="ThreadDetailSidebarHeaderSearchToggle"
                  onClick={toggleSearch}
                />
              </div>
            </div>
          </div>
          <div className="ThreadDetailSidebarBody">
            {!searchLoading && searchResults === null ? (
              <div className="ThreadDetailSidebarBodyPanels">
                {primaryPanel === 'About' && brandDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelAbout">
                    <div className="ThreadDetailSidebarUpNextItems">
                      {brandDetails.adminMatch &&
                        brandDetails.adminMatch.expertUnread && (
                          <div className="ThreadDetailSidebarUpNextItem">
                            <div
                              className="ThreadDetailSidebarUpNextUnread"
                              onClick={markAsRead}
                            >
                              <div className="ThreadDetailSidebarUpNextUnreadSummary">
                                {unreadReasonFormatted(
                                  brandDetails.adminMatch.expertUnread,
                                )}
                              </div>
                              <div className="ThreadDetailSidebarUpNextUnreadClose" />
                            </div>
                          </div>
                        )}
                      {!!emailDraft && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardDraft ThreadDetailSidebarUpNextCardDraftEmail">
                            <div
                              className="ThreadDetailSidebarUpNextCardHeader"
                              onClick={() => openEmailEditor()}
                            >
                              <div className="ThreadDetailSidebarUpNextCardType">
                                Draft Email
                              </div>
                              <div className="ThreadDetailSidebarUpNextCardSubtitle">
                                {emailSubject}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!!textDraft && (
                        <div className="ThreadDetailSidebarUpNextItem">
                          <div className="ThreadDetailSidebarUpNextCard ThreadDetailSidebarUpNextCardDraft ThreadDetailSidebarUpNextCardDraftEmail">
                            <div
                              className="ThreadDetailSidebarUpNextCardHeader"
                              onClick={() => openTextEditor()}
                            >
                              <div className="ThreadDetailSidebarUpNextCardType">
                                Draft Text
                              </div>
                              <div className="ThreadDetailSidebarUpNextCardSubtitle">
                                {textContent}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {isEditingInfo ? (
                      <div className="ThreadDetailSidebarAdminSection ThreadDetailSidebarAdminSectionPaddingTop">
                        <div
                          className="ThreadDetailSidebarAdminSectionTopRightBtn"
                          onClick={() => setIsEditingInfo(false)}
                        >
                          cancel
                        </div>
                        <div className="ThreadDetailSidebarAdminForm">
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Brand Name
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Brand Name"
                            value={editBrandName}
                            onChange={(e) =>
                              setEditBrandName(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Brand Description
                          </div>
                          <TextareaAutosize
                            type="text"
                            placeholder="Brand description..."
                            spellCheck="true"
                            className="ThreadDetailSidebarAdminFormInput"
                            value={editBrandDescription}
                            onChange={(e) => {
                              setEditBrandDescription(e.currentTarget.value);
                            }}
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Industry
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editBrandIndustryTag}
                            onChange={(e) =>
                              setEditBrandIndustryTag(e.currentTarget.value)
                            }
                          >
                            <option value="">Select Industry</option>
                            {INDUSTRY_OPTIONS.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Website URL
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Website URL"
                            value={editBrandUrl}
                            onChange={(e) =>
                              setEditBrandUrl(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Shopify Admin URL
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Shopify Admin URL"
                            value={editBrandAdminUrl}
                            onChange={(e) =>
                              setEditBrandAdminUrl(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Platform
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editBrandPlatform}
                            onChange={(e) =>
                              setEditBrandPlatform(e.currentTarget.value)
                            }
                          >
                            <option value="shopify">Shopify</option>
                            <option value="wordpress">WordPress</option>
                            <option value="squarespace">Squarespace</option>
                            <option value="other">Other</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Storetfront Password
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Storetfront Password"
                            value={editBrandStorefrontPwd}
                            onChange={(e) =>
                              setEditBrandStorefrontPwd(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Account Tier
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editBrandAccountTier}
                            onChange={(e) =>
                              setEditBrandAccountTier(e.currentTarget.value)
                            }
                          >
                            <option value="">Standard</option>
                            <option value="KEY_ACCOUNT">Key Account</option>
                            <option value="RISING_ACCOUNT">
                              Rising Account
                            </option>
                            <option value="FRIEND">Friend</option>
                            <option value="STRATEGIC_AGENCY_PARTNER">
                              Strategic Agency Partner
                            </option>
                            <option value="STRATEGIC_TECH_PARTNER">
                              Strategic Tech Partner
                            </option>
                            <option value="SPECIAL_OTHER">
                              Special / Other
                            </option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Is VIP Matching?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editBrandIsKeyAccount}
                            onChange={(e) =>
                              setEditBrandIsKeyAccount(e.currentTarget.value)
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Is Delicate?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editBrandIsDelicate}
                            onChange={(e) =>
                              setEditBrandIsDelicate(e.currentTarget.value)
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Shopify Plus?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editBrandIsShopifyPlus}
                            onChange={(e) =>
                              setEditBrandIsShopifyPlus(e.currentTarget.value)
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Invoicing Enabled?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editBrandInvoicing}
                            onChange={(e) =>
                              setEditBrandInvoicing(e.currentTarget.value)
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Exclude from KPIs?
                          </div>
                          <select
                            className="ThreadDetailSidebarAdminFormInput"
                            required
                            value={editBrandExcludeKpis}
                            onChange={(e) =>
                              setEditBrandExcludeKpis(e.currentTarget.value)
                            }
                          >
                            <option value="false">False</option>
                            <option value="true">True</option>
                          </select>
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Invoice: Address Line One
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Address Line One"
                            value={editBrandAddressOne}
                            onChange={(e) =>
                              setEditBrandAddressOne(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Invoice: Address Line Two
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Address Line Two"
                            value={editBrandAddressTwo}
                            onChange={(e) =>
                              setEditBrandAddressTwo(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Invoice: Vat Number
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Vat Number"
                            value={editBrandVatNumber}
                            onChange={(e) =>
                              setEditBrandVatNumber(e.currentTarget.value)
                            }
                          />
                          <div className="ThreadDetailSidebarAdminFormTitle">
                            Invoice: Legal Name
                          </div>
                          <input
                            className="ThreadDetailSidebarAdminFormInput"
                            type="text"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Legal Name"
                            value={editBrandLegalName}
                            onChange={(e) =>
                              setEditBrandLegalName(e.currentTarget.value)
                            }
                          />
                          <div
                            className={
                              'ThreadDetailSidebarAdminFormAction ' +
                              (actionLoading
                                ? ' ThreadDetailSidebarAdminFormActionLoading '
                                : '')
                            }
                            onClick={onSaveEditingInfo}
                          >
                            Save
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Fragment>
                        {brandDetails.teamLeader &&
                          brandDetails.teamLeader.expertReferralStr && (
                            <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                              <div className="ThreadDetailSidebarBodyPanelEmpty">
                                <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                                  An expert brought this client
                                </div>
                                <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                                  Storetasker only takes a 3% cut on clients you
                                  bring to the platform and you&apos;re welcome
                                  to charge them any hourly rate you like.
                                </div>
                              </div>
                            </div>
                          )}
                        <div className="ThreadDetailSidebarAdminSection ThreadDetailSidebarAdminSectionPaddingTop">
                          <div
                            className="ThreadDetailSidebarAdminSectionTopRightBtn"
                            onClick={startEditingInfo}
                          >
                            edit
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Brand
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionBig">
                            {brandDetails.name}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionParagraph">
                            {brandDetails.description ||
                              'No description found.'}
                          </div>
                          {brandDetails.createdAt && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Signed up:{' '}
                              {moment(brandDetails.createdAt).format(
                                'MMMM Do, YYYY',
                              )}
                            </div>
                          )}
                          {!!(
                            brandDetails.categoryType ||
                            brandDetails.estimateEmployees ||
                            brandDetails.estimateRevenue ||
                            brandDetails.estimateTheme ||
                            brandDetails.instagramFollowers ||
                            brandDetails.isShopifyPlus
                          ) && (
                            <Fragment>
                              <div className="ThreadDetailSidebarAboutSectionTitle ThreadDetailSidebarAboutSectionTitleExtraTop">
                                Details
                              </div>
                              {!!brandDetails.isShopifyPlus && (
                                <div className="ThreadDetailSidebarAboutSubtitle">
                                  Plan: Shopify Plus
                                </div>
                              )}
                              {!!brandDetails.categoryType && (
                                <div className="ThreadDetailSidebarAboutSubtitle">
                                  Category: {brandDetails.categoryType}
                                </div>
                              )}
                              {!!brandDetails.estimateEmployees && (
                                <div className="ThreadDetailSidebarAboutSubtitle">
                                  Est. Employees:{' '}
                                  {kpiNumber(brandDetails.estimateEmployees)}
                                </div>
                              )}
                              {!!brandDetails.estimateRevenue && (
                                <div className="ThreadDetailSidebarAboutSubtitle">
                                  Est. Monthly Revenue:{' '}
                                  {kpiDollars(brandDetails.estimateRevenue)}
                                </div>
                              )}
                              {!!brandDetails.estimateTheme && (
                                <div className="ThreadDetailSidebarAboutSubtitle">
                                  Theme: {brandDetails.estimateTheme}
                                </div>
                              )}
                              {!!brandDetails.instagramFollowers && (
                                <div className="ThreadDetailSidebarAboutSubtitle">
                                  Est. Instagram followers:{' '}
                                  {kpiNumber(brandDetails.instagramFollowers)}
                                </div>
                              )}
                            </Fragment>
                          )}
                        </div>
                        <div className="ThreadDetailSidebarAdminSection">
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Website
                          </div>
                          {brandDetails.shopifyAdminURL && (
                            <div className="ThreadDetailSidebarAboutWebsite">
                              <a
                                className="ThreadDetailSidebarAboutWebsiteLink"
                                href={'http://' + brandDetails.shopifyAdminURL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {brandDetails.shopifyAdminURL}
                              </a>
                            </div>
                          )}
                          {brandDetails.url && (
                            <div className="ThreadDetailSidebarAboutWebsite">
                              <a
                                className="ThreadDetailSidebarAboutWebsiteLink"
                                href={'http://' + brandDetails.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {brandDetails.url}
                              </a>
                            </div>
                          )}
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Platform: {brandDetails.platform}
                          </div>
                          {brandDetails.shopifyStorefrontPassword && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              {brandDetails.shopifyStorefrontPassword ===
                              'has-secret-password'
                                ? 'Has A Store Password: Waiting For Customer'
                                : 'Storetfront Password: ' +
                                  brandDetails.shopifyStorefrontPassword}
                            </div>
                          )}
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Account Tier:{' '}
                            {brandDetails.accountTier || 'Standard'}
                          </div>
                          <div className="ThreadDetailSidebarAdminSectionDescription">
                            Industry:{' '}
                            {brandDetails.industryTag
                              ? tagLabelIndustry(brandDetails.industryTag)
                              : 'missing'}
                          </div>
                          {brandDetails.isKeyAccount && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Is VIP Matching!
                            </div>
                          )}
                          {brandDetails.isDelicate && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Is Delicate!
                            </div>
                          )}
                          {brandDetails.invoicingEnabled && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Invoicing Enabled: True
                            </div>
                          )}
                          {brandDetails.shouldExcludeFromKpiReports && (
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Exclude from KPIs: True
                            </div>
                          )}
                        </div>
                        {brandDetails.businessInfo && (
                          <div className="ThreadDetailSidebarAdminSection">
                            <div className="ThreadDetailSidebarAdminSectionTitle">
                              Invoice Settings
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Legal Name: {brandDetails.businessInfo.legalName}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Address Line 1:{' '}
                              {brandDetails.businessInfo.addressLineOne}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              Address Line 2:{' '}
                              {brandDetails.businessInfo.addressLineTwo}
                            </div>
                            <div className="ThreadDetailSidebarAdminSectionDescription">
                              VAT Number: {brandDetails.businessInfo.vatNumber}
                            </div>
                          </div>
                        )}
                        <div className="ThreadDetailSidebarAdminSection ThreadDetailSidebarAdminSectionMinHeight">
                          {adminLoading ? (
                            <div className="ThreadDetailSidebarAdminSectionLoading" />
                          ) : (
                            <Fragment>
                              {brandDetails.adminMatch &&
                                !brandDetails.adminMatch.expertUnread && (
                                  <div
                                    className="ThreadDetailSidebarAdminAction"
                                    onClick={markAsUnread}
                                  >
                                    mark as unread
                                  </div>
                                )}
                              {!!brandDetails.adminMatch &&
                              brandDetails.adminMatch.expertSnoozedUntil &&
                              brandDetails.adminMatch.expertSnoozedUntil >
                                initTime ? (
                                <Fragment>
                                  <div className="ThreadDetailSidebarAdminSectionDescription">
                                    Snoozed until:{' '}
                                    {moment(
                                      brandDetails.adminMatch
                                        .expertSnoozedUntil,
                                    ).format('MMMM Do') +
                                      ' at ' +
                                      moment(
                                        brandDetails.adminMatch
                                          .expertSnoozedUntil,
                                      ).format('h:mma z')}
                                  </div>
                                  <div
                                    className="ThreadDetailSidebarAdminAction"
                                    onClick={() => onSnoozeAdmin()}
                                  >
                                    Unsnooze Brand
                                  </div>
                                </Fragment>
                              ) : (
                                <select
                                  className="ThreadDetailSidebarAdminActionSelect"
                                  value={adminSnoozed}
                                  onChange={(e) =>
                                    onSnoozeAdmin(e.currentTarget.value)
                                  }
                                >
                                  <option value="" disabled>
                                    Snooze for X days
                                  </option>
                                  <option value="1">1 Day</option>
                                  <option value="2">2 Days</option>
                                  <option value="3">3 Days</option>
                                  <option value="4">4 Days</option>
                                  <option value="5">5 Days</option>
                                  <option value="6">6 Days</option>
                                  <option value="7">7 Days</option>
                                </select>
                              )}
                              {!!brandDetails.adminMatch &&
                                !!brandDetails.adminMatch.adminAssigned && (
                                  <div className="ThreadDetailSidebarAdminSectionDescription">
                                    Assigned to:{' '}
                                    {brandDetails.adminMatch.adminAssigned}
                                  </div>
                                )}
                              {!!brandDetails.adminMatch &&
                                brandDetails.adminMatch.adminAssigned && (
                                  <div
                                    className="ThreadDetailSidebarAdminAction"
                                    onClick={() => onAssignAdmin('')}
                                  >
                                    Remove Assignment:{' '}
                                    {brandDetails.adminMatch.adminAssigned}
                                  </div>
                                )}
                              <select
                                className="ThreadDetailSidebarAdminActionSelect"
                                value={adminAssignment}
                                onChange={(e) =>
                                  onAssignAdmin(e.currentTarget.value)
                                }
                              >
                                <option value="" disabled>
                                  Assign team member
                                </option>
                                <option value="Richard">Richard</option>
                                <option value="Tim">Tim</option>
                                <option value="Gayle">Gayle</option>
                                <option value="Charlie">Charlie</option>
                              </select>
                            </Fragment>
                          )}
                        </div>
                        <div className="ThreadDetailSidebarAdminSection">
                          <div className="ThreadDetailSidebarAdminSectionTitle">
                            Merge Into Brand
                          </div>
                          <div className="ThreadDetailSidebarAdminSearch">
                            <div className="ThreadDetailSidebarAdminSearchBar">
                              <input
                                type="text"
                                className="ThreadDetailSidebarAdminSearchBarInput"
                                autoComplete="new-off"
                                spellCheck="false"
                                placeholder="Search"
                                value={searchInputBrands}
                                onChange={(e) =>
                                  setSearchInputBrands(e.target.value)
                                }
                                onKeyDown={handleSearchInputBrandsKeyDown}
                              />
                            </div>
                            <div className="ThreadDetailSidebarAdminSearchResults">
                              {searchResultsBrands &&
                                !searchResultsBrands.length && (
                                  <div className="ThreadDetailSidebarAdminEmpty">
                                    No brands found. Try another search query to
                                    find who you&apos;re looking for.
                                  </div>
                                )}
                              {searchResultsBrands &&
                                searchResultsBrands.map((brandResult) => (
                                  <div
                                    key={brandResult.id}
                                    className="ThreadDetailSidebarAdminSearchResult"
                                    onClick={() =>
                                      confirmMergeSpecific(brandResult.id)
                                    }
                                  >
                                    <div className="ThreadDetailSidebarAdminSearchResultTitle">
                                      {brandResult.name || 'no name...'}
                                    </div>
                                    <div className="ThreadDetailSidebarAdminSearchResultSubtitle">
                                      {brandResult.url ||
                                        brandResult.shopifyAdminURL ||
                                        'no url...'}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                )}
                {primaryPanel === 'Activity' && brandDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelActivity">
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Matches
                      </div>
                      {!allMatchesForBrand.length && (
                        <div className="ThreadDetailSidebarAdminEmpty">
                          {errorMatchPaginated
                            ? errorUtils.getErrorMessage(errorMatchPaginated)
                            : 'No matches yet.'}
                        </div>
                      )}
                      {allMatchesForBrand.map((m) => (
                        <Link
                          key={m.id}
                          to={'/match/' + m.id}
                          className="AdminLinkedRecord"
                        >
                          <div className="AdminLinkedRecordTitle">
                            {(m.expert.firstName || '') +
                              ' ' +
                              (m.expert.lastName || '')}
                          </div>
                          {m.lastUpdated && (
                            <div className="AdminLinkedRecordSubtitle">
                              Updated:{' '}
                              {moment(m.lastUpdated).format('MMMM Do, YYYY')}
                            </div>
                          )}
                          <div className="AdminLinkedRecordTag">Match</div>
                        </Link>
                      ))}
                      {allMatchesForBrand.length >= ACTIVITY_PAGE_LIMIT &&
                        oldestUpdatedMatch &&
                        showLoadMoreMatches && (
                          <div
                            className="AdminListItemLoader"
                            onClick={loadMoreMatches}
                          >
                            load more matches
                          </div>
                        )}
                    </div>
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Projects
                      </div>
                      {!allProjectsForBrand.length && (
                        <div className="ThreadDetailSidebarAdminEmpty">
                          {errorProjectPaginated
                            ? errorUtils.getErrorMessage(
                                errorProjectPaginated,
                              ) || 'Loading Error'
                            : 'No projects yet.'}
                        </div>
                      )}
                      {allProjectsForBrand.map((p) => (
                        <Link
                          key={p.id}
                          to={'/match/' + p.matchStr}
                          className="AdminLinkedRecord"
                        >
                          <div className="AdminLinkedRecordTitle">
                            {(p.quote && p.quote.title) || ''}
                          </div>
                          <div className="AdminLinkedRecordSubtitle">
                            {(p.expert.firstName || '') +
                              ' ' +
                              (p.expert.lastName || '')}
                          </div>
                          <div className="AdminLinkedRecordSubtitle">
                            $
                            {centsDollarsRounded(
                              (p.quote && p.quote.cents) || 0,
                            )}
                            :{' '}
                            {quoteStatusFormatted(
                              (p.quote && p.quote.status) || '',
                            )}
                          </div>
                          {p.createdAt && (
                            <div className="AdminLinkedRecordSubtitle">
                              Created:{' '}
                              {moment(p.createdAt).format('MMMM Do, YYYY')}
                            </div>
                          )}
                          {p.clientFee && (
                            <div className="AdminLinkedRecordSubtitle AdminLinkedRecordSubtitleAlert">
                              Client fee of {amountClientFee(p.clientFee)}%
                            </div>
                          )}
                          {p.discountCode && (
                            <div className="AdminLinkedRecordSubtitle AdminLinkedRecordSubtitleAlert">
                              Client used {amountDiscountCode(p.discountCode)}%
                              off discount code
                            </div>
                          )}
                          <div className="AdminLinkedRecordTag">Project</div>
                        </Link>
                      ))}
                      {allProjectsForBrand.length >= ACTIVITY_PAGE_LIMIT &&
                        oldestCreatedProject &&
                        showLoadMoreProjects && (
                          <div
                            className="AdminListItemLoader"
                            onClick={loadMoreProjects}
                          >
                            load more projects
                          </div>
                        )}
                    </div>
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Requests
                      </div>
                      {!allRequestsForBrand.length && (
                        <div className="ThreadDetailSidebarAdminEmpty">
                          {errorRequestPaginated
                            ? errorUtils.getErrorMessage(
                                errorRequestPaginated,
                              ) || 'Loading Error'
                            : 'No requests yet.'}
                        </div>
                      )}
                      <div
                        className="ThreadDetailSidebarAdminAction"
                        onClick={() => setCreateNewRequest(true)}
                      >
                        Create New Request
                      </div>
                      {allRequestsForBrand.map((r) => {
                        let requestStatusStr = '';
                        if (r.isMultipleMatch && r.isActive) {
                          requestStatusStr = 'Waiting for multiple match';
                        } else if (r.currentMatchStr && !r.inReview) {
                          requestStatusStr = 'Matched';
                        } else if (!r.isActive) {
                          requestStatusStr = 'Canceled';
                        } else if (r.inReview) {
                          requestStatusStr = 'Needs Review';
                        } else {
                          requestStatusStr = 'Waiting for single match';
                        }
                        return (
                          <Link
                            key={r.id}
                            to={'/requests/' + r.id}
                            className="AdminLinkedRecord"
                          >
                            <div className="AdminLinkedRecordTitle">
                              {requestStatusStr}
                            </div>
                            {r.createdAt && (
                              <div className="AdminLinkedRecordSubtitle">
                                Submitted:{' '}
                                {moment(r.createdAt).format('MMMM Do, YYYY')}
                              </div>
                            )}
                            {!!r.isMultipleMatch && (
                              <div className="AdminLinkedRecordSubtitle AdminLinkedRecordSubtitleAlert">
                                Is Multiple Match (made:{' '}
                                {r.matchLog ? r.matchLog.length : 0})
                              </div>
                            )}
                            <div className="AdminLinkedRecordTag">Request</div>
                          </Link>
                        );
                      })}
                      {allRequestsForBrand.length >= ACTIVITY_PAGE_LIMIT &&
                        oldestCreatedRequest &&
                        showLoadMoreRequests && (
                          <div
                            className="AdminListItemLoader"
                            onClick={loadMoreRequests}
                          >
                            load more requests
                          </div>
                        )}
                    </div>
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Match With A Specifc Expert?
                      </div>
                      <div className="ThreadDetailSidebarAdminSearch">
                        <div className="ThreadDetailSidebarAdminSearchBar">
                          <input
                            type="text"
                            className="ThreadDetailSidebarAdminSearchBarInput"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Search"
                            value={searchInputExperts}
                            onChange={(e) =>
                              setSearchInputExperts(e.target.value)
                            }
                            onKeyDown={handleSearchInputExpertsKeyDown}
                          />
                        </div>
                        <div className="ThreadDetailSidebarAdminSearchResults">
                          {searchResultsExperts &&
                            !searchResultsExperts.length && (
                              <div className="ThreadDetailSidebarAdminEmpty">
                                No experts found. Try another search query to
                                find who you&apos;re looking for.
                              </div>
                            )}
                          {searchResultsExperts &&
                            searchResultsExperts.map((expertResult) => (
                              <div
                                key={expertResult.id}
                                className="ThreadDetailSidebarAdminSearchResult"
                                onClick={() =>
                                  confirmMatchSpecific(expertResult.id)
                                }
                              >
                                <div className="ThreadDetailSidebarAdminSearchResultTitle">
                                  {(
                                    (expertResult.firstName || '') +
                                    ' ' +
                                    (expertResult.lastName || '')
                                  ).trim()}
                                </div>
                                <div className="ThreadDetailSidebarAdminSearchResultSubtitle">
                                  {expertResult.primaryPublicEmail || ''}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {primaryPanel === 'Team' && brandDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelTeam">
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Team Leader
                      </div>
                      {brandDetails.teamLeader && (
                        <Link
                          to={'/humans/' + brandDetails.teamLeader.id}
                          className="AdminLinkedRecord"
                        >
                          <div className="AdminLinkedRecordTitle">
                            {brandDetails.teamLeader.proStart ? '(Pro) ' : ''}
                            {(
                              (brandDetails.teamLeader.firstName || '') +
                              ' ' +
                              (brandDetails.teamLeader.lastName || '')
                            ).trim()}
                          </div>
                          <div className="AdminLinkedRecordSubtitle">
                            {brandDetails.teamLeader.primaryEmail ||
                              brandDetails.teamLeader.primaryPhone ||
                              ''}
                          </div>
                          <div className="AdminLinkedRecordTag">Human</div>
                        </Link>
                      )}
                    </div>
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Teammates
                      </div>
                      {!restOfTeam.length && (
                        <div className="ThreadDetailSidebarAdminEmpty">
                          No teammates yet.
                        </div>
                      )}
                      {restOfTeam.map((h) => (
                        <div key={h.id}>
                          <Link
                            to={'/humans/' + h.id}
                            className="AdminLinkedRecord AdminLinkedRecordWithBtn"
                          >
                            <div className="AdminLinkedRecordTitle">
                              {h.proStart ? '(Pro) ' : ''}
                              {(
                                (h.firstName || '') +
                                ' ' +
                                (h.lastName || '')
                              ).trim()}
                            </div>
                            <div className="AdminLinkedRecordSubtitle">
                              {h.primaryEmail || h.primaryPhone || ''}
                            </div>
                            <div className="AdminLinkedRecordTag">Human</div>
                          </Link>
                          <div
                            className="ThreadDetailSidebarAdminSectionRowBtn ThreadDetailSidebarAdminSectionRowBtnLeft"
                            onClick={() => switchTeamLeader(h.id)}
                          >
                            Make team lead?
                          </div>
                          <div
                            className="ThreadDetailSidebarAdminSectionRowBtn"
                            onClick={() => confirmRemoveSpecific(h.id)}
                          >
                            Remove from team?
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="ThreadDetailSidebarAdminSection">
                      <div className="ThreadDetailSidebarAdminSectionTitle">
                        Add a new teammate?
                      </div>
                      <div className="ThreadDetailSidebarAdminSearch">
                        <div className="ThreadDetailSidebarAdminSearchBar">
                          <input
                            type="text"
                            className="ThreadDetailSidebarAdminSearchBarInput"
                            autoComplete="new-off"
                            spellCheck="false"
                            placeholder="Search"
                            value={searchInputHumans}
                            onChange={(e) =>
                              setSearchInputHumans(e.target.value)
                            }
                            onKeyDown={handleSearchInputHumansKeyDown}
                          />
                        </div>
                        <div className="ThreadDetailSidebarAdminSearchResults">
                          {searchResultsHumans &&
                            !searchResultsHumans.length && (
                              <div className="ThreadDetailSidebarAdminEmpty">
                                No humans found. Try another search query to
                                find who you&apos;re looking for.
                              </div>
                            )}
                          {searchResultsHumans &&
                            searchResultsHumans.map((humanResult) => (
                              <div
                                key={humanResult.id}
                                className="ThreadDetailSidebarAdminSearchResult"
                                onClick={() =>
                                  confirmAddSpecific(humanResult.id)
                                }
                              >
                                <div className="ThreadDetailSidebarAdminSearchResultTitle">
                                  {(
                                    (humanResult.firstName || '') +
                                    ' ' +
                                    (humanResult.lastName || '')
                                  ).trim()}
                                </div>
                                <div className="ThreadDetailSidebarAdminSearchResultSubtitle">
                                  {humanResult.primaryEmail ||
                                    humanResult.primaryPhone ||
                                    ''}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {primaryPanel === 'Notes' && brandDetails && (
                  <div className="ThreadDetailSidebarBodyPanel ThreadDetailSidebarBodyPanelTeam">
                    <div className="ThreadDetailSidebarAdminSection">
                      <UserNoteEditor
                        onSave={onSaveNote}
                        isLoading={noteLoading}
                        errorFound={errorUserNotes}
                        userNotes={allUserNotes}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="ThreadDetailSidebarBodySearch">
                {!searchLoading && searchResults && !searchResults.length && (
                  <div className="ThreadDetailSidebarBodyPanelEmptyWrapper">
                    <div className="ThreadDetailSidebarBodyPanelEmpty">
                      <div className="ThreadDetailSidebarBodyPanelEmptyTitle">
                        No search results found
                      </div>
                      <div className="ThreadDetailSidebarBodyPanelEmptyDescription">
                        Please try again with another search.
                      </div>
                    </div>
                  </div>
                )}
                {!searchLoading && searchResults && !!searchResults.length && (
                  <div className="ThreadDetailSidebarSearchResults">
                    {searchResults.map(renderSearchResult)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <div
              onClick={() =>
                history.length > 1
                  ? history.goBack()
                  : history.replace('/brands')
              }
              className="DashboardErrorCoverNav"
            >
              back
            </div>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

AdminBrandDetail.propTypes = {
  brandId: PropTypes.string.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  socketClient: PropTypes.object.isRequired,
};

export default AdminBrandDetail;
