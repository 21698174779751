import { gql } from '@apollo/client';

export const ExpertPartialTop = gql`
  fragment ExpertPartialTop on Expert {
    id
    firstName
    lastName
    photo
  }
`;

export const ExpertSummary = gql`
  fragment ExpertSummary on Expert {
    ...ExpertPartialTop
    activeEscrowCents
    activeProjectCount
    claimsLeft
    primaryPublicEmail
    primaryPublicPhone
    status
    inSpotlight
    tagFocus
  }
  ${ExpertPartialTop}
`;

export const ExpertDetailHuman = gql`
  fragment ExpertDetailHuman on Expert {
    ...ExpertSummary
    locationStr
    locationTimezone
    primaryPublicEmail
    primaryPublicPhone
    profilePath
    profileBio
    scheduleTimeDefaultDescription
    scheduleTimeDefaultLocation
    scheduleTimeEnabled
    slackId
    tagSkills
    tagTools
  }
  ${ExpertSummary}
`;

export const ExpertDetail = gql`
  fragment ExpertDetail on Expert {
    ...ExpertSummary
    createdAt
    adminSupportChannel {
      id
      expertUnread
    }
    allowIncomingCalls
    clientEscalations
    kpis {
      clientsTotal
      closeRateRecent
      completionRateRecent
      feedbackRatingRecent
      gmvCurrentMonth
      gmvPreviousMonth
      gmvTotal
      repeatRateOverall
      updatedAt
    }
    isOnlyAdminTag
    isNoKeyAccounts
    levelEstimate
    locationContinent
    locationCountry
    locationStr
    locationTimezone
    notificationDelay
    notificationSounds
    notificationMedium
    nylasAccessToken
    nylasAccountName
    nylasCalendarId
    nylasCalendarName
    potentialExpertReferrals {
      email
      expertise
      name
    }
    primaryPersonalEmail
    primaryPersonalPhone
    profileBio
    profileEmailIntro
    pastClients {
      collaborators
      details
      industryTag
      name
      skills
      url
    }
    profileHourlyRate
    profilePath
    profileStartingProject
    profileStartingRetainer
    recipientId
    scheduleTimeDayFriday {
      end
      start
    }
    scheduleTimeDayMonday {
      end
      start
    }
    scheduleTimeDaySaturday {
      end
      start
    }
    scheduleTimeDaySunday {
      end
      start
    }
    scheduleTimeDayThursday {
      end
      start
    }
    scheduleTimeDayTuesday {
      end
      start
    }
    scheduleTimeDayWednesday {
      end
      start
    }
    scheduleTimeDefaultDescription
    scheduleTimeDefaultLocation
    scheduleTimeEnabled
    secondaryPersonalEmails
    secondaryPersonalPhones
    ski
    tagNotifyEmail
    tagNotifySlack
    tagNotifyText
    tagProblems
    tagSkills
    tagTools
  }
  ${ExpertSummary}
`;

export const ExpertDetailHq = gql`
  fragment ExpertDetailHq on Expert {
    ...ExpertDetail
    adminSupportChannel {
      id
      expertUnread
      adminUnread
      adminSnoozedUntil
      adminAssigned
    }
    blockAdminAccess
    claimsAllowed
    expertReferral {
      id
      firstName
      lastName
    }
    kpis {
      clientsTotal
      closeRateOverall
      closeRateRecent
      completionRateOverall
      completionRateRecent
      deadlinesMetOverall
      deadlinesMetRecent
      feedbackRatingOverall
      feedbackRatingRecent
      gmvCurrentMonth
      gmvPreviousMonth
      gmvTotal
      matchesTotal
      repeatRateOverall
      repeatRateRecent
      supportCasesOpen
      supportCasesTotal
      updatedAt
    }
    isAgency
    isAutoApproveBlocked
    isOnlyKeyRisingAccounts
    isOnlyMatchingTags
    isOnlySuggested
    grandfatheredGMVLevel
    partnerDirectories {
      id
      partnerName
      trackingId
    }
    profileBeyond
    profileEmailIntro
    profileQuotes {
      attribution
      text
    }
    profileTagline
    profileBandwidth
    profileHourlyRate
    profileLanguages
    profilePortfolioUrl
    secondaryPublicEmails
    shouldExcludeFromKpiReports
    skillLevel
    slackId
    startedAt
  }
  ${ExpertDetail}
`;

export const TemplateSummary = gql`
  fragment TemplateSummary on Template {
    id
    createdAt
    description
    expertStr
    isQuote
    title
  }
`;

export const ProjectPrefabSummary = gql`
  fragment ProjectPrefabSummary on ProjectPrefab {
    id
    cents
    description
    internalNote
    estimatedCompletion
    priceType
    title
  }
`;

export const ProjectPrefabDetail = gql`
  fragment ProjectPrefabDetail on ProjectPrefab {
    ...ProjectPrefabSummary
    createdAt
    expertStr
    wasDeleted
  }
  ${ProjectPrefabSummary}
`;

export const ProjectPrefabDetailHuman = gql`
  fragment ProjectPrefabDetailHuman on ProjectPrefab {
    ...ProjectPrefabDetail
    expert {
      ...ExpertSummary
    }
    type
  }
  ${ProjectPrefabDetail}
  ${ExpertSummary}
`;

export const ProjectPrefabDetailHq = gql`
  fragment ProjectPrefabDetailHq on ProjectPrefab {
    ...ProjectPrefabDetailHuman
    type
    expert {
      id
      firstName
      lastName
      primaryPublicEmail
      profilePath
    }
    partner {
      id
      partnerName
      trackingId
    }
  }
  ${ProjectPrefabDetailHuman}
`;

export const DangerZoneEventDetail = gql`
  fragment DangerZoneEventDetail on DangerZoneEvent {
    id
    createdAt
    dangerZoneAdditionalDescription
    dangerZoneAssignment
    dangerZoneReason
    dangerZoneRemovalReason
    dangerZoneSnoozedUntil
    expertStr
    isSupportCase
    matchStr
    type
  }
`;

export const DangerZoneEventDetailHq = gql`
  fragment DangerZoneEventDetailHq on DangerZoneEvent {
    ...DangerZoneEventDetail
    expert {
      id
      firstName
      lastName
    }
  }
  ${DangerZoneEventDetail}
`;

export const RequestPartialTop = gql`
  fragment RequestPartialTop on Request {
    id
    adminAssigned
    brandStr
    cancelledAt
    createdAt
    currentMatchStr
    expertsSkippedReason {
      expertStr
    }
    inReview
    isActive
    isAgency
    isKeyAccount
    isHqApprove
    isMultipleMatch
    isOngoing
    isSearchAgency
    matchLog {
      matchStr
      matchedWhoStr
      unmatchedAt
      unmatchedHow
    }
    needsApproval {
      expertStr
    }
    potentialMatchesStr
    readLog {
      readByStr
    }
    readSessionClaimedByStr
    suggestedMatchesStr
    tagExperience
    tagFocus
    tagLocations
    tagSkills
    tagTools
  }
`;

export const RequestSummaryHuman = gql`
  fragment RequestSummaryHuman on Request {
    ...RequestPartialTop
    contactPreference
    contentPlainText
    matchLog {
      matchStr
      matchedWhoStr
      matchedWho {
        ...ExpertSummary
        fullName
      }
      unmatchedAt
    }
  }
  ${ExpertSummary}
  ${RequestPartialTop}
`;

export const RequestSummary = gql`
  fragment RequestSummary on Request {
    ...RequestPartialTop
    brand {
      id
      name
      url
      shopifyAdminURL
      accountTier
    }
    content
    readSessionClaimedBy {
      id
      firstName
    }
    projectPrefab {
      ...ProjectPrefabSummary
    }
  }
  ${ProjectPrefabSummary}
  ${RequestPartialTop}
`;

export const RequestSummaryHq = gql`
  fragment RequestSummaryHq on Request {
    ...RequestSummary
    ...RequestSummaryHuman
    cancelledCompleted
    cancelledHow
    cancelledReason
    waitType
  }
  ${RequestSummary}
  ${RequestSummaryHuman}
`;

export const RequestDetail = gql`
  fragment RequestDetail on Request {
    ...RequestSummaryHq
    brand {
      id
      accountTier
      categoryType
      createdAt
      estimateEmployees
      estimateRevenue
      estimateSpend
      estimateTheme
      instagramFollowers
      isShopifyPlus
      name
      url
      shopifyAdminURL
      description
      platform
      shopifyStorefrontPassword
    }
    cancelledMoreDetail
    contactPreference
    createdBy {
      id
      createdAt
      discountCode
      estimateSpend
      firstName
      lastName
      fullName
      primaryPhone
      primaryEmail
      proStart
      location {
        city
        regionCode
        timezone
      }
      partnerReferral {
        partner {
          id
          partnerName
          partnerUrl
        }
      }
    }
    files {
      id
      filename
      url
    }
    matchLog {
      matchStr
      matchedWhoStr
      matchedWho {
        ...ExpertDetailHuman
        fullName
      }
      unmatchedAt
      unmatchedHow
      unmatchedMoreDetail
      unmatchedReason
    }
    readSessionEndsAt
    readSessionExtended
    tagBudgetMax
    tagBudgetMin
    tagTimeline
    waitSlowDelay
    waitStart
  }
  ${ExpertDetailHuman}
  ${RequestSummaryHq}
`;

export const RequestDetailHq = gql`
  fragment RequestDetailHq on Request {
    ...RequestDetail
    expertsSkippedReason {
      expertStr
      expert {
        id
        firstName
        lastName
      }
      reason
    }
    needsApproval {
      expertStr
      expert {
        id
        firstName
        lastName
      }
    }
    readLog {
      readByStr
      readBy {
        id
        firstName
        lastName
      }
    }
    suggestedMatches {
      id
      firstName
      lastName
    }
  }
  ${RequestDetail}
`;

export const AdminDetailHq = gql`
  fragment AdminDetailHq on Expert {
    id
    createdAt
    blockAdminAccess
    firstName
    lastName
    photo
    primaryPublicEmail
    primaryPersonalEmail
    secondaryPersonalEmails
    status
  }
`;

export const HumanSummary = gql`
  fragment HumanSummary on Human {
    id
    clientFee
    discountCode
    estimateSpend
    expertReferralStr
    firstName
    lastName
    primaryPhone
    primaryEmail
    proStart
    secondaryEmails
  }
`;

export const HumanDetail = gql`
  fragment HumanDetail on Human {
    ...HumanSummary
    createdAt
    cardBrand
    cardLast4
    leadStr
    location {
      timezone
    }
    migratedToHumanStr
    paymentMethodId
    pendingPhone
    secondaryPhones
    surveyFba
  }
  ${HumanSummary}
`;

export const HumanDetailHq = gql`
  fragment HumanDetailHq on Human {
    ...HumanDetail
    isAgency
    isDelinquent
    isVerified
    location {
      city
      regionCode
      timezone
    }
    partnerReferral {
      campaign
      isSoft
      medium
      partner {
        id
        partnerName
      }
      ticket
    }
    proSubscriptionId
    shouldExcludeFromKpiReports
  }
  ${HumanDetail}
`;

export const BrandSummary = gql`
  fragment BrandSummary on Brand {
    id
    createdAt
    businessInfo {
      addressLineOne
      addressLineTwo
      legalName
      vatNumber
    }
    invoicingEnabled
    name
    shopifyAdminURL
    shopifyStorefrontPassword
    teamStr
    url
  }
`;

export const BrandDetail = gql`
  fragment BrandDetail on Brand {
    ...BrandSummary
    categoryType
    description
    estimateEmployees
    estimateRevenue
    estimateSpend
    estimateTheme
    ignoreHumansStr
    ignoreHumans {
      id
      firstName
      lastName
      primaryPhone
      primaryEmail
    }
    industryTag
    instagramFollowers
    isShopifyPlus
    teamLeader {
      ...HumanSummary
    }
    team {
      ...HumanSummary
      location {
        city
        regionCode
        timezone
      }
      partnerReferral {
        partner {
          id
          partnerName
          partnerUrl
        }
      }
    }
  }
  ${BrandSummary}
  ${HumanSummary}
`;

export const BrandDetailHq = gql`
  fragment BrandDetailHq on Brand {
    ...BrandDetail
    accountTier
    adminMatch {
      id
      adminAssigned
      expertUnread
      expertSnoozedUntil
    }
    isKeyAccount
    isDelicate
    platform
    shouldExcludeFromKpiReports
  }
  ${BrandDetail}
`;

export const AccountBalanceEventDetail = gql`
  fragment AccountBalanceEventDetail on AccountBalanceEvent {
    id
    createdAt
    action
    amount
    brand {
      id
      name
    }
    ownerExpertStr
    ownerPartnerStr
    paymentRailsPaymentID
    paymentRailsPaymentStatus
    project {
      id
      quote {
        id
        title
      }
    }
    reason
  }
`;

export const PartnerSummary = gql`
  fragment PartnerSummary on Partner {
    id
    partnerName
    trackingId
  }
`;

export const PartnerDetail = gql`
  fragment PartnerDetail on Partner {
    ...PartnerSummary
    createdAt
    affiliateId
    allPurchasesEstimate
    contactEmail
    contactName
    grandfatheredGMVLevel
    gmvTotalEstimate
    isAlwaysDiscount
    isNoCommission
    levelEstimate
    partnerLogo
    partnerType
    partnerUrl
    recipientId
    rfsnId
    secondaryEmails
    shouldExcludeFromKpiReports
    stAmbassadorId
    statusNote
    tagTool
    uniqueActiveProjectsEstimate
    uniqueLeadsEstimate
    uniquePurchasesEstimate
    uniqueReferredHumansEstimate
    uniqueSignupsEstimate
  }
  ${PartnerSummary}
`;

export const ProjectPartialTop = gql`
  fragment ProjectPartialTop on Project {
    id
    brandStr
    createdAt
    expertStr
    isActive
    matchStr
    quoteStr
  }
`;

export const ProjectSummary = gql`
  fragment ProjectSummary on Project {
    ...ProjectPartialTop
    quote {
      id
      createdAt
      cents
      estimatedCompletionDate
      estimatedCompletionDays
      paymentType
      status
      title
    }
  }
  ${ProjectPartialTop}
`;

export const ProjectSummaryHuman = gql`
  fragment ProjectSummaryHuman on Project {
    ...ProjectSummary
    expert {
      id
      firstName
      lastName
      photo
    }
  }
  ${ProjectSummary}
`;

export const ProjectDetail = gql`
  fragment ProjectDetail on Project {
    ...ProjectSummaryHuman
    clientFee
    discountCode
    quote {
      id
      createdAt
      cents
      description
      estimatedCompletionDate
      estimatedCompletionDays
      paymentType
      revisionsRequested
      status
      title
    }
  }
  ${ProjectSummaryHuman}
`;

export const ProjectDetailHuman = gql`
  fragment ProjectDetailHuman on Project {
    ...ProjectDetail
    autoApproveAt
    feedbackDetail
    feedbackValue
    latestAcceptCents
    quote {
      id
      createdAt
      cents
      description
      estimatedCompletionDate
      estimatedCompletionDays
      paymentType
      revisionsRequested
      status
      title
    }
    expert {
      id
      firstName
      lastName
      photo
      profileBio
    }
  }
  ${ProjectDetail}
`;

export const ProjectDetailHq = gql`
  fragment ProjectDetailHq on Project {
    ...ProjectDetailHuman
    brand {
      id
      name
    }
  }
  ${ProjectDetailHuman}
`;

export const MatchPartialTop = gql`
  fragment MatchPartialTop on Match {
    id
    brandStr
    dangerZoneEventExpertStr
    dangerZoneProjectsStr
    expertStr
    expertUnread
    expertReadAt
    isBlocked
  }
`;

export const MatchPartialTopHq = gql`
  fragment MatchPartialTopHq on Match {
    ...MatchPartialTop
    adminAssigned
    dangerZoneEventStr
    dangerZoneSnoozedUntil
    expertSnoozedUntil
  }
  ${MatchPartialTop}
`;

export const MatchSummary = gql`
  fragment MatchSummary on Match {
    ...MatchPartialTopHq
    brandStr
    brand {
      id
      name
    }
    createdAt
    hasActiveProjectsStr
    hasActiveQuotesStr
    expertTagged
    expertPinned
    isLead
    wasBlocked
  }
  ${MatchPartialTopHq}
`;

export const MatchSummaryHq = gql`
  fragment MatchSummaryHq on Match {
    ...MatchSummary
    dangerZoneEvent {
      ...DangerZoneEventDetail
    }
    dangerZoneEventExpert {
      ...DangerZoneEventDetail
    }
    expert {
      id
      firstName
      lastName
    }
    lastUpdated
  }
  ${MatchSummary}
  ${DangerZoneEventDetail}
`;

export const MatchSummaryHuman = gql`
  fragment MatchSummaryHuman on Match {
    ...MatchSummaryHq
    expert {
      ...ExpertSummary
    }
  }
  ${MatchSummaryHq}
  ${ExpertSummary}
`;

export const MatchActionSummary = gql`
  fragment MatchActionSummary on Match {
    ...MatchSummaryHuman
    hasActiveProjects {
      id
      isActive
      quote {
        id
        createdAt
        cents
        estimatedCompletionDate
        estimatedCompletionDays
        paymentType
        revisionsRequested
        status
        title
      }
    }
    hasActiveQuotes {
      id
      isActive
      quote {
        id
        createdAt
        cents
        estimatedCompletionDate
        estimatedCompletionDays
        paymentType
        revisionsRequested
        status
        title
      }
    }
    leadCloseTimeline
  }
  ${MatchSummaryHuman}
`;

export const MatchActionSummaryWithBrand = gql`
  fragment MatchActionSummaryWithBrand on Match {
    ...MatchActionSummary
    brand {
      id
      name
      teamLeader {
        id
        firstName
        lastName
        primaryPhone
        primaryEmail
      }
    }
  }
  ${MatchActionSummary}
`;

export const MatchDetail = gql`
  fragment MatchDetail on Match {
    ...MatchActionSummaryWithBrand
    brand {
      ...BrandDetail
    }
    expert {
      id
      firstName
      lastName
      primaryPublicEmail
      profileBio
    }
    excludeFromExpertPerformanceMetrics
    expertSnoozedDetail
    hasActiveProjects {
      id
      isActive
      clientFee
      discountCode
      quote {
        id
        createdAt
        cents
        description
        estimatedCompletionDate
        estimatedCompletionDays
        paymentType
        revisionsRequested
        status
        title
      }
    }
    hasActiveQuotes {
      id
      isActive
      clientFee
      discountCode
      quote {
        id
        createdAt
        cents
        description
        estimatedCompletionDate
        estimatedCompletionDays
        paymentType
        revisionsRequested
        status
        title
      }
    }
    leadRequest {
      id
      cancelledAt
      currentMatchStr
      inReview
      isActive
      isMultipleMatch
      matchLog {
        matchStr
        matchedWhoStr
        unmatchedAt
      }
      createdAt
    }
  }
  ${MatchActionSummaryWithBrand}
  ${BrandDetail}
`;

export const OrphanPartialTop = gql`
  fragment OrphanPartialTop on Orphan {
    id
    adminAssigned
    expertStr
    expertUnread
    isArchivedByExpert
    migratedToMatchStr
  }
`;

export const OrphanSummary = gql`
  fragment OrphanSummary on Orphan {
    ...OrphanPartialTop
    createdAt
    humans {
      id
      firstName
      lastName
      primaryEmail
      primaryPhone
    }
    lastThreadEvent {
      id
      plainTextContent
      plainTextSubject
    }
    lastUpdated
  }
  ${OrphanPartialTop}
`;

export const OrphanDetail = gql`
  fragment OrphanDetail on Orphan {
    ...OrphanSummary
    expertReadAt
  }
  ${OrphanSummary}
`;

export const MeetingPartialTop = gql`
  fragment MeetingPartialTop on Meeting {
    id
  }
`;

export const MeetingSummary = gql`
  fragment MeetingSummary on Meeting {
    ...MeetingPartialTop
    createdAt
    eventEnd
    eventStart
    eventAllDay
    eventStatus
    eventTimezone
    eventTitle
    expertResponse
    matchStr
    organizerExpert {
      ...ExpertPartialTop
    }
    organizerHuman {
      id
      firstName
      lastName
      primaryEmail
      primaryPhone
    }
    orphanStr
    participantExpert {
      ...ExpertPartialTop
    }
    participantHumansStatus {
      status
      humanStr
    }
    supportChannelStr
  }
  ${ExpertPartialTop}
  ${MeetingPartialTop}
`;

export const MeetingDetail = gql`
  fragment MeetingDetail on Meeting {
    ...MeetingSummary
    eventLocation
    eventNotes
    participantHumansStatus {
      status
      humanStr
      human {
        id
        firstName
        lastName
        primaryEmail
        primaryPhone
      }
    }
    requestedByHuman
  }
  ${MeetingSummary}
`;

export const SupportChannelPartialTop = gql`
  fragment SupportChannelPartialTop on SupportChannel {
    id
    adminAssigned
    adminSnoozedUntil
    adminUnread
    expertStr
    expertUnread
  }
`;

export const SupportChannelSummary = gql`
  fragment SupportChannelSummary on SupportChannel {
    ...SupportChannelPartialTop
    expert {
      ...ExpertSummary
    }
    lastUpdated
  }
  ${ExpertSummary}
  ${SupportChannelPartialTop}
`;

export const SupportChannelDetail = gql`
  fragment SupportChannelDetail on SupportChannel {
    ...SupportChannelSummary
    expertReadAt
  }
  ${SupportChannelSummary}
`;

export const FileUploadDetail = gql`
  fragment FileUploadDetail on FileUpload {
    id
    createdAt
    filename
    isHiddenByExpert
    isInlineEmail
    matchStr
    orphanStr
    supportChannelStr
    url
  }
`;

export const QuoteDetail = gql`
  fragment QuoteDetail on Quote {
    id
    createdAt
    cents
    description
    estimatedCompletionDate
    estimatedCompletionDays
    matchStr
    ownerExpert {
      ...ExpertPartialTop
    }
    ownerHuman {
      id
      firstName
      lastName
      primaryEmail
      primaryPhone
    }
    paymentType
    revisionsRequested
    status
    threadEvent
    title
  }
  ${ExpertPartialTop}
`;

export const TextMessageEventDetail = gql`
  fragment TextMessageEventDetail on TextMessageEvent {
    id
    createdAt
    content
    files {
      ...FileUploadDetail
    }
    isTransactional
    matchStr
    orphanStr
    ownerExpert {
      ...ExpertPartialTop
    }
    ownerHuman {
      id
      firstName
      lastName
      primaryEmail
      primaryPhone
    }
    quote {
      ...QuoteDetail
    }
    recipientExpert {
      ...ExpertPartialTop
    }
    recipientHumans {
      id
      firstName
      lastName
      primaryPhone
      primaryEmail
    }
    sendingError
    supportChannelStr
    threadEvent
  }
  ${FileUploadDetail}
  ${QuoteDetail}
  ${ExpertPartialTop}
`;

export const EmailMessageEventDetail = gql`
  fragment EmailMessageEventDetail on EmailMessageEvent {
    id
    createdAt
    content
    contentExpanded
    files {
      ...FileUploadDetail
    }
    isTransactional
    matchStr
    noExpertRecipientsFound
    orphanStr
    ownerExpert {
      ...ExpertPartialTop
    }
    ownerHuman {
      id
      firstName
      lastName
      primaryEmail
      primaryPhone
    }
    quote {
      ...QuoteDetail
    }
    recipientCopiedExperts {
      ...ExpertPartialTop
    }
    recipientExpert {
      ...ExpertPartialTop
    }
    recipientHumans {
      id
      firstName
      lastName
      primaryPhone
      primaryEmail
    }
    sendingError
    sendingStatus
    subject
    supportChannelStr
    threadEvent
  }
  ${FileUploadDetail}
  ${QuoteDetail}
  ${ExpertPartialTop}
`;

export const MeetingEventDetail = gql`
  fragment MeetingEventDetail on MeetingEvent {
    id
    createdAt
    action
    matchStr
    meeting {
      ...MeetingDetail
    }
    orphanStr
    ownerExpert {
      ...ExpertPartialTop
    }
    ownerHuman {
      id
      firstName
      lastName
      primaryPhone
      primaryEmail
    }
    recipientExpert {
      ...ExpertPartialTop
    }
    recipientHumans {
      id
      firstName
      lastName
      primaryPhone
      primaryEmail
    }
    response
    supportChannelStr
    threadEvent
  }
  ${MeetingDetail}
  ${ExpertPartialTop}
`;

export const RequestEventDetail = gql`
  fragment RequestEventDetail on RequestEvent {
    id
    createdAt
    content
    files {
      ...FileUploadDetail
    }
    matchStr
    ownerHuman {
      id
      firstName
      lastName
      primaryEmail
      primaryPhone
    }
    projectPrefab {
      ...ProjectPrefabSummary
    }
    tagBudgetMax
    tagBudgetMin
    tagFocus
    tagLocations
    tagSkills
    tagTimeline
    tagTools
    threadEvent
  }
  ${FileUploadDetail}
  ${ProjectPrefabSummary}
`;

export const PhoneCallEventDetail = gql`
  fragment PhoneCallEventDetail on PhoneCallEvent {
    id
    createdAt
    matchStr
    orphanStr
    ownerExpert {
      ...ExpertPartialTop
    }
    ownerHuman {
      id
      firstName
      lastName
      primaryEmail
      primaryPhone
    }
    recipientExpert {
      ...ExpertPartialTop
    }
    recipientHumans {
      id
      firstName
      lastName
      primaryPhone
      primaryEmail
    }
    resultStatus
    supportChannelStr
    threadEvent
    voicemailRecordingUrl
  }
  ${ExpertPartialTop}
`;

export const MessageEventDetail = gql`
  fragment MessageEventDetail on MessageEvent {
    id
    createdAt
    content
    matchStr
    orphanStr
    ownerExpert {
      ...ExpertPartialTop
    }
    ownerHuman {
      id
      firstName
      lastName
      primaryEmail
      primaryPhone
    }
    recipientExpert {
      ...ExpertPartialTop
    }
    recipientHumans {
      id
      firstName
      lastName
      primaryEmail
      primaryPhone
    }
    supportChannelStr
    threadEvent
  }
  ${ExpertPartialTop}
`;

export const UserNoteDetail = gql`
  fragment UserNoteDetail on UserNote {
    id
    createdAt
    author {
      id
      firstName
      lastName
    }
    body
    humanStr
    matchStr
    supportChannelStr
  }
`;
