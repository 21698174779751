/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import moment from 'moment-timezone';
import copyToClipboard from 'clipboard-copy';
import TextareaAutosize from 'react-autosize-textarea';
import { convertToRaw, ContentState } from 'draft-js';
import { useState, useEffect, Fragment, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  useQuery,
  gql,
  useMutation,
  ApolloError,
  useLazyQuery,
} from '@apollo/client';
import PropTypes from 'prop-types';
import '../../styles/page/ExpertProfileView.scss';
import logError from '../../utils/airbrake';
import RichTextEditor from '../feature/RichTextEditor';
import CalendarSchedule from '../feature/CalendarSchedule';
import errorUtils from '../../utils/error';
import envUtils from '../../utils/env';
import urlUtils from '../../utils/url';
import { GlobalNotificationContext } from '../context/GlobalNotification';
import {
  ExpertDetail,
  ProjectPrefabDetail,
  TemplateSummary,
  AccountBalanceEventDetail,
} from '../../utils/gql';
import ExpertUserBubble from '../feature/ExpertUserBubble';
import PhoneNumberInput from '../feature/PhoneNumberInput';
import MultiSelect from '../feature/MultiSelect';
import {
  formatValidPhoneNumber,
  kpiPercentage,
  kpiDollars,
  kpiRating,
  getExpertLevelStr,
  getNumericValue,
  formatNumberWithCommas,
  centsDollarsRounded,
  centsDollarsDecimal,
  tagLabelSkill,
  tagLabelTool,
  tagLabelProblem,
} from '../../utils/format';
import {
  KPI_GOALS,
  SKILL_OPTIONS,
  TOOL_OPTIONS,
  QUOTE_TEMPLATE,
  PROBLEM_OPTIONS,
  INDUSTRY_OPTIONS,
} from '../../utils/constants';
import nylasUtils from '../../utils/nylas';
import templateUtils from '../../utils/template';
import { IDaySchedule, IPastClients } from '../../utils/props';
import {
  TemplatesQuery,
  ExpertDetailsFullQuery,
  ExpertDetailsFullQueryVariables,
  ExpertCalendarChoicesQuery,
  ExpertCalendarChoicesQueryVariables,
  PrefabByExpertQuery,
  AccountBalanceEventPaginateQuery,
  AccountBalanceEventPaginateQueryVariables,
  ProjectPaginateFeedbackQuery,
  ProjectPaginateFeedbackQueryVariables,
  AccountBalanceDetailsQuery,
  ExpertEditContactDetailsMutation,
  ExpertEditContactDetailsMutationVariables,
  ExpertEditTagNotificationsMutation,
  ExpertEditTagNotificationsMutationVariables,
  PrefabCreateMutation,
  PrefabCreateMutationVariables,
  PrefabEditMutation,
  PrefabEditMutationVariables,
  PrefabDeleteMutation,
  PrefabDeleteMutationVariables,
  TemplateCreateMutation,
  TemplateCreateMutationVariables,
  TemplateEditMutation,
  TemplateEditMutationVariables,
  TemplateDeleteMutation,
  TemplateDeleteMutationVariables,
  AccountBalanceCashoutForExpertMutation,
  ExpertSelectCalendarMutation,
  ExpertSelectCalendarMutationVariables,
  ExpertEditCalendarAvailabilityMutation,
  ExpertEditCalendarAvailabilityMutationVariables,
  ExpertEnableDisableSchedulingLinkMutation,
  ExpertEnableDisableSchedulingLinkMutationVariables,
  ExpertEditCalendarSettingsMutation,
  ExpertEditCalendarSettingsMutationVariables,
  ExpertRemoveCalendarMutation,
  ExpertEditPastClientsMutation,
  ExpertEditPastClientsMutationVariables,
} from '../../gql/graphql';
import logo from '../../images/storetasker-logo-black.png';
import ReactTooltip from 'react-tooltip';

const expertDetailsFullQuery = gql`
  query ExpertDetailsFull($expertId: ID!) {
    expertDetails(expertId: $expertId) {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;

const prefabByExpertQuery = gql`
  query PrefabByExpert {
    prefabByExpert {
      ...ProjectPrefabDetail
    }
  }
  ${ProjectPrefabDetail}
`;

const accountBalanceDetailsQuery = gql`
  query AccountBalanceDetails {
    accountBalanceDetails {
      id
      accountIsActive
      accountNeedsTaxReview
      balance
      estimatedFees
      deliveryMethod
    }
  }
`;

const accountBalanceEventPaginateQuery = gql`
  query AccountBalanceEventPaginate(
    $direction: String!
    $limit: Int!
    $fromDate: Date
  ) {
    accountBalanceEventPaginate(
      direction: $direction
      limit: $limit
      fromDate: $fromDate
    ) {
      ...AccountBalanceEventDetail
    }
  }
  ${AccountBalanceEventDetail}
`;
const projectPaginateFeedbackQuery = gql`
  query ProjectPaginateFeedback(
    $direction: String!
    $limit: Int!
    $fromDate: Date
    $expertId: ID!
  ) {
    projectPaginateFeedback(
      direction: $direction
      limit: $limit
      fromDate: $fromDate
      expertId: $expertId
    ) {
      id
      createdAt
      brand {
        id
        name
        url
        shopifyAdminURL
      }
      expertStr
      feedbackDetail
      feedbackValue
      matchStr
      quote {
        id
        title
      }
    }
  }
`;
const expertCalendarChoicesQuery = gql`
  query ExpertCalendarChoices($accessToken: String!) {
    expertCalendarChoices(accessToken: $accessToken) {
      id
      name
    }
  }
`;
const expertEnableDisableSchedulingLinkMutation = gql`
  mutation ExpertEnableDisableSchedulingLink($doEnable: Boolean!) {
    expertEnableDisableSchedulingLink(doEnable: $doEnable) {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;
const expertEditCalendarSettingsMutation = gql`
  mutation ExpertEditCalendarSettings(
    $locationTimezone: String!
    $defaultLocation: String
    $defaultDescription: String
  ) {
    expertEditCalendarSettings(
      locationTimezone: $locationTimezone
      defaultLocation: $defaultLocation
      defaultDescription: $defaultDescription
    ) {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;
const expertEditCalendarAvailabilityMutation = gql`
  mutation ExpertEditCalendarAvailability($input: AvailabilityInput) {
    expertEditCalendarAvailability(input: $input) {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;
const expertSelectCalendarMutation = gql`
  mutation ExpertSelectCalendar($calendarId: String!) {
    expertSelectCalendar(calendarId: $calendarId) {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;
const expertRemoveCalendarMutation = gql`
  mutation ExpertRemoveCalendar {
    expertRemoveCalendar {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;
const accountBalanceCashoutForExpertMutation = gql`
  mutation AccountBalanceCashoutForExpert {
    accountBalanceCashoutForExpert {
      ...AccountBalanceEventDetail
    }
  }
  ${AccountBalanceEventDetail}
`;
const expertEditContactDetailsMutation = gql`
  mutation ExpertEditContactDetails(
    $notificationDelay: String
    $notificationMedium: String
    $notificationSounds: String
    $allowIncomingCalls: String
    $primaryEmail: String!
    $primaryPhone: String
    $secondaryEmails: [String!]!
    $profileStartingProject: Int
    $profileStartingRetainer: Int
    $profileHourlyRate: Int
  ) {
    expertEditContactDetails(
      notificationDelay: $notificationDelay
      notificationMedium: $notificationMedium
      notificationSounds: $notificationSounds
      allowIncomingCalls: $allowIncomingCalls
      primaryEmail: $primaryEmail
      primaryPhone: $primaryPhone
      secondaryEmails: $secondaryEmails
      profileStartingProject: $profileStartingProject
      profileStartingRetainer: $profileStartingRetainer
      profileHourlyRate: $profileHourlyRate
    ) {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;
const expertEditTagNotificationsMutation = gql`
  mutation ExpertEditTagNotifications(
    $tagNotifyEmail: [String!]!
    $tagNotifySlack: [String!]!
    $tagNotifyText: [String!]!
    $tagProblems: [String!]
    $tagSkills: [String!]!
    $tagTools: [String!]!
  ) {
    expertEditTagNotifications(
      tagNotifyEmail: $tagNotifyEmail
      tagNotifySlack: $tagNotifySlack
      tagNotifyText: $tagNotifyText
      tagProblems: $tagProblems
      tagSkills: $tagSkills
      tagTools: $tagTools
    ) {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;
const expertEditPastClientsMutation = gql`
  mutation ExpertEditPastClients($pastClients: [PastClientInput!]!) {
    expertEditPastClients(pastClients: $pastClients) {
      ...ExpertDetail
    }
  }
  ${ExpertDetail}
`;
const prefabCreateMutation = gql`
  mutation PrefabCreate(
    $title: String!
    $description: String!
    $descriptionPlainText: String!
    $priceType: String!
    $cents: Int!
    $estimatedCompletion: Int!
  ) {
    prefabCreate(
      title: $title
      description: $description
      descriptionPlainText: $descriptionPlainText
      priceType: $priceType
      cents: $cents
      estimatedCompletion: $estimatedCompletion
    ) {
      ...ProjectPrefabDetail
    }
  }
  ${ProjectPrefabDetail}
`;
const prefabEditMutation = gql`
  mutation PrefabEdit(
    $prefabId: ID!
    $title: String!
    $description: String!
    $descriptionPlainText: String!
    $priceType: String!
    $cents: Int!
    $estimatedCompletion: Int!
  ) {
    prefabEdit(
      prefabId: $prefabId
      title: $title
      description: $description
      descriptionPlainText: $descriptionPlainText
      priceType: $priceType
      cents: $cents
      estimatedCompletion: $estimatedCompletion
    ) {
      ...ProjectPrefabDetail
    }
  }
  ${ProjectPrefabDetail}
`;
const prefabDeleteMutation = gql`
  mutation PrefabDelete($prefabId: ID!) {
    prefabDelete(prefabId: $prefabId) {
      ...ProjectPrefabDetail
    }
  }
  ${ProjectPrefabDetail}
`;
const templateCreateMutation = gql`
  mutation TemplateCreate(
    $title: String!
    $description: String!
    $isQuote: Boolean!
  ) {
    templateCreate(
      title: $title
      description: $description
      isQuote: $isQuote
    ) {
      ...TemplateSummary
    }
  }
  ${TemplateSummary}
`;
const templateEditMutation = gql`
  mutation TemplateEdit(
    $templateId: ID!
    $title: String!
    $description: String!
  ) {
    templateEdit(
      templateId: $templateId
      title: $title
      description: $description
    ) {
      ...TemplateSummary
    }
  }
  ${TemplateSummary}
`;
const templateDeleteMutation = gql`
  mutation TemplateDelete($templateId: ID!) {
    templateDelete(templateId: $templateId)
  }
`;

interface ExpertProfileViewProps {
  emailTemplates: TemplatesQuery['templates'];
  expertId: string;
  quoteTemplates: TemplatesQuery['templates'];
  refetchTemplates: () => void;
}

let sentCodeOnce = false;

function startingTab() {
  if (urlUtils.getQueryParam('code')) return 'calendar';
  if (urlUtils.getQueryParam('calendar')) return 'calendar';
  if (urlUtils.getQueryParam('signature')) return 'profile';
  if (urlUtils.getQueryParam('skills')) return 'skills';
  if (urlUtils.getQueryParam('templates')) return 'templates';
  return 'profile';
}

const PAGE_LIMIT = 10;

const ExpertProfileView = ({
  emailTemplates,
  expertId,
  quoteTemplates,
  refetchTemplates,
}: ExpertProfileViewProps) => {
  const { addNotification } = useContext(GlobalNotificationContext);
  const [initDate] = useState(() => new Date());
  const [actionLoading, setActionLoading] = useState(false);
  const [actionClientLoading, setActionClientLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(() => startingTab());
  const [personalPhone, setPersonalPhone] = useState('');
  const [personalPhoneValid, setPersonalPhoneValid] = useState(true);
  const [personalEmail, setPersonalEmail] = useState('');
  const [secondaryEmails, setSecondaryEmails] = useState([] as string[]);
  const [tagNotifyEmail, setTagNotifyEmail] = useState([] as string[]);
  const [tagNotifySlack, setTagNotifySlack] = useState([] as string[]);
  const [tagNotifyText, setTagNotifyText] = useState([] as string[]);
  const [tagProblems, setTagProblems] = useState([] as string[]);
  const [pastClients, setPastClients] = useState([] as IPastClients[]);
  const [tagSkills, setTagSkills] = useState([] as string[]);
  const [tagTools, setTagTools] = useState([] as string[]);
  const [notificationDelay, setNotificationDelay] = useState('10_MIN');
  const [notificationMedium, setNotificationMedium] = useState('EMAIL');
  const [notificationSounds, setNotificationSounds] = useState('NONE');
  const [allowIncomingCalls, setAllowIncomingCalls] = useState('CLIENTS');
  const [
    editExpertProfileStartingProject,
    setEditExpertProfileStartingProject,
  ] = useState(0);
  const [
    editExpertProfileStartingRetainer,
    setEditExpertProfileStartingRetainer,
  ] = useState(0);
  const [editExpertProfileHourlyRate, setEditExpertProfileHourlyRate] =
    useState(0);
  const [expertCalendarTimezone, setExpertCalendarTimezone] = useState('');
  const [expertCalendarDefaultLocation, setExpertCalendarDefaultLocation] =
    useState('');
  const [
    expertCalendarDefaultDescription,
    setExpertCalendarDefaultDescription,
  ] = useState('');
  const [expertCalendarLocationChoice, setExpertCalendarLocationChoice] =
    useState('EXPERT_CHOICE');
  const [expertCalendarEditAvailability, setExpertCalendarEditAvailability] =
    useState(false);
  const [
    expertCalendarLoadingAvailability,
    setExpertCalendarLoadingAvailability,
  ] = useState(false);
  const [expertCalendarDayMonday, setExpertCalendarDayMonday] = useState<
    IDaySchedule[]
  >([]);
  const [expertCalendarDayTuesday, setExpertCalendarDayTuesday] = useState<
    IDaySchedule[]
  >([]);
  const [expertCalendarDayWednesday, setExpertCalendarDayWednesday] = useState<
    IDaySchedule[]
  >([]);
  const [expertCalendarDayThursday, setExpertCalendarDayThursday] = useState<
    IDaySchedule[]
  >([]);
  const [expertCalendarDayFriday, setExpertCalendarDayFriday] = useState<
    IDaySchedule[]
  >([]);
  const [expertCalendarDaySaturday, setExpertCalendarDaySaturday] = useState<
    IDaySchedule[]
  >([]);
  const [expertCalendarDaySunday, setExpertCalendarDaySunday] = useState<
    IDaySchedule[]
  >([]);
  const [editPrefab, setEditPrefab] = useState('');
  const [prefabInitDesc, setPrefabInitDesc] = useState('');
  const [prefabTitle, setPrefabTitle] = useState('');
  const [editPastClient, setEditPastClient] = useState(-1);
  const [editTemplate, setEditTemplate] = useState('');
  const [templateInitDesc, setTemplateInitDesc] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');
  const [templateIsQuote, setTemplateIsQuote] = useState(false);
  const [tempAccessToken, setTempAccessToken] = useState('');
  const [connectingNylasCode, setConnectingNylasCode] = useState(
    () => urlUtils.getQueryParam('code') || '',
  );
  const [prefabDescription, setPrefabDescription] =
    useState<ContentState | null>(null);
  const [templateDescription, setTemplateDescription] =
    useState<ContentState | null>(null);
  const [prefabDays, setPrefabDays] = useState('');
  const [prefabPriceType, setPrefabPriceType] = useState('');
  const [prefabPriceValue, setPrefabPriceValue] = useState('');
  const [tempPrefabs, setTempPrefabs] = useState(
    [] as PrefabByExpertQuery['prefabByExpert'],
  );
  const [refreshingCalendarList, setRefreshingCalendarList] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [showLoadMoreFeedbacks, setShowLoadMoreFeedbacks] = useState(true);
  const [showCashoutModal, setShowCashoutModal] = useState(false);
  const [tempBalanceEvents, setTempBalanceEvents] = useState(
    [] as AccountBalanceEventPaginateQuery['accountBalanceEventPaginate'],
  );
  const timezones = useMemo(
    () =>
      moment.tz
        .names()
        .map((tz) => ({ offset: moment.tz(tz).utcOffset(), tz }))
        .sort((a, b) => a.offset - b.offset),
    [],
  );
  const priceVal =
    Math.round(parseFloat((prefabPriceValue || '').replace(/\$/gi, '')) || 0) *
    100;
  const timelineVal = Math.round(parseFloat(prefabDays || '') || 0);
  const {
    data: dataExpertDetails,
    error: errorExpertDetails,
    loading: loadingExpertDetails,
  } = useQuery<ExpertDetailsFullQuery, ExpertDetailsFullQueryVariables>(
    expertDetailsFullQuery,
    {
      onCompleted: ({ expertDetails: expertDetailsCompleted }) => {
        if (expertDetailsCompleted) {
          setPersonalPhone(expertDetailsCompleted.primaryPersonalPhone || '');
          setPersonalEmail(expertDetailsCompleted.primaryPersonalEmail || '');
          setSecondaryEmails(
            (expertDetailsCompleted.secondaryPersonalEmails || []).filter(
              (se) => se !== expertDetailsCompleted.primaryPersonalEmail,
            ),
          );
          setTagNotifyEmail(expertDetailsCompleted.tagNotifyEmail || []);
          setTagNotifySlack(expertDetailsCompleted.tagNotifySlack || []);
          setTagNotifyText(expertDetailsCompleted.tagNotifyText || []);
          setTagProblems(expertDetailsCompleted.tagProblems || []);
          setPastClients(expertDetailsCompleted.pastClients || []);
          setTagSkills(expertDetailsCompleted.tagSkills || []);
          setTagTools(expertDetailsCompleted.tagTools || []);
          setNotificationDelay(
            expertDetailsCompleted.notificationDelay || '10_MIN',
          );
          setNotificationMedium(
            expertDetailsCompleted.notificationMedium || 'EMAIL',
          );
          setNotificationSounds(
            expertDetailsCompleted.notificationSounds || 'NONE',
          );
          setAllowIncomingCalls(
            expertDetailsCompleted.allowIncomingCalls || 'CLIENTS',
          );
          setEditExpertProfileStartingProject(
            expertDetailsCompleted.profileStartingProject || 0,
          );
          setEditExpertProfileStartingRetainer(
            expertDetailsCompleted.profileStartingRetainer || 0,
          );
          setEditExpertProfileHourlyRate(
            expertDetailsCompleted.profileHourlyRate || 0,
          );
          setExpertCalendarTimezone(
            expertDetailsCompleted.locationTimezone || '',
          );
          setExpertCalendarDefaultLocation(
            expertDetailsCompleted.scheduleTimeDefaultLocation || '',
          );
          setExpertCalendarDefaultDescription(
            expertDetailsCompleted.scheduleTimeDefaultDescription || '',
          );
          setExpertCalendarLocationChoice(
            expertDetailsCompleted.scheduleTimeDefaultLocation
              ? 'EXPERT_CHOICE'
              : 'CLIENT_CHOICE',
          );
          setExpertCalendarDayMonday(
            expertDetailsCompleted.scheduleTimeDayMonday || [],
          );
          setExpertCalendarDayTuesday(
            expertDetailsCompleted.scheduleTimeDayTuesday || [],
          );
          setExpertCalendarDayWednesday(
            expertDetailsCompleted.scheduleTimeDayWednesday || [],
          );
          setExpertCalendarDayThursday(
            expertDetailsCompleted.scheduleTimeDayThursday || [],
          );
          setExpertCalendarDayFriday(
            expertDetailsCompleted.scheduleTimeDayFriday || [],
          );
          setExpertCalendarDaySaturday(
            expertDetailsCompleted.scheduleTimeDaySaturday || [],
          );
          setExpertCalendarDaySunday(
            expertDetailsCompleted.scheduleTimeDaySunday || [],
          );
        }
      },
      returnPartialData: true,
      variables: {
        expertId,
      },
    },
  );
  let fullErrorCover = '';
  if (errorExpertDetails) {
    fullErrorCover =
      errorUtils.getErrorMessage(errorExpertDetails) ||
      'Could not load expert details';
  }
  const expertDetails =
    dataExpertDetails &&
    dataExpertDetails.expertDetails &&
    dataExpertDetails.expertDetails.id
      ? dataExpertDetails.expertDetails
      : undefined;
  const expertDetailsId = expertDetails?.id || '';
  useEffect(() => {
    setTimeout(() => ReactTooltip.rebuild(), 500);
  }, [currentTab, expertDetailsId, editPastClient]);
  const {
    data: dataPrefabs,
    error: errorPrefabs,
    loading: loadingPrefabs,
  } = useQuery<PrefabByExpertQuery>(prefabByExpertQuery);
  const [
    getAccountDetails,
    {
      data: dataAccountDetails,
      error: errorAccountDetails,
      loading: loadingAccountDetails,
      refetch: refetchAccountDetails,
    },
  ] = useLazyQuery<AccountBalanceDetailsQuery>(accountBalanceDetailsQuery);
  const {
    data: dataAccountBalanceEventPaginated,
    error: errorAccountBalanceEventPaginated,
    loading: loadingAccountBalanceEventPaginated,
    fetchMore: fetchMoreAccountBalanceEventPaginated,
  } = useQuery<
    AccountBalanceEventPaginateQuery,
    AccountBalanceEventPaginateQueryVariables
  >(accountBalanceEventPaginateQuery, {
    variables: {
      direction: 'BACKWARD',
      fromDate: initDate.getTime(),
      limit: PAGE_LIMIT,
    },
  });
  const [
    getFeedbacks,
    {
      called: calledFeedbacks,
      data: dataFeedbacks,
      error: errorFeedbacks,
      loading: loadingFeedbacks,
      fetchMore: fetchMoreFeedbacks,
    },
  ] = useLazyQuery<
    ProjectPaginateFeedbackQuery,
    ProjectPaginateFeedbackQueryVariables
  >(projectPaginateFeedbackQuery, {
    returnPartialData: true,
    variables: {
      direction: 'BACKWARD',
      expertId,
      fromDate: initDate.getTime(),
      limit: PAGE_LIMIT,
    },
  });
  const [tryCreatePrefab] = useMutation<
    PrefabCreateMutation,
    PrefabCreateMutationVariables
  >(prefabCreateMutation);
  const [tryEditPrefab] = useMutation<
    PrefabEditMutation,
    PrefabEditMutationVariables
  >(prefabEditMutation);
  const [tryDeletePrefab] = useMutation<
    PrefabDeleteMutation,
    PrefabDeleteMutationVariables
  >(prefabDeleteMutation);
  const [tryCreateTemplate] = useMutation<
    TemplateCreateMutation,
    TemplateCreateMutationVariables
  >(templateCreateMutation);
  const [tryEditTemplate] = useMutation<
    TemplateEditMutation,
    TemplateEditMutationVariables
  >(templateEditMutation);
  const [tryDeleteTemplate] = useMutation<
    TemplateDeleteMutation,
    TemplateDeleteMutationVariables
  >(templateDeleteMutation);
  const [tryEditExpert] = useMutation<
    ExpertEditContactDetailsMutation,
    ExpertEditContactDetailsMutationVariables
  >(expertEditContactDetailsMutation);
  const [tryEditTagNotifications] = useMutation<
    ExpertEditTagNotificationsMutation,
    ExpertEditTagNotificationsMutationVariables
  >(expertEditTagNotificationsMutation);
  const [tryEditPastClients] = useMutation<
    ExpertEditPastClientsMutation,
    ExpertEditPastClientsMutationVariables
  >(expertEditPastClientsMutation);
  const [tryCashout] = useMutation<AccountBalanceCashoutForExpertMutation>(
    accountBalanceCashoutForExpertMutation,
  );
  const [tryEnableDisableLink] = useMutation<
    ExpertEnableDisableSchedulingLinkMutation,
    ExpertEnableDisableSchedulingLinkMutationVariables
  >(expertEnableDisableSchedulingLinkMutation);
  const [tryEditLinkSettings] = useMutation<
    ExpertEditCalendarSettingsMutation,
    ExpertEditCalendarSettingsMutationVariables
  >(expertEditCalendarSettingsMutation);
  const [trySelectCalendar] = useMutation<
    ExpertSelectCalendarMutation,
    ExpertSelectCalendarMutationVariables
  >(expertSelectCalendarMutation);
  const [tryUpdateAvailability] = useMutation<
    ExpertEditCalendarAvailabilityMutation,
    ExpertEditCalendarAvailabilityMutationVariables
  >(expertEditCalendarAvailabilityMutation);
  const [tryRemoveCalendar] = useMutation<ExpertRemoveCalendarMutation>(
    expertRemoveCalendarMutation,
  );
  const expertPrefabs = _.uniqBy(
    ((dataPrefabs && dataPrefabs.prefabByExpert) || []).concat(tempPrefabs),
    'id',
  ).filter((p) => p.expertStr === expertId && !p.wasDeleted);
  const allProjectFeedbacks = _.uniqBy(
    [
      ...((calledFeedbacks &&
        dataFeedbacks &&
        dataFeedbacks.projectPaginateFeedback) ||
        []),
    ].filter((p) => p.expertStr === expertId),
    'id',
  ).sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));
  function loadMoreFeedbacks() {
    if (fetchMoreFeedbacks) {
      fetchMoreFeedbacks({
        updateQuery: (prev, { fetchMoreResult }) => {
          console.log('fetchMoreResult', fetchMoreResult);
          if (!fetchMoreResult || !fetchMoreResult.projectPaginateFeedback)
            return prev;
          if (fetchMoreResult.projectPaginateFeedback.length < PAGE_LIMIT) {
            setShowLoadMoreFeedbacks(false);
          }
          return {
            projectPaginateFeedback: (
              prev.projectPaginateFeedback || []
            ).concat(fetchMoreResult.projectPaginateFeedback),
          };
        },
        variables: {
          direction: 'BACKWARD',
          fromDate:
            allProjectFeedbacks[allProjectFeedbacks.length - 1].createdAt,
          limit: PAGE_LIMIT,
        },
      }).catch((err: ApolloError) => {
        addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
        logError(err, {
          component: 'ExpertProfileView',
          func: 'loadMoreFeedbacks',
        });
      });
    }
  }
  const accountDetails =
    dataAccountDetails && dataAccountDetails.accountBalanceDetails;
  const isCashoutAllowed =
    accountDetails &&
    accountDetails.accountIsActive &&
    !accountDetails.accountNeedsTaxReview &&
    !actionLoading &&
    accountDetails.balance >= 5000;
  let balanceInfoStr = '';
  if (accountDetails && !actionLoading) {
    balanceInfoStr = `Your current balance is $${formatNumberWithCommas(
      centsDollarsRounded(accountDetails.balance || 0),
    )}. `;
    if (accountDetails.balance < 5000) {
      balanceInfoStr += 'You need at least $50 in order to cash out. ';
    } else if (accountDetails.accountNeedsTaxReview) {
      balanceInfoStr +=
        'Before you can cash out, we need to finish reviewing your PaymentRails account (1-2 days). ';
    } else if (!accountDetails.accountIsActive) {
      balanceInfoStr +=
        'Before you can cash out, you need to fully set up your PaymentRails account. ';
    } else if (accountDetails.balance > 900000) {
      balanceInfoStr +=
        'Feel free to cash out at any time, but heads up that we only do $9k at a time.';
    } else {
      balanceInfoStr += 'Feel free to cash out at any time.';
    }
  }
  const allAccountBalanceEvents = _.uniqBy(
    [
      ...((dataAccountBalanceEventPaginated &&
        dataAccountBalanceEventPaginated.accountBalanceEventPaginate) ||
        []),
    ]
      .concat(tempBalanceEvents)
      .filter((abe) => abe.ownerExpertStr === expertId),
    'id',
  ).sort((a, b) => b.createdAt - a.createdAt);
  function loadMoreBalanceEvents() {
    fetchMoreAccountBalanceEventPaginated({
      updateQuery: (prev, { fetchMoreResult }) => {
        console.log('fetchMoreResult', fetchMoreResult);
        if (!fetchMoreResult || !fetchMoreResult.accountBalanceEventPaginate)
          return prev;
        if (fetchMoreResult.accountBalanceEventPaginate.length < PAGE_LIMIT) {
          setShowLoadMore(false);
        }
        return {
          accountBalanceEventPaginate: (
            prev.accountBalanceEventPaginate || []
          ).concat(fetchMoreResult.accountBalanceEventPaginate),
        };
      },
      variables: {
        direction: 'BACKWARD',
        fromDate:
          allAccountBalanceEvents[allAccountBalanceEvents.length - 1].createdAt,
        limit: PAGE_LIMIT,
      },
    }).catch((err: ApolloError) => {
      addNotification(errorUtils.getErrorMessage(err) || 'Load More Error');
      logError(err, {
        component: 'ExpertProfileView',
        func: 'loadMoreBalanceEvents',
      });
    });
  }
  useEffect(() => {
    if (connectingNylasCode && !sentCodeOnce) {
      sentCodeOnce = true;
      nylasUtils.sendNylasCode(
        connectingNylasCode,
        (nylasErr?: Error, nylasAccessToken?: string) => {
          setConnectingNylasCode('');
          setExpertCalendarLocationChoice('EXPERT_CHOICE');
          if (nylasAccessToken) {
            setTempAccessToken(nylasAccessToken);
          } else {
            addNotification(
              (nylasErr && nylasErr.message) ||
                'An error occured, please refresh and try again.',
              undefined,
              5000,
            );
          }
        },
      );
    }
  }, [
    setTempAccessToken,
    addNotification,
    setConnectingNylasCode,
    connectingNylasCode,
  ]);
  const theAccessToken =
    (expertDetails && expertDetails.nylasAccessToken) || tempAccessToken || '';
  const theCalendarId = (expertDetails && expertDetails.nylasCalendarId) || '';
  const {
    data: dataCalendarChoices,
    error: errorCalendarChoices,
    loading: loadingCalendarChoices,
    refetch: refetchCalendarChoices,
  } = useQuery<ExpertCalendarChoicesQuery, ExpertCalendarChoicesQueryVariables>(
    expertCalendarChoicesQuery,
    {
      skip: !theAccessToken || !!theCalendarId,
      variables: {
        accessToken: theAccessToken,
      },
    },
  );
  const expertCalendarChoices = _.uniqBy(
    [
      ...((dataCalendarChoices && dataCalendarChoices.expertCalendarChoices) ||
        []),
    ],
    'id',
  );
  function refreshCalendarList() {
    if (refetchCalendarChoices) {
      setRefreshingCalendarList(true);
      refetchCalendarChoices()
        .then(() => {
          setRefreshingCalendarList(false);
        })
        .catch((err: ApolloError) => {
          setRefreshingCalendarList(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Refresh Calendar List Error',
          );
          logError(err, {
            component: 'ExpertProfileView',
            func: 'refetchCalendarChoices',
          });
        });
    }
  }
  function selectCalendar(calendarId: string, calendarName: string) {
    if (!calendarId || !expertDetails) return;
    setExpertCalendarLocationChoice('EXPERT_CHOICE');
    trySelectCalendar({
      optimisticResponse: {
        expertSelectCalendar: {
          ...expertDetails,
          nylasCalendarId: calendarId,
          nylasCalendarName: calendarName,
          scheduleTimeDayFriday: [
            { __typename: 'TimeSchedule', end: 1020, start: 540 },
          ],
          scheduleTimeDayMonday: [
            { __typename: 'TimeSchedule', end: 1020, start: 540 },
          ],
          scheduleTimeDaySaturday: [],
          scheduleTimeDaySunday: [],
          scheduleTimeDayThursday: [
            { __typename: 'TimeSchedule', end: 1020, start: 540 },
          ],
          scheduleTimeDayTuesday: [
            { __typename: 'TimeSchedule', end: 1020, start: 540 },
          ],
          scheduleTimeDayWednesday: [
            { __typename: 'TimeSchedule', end: 1020, start: 540 },
          ],
          scheduleTimeEnabled: true,
        },
      },
      update: (_cache, { data: dataSelectMutation }) => {
        if (dataSelectMutation && dataSelectMutation.expertSelectCalendar) {
          setExpertCalendarDayMonday(
            dataSelectMutation.expertSelectCalendar.scheduleTimeDayMonday || [],
          );
          setExpertCalendarDayTuesday(
            dataSelectMutation.expertSelectCalendar.scheduleTimeDayTuesday ||
              [],
          );
          setExpertCalendarDayWednesday(
            dataSelectMutation.expertSelectCalendar.scheduleTimeDayWednesday ||
              [],
          );
          setExpertCalendarDayThursday(
            dataSelectMutation.expertSelectCalendar.scheduleTimeDayThursday ||
              [],
          );
          setExpertCalendarDayFriday(
            dataSelectMutation.expertSelectCalendar.scheduleTimeDayFriday || [],
          );
          setExpertCalendarDaySaturday(
            dataSelectMutation.expertSelectCalendar.scheduleTimeDaySaturday ||
              [],
          );
          setExpertCalendarDaySunday(
            dataSelectMutation.expertSelectCalendar.scheduleTimeDaySunday || [],
          );
          setExpertCalendarEditAvailability(true);
        }
      },
      variables: {
        calendarId,
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Select Calendar Error',
      );
      logError(err, {
        component: 'ExpertProfileView',
        func: 'trySelectCalendar',
      });
    });
  }
  function removeCalendar() {
    if (!expertDetails) return;
    setTempAccessToken('');
    tryRemoveCalendar({
      optimisticResponse: {
        expertRemoveCalendar: {
          ...expertDetails,
          nylasAccessToken: null,
          nylasAccountName: null,
          nylasCalendarId: null,
          nylasCalendarName: null,
          scheduleTimeEnabled: false,
        },
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Remove Calendar Error',
      );
      logError(err, {
        component: 'ExpertProfileView',
        func: 'tryRemoveCalendar',
      });
    });
  }
  function saveSchedulingLinkSettings() {
    if (actionLoading || !expertDetails) return;
    if (
      !expertCalendarTimezone ||
      (expertCalendarLocationChoice === 'EXPERT_CHOICE' &&
        !expertCalendarDefaultLocation.trim())
    ) {
      addNotification('Please fill out all the fields.', undefined, 5000);
      return;
    }
    setActionLoading(true);
    tryEditLinkSettings({
      optimisticResponse: {
        expertEditCalendarSettings: {
          ...expertDetails,
          locationTimezone: expertCalendarTimezone,
          scheduleTimeDefaultDescription:
            expertCalendarLocationChoice === 'EXPERT_CHOICE'
              ? expertCalendarDefaultDescription
              : '',
          scheduleTimeDefaultLocation:
            expertCalendarLocationChoice === 'EXPERT_CHOICE'
              ? expertCalendarDefaultLocation
              : '',
        },
      },
      variables: {
        defaultDescription:
          expertCalendarLocationChoice === 'EXPERT_CHOICE'
            ? expertCalendarDefaultDescription
            : '',
        defaultLocation:
          expertCalendarLocationChoice === 'EXPERT_CHOICE'
            ? expertCalendarDefaultLocation
            : '',
        locationTimezone: expertCalendarTimezone,
      },
    })
      .then(() => {
        setActionLoading(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Edit Scheduling Link Error',
        );
        logError(err, {
          component: 'ExpertProfileView',
          func: 'tryEditLinkSettings',
        });
      });
  }
  function enableDisableSchedulingLink(doEnable: boolean) {
    if (!expertDetails) return;
    setExpertCalendarLocationChoice('EXPERT_CHOICE');
    tryEnableDisableLink({
      optimisticResponse: {
        expertEnableDisableSchedulingLink: {
          ...expertDetails,
          ...(doEnable && {
            scheduleTimeDayFriday: [
              { __typename: 'TimeSchedule', end: 1020, start: 540 },
            ],
            scheduleTimeDayMonday: [
              { __typename: 'TimeSchedule', end: 1020, start: 540 },
            ],
            scheduleTimeDaySaturday: [],
            scheduleTimeDaySunday: [],
            scheduleTimeDayThursday: [
              { __typename: 'TimeSchedule', end: 1020, start: 540 },
            ],
            scheduleTimeDayTuesday: [
              { __typename: 'TimeSchedule', end: 1020, start: 540 },
            ],
            scheduleTimeDayWednesday: [
              { __typename: 'TimeSchedule', end: 1020, start: 540 },
            ],
          }),
          scheduleTimeEnabled: doEnable,
        },
      },
      update: (_cache, { data: dataEnableMutation }) => {
        if (
          dataEnableMutation &&
          dataEnableMutation.expertEnableDisableSchedulingLink
        ) {
          setExpertCalendarDayMonday(
            dataEnableMutation.expertEnableDisableSchedulingLink
              .scheduleTimeDayMonday || [],
          );
          setExpertCalendarDayTuesday(
            dataEnableMutation.expertEnableDisableSchedulingLink
              .scheduleTimeDayTuesday || [],
          );
          setExpertCalendarDayWednesday(
            dataEnableMutation.expertEnableDisableSchedulingLink
              .scheduleTimeDayWednesday || [],
          );
          setExpertCalendarDayThursday(
            dataEnableMutation.expertEnableDisableSchedulingLink
              .scheduleTimeDayThursday || [],
          );
          setExpertCalendarDayFriday(
            dataEnableMutation.expertEnableDisableSchedulingLink
              .scheduleTimeDayFriday || [],
          );
          setExpertCalendarDaySaturday(
            dataEnableMutation.expertEnableDisableSchedulingLink
              .scheduleTimeDaySaturday || [],
          );
          setExpertCalendarDaySunday(
            dataEnableMutation.expertEnableDisableSchedulingLink
              .scheduleTimeDaySunday || [],
          );
          setExpertCalendarEditAvailability(true);
        }
      },
      variables: {
        doEnable,
      },
    }).catch((err: ApolloError) => {
      addNotification(
        errorUtils.getErrorMessage(err) || 'Toggle Scheduling Link Error',
      );
      logError(err, {
        component: 'ExpertProfileView',
        func: 'tryEnableDisableLink',
      });
    });
  }
  function startEditSchedule() {
    setExpertCalendarEditAvailability(true);
    if (expertDetails) {
      setExpertCalendarDayMonday(expertDetails.scheduleTimeDayMonday || []);
      setExpertCalendarDayTuesday(expertDetails.scheduleTimeDayTuesday || []);
      setExpertCalendarDayWednesday(
        expertDetails.scheduleTimeDayWednesday || [],
      );
      setExpertCalendarDayThursday(expertDetails.scheduleTimeDayThursday || []);
      setExpertCalendarDayFriday(expertDetails.scheduleTimeDayFriday || []);
      setExpertCalendarDaySaturday(expertDetails.scheduleTimeDaySaturday || []);
      setExpertCalendarDaySunday(expertDetails.scheduleTimeDaySunday || []);
    }
  }
  function saveCalendarAvailability() {
    if (!expertDetails || expertCalendarLoadingAvailability) return;
    setExpertCalendarLoadingAvailability(true);
    tryUpdateAvailability({
      optimisticResponse: {
        expertEditCalendarAvailability: {
          ...expertDetails,
          scheduleTimeDayFriday: expertCalendarDayFriday.map((ds) => ({
            ...ds,
            __typename: 'TimeSchedule',
          })),
          scheduleTimeDayMonday: expertCalendarDayMonday.map((ds) => ({
            ...ds,
            __typename: 'TimeSchedule',
          })),
          scheduleTimeDaySaturday: expertCalendarDaySaturday.map((ds) => ({
            ...ds,
            __typename: 'TimeSchedule',
          })),
          scheduleTimeDaySunday: expertCalendarDaySunday.map((ds) => ({
            ...ds,
            __typename: 'TimeSchedule',
          })),
          scheduleTimeDayThursday: expertCalendarDayThursday.map((ds) => ({
            ...ds,
            __typename: 'TimeSchedule',
          })),
          scheduleTimeDayTuesday: expertCalendarDayTuesday.map((ds) => ({
            ...ds,
            __typename: 'TimeSchedule',
          })),
          scheduleTimeDayWednesday: expertCalendarDayWednesday.map((ds) => ({
            ...ds,
            __typename: 'TimeSchedule',
          })),
        },
      },
      variables: {
        input: {
          scheduleFriday: expertCalendarDayFriday.map((ds) => ({
            end: ds.end,
            start: ds.start,
          })),
          scheduleMonday: expertCalendarDayMonday.map((ds) => ({
            end: ds.end,
            start: ds.start,
          })),
          scheduleSaturday: expertCalendarDaySaturday.map((ds) => ({
            end: ds.end,
            start: ds.start,
          })),
          scheduleSunday: expertCalendarDaySunday.map((ds) => ({
            end: ds.end,
            start: ds.start,
          })),
          scheduleThursday: expertCalendarDayThursday.map((ds) => ({
            end: ds.end,
            start: ds.start,
          })),
          scheduleTuesday: expertCalendarDayTuesday.map((ds) => ({
            end: ds.end,
            start: ds.start,
          })),
          scheduleWednesday: expertCalendarDayWednesday.map((ds) => ({
            end: ds.end,
            start: ds.start,
          })),
        },
      },
    })
      .then(() => {
        setExpertCalendarLoadingAvailability(false);
        setExpertCalendarEditAvailability(false);
      })
      .catch((err: ApolloError) => {
        setExpertCalendarLoadingAvailability(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Update Availability Error',
        );
        logError(err, {
          component: 'ExpertProfileView',
          func: 'tryUpdateAvailability',
        });
      });
  }
  function formatAvailability(slots?: IDaySchedule[]) {
    if (!slots || !slots.length) {
      return 'No times available';
    }
    return slots
      .map(
        (s) =>
          moment().hour(0).minute(s.start).format('h:mma') +
          ' - ' +
          moment().hour(0).minute(s.end).format('h:mma'),
      )
      .join(', ');
  }
  function startCashout() {
    if (actionLoading) return;
    if (!accountDetails || !accountDetails.balance) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryCashout()
      .then(({ data: mutationData }) => {
        if (mutationData && mutationData.accountBalanceCashoutForExpert) {
          setTempBalanceEvents(
            _.uniqBy(
              tempBalanceEvents.concat(
                mutationData.accountBalanceCashoutForExpert,
              ),
              'id',
            ),
          );
        }
        if (refetchAccountDetails) {
          refetchAccountDetails()
            .catch((err: ApolloError) => {
              logError(err, {
                component: 'ExpertProfileView',
                func: 'refetchAccountDetails',
              });
            })
            .finally(() => {
              setActionLoading(false);
              setShowCashoutModal(false);
            });
        } else {
          setActionLoading(false);
          setShowCashoutModal(false);
        }
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(errorUtils.getErrorMessage(err) || 'Cashout Error');
        logError(err, {
          component: 'ExpertProfileView',
          func: 'tryCashout',
        });
      });
  }
  function notificationType(tag: string) {
    if (tagNotifySlack.indexOf(tag) >= 0) return 'slack';
    if (tagNotifyEmail.indexOf(tag) >= 0) return 'email';
    if (tagNotifyText.indexOf(tag) >= 0) return 'text';
    return 'dash';
  }
  function switchTagNotificationType(tag: string, newType: string) {
    setTagNotifyText((prevTagNotifyText) =>
      prevTagNotifyText
        .filter((t) => t !== tag)
        .concat(newType === 'text' ? [tag] : []),
    );
    setTagNotifySlack((prevTagNotifySlack) =>
      prevTagNotifySlack
        .filter((t) => t !== tag)
        .concat(newType === 'slack' ? [tag] : []),
    );
    setTagNotifyEmail((prevTagNotifyEmail) =>
      prevTagNotifyEmail
        .filter((t) => t !== tag)
        .concat(newType === 'email' ? [tag] : []),
    );
  }
  function saveTags() {
    if (actionLoading || !expertDetails) return;
    setActionLoading(true);
    tryEditTagNotifications({
      optimisticResponse: {
        expertEditTagNotifications: {
          ...expertDetails,
          tagNotifyEmail,
          tagNotifySlack,
          tagNotifyText,
          tagProblems,
          tagSkills,
          tagTools,
        },
      },
      update: (_cache, { data: dataEditMutation }) => {
        if (dataEditMutation && dataEditMutation.expertEditTagNotifications) {
          setTagNotifyEmail(
            dataEditMutation.expertEditTagNotifications.tagNotifyEmail || [],
          );
          setTagNotifySlack(
            dataEditMutation.expertEditTagNotifications.tagNotifySlack || [],
          );
          setTagNotifyText(
            dataEditMutation.expertEditTagNotifications.tagNotifyText || [],
          );
          setTagProblems(
            dataEditMutation.expertEditTagNotifications.tagProblems || [],
          );
          setTagSkills(
            dataEditMutation.expertEditTagNotifications.tagSkills || [],
          );
          setTagTools(
            dataEditMutation.expertEditTagNotifications.tagTools || [],
          );
        }
      },
      variables: {
        tagNotifyEmail,
        tagNotifySlack,
        tagNotifyText,
        tagProblems,
        tagSkills,
        tagTools,
      },
    })
      .then(() => {
        setTimeout(() => {
          setActionLoading(false);
        }, 500);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Edit Notifications Error',
        );
        logError(err, {
          component: 'ExpertProfileView',
          func: 'tryEditTagNotifications',
        });
      });
  }
  function savePastClients() {
    if (actionClientLoading || !expertDetails) return;
    setActionClientLoading(true);
    tryEditPastClients({
      optimisticResponse: {
        expertEditPastClients: {
          ...expertDetails,
          pastClients,
        },
      },
      update: (_cache, { data: dataEditMutation }) => {
        if (dataEditMutation && dataEditMutation.expertEditPastClients) {
          setPastClients(
            dataEditMutation.expertEditPastClients.pastClients || [],
          );
        }
      },
      variables: {
        pastClients: pastClients
          .filter(
            (c) =>
              c.name.trim() && c.url.trim() && c.industryTag && c.skills.length,
          )
          .map((c) => ({
            collaborators: c.collaborators,
            details: c.details,
            industryTag: c.industryTag,
            name: c.name,
            skills: c.skills,
            url: c.url,
          })),
      },
    })
      .then(() => {
        setTimeout(() => {
          setActionClientLoading(false);
        }, 500);
      })
      .catch((err: ApolloError) => {
        setActionClientLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Edit Clients Error',
        );
        logError(err, {
          component: 'ExpertProfileView',
          func: 'tryEditPastClients',
        });
      });
  }
  function saveContactSettings() {
    if (actionLoading || !expertDetails) return;
    if (!personalPhoneValid || !personalEmail) {
      addNotification('Please submit a phone and an email.', undefined, 5000);
      return;
    }
    if (personalPhone && personalPhone.indexOf('+44') === 0) {
      addNotification(
        'Due to country restrictions, we are not able to use UK phone numbers.',
        undefined,
        5000,
      );
    }
    const cleanSecondary = secondaryEmails.filter((se) => !!se && !!se.trim());
    setActionLoading(true);
    tryEditExpert({
      optimisticResponse: {
        expertEditContactDetails: {
          ...expertDetails,
          allowIncomingCalls,
          notificationDelay,
          notificationMedium,
          notificationSounds,
          primaryPersonalEmail: personalEmail,
          primaryPersonalPhone: personalPhone,
          profileHourlyRate: editExpertProfileHourlyRate,
          profileStartingProject: editExpertProfileStartingProject,
          profileStartingRetainer: editExpertProfileStartingRetainer,
          secondaryPersonalEmails: cleanSecondary,
          secondaryPersonalPhones: [personalPhone],
        },
      },
      update: (_cache, { data: dataEditMutation }) => {
        if (dataEditMutation && dataEditMutation.expertEditContactDetails) {
          setPersonalPhone(
            dataEditMutation.expertEditContactDetails.primaryPersonalPhone ||
              '',
          );
          setPersonalEmail(
            dataEditMutation.expertEditContactDetails.primaryPersonalEmail ||
              '',
          );
          setNotificationDelay(
            dataEditMutation.expertEditContactDetails.notificationDelay ||
              '10_MIN',
          );
          setNotificationMedium(
            dataEditMutation.expertEditContactDetails.notificationMedium ||
              'EMAIL',
          );
          setNotificationSounds(
            dataEditMutation.expertEditContactDetails.notificationSounds ||
              'NONE',
          );
          setAllowIncomingCalls(
            dataEditMutation.expertEditContactDetails.allowIncomingCalls ||
              'CLIENTS',
          );
          setEditExpertProfileStartingProject(
            dataEditMutation.expertEditContactDetails.profileStartingProject ||
              0,
          );
          setEditExpertProfileStartingRetainer(
            dataEditMutation.expertEditContactDetails.profileStartingRetainer ||
              0,
          );
          setEditExpertProfileHourlyRate(
            dataEditMutation.expertEditContactDetails.profileHourlyRate || 0,
          );
          setSecondaryEmails(
            (
              dataEditMutation.expertEditContactDetails
                .secondaryPersonalEmails || []
            ).filter(
              (se) =>
                se !==
                dataEditMutation.expertEditContactDetails.primaryPersonalEmail,
            ),
          );
        }
      },
      variables: {
        allowIncomingCalls,
        notificationDelay,
        notificationMedium,
        notificationSounds,
        primaryEmail: personalEmail,
        primaryPhone: personalPhone,
        profileHourlyRate: editExpertProfileHourlyRate,
        profileStartingProject: editExpertProfileStartingProject,
        profileStartingRetainer: editExpertProfileStartingRetainer,
        secondaryEmails: cleanSecondary,
      },
    })
      .then(() => {
        setActionLoading(false);
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Edit Settings Error',
        );
        logError(err, {
          component: 'ExpertProfileView',
          func: 'tryEditExpert',
        });
      });
  }
  function phoneNumberChange(phone: string, isValid: boolean) {
    setPersonalPhone(phone);
    if (!phone || isValid) {
      setPersonalPhoneValid(true);
    }
  }
  function phoneNumberBlur(isValid: boolean) {
    setPersonalPhoneValid(!personalPhone || isValid);
  }
  function openPrefabEditor(prefabId: string) {
    if (prefabId === 'new') {
      setPrefabTitle('');
      setPrefabDescription(null);
      setPrefabInitDesc('');
      setPrefabDays('3');
      setPrefabPriceType('EXACTLY');
      setPrefabPriceValue('$250');
    } else {
      const prefab = expertPrefabs.find((p) => p.id === prefabId);
      if (!prefab) {
        return;
      }
      setPrefabTitle(prefab.title || '');
      setPrefabInitDesc(prefab.description || '');
      setPrefabDays(
        prefab.estimatedCompletion
          ? prefab.estimatedCompletion.toString()
          : '3',
      );
      setPrefabPriceType(prefab.priceType);
      setPrefabPriceValue('$' + centsDollarsRounded(prefab.cents || 0));
    }
    setEditPrefab(prefabId);
  }
  function deletePrefab(prefabId: string) {
    if (actionLoading) return;
    const prefab = expertPrefabs.find((p) => p.id === prefabId);
    if (!prefab) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryDeletePrefab({
      optimisticResponse: {
        prefabDelete: {
          ...prefab,
          wasDeleted: true,
        },
      },
      variables: {
        prefabId,
      },
    })
      .then(() => {
        setActionLoading(false);
        setTempPrefabs(
          _.uniqBy(
            tempPrefabs.filter((p) => p.id !== prefabId),
            'id',
          ),
        );
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Delete Service Error',
        );
        logError(err, {
          component: 'ExpertProfileView',
          func: 'tryDeletePrefab',
        });
      });
  }
  function prefabSubmit() {
    if (actionLoading) return;
    let draftContent = '';
    let plainTextContent = '';
    if (prefabDescription && prefabDescription.hasText()) {
      plainTextContent = prefabDescription.getPlainText().trim();
      if (plainTextContent.replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(prefabDescription));
        } catch (rawError) {
          console.log('prefabDescription convertToRaw error', rawError);
        }
      }
    }
    if (!prefabTitle.trim() || !draftContent) {
      addNotification(
        'Please submit a title and a description.',
        undefined,
        5000,
      );
      return;
    }
    if (!prefabPriceType || !priceVal || priceVal <= 0) {
      addNotification('Please submit a valid price.', undefined, 5000);
      return;
    }
    if (!timelineVal || timelineVal <= 0) {
      addNotification('Please submit a valid timeline.', undefined, 5000);
      return;
    }
    if (editPrefab === 'new') {
      setActionLoading(true);
      tryCreatePrefab({
        variables: {
          cents: priceVal,
          description: draftContent,
          descriptionPlainText: plainTextContent,
          estimatedCompletion: timelineVal,
          priceType: prefabPriceType,
          title: prefabTitle,
        },
      })
        .then(({ data: mutationData }) => {
          setActionLoading(false);
          if (mutationData && mutationData.prefabCreate) {
            setTempPrefabs(
              _.uniqBy(tempPrefabs.concat(mutationData.prefabCreate), 'id'),
            );
          }
          setEditPrefab('');
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Create Service Error',
          );
          logError(err, {
            component: 'ExpertProfileView',
            func: 'tryCreatePrefab',
          });
        });
    } else {
      const prefab = expertPrefabs.find((p) => p.id === editPrefab);
      if (!prefab) {
        addNotification(
          'An error occured, please refresh and try again.',
          undefined,
          5000,
        );
        return;
      }
      setActionLoading(true);
      tryEditPrefab({
        optimisticResponse: {
          prefabEdit: {
            ...prefab,
            cents: priceVal,
            description: draftContent,
            estimatedCompletion: timelineVal,
            priceType: prefabPriceType,
            title: prefabTitle,
          },
        },
        variables: {
          cents: priceVal,
          description: draftContent,
          descriptionPlainText: plainTextContent,
          estimatedCompletion: timelineVal,
          prefabId: editPrefab,
          priceType: prefabPriceType,
          title: prefabTitle,
        },
      })
        .then(() => {
          setActionLoading(false);
          setEditPrefab('');
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Edit Service Error',
          );
          logError(err, {
            component: 'ExpertProfileView',
            func: 'tryEditPrefab',
          });
        });
    }
  }
  function closePrefabEditor() {
    setPrefabTitle('');
    setPrefabDescription(null);
    setPrefabInitDesc('');
    setPrefabDays('');
    setPrefabPriceType('');
    setPrefabPriceValue('');
    setEditPrefab('');
  }
  function openTemplateEditor(templateId: string, isQuote: boolean) {
    if (templateId === 'new') {
      setTemplateTitle('');
      setTemplateDescription(null);
      setTemplateInitDesc(
        isQuote
          ? QUOTE_TEMPLATE
          : templateUtils.getDefaultEmail([], { ...expertDetails }),
      );
      setTemplateIsQuote(isQuote);
    } else {
      const template = (isQuote ? quoteTemplates : emailTemplates).find(
        (p) => p.id === templateId,
      );
      if (!template) {
        return;
      }
      setTemplateTitle(template.title || '');
      setTemplateInitDesc(template.description || '');
    }
    setEditTemplate(templateId);
  }
  function deleteTemplate(templateId: string, isQuote: boolean) {
    if (actionLoading) return;
    const template = (isQuote ? quoteTemplates : emailTemplates).find(
      (p) => p.id === templateId,
    );
    if (!template) {
      addNotification(
        'An error occured, please refresh and try again.',
        undefined,
        5000,
      );
      return;
    }
    setActionLoading(true);
    tryDeleteTemplate({
      variables: {
        templateId,
      },
    })
      .then(() => {
        setActionLoading(false);
        refetchTemplates();
      })
      .catch((err: ApolloError) => {
        setActionLoading(false);
        addNotification(
          errorUtils.getErrorMessage(err) || 'Delete Template Error',
        );
        logError(err, {
          component: 'ExpertProfileView',
          func: 'tryDeleteTemplate',
        });
      });
  }
  function templateSubmit() {
    if (actionLoading) return;
    let draftContent = '';
    let plainTextContent = '';
    if (templateDescription && templateDescription.hasText()) {
      plainTextContent = templateDescription.getPlainText().trim();
      if (plainTextContent.replace(/\s/gi, '')) {
        try {
          draftContent = JSON.stringify(convertToRaw(templateDescription));
        } catch (rawError) {
          console.log('templateDescription convertToRaw error', rawError);
        }
      }
    }
    if (!templateTitle.trim() || !draftContent) {
      addNotification(
        'Please submit a title and a description.',
        undefined,
        5000,
      );
      return;
    }
    if (editTemplate === 'new') {
      setActionLoading(true);
      tryCreateTemplate({
        variables: {
          description: draftContent,
          isQuote: templateIsQuote,
          title: templateTitle,
        },
      })
        .then(() => {
          setActionLoading(false);
          setEditTemplate('');
          refetchTemplates();
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Create Template Error',
          );
          logError(err, {
            component: 'ExpertProfileView',
            func: 'tryCreateTemplate',
          });
        });
    } else {
      const template = (templateIsQuote ? quoteTemplates : emailTemplates).find(
        (p) => p.id === editTemplate,
      );
      if (!template) {
        addNotification(
          'An error occured, please refresh and try again.',
          undefined,
          5000,
        );
        return;
      }
      setActionLoading(true);
      tryEditTemplate({
        optimisticResponse: {
          templateEdit: {
            ...template,
            description: draftContent,
            title: templateTitle,
          },
        },
        variables: {
          description: draftContent,
          templateId: editTemplate,
          title: templateTitle,
        },
      })
        .then(() => {
          setActionLoading(false);
          setEditTemplate('');
          refetchTemplates();
        })
        .catch((err: ApolloError) => {
          setActionLoading(false);
          addNotification(
            errorUtils.getErrorMessage(err) || 'Edit Template Error',
          );
          logError(err, {
            component: 'ExpertProfileView',
            func: 'tryEditTemplate',
          });
        });
    }
  }
  function closeTemplateEditor() {
    setTemplateTitle('');
    setTemplateIsQuote(false);
    setTemplateDescription(null);
    setTemplateInitDesc('');
    setEditTemplate('');
  }
  function closeClientEditor() {
    setEditPastClient(-1);
    setPastClients((prev) =>
      prev.filter(
        (c) =>
          c.name.trim() && c.url.trim() && c.industryTag && c.skills.length,
      ),
    );
  }
  function openClientEditor(index: number) {
    setEditPastClient(index);
  }
  function deleteClient(index: number) {
    setPastClients(pastClients.filter((_c, i) => i !== index));
  }
  function addClient() {
    setPastClients((prev) =>
      prev.concat({
        collaborators: '',
        details: '',
        industryTag: '',
        name: '',
        skills: [],
        url: '',
      }),
    );
    setEditPastClient(pastClients.length);
  }
  const profileUrlFull =
    expertDetails && expertDetails.profilePath
      ? 'https://www.storetasker.com/experts/' + expertDetails.profilePath
      : '';
  const profileUrlUnique =
    expertDetails && expertDetails.profilePath
      ? 'storetasker.com/experts/' + expertDetails.profilePath
      : '';
  let lastUpdatedStr = '3 hours ago';
  if (expertDetails && expertDetails.kpis && expertDetails.kpis.updatedAt) {
    lastUpdatedStr = moment(expertDetails.kpis.updatedAt).fromNow();
  }
  const levelStr =
    expertDetails && expertDetails.levelEstimate
      ? getExpertLevelStr(expertDetails.levelEstimate, expertDetails.createdAt)
      : null;
  function openPaymentsTab() {
    setCurrentTab('payments');
    getAccountDetails().catch((err: ApolloError) => {
      logError(err, {
        component: 'ExpertProfileView',
        func: 'getAccountDetails',
      });
    });
  }
  function openReviewsTab() {
    setCurrentTab('reviews');
    getFeedbacks().catch((err: ApolloError) => {
      logError(err, {
        component: 'ExpertProfileView',
        func: 'getFeedbacks',
      });
    });
  }
  if (
    loadingExpertDetails ||
    loadingPrefabs ||
    loadingAccountDetails ||
    loadingFeedbacks ||
    loadingAccountBalanceEventPaginated ||
    loadingCalendarChoices
  ) {
    // ignore these
  }
  return (
    <div className="DashboardModal ExpertProfileViewModal">
      <div className="DashboardModalTopCircle" />
      <div className="DashboardModalBottomCircle" />
      <div className="ExpertProfileView">
        <div
          className={
            'ExpertProfileNav ExpertProfileNavActive' + currentTab.toUpperCase()
          }
        >
          <div className="ExpertProfileNavHeader">
            <Link to="/home" className="ExpertProfileNavHeaderBack">
              Dashboard
            </Link>
          </div>
          <div className="ExpertProfileNavIntro">
            {currentTab === 'profile' && (
              <Fragment>
                <div className="ExpertProfileNavIntroHeader">
                  Your Public Profile
                </div>
                <div className="ExpertProfileNavIntroBody">
                  Here you&apos;ll find the email signature and bio that is
                  shown to clients.
                </div>
              </Fragment>
            )}
            {currentTab === 'metrics' && (
              <Fragment>
                <div className="ExpertProfileNavIntroHeader">
                  Account Metrics
                </div>
                <div className="ExpertProfileNavIntroBody">
                  The most important KPIs to understand how you&apos;re doing as
                  a Storetasker Member.
                </div>
              </Fragment>
            )}
            {currentTab === 'calendar' && (
              <Fragment>
                <div className="ExpertProfileNavIntroHeader">
                  Link Your Calendar
                </div>
                <div className="ExpertProfileNavIntroBody">
                  Sync your personal calendar with your Storetasker client
                  meetings.
                </div>
              </Fragment>
            )}
            {currentTab === 'services' && (
              <Fragment>
                <div className="ExpertProfileNavIntroHeader">
                  Predefined Services
                </div>
                <div className="ExpertProfileNavIntroBody">
                  Build, offer, and sell specific services to clients.
                </div>
              </Fragment>
            )}
            {currentTab === 'payments' && (
              <Fragment>
                <div className="ExpertProfileNavIntroHeader">
                  Your Transactions
                </div>
                <div className="ExpertProfileNavIntroBody">
                  View your payments, total earnings, and bank transfers.
                </div>
              </Fragment>
            )}
            {currentTab === 'reviews' && (
              <Fragment>
                <div className="ExpertProfileNavIntroHeader">
                  Client Reviews
                </div>
                <div className="ExpertProfileNavIntroBody">
                  Clients can leave a review after they approve a completed
                  project.
                </div>
              </Fragment>
            )}
            {currentTab === 'skills' && (
              <Fragment>
                <div className="ExpertProfileNavIntroHeader">
                  Skills and Preferences
                </div>
                <div className="ExpertProfileNavIntroBody">
                  Configure notification settings for suggested projects.
                </div>
              </Fragment>
            )}
            {currentTab === 'templates' && (
              <Fragment>
                <div className="ExpertProfileNavIntroHeader">
                  Saved Templates
                </div>
                <div className="ExpertProfileNavIntroBody">
                  Update your saved templates for emails and quotes.
                </div>
              </Fragment>
            )}
          </div>
          <div className="ExpertProfileNavLinks">
            <div className="ExpertProfileNavLinkPointer" />
            <div
              className="ExpertProfileNavLink ExpertProfileNavLinkPROFILE"
              onClick={() => setCurrentTab('profile')}
            >
              Profile
            </div>
            <div
              className="ExpertProfileNavLink ExpertProfileNavLinkMETRICS"
              onClick={() => setCurrentTab('metrics')}
            >
              Metrics
            </div>
            <div
              className="ExpertProfileNavLink ExpertProfileNavLinkCALENDAR"
              onClick={() => setCurrentTab('calendar')}
            >
              Calendar
            </div>
            <div
              className="ExpertProfileNavLink ExpertProfileNavLinkSERVICES"
              onClick={() => setCurrentTab('services')}
            >
              Services
            </div>
            <div
              className="ExpertProfileNavLink ExpertProfileNavLinkPAYMENTS"
              onClick={openPaymentsTab}
            >
              Payments
            </div>
            <div
              className="ExpertProfileNavLink ExpertProfileNavLinkREVIEWS"
              onClick={openReviewsTab}
            >
              Reviews
            </div>
            <div
              className="ExpertProfileNavLink ExpertProfileNavLinkSKILLS"
              onClick={() => setCurrentTab('skills')}
            >
              Expertise
            </div>
            <div
              className="ExpertProfileNavLink ExpertProfileNavLinkTEMPLATES"
              onClick={() => setCurrentTab('templates')}
            >
              Templates
            </div>
          </div>
        </div>
        <div className="ExpertProfileMain">
          <Link className="ExpertProfileMainLogoLink" to="/home">
            <img
              className="ExpertProfileMainLogo"
              src={logo}
              alt="Storetasker Logo"
            />
          </Link>
          {currentTab === 'profile' && !!expertDetails && (
            <div className="ExpertProfilePanel">
              <div className="ExpertProfileSection">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Profile
                </div>
                <div className="ExpertProfileSectionAction">
                  <a
                    className="ExpertProfileSectionActionLink"
                    href={profileUrlFull}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Profile
                  </a>
                </div>
                <div className="ExpertProfileMockup">
                  <ExpertUserBubble
                    className="ExpertProfileMockupPhoto"
                    expertDetails={expertDetails}
                    expertId={expertId}
                  />
                  <div className="ExpertProfileMockupName">
                    {(
                      (expertDetails.firstName || '').trim() +
                      ' ' +
                      (expertDetails.lastName || '').trim()
                    ).trim()}
                  </div>
                  <div className="ExpertProfileMockupDetail ExpertProfileMockupDetailEmail">
                    {expertDetails.primaryPublicEmail}
                  </div>
                  <div className="ExpertProfileMockupDetail ExpertProfileMockupDetailPhone">
                    {formatValidPhoneNumber(
                      expertDetails.primaryPublicPhone,
                      true,
                    ) || expertDetails.primaryPublicPhone}
                  </div>
                </div>
                <div className="ExpertProfileSectionMinis">
                  <div className="ExpertProfileSectionMini">
                    <div className="ExpertProfileSectionMiniTitle">Bio</div>
                    <div className="ExpertProfileSectionMiniBody">
                      {expertDetails.profileBio || 'Missing. Talk to Richard.'}
                    </div>
                  </div>
                  <div className="ExpertProfileSectionMini ExpertProfileSectionMiniSecond">
                    <div className="ExpertProfileSectionMiniTitle">
                      Location
                    </div>
                    <div className="ExpertProfileSectionMiniBody">
                      {expertDetails.locationStr} (
                      {expertDetails.locationTimezone})
                    </div>
                    <div className="ExpertProfileSectionMiniTitle">
                      Profile Url
                    </div>
                    <a
                      className="ExpertProfileSectionMiniBody"
                      href={profileUrlFull}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {profileUrlUnique}
                    </a>
                  </div>
                </div>
              </div>
              <div className="ExpertProfileSection ExpertProfileSectionBelow">
                <div className="ExpertProfileSectionTitle">Settings</div>
                {expertDetails.primaryPersonalEmail && (
                  <div className="ExpertProfileSettings">
                    <div className="ExpertProfileSettingsField">
                      <div className="ExpertProfileSettingsFieldTitle">
                        Notification Channel
                      </div>
                      <select
                        className="ExpertProfileSettingsFieldInput"
                        required
                        value={notificationMedium}
                        onChange={(e) =>
                          setNotificationMedium(e.currentTarget.value)
                        }
                      >
                        <option value="EMAIL">
                          Email me about missed messages
                        </option>
                        <option value="TEXT">
                          Text me about missed messages
                        </option>
                      </select>
                    </div>
                    <div className="ExpertProfileSettingsField">
                      <div className="ExpertProfileSettingsFieldTitle">
                        Notification Delay
                      </div>
                      <select
                        className="ExpertProfileSettingsFieldInput"
                        required
                        value={notificationDelay}
                        onChange={(e) =>
                          setNotificationDelay(e.currentTarget.value)
                        }
                      >
                        <option value="10_MIN">10 minutes</option>
                        <option value="1_HR">1 hour</option>
                        <option value="1_DAY">24 hours</option>
                      </select>
                    </div>
                    <div className="ExpertProfileSettingsField">
                      <div className="ExpertProfileSettingsFieldTitle">
                        Notification Sounds
                      </div>
                      <select
                        className="ExpertProfileSettingsFieldInput"
                        required
                        value={notificationSounds}
                        onChange={(e) =>
                          setNotificationSounds(e.currentTarget.value)
                        }
                      >
                        <option value="NONE">No dings please!</option>
                        <option value="QUEUE">Only for new leads</option>
                        <option value="UNREAD">Only for client alerts</option>
                        <option value="ALL">
                          Both new leads and client alerts
                        </option>
                      </select>
                    </div>
                    <div className="ExpertProfileSettingsField">
                      <div className="ExpertProfileSettingsFieldTitle">
                        Allow Incoming Calls?
                      </div>
                      <select
                        className="ExpertProfileSettingsFieldInput"
                        required
                        value={allowIncomingCalls}
                        onChange={(e) =>
                          setAllowIncomingCalls(e.currentTarget.value)
                        }
                      >
                        <option value="CLIENTS">Yes, from clients only</option>
                        <option value="NONE">
                          No, forward all to voicemail
                        </option>
                      </select>
                    </div>
                    <div className="ExpertProfileSettingsField">
                      <div className="ExpertProfileSettingsFieldTitle">
                        Project Starting Price
                        {editExpertProfileStartingProject
                          ? `: $${editExpertProfileStartingProject}+`
                          : ''}
                      </div>
                      <input
                        className="ExpertProfileSettingsFieldInput"
                        type="number"
                        autoComplete="new-off"
                        spellCheck="false"
                        placeholder="Starting Project Price"
                        value={editExpertProfileStartingProject}
                        min="0"
                        step={1}
                        onChange={(e) =>
                          setEditExpertProfileStartingProject(
                            Number(e.currentTarget.value),
                          )
                        }
                      />
                    </div>
                    <div className="ExpertProfileSettingsField">
                      <div className="ExpertProfileSettingsFieldTitle">
                        Retainer Starting Price
                        {editExpertProfileStartingRetainer
                          ? `: $${editExpertProfileStartingRetainer}+`
                          : ''}
                      </div>
                      <input
                        className="ExpertProfileSettingsFieldInput"
                        type="number"
                        autoComplete="new-off"
                        spellCheck="false"
                        placeholder="Starting Project Retainer"
                        value={editExpertProfileStartingRetainer}
                        min="0"
                        step={1}
                        onChange={(e) =>
                          setEditExpertProfileStartingRetainer(
                            Number(e.currentTarget.value),
                          )
                        }
                      />
                    </div>
                    <div className="ExpertProfileSettingsField">
                      <div className="ExpertProfileSettingsFieldTitle">
                        Hourly Rate
                        {editExpertProfileHourlyRate
                          ? `: $${editExpertProfileHourlyRate} / hour`
                          : ''}
                      </div>
                      <div
                        className="ExpertProfileSettingsFieldHidden"
                        data-tip="Not shown to potential clients. This info is used to improve our matching and introductions"
                      >
                        hidden
                      </div>
                      <input
                        className="ExpertProfileSettingsFieldInput"
                        type="number"
                        autoComplete="new-off"
                        spellCheck="false"
                        placeholder="Hourly Rate"
                        value={editExpertProfileHourlyRate}
                        min="0"
                        step={1}
                        onChange={(e) =>
                          setEditExpertProfileHourlyRate(
                            Number(e.currentTarget.value),
                          )
                        }
                      />
                    </div>
                    <div
                      className={
                        'ExpertProfileSettingsField ' +
                        (!personalPhoneValid
                          ? ' ExpertProfileSettingsFieldInvalid '
                          : '')
                      }
                    >
                      <div className="ExpertProfileSettingsFieldTitle">
                        Phone Number
                      </div>
                      <PhoneNumberInput
                        className="ExpertProfileSettingsFieldInput"
                        value={personalPhone}
                        onChange={phoneNumberChange}
                        onBlur={phoneNumberBlur}
                      />
                    </div>
                    <div className="ExpertProfileSettingsField">
                      <div className="ExpertProfileSettingsFieldTitle">
                        Primary Email
                      </div>
                      <input
                        className="ExpertProfileSettingsFieldInput"
                        type="email"
                        autoComplete="new-off"
                        spellCheck="false"
                        placeholder="Primary Email Address"
                        value={personalEmail}
                        onChange={(e) =>
                          setPersonalEmail(e.currentTarget.value)
                        }
                      />
                    </div>
                    {secondaryEmails.map((se, i) => (
                      <div key={i} className="ExpertProfileSettingsField">
                        <div className="ExpertProfileSettingsFieldTitle">
                          {!i ? 'Secondary Email' : ''}
                        </div>
                        <input
                          className="ExpertProfileSettingsFieldInput"
                          type="email"
                          autoComplete="new-off"
                          spellCheck="false"
                          placeholder="Secondary Email Address"
                          value={se}
                          onChange={(e) =>
                            setSecondaryEmails(
                              secondaryEmails
                                .slice(0, i)
                                .concat(e.currentTarget.value)
                                .concat(secondaryEmails.slice(i + 1)),
                            )
                          }
                        />
                      </div>
                    ))}
                    <div
                      className="ExpertProfileSettingsExtra"
                      onClick={() =>
                        setSecondaryEmails(secondaryEmails.concat(''))
                      }
                    >
                      Add another email?
                    </div>
                    <div
                      className={
                        'ExpertProfileSettingsAction ' +
                        (actionLoading
                          ? ' ExpertProfileSettingsActionLoading '
                          : '')
                      }
                      onClick={saveContactSettings}
                    >
                      Save
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {currentTab === 'metrics' && expertDetails && (
            <div className="ExpertProfilePanel">
              <div className="ExpertProfileSection">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Metrics
                </div>
                <div className="ExpertProfileSectionAction">
                  <div className="ExpertProfileSectionActionInfo">
                    updated {lastUpdatedStr}
                  </div>
                </div>
                {(!expertDetails.kpis || !expertDetails.kpis.updatedAt) && (
                  <div className="ExpertProfileInfo">
                    <div className="ExpertProfileInfoTitle">
                      No metrics found
                    </div>
                    <div className="ExpertProfileInfoDesc">
                      This probably just means we&apos;re recalculating them or
                      you&apos;re a new expert. Either way, contact Support if
                      they don&apos;t show up in the next 24 hours.
                    </div>
                  </div>
                )}
                {expertDetails.status === 'new' &&
                  expertDetails.kpis &&
                  expertDetails.kpis.updatedAt && (
                    <div className="ExpertProfileInfo">
                      <div className="ExpertProfileInfoTitle">
                        Metrics for new experts
                      </div>
                      <div className="ExpertProfileInfoDesc">
                        Since you&apos;re a new expert on the platform, it may
                        take 15-20 projects before these metrics stabilize.
                        Until then, don&apos;t worry if these numbers jump
                        around a bit, just focus on happy customers!
                      </div>
                    </div>
                  )}
                {expertDetails.kpis && expertDetails.kpis.updatedAt && (
                  <div className="ExpertProfileMetrics">
                    {!!expertDetails.kpis.closeRateRecent && (
                      <div
                        className={
                          'ExpertProfileMetric ' +
                          (expertDetails.kpis.closeRateRecent <
                          KPI_GOALS['close-rate']
                            ? 'ExpertProfileMetricBad'
                            : '')
                        }
                      >
                        <div className="ExpertProfileMetricNumber">
                          {kpiPercentage(expertDetails.kpis.closeRateRecent)}
                        </div>
                        <div className="ExpertProfileMetricTitle">
                          Close Rate
                          <span>
                            {' '}
                            (your goal is over{' '}
                            {kpiPercentage(KPI_GOALS['close-rate'])})
                          </span>
                        </div>
                        <div className="ExpertProfileMetricDescription">
                          The % of client introductions that turn into paid
                          engagements. Leads that were introduced to another
                          member and then put back into the queue don&apos;t
                          count. This is the most important metric the platform
                          considers when connecting members to new client
                          opportunities.
                        </div>
                      </div>
                    )}
                    {!!expertDetails.kpis.repeatRateOverall && (
                      <div
                        className={
                          'ExpertProfileMetric ' +
                          (expertDetails.kpis.repeatRateOverall <
                          KPI_GOALS['repeat-rate']
                            ? 'ExpertProfileMetricBad'
                            : '')
                        }
                      >
                        <div className="ExpertProfileMetricNumber">
                          {kpiPercentage(expertDetails.kpis.repeatRateOverall)}
                        </div>
                        <div className="ExpertProfileMetricTitle">
                          Repeat Rate
                          <span>
                            {' '}
                            (your goal is over{' '}
                            {kpiPercentage(KPI_GOALS['repeat-rate'])})
                          </span>
                        </div>
                        <div className="ExpertProfileMetricDescription">
                          The % of your paying clients that do another paid
                          engagement with you. This is the second most important
                          metric the platform considers when connecting members
                          to new client opportunities.
                        </div>
                      </div>
                    )}
                    {!!expertDetails.kpis.completionRateRecent && (
                      <div
                        className={
                          'ExpertProfileMetric ' +
                          (expertDetails.kpis.completionRateRecent <
                          KPI_GOALS['completion-rate']
                            ? 'ExpertProfileMetricBad'
                            : '')
                        }
                      >
                        <div className="ExpertProfileMetricNumber">
                          {kpiPercentage(
                            expertDetails.kpis.completionRateRecent,
                          )}
                        </div>
                        <div className="ExpertProfileMetricTitle">
                          Completion Rate
                          <span>
                            {' '}
                            (your goal is over{' '}
                            {kpiPercentage(KPI_GOALS['completion-rate'])})
                          </span>
                        </div>
                        <div className="ExpertProfileMetricDescription">
                          The % of quote accepted projects that are completed
                          &amp; approved by the client.
                        </div>
                      </div>
                    )}
                    {!!expertDetails.kpis.feedbackRatingRecent && (
                      <div
                        className={
                          'ExpertProfileMetric ' +
                          (expertDetails.kpis.feedbackRatingRecent <
                          KPI_GOALS['feedback-rating']
                            ? 'ExpertProfileMetricBad'
                            : '')
                        }
                      >
                        <div className="ExpertProfileMetricNumber">
                          {kpiRating(expertDetails.kpis.feedbackRatingRecent)}
                        </div>
                        <div className="ExpertProfileMetricTitle">
                          Feedback Rating
                          <span>
                            {' '}
                            (your goal is over {
                              KPI_GOALS['feedback-rating']
                            }{' '}
                            stars)
                          </span>
                        </div>
                        <div className="ExpertProfileMetricDescription">
                          The average number of stars out of 5 that you receive
                          from clients after they complete a project with you
                          and leave a review.
                        </div>
                      </div>
                    )}
                    {!!expertDetails.kpis.gmvPreviousMonth && (
                      <div className="ExpertProfileMetric">
                        <div className="ExpertProfileMetricNumber">
                          {kpiDollars(expertDetails.kpis.gmvPreviousMonth)}
                        </div>
                        <div className="ExpertProfileMetricTitle">
                          Last Month
                        </div>
                        <div className="ExpertProfileMetricDescription">
                          Total revenue of all completed (and approved) projects
                          last month.
                        </div>
                      </div>
                    )}
                    {!!expertDetails.kpis.gmvCurrentMonth && (
                      <div className="ExpertProfileMetric">
                        <div className="ExpertProfileMetricNumber">
                          {kpiDollars(expertDetails.kpis.gmvCurrentMonth)}
                        </div>
                        <div className="ExpertProfileMetricTitle">
                          This Month
                        </div>
                        <div className="ExpertProfileMetricDescription">
                          Total revenue of all completed (and approved) projects
                          this month.
                        </div>
                      </div>
                    )}
                    {!!expertDetails.kpis.clientsTotal &&
                      !!expertDetails.kpis.gmvTotal && (
                        <div
                          className={
                            'ExpertProfileMetric ' +
                            ((expertDetails.kpis.gmvTotal || 0) /
                              (expertDetails.kpis.clientsTotal || 1) <
                            KPI_GOALS['account-value']
                              ? 'ExpertProfileMetricBad'
                              : '')
                          }
                        >
                          <div className="ExpertProfileMetricNumber">
                            {kpiDollars(
                              (expertDetails.kpis.gmvTotal || 0) /
                                (expertDetails.kpis.clientsTotal || 1),
                            )}
                          </div>
                          <div className="ExpertProfileMetricTitle">
                            Average Account Value (your goal is over $4k)
                          </div>
                          <div className="ExpertProfileMetricDescription">
                            Average amount you&apos;ve earned for every lead
                            you&apos;ve closed.
                          </div>
                        </div>
                      )}
                    <div
                      className={
                        'ExpertProfileMetric ' +
                        ((expertDetails.clientEscalations || 0) > 0
                          ? 'ExpertProfileMetricBad'
                          : '')
                      }
                    >
                      <div className="ExpertProfileMetricNumber">
                        {expertDetails.clientEscalations || 0}
                      </div>
                      <div className="ExpertProfileMetricTitle">
                        Client Escalations
                      </div>
                      <div className="ExpertProfileMetricDescription">
                        The number of times your clients have reported or
                        escalated an issue to Storetasker Support. Experts
                        typically receive 0-1 escalations per year. If you
                        receive 3 or more client escalations in total, your
                        Storetasker membership may be at risk.
                      </div>
                    </div>
                    {!!expertDetails.kpis.gmvTotal && (
                      <div
                        className={
                          'ExpertProfileMetric ' +
                          (expertDetails.clientEscalations &&
                          (expertDetails.kpis.gmvTotal || 0) /
                            expertDetails.clientEscalations <
                            KPI_GOALS['earnings-escalations']
                            ? 'ExpertProfileMetricBad'
                            : '')
                        }
                      >
                        <div className="ExpertProfileMetricNumber">
                          {expertDetails.clientEscalations
                            ? kpiDollars(
                                (expertDetails.kpis.gmvTotal || 0) /
                                  expertDetails.clientEscalations,
                              )
                            : '--'}
                        </div>
                        <div className="ExpertProfileMetricTitle">
                          Earnings per Escalation (your goal is over $50k)
                        </div>
                        <div className="ExpertProfileMetricDescription">
                          Your total sales divided by the number of client
                          escalations you&apos;ve received. If this number drops
                          too low, your Storetasker membership may be at risk.
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {currentTab === 'calendar' && expertDetails && (
            <div className="ExpertProfilePanel">
              <div className="ExpertProfileSection">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Your Calendar
                </div>
                {connectingNylasCode || refreshingCalendarList ? (
                  <div className="ExpertProfileInfo">
                    <div className="ExpertProfileInfoLoading" />
                  </div>
                ) : (
                  <Fragment>
                    <div className="ExpertProfileSectionAction">
                      {!theAccessToken || !theCalendarId ? (
                        <a
                          className="ExpertProfileSectionActionBtn"
                          href={`https://api.us.nylas.com/v3/connect/auth?client_id=${envUtils.pick(
                            'ba3822c9-0605-4c18-b155-f3ad6259e505',
                            'e322fa78-a39b-4cf0-b6b1-3e1d3e3a4f3c',
                            '243dc240-8832-4ed0-832b-70d28f8dcc63',
                          )}&redirect_uri=${envUtils.pick(
                            'https://expert.storetasker.com/profile',
                            'https://expert-dev.storetasker.com/profile',
                            'http://localhost:3002/profile',
                          )}&response_type=code&access_type=online`}
                        >
                          {theAccessToken
                            ? 'Switch Accounts'
                            : 'Set Up Calendar'}
                        </a>
                      ) : (
                        <div
                          className="ExpertProfileSectionActionBtn"
                          onClick={removeCalendar}
                        >
                          Disconnect
                        </div>
                      )}
                    </div>
                    {!theAccessToken && (
                      <div className="ExpertProfileInfo">
                        <div className="ExpertProfileInfoTitle">
                          Link Your Personal Calendar
                        </div>
                        <div className="ExpertProfileInfoDesc">
                          When you schedule a meeting with a Storetasker client,
                          we&apos;ll add it to your personal calendar.
                        </div>
                      </div>
                    )}
                    {!!theAccessToken && !theCalendarId && (
                      <Fragment>
                        {!expertCalendarChoices.length ? (
                          <div className="ExpertProfileInfo">
                            <div className="ExpertProfileInfoTitle">
                              {tempAccessToken && !errorCalendarChoices
                                ? 'This may take a few minutes'
                                : 'No calendars found for this account'}
                            </div>
                            {errorCalendarChoices ? (
                              <div className="ExpertProfileInfoDesc">
                                {errorUtils.getErrorMessage(
                                  errorCalendarChoices,
                                )}
                              </div>
                            ) : (
                              <Fragment>
                                <div className="ExpertProfileInfoDesc">
                                  {tempAccessToken
                                    ? 'It can take a bit of time to find the calendars associated with this account. Please click to refresh below or try another account above.'
                                    : "Click to refresh below or try another account above. Please get in touch with our Support Team if you think we're missing your calendar somehow."}
                                </div>
                                <div
                                  className="ExpertProfileInfoAction"
                                  onClick={refreshCalendarList}
                                >
                                  Refresh calendars
                                </div>
                              </Fragment>
                            )}
                          </div>
                        ) : (
                          <div className="ExpertProfileCalendarOptions">
                            <div className="ExpertProfileCalendarOptionsTitle">
                              Select your primary calendar:
                            </div>
                            <div className="ExpertProfileCalendarOptionsSubtitle">
                              When you schedule a meeting with a Storetasker
                              client, we&apos;ll add it to your personal
                              calendar.
                            </div>
                            {expertCalendarChoices.map((cc) => {
                              let accountName =
                                expertDetails.nylasAccountName || '';
                              if (cc.name === accountName) {
                                accountName = '';
                              }
                              return (
                                <div
                                  key={cc.id}
                                  className="ExpertProfileCalendarOptionItem"
                                  onClick={() =>
                                    selectCalendar(cc.id, cc.name || 'Calendar')
                                  }
                                >
                                  {cc.name}
                                  {accountName ? ` - ${accountName}` : ''}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </Fragment>
                    )}
                    {!!theAccessToken && !!theCalendarId && (
                      <div className="ExpertProfileCalendarOptions">
                        <div className="ExpertProfileCalendarOptionsTitle">
                          Synced:{' '}
                          {expertDetails.nylasCalendarName ||
                            'Personal Calendar'}
                          {expertDetails.nylasAccountName &&
                          expertDetails.nylasCalendarName !==
                            expertDetails.nylasAccountName
                            ? ` - ${expertDetails.nylasAccountName}`
                            : ''}
                        </div>
                        <div className="ExpertProfileCalendarOptionsSubtitle">
                          When you schedule a meeting with a Storetasker client,
                          we&apos;ll add it to your personal calendar.
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
              {!!theAccessToken && !!theCalendarId && (
                <Fragment>
                  <div className="ExpertProfileSection ExpertProfileSectionBelow">
                    <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                      Scheduling Link
                    </div>
                    {!!expertDetails.scheduleTimeEnabled && (
                      <div className="ExpertProfileSectionAction">
                        <div
                          className="ExpertProfileSectionActionBtn"
                          onClick={() => enableDisableSchedulingLink(false)}
                        >
                          Disable
                        </div>
                      </div>
                    )}
                    {expertDetails.scheduleTimeEnabled ? (
                      <div className="ExpertProfileCalendarOptions">
                        <div className="ExpertProfileCalendarOptionsTitle">
                          {envUtils.pick(
                            `meet.storetasker.com/${expertDetails.profilePath}`,
                            `www-dev.storetasker.com/experts/${expertDetails.profilePath}?meet=true`,
                            `localhost:3000/experts/${expertDetails.profilePath}?meet=true`,
                          )}
                        </div>
                        <div className="ExpertProfileCalendarOptionsSubtitle">
                          Similar to Calendly, you can send this link to clients
                          and have them book time on your calendar to meet.
                        </div>
                      </div>
                    ) : (
                      <div className="ExpertProfileInfo">
                        <div className="ExpertProfileInfoTitle">
                          Set up your scheduling link
                        </div>
                        <div className="ExpertProfileInfoDesc">
                          Similar to Calendly, you can send this link to clients
                          and have them book time on your calendar to meet.
                        </div>
                        <div
                          className="ExpertProfileInfoAction"
                          onClick={() => enableDisableSchedulingLink(true)}
                        >
                          Enable
                        </div>
                      </div>
                    )}
                  </div>
                  {expertDetails.scheduleTimeEnabled && (
                    <div className="ExpertProfileSection ExpertProfileSectionBelow">
                      <div className="ExpertProfileSectionTitle">Settings</div>
                      <div className="ExpertProfileSettings">
                        <div className="ExpertProfileSettingsField">
                          <div className="ExpertProfileSettingsFieldTitle">
                            Timezone
                          </div>
                          <select
                            className="ExpertProfileSettingsFieldInput"
                            required
                            value={expertCalendarTimezone}
                            onChange={(e) =>
                              setExpertCalendarTimezone(e.currentTarget.value)
                            }
                          >
                            <option value="" disabled>
                              Timezone
                            </option>
                            {timezones.map((tzObj) => (
                              <option key={tzObj.tz} value={tzObj.tz}>
                                (GMT{moment.tz(tzObj.tz).format('Z')}){' '}
                                {tzObj.tz}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="ExpertProfileSettingsField">
                          <div className="ExpertProfileSettingsFieldTitle">
                            Meeting Details
                          </div>
                          <select
                            className="ExpertProfileSettingsFieldInput"
                            required
                            value={expertCalendarLocationChoice}
                            onChange={(e) =>
                              setExpertCalendarLocationChoice(
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value="EXPERT_CHOICE">
                              Use my defaults for all meetings
                            </option>
                            <option value="CLIENT_CHOICE">
                              Make the client choose meeting details
                            </option>
                          </select>
                        </div>
                        {expertCalendarLocationChoice === 'EXPERT_CHOICE' && (
                          <div className="ExpertProfileSettingsField ExpertProfileSettingsFieldFull">
                            <div className="ExpertProfileSettingsFieldTitle">
                              Default Meeting Details / Location
                            </div>
                            <input
                              className="ExpertProfileSettingsFieldInput"
                              type="text"
                              autoComplete="new-off"
                              spellCheck="true"
                              placeholder="Meeting Details / Location"
                              value={expertCalendarDefaultLocation}
                              onChange={(e) =>
                                setExpertCalendarDefaultLocation(
                                  e.currentTarget.value,
                                )
                              }
                            />
                          </div>
                        )}
                        {expertCalendarLocationChoice === 'EXPERT_CHOICE' && (
                          <div className="ExpertProfileSettingsField ExpertProfileSettingsFieldFull">
                            <div className="ExpertProfileSettingsFieldTitle">
                              Default Meeting Notes (optional)
                            </div>
                            <TextareaAutosize
                              type="text"
                              placeholder="Meeting Notes"
                              spellCheck="true"
                              className="ExpertProfileSettingsFieldTextarea"
                              value={expertCalendarDefaultDescription}
                              onChange={(e) => {
                                setExpertCalendarDefaultDescription(
                                  e.currentTarget.value,
                                );
                              }}
                            />
                          </div>
                        )}
                        <div
                          className={
                            'ExpertProfileSettingsAction ' +
                            (actionLoading
                              ? ' ExpertProfileSettingsActionLoading '
                              : '')
                          }
                          onClick={saveSchedulingLinkSettings}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                  )}
                  {expertDetails.scheduleTimeEnabled && (
                    <div className="ExpertProfileSection ExpertProfileSectionBelow">
                      <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                        Availability
                      </div>
                      <div className="ExpertProfileSectionAction">
                        {!!expertCalendarEditAvailability && (
                          <div
                            className="ExpertProfileSectionActionOther"
                            onClick={() =>
                              setExpertCalendarEditAvailability(false)
                            }
                          >
                            Cancel
                          </div>
                        )}
                        {expertCalendarEditAvailability ? (
                          <div
                            className="ExpertProfileSectionActionBtn"
                            onClick={saveCalendarAvailability}
                          >
                            Save
                          </div>
                        ) : (
                          <div
                            className="ExpertProfileSectionActionBtn"
                            onClick={startEditSchedule}
                          >
                            Edit
                          </div>
                        )}
                      </div>
                      {expertCalendarEditAvailability ? (
                        <CalendarSchedule
                          isLoading={expertCalendarLoadingAvailability}
                          scheduleDays={[
                            expertCalendarDaySunday,
                            expertCalendarDayMonday,
                            expertCalendarDayTuesday,
                            expertCalendarDayWednesday,
                            expertCalendarDayThursday,
                            expertCalendarDayFriday,
                            expertCalendarDaySaturday,
                          ]}
                          updateScheduleDays={[
                            setExpertCalendarDaySunday,
                            setExpertCalendarDayMonday,
                            setExpertCalendarDayTuesday,
                            setExpertCalendarDayWednesday,
                            setExpertCalendarDayThursday,
                            setExpertCalendarDayFriday,
                            setExpertCalendarDaySaturday,
                          ]}
                        />
                      ) : (
                        <div className="ExpertProfileCalendarAvailabilityWeek">
                          <div className="ExpertProfileCalendarAvailabilityDay">
                            <span>Monday: </span>
                            {formatAvailability(
                              expertDetails.scheduleTimeDayMonday,
                            )}
                          </div>
                          <div className="ExpertProfileCalendarAvailabilityDay">
                            <span>Tuesday: </span>
                            {formatAvailability(
                              expertDetails.scheduleTimeDayTuesday,
                            )}
                          </div>
                          <div className="ExpertProfileCalendarAvailabilityDay">
                            <span>Wednesday: </span>
                            {formatAvailability(
                              expertDetails.scheduleTimeDayWednesday,
                            )}
                          </div>
                          <div className="ExpertProfileCalendarAvailabilityDay">
                            <span>Thursday: </span>
                            {formatAvailability(
                              expertDetails.scheduleTimeDayThursday,
                            )}
                          </div>
                          <div className="ExpertProfileCalendarAvailabilityDay">
                            <span>Friday: </span>
                            {formatAvailability(
                              expertDetails.scheduleTimeDayFriday,
                            )}
                          </div>
                          <div className="ExpertProfileCalendarAvailabilityDay">
                            <span>Saturday: </span>
                            {formatAvailability(
                              expertDetails.scheduleTimeDaySaturday,
                            )}
                          </div>
                          <div className="ExpertProfileCalendarAvailabilityDay">
                            <span>Sunday: </span>
                            {formatAvailability(
                              expertDetails.scheduleTimeDaySunday,
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          )}
          {currentTab === 'services' && expertDetails && (
            <div className="ExpertProfilePanel">
              <div className="ExpertProfileSection">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Your Services
                </div>
                <div className="ExpertProfileSectionAction">
                  <div
                    className="ExpertProfileSectionActionBtn"
                    onClick={() => openPrefabEditor('new')}
                  >
                    Create
                  </div>
                </div>
                {!expertPrefabs.length && (
                  <div className="ExpertProfileInfo">
                    <div className="ExpertProfileInfoTitle">
                      No services found
                    </div>
                    {errorPrefabs ? (
                      <div className="ExpertProfileInfoDesc">
                        {errorUtils.getErrorMessage(errorPrefabs)}
                      </div>
                    ) : (
                      <div className="ExpertProfileInfoDesc">
                        You don&apos;t need these necessarily, but some experts
                        like to create their own pre-packaged services that they
                        can share with potential clients. You&apos;ll get a
                        unique, public link for each service you create.
                      </div>
                    )}
                  </div>
                )}
                <div className="ExpertProfileServices">
                  {expertPrefabs.map((p) => (
                    <div key={p.id} className="ExpertProfileService">
                      <div
                        className="ExpertProfileServiceCard"
                        onClick={() => openPrefabEditor(p.id)}
                      >
                        <div className="ExpertProfileServiceTitle">
                          {p.title}
                        </div>
                        {p.estimatedCompletion && (
                          <div className="ExpertProfileServiceDays">
                            {p.estimatedCompletion} day
                            {p.estimatedCompletion > 1 ? 's' : ''}
                            <span> to complete</span>
                          </div>
                        )}
                        {p.cents && (
                          <div className="ExpertProfileServicePrice">
                            {p.priceType === 'STARTING_AT' && (
                              <div className="ExpertProfileServicePriceTop">
                                starting at
                              </div>
                            )}
                            <div className="ExpertProfileServicePriceBottom">
                              $
                              {formatNumberWithCommas(
                                centsDollarsRounded(p.cents),
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="ExpertProfileServiceFooter">
                        <div
                          className="ExpertProfileServiceFooterLeft"
                          onClick={() => openPrefabEditor(p.id)}
                        >
                          Edit
                        </div>
                        <div
                          className="ExpertProfileServiceFooterLeftNext"
                          onClick={() => deletePrefab(p.id)}
                        >
                          Delete
                        </div>
                        <div
                          className="ExpertProfileServiceFooterRight"
                          onClick={() => {
                            copyToClipboard(
                              `https://www.storetasker.com/experts/${expertDetails.profilePath}?p=${p.id}`,
                            ).catch(() => {});
                          }}
                        >
                          Copy Link
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {!!editPrefab && (
                  <div className="ExpertProfileModal">
                    <div
                      className="ExpertProfileModalCover"
                      onClick={closePrefabEditor}
                    />
                    <div className="ExpertProfileModalContainer">
                      <div className="ExpertProfileModalContainerBody ExpertProfileModalContainerBodyEditPrefab">
                        <div className="ExpertProfileModalContainerTitle">
                          {editPrefab === 'new' ? 'Create' : 'Edit'} Service
                        </div>
                        {editPrefab !== 'new' && (
                          <a
                            className="ExpertProfileModalContainerLink"
                            href={`https://www.storetasker.com/experts/${expertDetails.profilePath}?p=${editPrefab}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {`https://www.storetasker.com/experts/${expertDetails.profilePath}?p=${editPrefab}`}
                          </a>
                        )}
                        <div className="ExpertProfileModalForm">
                          <div className="ExpertProfileModalFormField">
                            <div className="ExpertProfileModalFormFieldTitle">
                              Title
                            </div>
                            <div className="ExpertProfileModalFormFieldInput">
                              <input
                                type="text"
                                autoComplete="new-off"
                                spellCheck="true"
                                placeholder="Title"
                                value={prefabTitle}
                                onChange={(e) =>
                                  setPrefabTitle(e.currentTarget.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="ExpertProfileModalFormField">
                            <div className="ExpertProfileModalFormFieldTitle">
                              Description
                            </div>
                            <div className="ExpertProfileModalFormFieldRich">
                              <RichTextEditor
                                initContent={prefabInitDesc}
                                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                                onUpdated={setPrefabDescription}
                              />
                            </div>
                          </div>
                          <div className="ExpertProfileModalFormOptions">
                            <div className="ExpertProfileModalFormOption ExpertProfileModalFormOptionPrice">
                              <div className="ExpertProfileModalFormOptionTitle">
                                <div className="ExpertProfileModalFormOptionTitleOption ExpertProfileModalFormOptionTitleOptionActive">
                                  {prefabPriceType === 'STARTING_AT'
                                    ? 'Starting At'
                                    : 'Price'}
                                </div>
                                <div
                                  className="ExpertProfileModalFormOptionTitleOption ExpertProfileModalFormOptionTitleOptionOther"
                                  onClick={() =>
                                    setPrefabPriceType(
                                      prefabPriceType === 'STARTING_AT'
                                        ? 'EXACTLY'
                                        : 'STARTING_AT',
                                    )
                                  }
                                >
                                  {prefabPriceType === 'STARTING_AT'
                                    ? 'Price'
                                    : 'Starting At'}
                                </div>
                              </div>
                              <div className="ExpertProfileModalFormOptionField">
                                <div className="ExpertProfileModalFormOptionFieldGrow">
                                  <div className="ExpertProfileModalFormOptionFieldGrowHidden">
                                    {prefabPriceValue}
                                  </div>
                                  <input
                                    type="text"
                                    className={
                                      'ExpertProfileModalFormOptionFieldGrowInput ' +
                                      (priceVal && priceVal > 0
                                        ? ''
                                        : ' ExpertProfileModalFormOptionFieldGrowInputInvalid ')
                                    }
                                    autoComplete="new-off"
                                    spellCheck="false"
                                    placeholder="$250"
                                    value={prefabPriceValue}
                                    onChange={(e) =>
                                      setPrefabPriceValue(
                                        '$' +
                                          (e.currentTarget.value || '').replace(
                                            /\$/gi,
                                            '',
                                          ),
                                      )
                                    }
                                    onBlur={(e) =>
                                      setPrefabPriceValue(
                                        '$' +
                                          (
                                            Math.max(
                                              0,
                                              Math.round(
                                                parseFloat(
                                                  (
                                                    e.currentTarget.value || ''
                                                  ).replace(/\$/gi, ''),
                                                ) || 0,
                                              ),
                                            ) || ''
                                          ).toString(),
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="ExpertProfileModalFormOption ExpertProfileModalFormOptionDays">
                              <div className="ExpertProfileModalFormOptionTitle">
                                <div className="ExpertProfileModalFormOptionTitleOption ExpertProfileModalFormOptionTitleOptionActive">
                                  Timeline
                                </div>
                              </div>
                              <div className="ExpertProfileModalFormOptionField">
                                <div className="ExpertProfileModalFormOptionFieldGrow">
                                  <div className="ExpertProfileModalFormOptionFieldGrowHidden">
                                    {prefabDays}
                                  </div>
                                  <input
                                    type="text"
                                    className={
                                      'ExpertProfileModalFormOptionFieldGrowInput ' +
                                      (timelineVal && timelineVal > 0
                                        ? ''
                                        : ' ExpertProfileModalFormOptionFieldGrowInputInvalid ')
                                    }
                                    autoComplete="new-off"
                                    spellCheck="false"
                                    placeholder="3"
                                    value={prefabDays}
                                    onChange={(e) =>
                                      setPrefabDays(
                                        (e.currentTarget.value || '').replace(
                                          /[^\d]/gi,
                                          '',
                                        ),
                                      )
                                    }
                                    onBlur={(e) =>
                                      setPrefabDays(
                                        (
                                          Math.max(
                                            0,
                                            Math.round(
                                              parseFloat(
                                                e.currentTarget.value || '',
                                              ) || 0,
                                            ),
                                          ) || ''
                                        ).toString(),
                                      )
                                    }
                                  />
                                </div>
                                <span> day{prefabDays !== '1' ? 's' : ''}</span>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              'ExpertProfileModalFormAction ' +
                              (actionLoading
                                ? ' ExpertProfileModalFormActionLoading '
                                : '')
                            }
                            onClick={prefabSubmit}
                          >
                            Save
                          </div>
                        </div>
                      </div>
                      <div
                        className="ExpertProfileModalClose"
                        onClick={closePrefabEditor}
                      >
                        cancel
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {currentTab === 'payments' && expertDetails && (
            <div className="ExpertProfilePanel">
              <div className="ExpertProfileSection">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Payments
                </div>
                <div className="ExpertProfileSectionAction">
                  <a
                    className="ExpertProfileSectionActionLink"
                    href={`https://${envUtils.pick(
                      'storetasker.portal.paymentrails.com',
                      'storetaskerdev.portal.paymentrails.com',
                      'storetaskerdev.portal.paymentrails.com',
                    )}?email=${
                      (expertDetails && expertDetails.primaryPersonalEmail) ||
                      ''
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Settings
                  </a>
                </div>
                <div className="ExpertProfileBalances">
                  {!loadingAccountDetails && (
                    <div className="ExpertProfileBalance">
                      <div className="ExpertProfileBalanceNumber">
                        {accountDetails
                          ? '$' +
                            formatNumberWithCommas(
                              centsDollarsRounded(accountDetails.balance || 0),
                            )
                          : '$'}
                      </div>
                      <div className="ExpertProfileBalanceTitle">
                        Available Balance
                      </div>
                      <div className="ExpertProfileBalanceDescription">
                        {errorAccountDetails
                          ? 'Error: ' +
                            (errorUtils.getErrorMessage(errorAccountDetails) ||
                              'Account Error')
                          : balanceInfoStr}
                      </div>
                      {!!isCashoutAllowed && (
                        <div
                          className="ExpertProfileBalanceBtn"
                          onClick={() => setShowCashoutModal(true)}
                        >
                          Cash out
                        </div>
                      )}
                    </div>
                  )}
                  <div className="ExpertProfileBalance">
                    <div className="ExpertProfileBalanceNumber">
                      {expertDetails.kpis && expertDetails.kpis.updatedAt
                        ? kpiDollars(expertDetails.kpis.gmvTotal || 0)
                        : '$0'}
                    </div>
                    <div className="ExpertProfileBalanceTitle">Total Sales</div>
                    {levelStr && (
                      <div className="ExpertProfileBalanceDescription">
                        {levelStr.info}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="ExpertProfileSection ExpertProfileSectionBelow">
                <div className="ExpertProfileSectionTitle">Transactions</div>
                {!allAccountBalanceEvents.length && (
                  <div className="ExpertProfileInfo">
                    <div className="ExpertProfileInfoTitle">
                      No transactions yet
                    </div>
                    <div className="ExpertProfileInfoDesc">
                      {errorAccountBalanceEventPaginated
                        ? errorUtils.getErrorMessage(
                            errorAccountBalanceEventPaginated,
                          )
                        : 'Complete your first job to get paid!'}
                    </div>
                  </div>
                )}
                <div className="ExpertProfileTransactions">
                  {allAccountBalanceEvents.map((abe) => {
                    let titleStr = '';
                    let subStr = '';
                    if (abe.brand && abe.project && abe.project.quote) {
                      titleStr = abe.brand.name || 'Project';
                      subStr = abe.project.quote.title || abe.reason || '';
                    } else if (abe.paymentRailsPaymentID) {
                      titleStr = 'Cashed Out';
                      subStr =
                        abe.paymentRailsPaymentStatus || abe.reason || '';
                    } else if (abe.action === 'credit') {
                      titleStr = 'Credit Added';
                      subStr = abe.reason || '';
                    } else if (abe.action === 'debit') {
                      titleStr = 'Credit Removed';
                      subStr = abe.reason || '';
                    }
                    return (
                      <div
                        key={abe.id}
                        className={
                          'ExpertProfileTransaction ' +
                          (abe.action === 'credit'
                            ? ' ExpertProfileTransactionCredit '
                            : ' ExpertProfileTransactionDebit ')
                        }
                      >
                        <div className="ExpertProfileTransactionTitle">
                          {titleStr}
                        </div>
                        <div className="ExpertProfileTransactionSub">
                          {subStr}
                        </div>
                        <div className="ExpertProfileTransactionAmount">
                          $
                          {formatNumberWithCommas(
                            centsDollarsDecimal(abe.amount || 0),
                          )}
                        </div>
                        <div className="ExpertProfileTransactionDate">
                          {moment(abe.createdAt).format('MMMM Do, YYYY')}
                        </div>
                      </div>
                    );
                  })}
                  {allAccountBalanceEvents.length >= PAGE_LIMIT &&
                    showLoadMore && (
                      <div
                        className="ExpertProfileTransactionsLoader"
                        onClick={loadMoreBalanceEvents}
                      >
                        load more
                      </div>
                    )}
                </div>
              </div>
              {!!showCashoutModal && !!isCashoutAllowed && accountDetails && (
                <div className="ExpertProfileModal">
                  <div
                    className="ExpertProfileModalCover"
                    onClick={() => !actionLoading && setShowCashoutModal(false)}
                  />
                  <div className="ExpertProfileModalContainer">
                    <div className="ExpertProfileModalContainerBody ExpertProfileModalContainerBodyCashout">
                      <div className="ExpertProfileModalContainerTitle">
                        Ready to cash out?
                      </div>
                      <div className="ExpertProfileModalCashout">
                        <div className="ExpertProfileModalCashoutTitle">
                          $
                          {formatNumberWithCommas(
                            centsDollarsDecimal(accountDetails.balance || 0),
                          )}
                        </div>
                        {accountDetails.balance > 900000 && (
                          <div className="ExpertProfileModalCashoutWarn">
                            We only do $9k at time. You can cash out the rest
                            after that.
                          </div>
                        )}
                        <div
                          className={
                            'ExpertProfileModalCashoutAction ' +
                            (actionLoading
                              ? ' ExpertProfileModalCashoutActionLoading '
                              : '')
                          }
                          onClick={startCashout}
                        >
                          Cash out
                        </div>
                        <div className="ExpertProfileModalCashoutSub">
                          {accountDetails.deliveryMethod === 'paypal'
                            ? 'Funds will hit your PayPal account immediately.'
                            : 'Funds will direct deposit to your bank account in 24-48 hours.'}{' '}
                          {accountDetails.estimatedFees
                            ? `A cashout fee of ${accountDetails.estimatedFees} will be applied.`
                            : ''}
                        </div>
                        <a
                          className="ExpertProfileModalCashoutLink"
                          href={`https://${envUtils.pick(
                            'storetasker.portal.paymentrails.com',
                            'storetaskerdev.portal.paymentrails.com',
                            'storetaskerdev.portal.paymentrails.com',
                          )}?email=${
                            (expertDetails &&
                              expertDetails.primaryPersonalEmail) ||
                            ''
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          update payment settings
                        </a>
                      </div>
                    </div>
                    {!actionLoading && (
                      <div
                        className="ExpertProfileModalClose"
                        onClick={() => setShowCashoutModal(false)}
                      >
                        cancel
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {currentTab === 'reviews' && expertDetails && (
            <div className="ExpertProfilePanel">
              <div className="ExpertProfileSection">
                <div className="ExpertProfileSectionTitle">Reviews</div>
                {!allProjectFeedbacks.length && (
                  <div className="ExpertProfileInfo">
                    <div className="ExpertProfileInfoTitle">No reviews yet</div>
                    <div className="ExpertProfileInfoDesc">
                      {errorFeedbacks
                        ? errorUtils.getErrorMessage(errorFeedbacks)
                        : 'Clients can leave a review after they approve a completed project.'}
                    </div>
                  </div>
                )}
                <div className="ExpertProfileReviews">
                  {allProjectFeedbacks.map((p) => {
                    const starCount = getNumericValue(p.feedbackValue || '');
                    let titleStr = '';
                    let subStr = '';
                    if (p.brand && p.quote) {
                      titleStr = p.quote.title || '';
                      subStr =
                        p.brand.name ||
                        p.brand.url ||
                        p.brand.shopifyAdminURL ||
                        '';
                    }
                    return (
                      <div key={p.id} className="ExpertProfileReview">
                        <Link
                          to={'/clients/' + p.matchStr}
                          className="ExpertProfileReviewHeader"
                        >
                          <div className="ExpertProfileReviewTitle">
                            {titleStr || 'Project'}
                          </div>
                          <div className="ExpertProfileReviewSub">
                            {subStr || 'Brand'}
                          </div>
                          {!!starCount && (
                            <div className="ExpertProfileReviewStars">
                              <div
                                className={
                                  'ExpertProfileReviewStar ' +
                                  (starCount >= 1
                                    ? ' ExpertProfileReviewStarActive '
                                    : '')
                                }
                              />
                              <div
                                className={
                                  'ExpertProfileReviewStar ' +
                                  (starCount >= 2
                                    ? ' ExpertProfileReviewStarActive '
                                    : '')
                                }
                              />
                              <div
                                className={
                                  'ExpertProfileReviewStar ' +
                                  (starCount >= 3
                                    ? ' ExpertProfileReviewStarActive '
                                    : '')
                                }
                              />
                              <div
                                className={
                                  'ExpertProfileReviewStar ' +
                                  (starCount >= 4
                                    ? ' ExpertProfileReviewStarActive '
                                    : '')
                                }
                              />
                              <div
                                className={
                                  'ExpertProfileReviewStar ' +
                                  (starCount >= 5
                                    ? ' ExpertProfileReviewStarActive '
                                    : '')
                                }
                              />
                            </div>
                          )}
                        </Link>
                        <div className="ExpertProfileReviewBody">
                          {p.feedbackDetail || 'No review text'}
                        </div>
                      </div>
                    );
                  })}
                  {allProjectFeedbacks.length >= PAGE_LIMIT &&
                    showLoadMoreFeedbacks && (
                      <div
                        className="ExpertProfileReviewsLoader"
                        onClick={loadMoreFeedbacks}
                      >
                        load more
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
          {currentTab === 'skills' && expertDetails && (
            <div className="ExpertProfilePanel">
              <div className="ExpertProfileInfo ExpertProfileInfoFull">
                <div className="ExpertProfileInfoTitle">Suggested Projects</div>
                <div className="ExpertProfileInfoDesc">
                  {!expertDetails.isOnlyAdminTag ? (
                    <Fragment>
                      Setting your preferences will not limit which leads you
                      see, but helps us suggest new clients and projects we
                      think you&apos;ll like.{' '}
                      <a
                        href="https://kb.storetasker.com/Tags-and-Notifications-de4e35fb954b47c5a3ab3ced8a0626fa"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn more
                      </a>
                    </Fragment>
                  ) : (
                    'Your account is set to see only relevant leads that match your skills. If you have questions, please contact the Support Team.'
                  )}
                </div>
              </div>
              <div className="ExpertProfileSection ExpertProfileSectionExtraBottom">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Skills Expertise
                  {!expertDetails.isOnlyAdminTag && <span> (max 5)</span>}
                </div>
                {!expertDetails.isOnlyAdminTag && (
                  <div className="ExpertProfileSectionAction">
                    <div
                      className={
                        'ExpertProfileSectionActionBtn ' +
                        (actionLoading
                          ? ' ExpertProfileSectionActionBtnLoading '
                          : '')
                      }
                      onClick={saveTags}
                    >
                      Save
                    </div>
                  </div>
                )}
                <div className="ExpertProfileTags">
                  <div className="ExpertProfileTagsTable">
                    {tagSkills.map((tag) => (
                      <div key={tag} className="ExpertProfileTag">
                        <div className="ExpertProfileTagName">
                          {tagLabelSkill(tag)}
                        </div>
                        <div className="ExpertProfileTagType">
                          <select
                            className="ExpertProfileTagTypeSelect"
                            value={notificationType(tag)}
                            onChange={(e) =>
                              switchTagNotificationType(
                                tag,
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value="dash">Dashboard</option>
                            <option value="slack">Slack</option>
                            <option value="email">Email</option>
                            <option value="text">Text</option>
                          </select>
                        </div>
                        {!expertDetails.isOnlyAdminTag && (
                          <div
                            className="ExpertProfileTagRemove"
                            onClick={() =>
                              setTagSkills(tagSkills.filter((v) => v !== tag))
                            }
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  {!expertDetails.isOnlyAdminTag && tagSkills.length < 5 && (
                    <MultiSelect
                      options={SKILL_OPTIONS}
                      currValues={tagSkills}
                      onChange={setTagSkills}
                      max={5}
                    />
                  )}
                </div>
              </div>
              <div className="ExpertProfileSection ExpertProfileSectionExtraBottom">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Tools Expertise
                  {!expertDetails.isOnlyAdminTag && <span> (max 8)</span>}
                </div>
                {!expertDetails.isOnlyAdminTag && (
                  <div className="ExpertProfileSectionAction">
                    <div
                      className={
                        'ExpertProfileSectionActionBtn ' +
                        (actionLoading
                          ? ' ExpertProfileSectionActionBtnLoading '
                          : '')
                      }
                      onClick={saveTags}
                    >
                      Save
                    </div>
                  </div>
                )}
                <div className="ExpertProfileTags">
                  <div className="ExpertProfileTagsTable">
                    {tagTools.map((tag) => (
                      <div key={tag} className="ExpertProfileTag">
                        <div className="ExpertProfileTagName">
                          {tagLabelTool(tag) || 'Missing, please delete'}
                        </div>
                        <div className="ExpertProfileTagType">
                          <select
                            className="ExpertProfileTagTypeSelect"
                            value={notificationType(tag)}
                            onChange={(e) =>
                              switchTagNotificationType(
                                tag,
                                e.currentTarget.value,
                              )
                            }
                          >
                            <option value="dash">Dashboard</option>
                            <option value="slack">Slack</option>
                            <option value="email">Email</option>
                            <option value="text">Text</option>
                          </select>
                        </div>
                        {!expertDetails.isOnlyAdminTag && (
                          <div
                            className="ExpertProfileTagRemove"
                            onClick={() =>
                              setTagTools(tagTools.filter((v) => v !== tag))
                            }
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  {!expertDetails.isOnlyAdminTag && tagTools.length < 8 && (
                    <MultiSelect
                      options={TOOL_OPTIONS}
                      currValues={tagTools}
                      onChange={setTagTools}
                      max={8}
                    />
                  )}
                </div>
              </div>
              <div className="ExpertProfileSection ExpertProfileSectionExtraBottom">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Business Problem Expertise
                  {!expertDetails.isOnlyAdminTag && <span> (max 3)</span>}
                </div>
                {!expertDetails.isOnlyAdminTag && (
                  <div className="ExpertProfileSectionAction">
                    <div
                      className={
                        'ExpertProfileSectionActionBtn ' +
                        (actionLoading
                          ? ' ExpertProfileSectionActionBtnLoading '
                          : '')
                      }
                      onClick={saveTags}
                    >
                      Save
                    </div>
                  </div>
                )}
                <div className="ExpertProfileTags">
                  <div className="ExpertProfileTagsTable">
                    {tagProblems.map((tag) => (
                      <div key={tag} className="ExpertProfileTag">
                        <div className="ExpertProfileTagName">
                          {tagLabelProblem(tag)}
                        </div>
                        {!expertDetails.isOnlyAdminTag && (
                          <div
                            className="ExpertProfileTagRemove"
                            onClick={() =>
                              setTagProblems(
                                tagProblems.filter((v) => v !== tag),
                              )
                            }
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  {!expertDetails.isOnlyAdminTag && tagProblems.length < 5 && (
                    <MultiSelect
                      options={PROBLEM_OPTIONS}
                      currValues={tagProblems}
                      onChange={setTagProblems}
                      max={5}
                    />
                  )}
                </div>
              </div>
              <div className="ExpertProfileSection ExpertProfileSectionBelow">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Past &amp; Current Clients
                  {!expertDetails.isOnlyAdminTag && <span> (max 10)</span>}
                </div>
                {!expertDetails.isOnlyAdminTag && (
                  <div className="ExpertProfileSectionAction">
                    <div
                      className={
                        'ExpertProfileSectionActionBtn ' +
                        (actionClientLoading
                          ? ' ExpertProfileSectionActionBtnLoading '
                          : '')
                      }
                      onClick={savePastClients}
                    >
                      Save
                    </div>
                  </div>
                )}
                {!pastClients.length && (
                  <div className="ExpertProfileInfo">
                    <div className="ExpertProfileInfoTitle">
                      No clients found
                    </div>
                    <div className="ExpertProfileInfoDesc">
                      Not required, but it will help us make more relevant
                      introductions for you with future clients.
                    </div>
                  </div>
                )}
                <div className="ExpertProfileServices">
                  {pastClients.map((p, i) => (
                    <div key={p.url} className="ExpertProfileService">
                      <div
                        className="ExpertProfileServiceCard"
                        onClick={() => openClientEditor(i)}
                      >
                        <div className="ExpertProfileServiceTitle ExpertProfileServiceTitleSmall">
                          {p.name}
                        </div>
                        <div className="ExpertProfileServiceSubTitle">
                          {p.url}
                        </div>
                      </div>
                      <div className="ExpertProfileServiceFooter">
                        <div
                          className="ExpertProfileServiceFooterLeft"
                          onClick={() => openClientEditor(i)}
                        >
                          Edit
                        </div>
                        <div
                          className="ExpertProfileServiceFooterLeftNext"
                          onClick={() => deleteClient(i)}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  ))}
                  {!expertDetails.isOnlyAdminTag && pastClients.length < 10 && (
                    <div className="ExpertProfileServicesAdd">
                      <div
                        className="ExpertProfileServicesAddBtn"
                        onClick={addClient}
                      >
                        add a client
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {editPastClient !== -1 && (
                <div className="ExpertProfileModal">
                  <div
                    className="ExpertProfileModalCover"
                    onClick={closeClientEditor}
                  />
                  <div className="ExpertProfileModalContainer">
                    <div className="ExpertProfileModalContainerBody ExpertProfileModalContainerBodyEditTemplate">
                      <div className="ExpertProfileModalContainerTitle">
                        Client Details
                      </div>
                      <div className="ExpertProfileModalForm">
                        <div className="ExpertProfileModalFormField">
                          <div className="ExpertProfileModalFormFieldTitle">
                            Client Name *
                          </div>
                          <div className="ExpertProfileModalFormFieldInput">
                            <input
                              type="text"
                              autoComplete="new-off"
                              spellCheck="true"
                              placeholder="Nike"
                              value={pastClients[editPastClient].name}
                              onChange={(e) => {
                                const updated = e.currentTarget.value;
                                setPastClients((prev) =>
                                  prev
                                    .slice(0, editPastClient)
                                    .concat([
                                      {
                                        ...prev[editPastClient],
                                        name: updated,
                                      },
                                    ])
                                    .concat(prev.slice(editPastClient + 1)),
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ExpertProfileModalFormField">
                          <div className="ExpertProfileModalFormFieldTitle">
                            Client Url *
                          </div>
                          <div className="ExpertProfileModalFormFieldInput">
                            <input
                              type="text"
                              autoComplete="new-off"
                              spellCheck="true"
                              placeholder="www.nike.com"
                              value={pastClients[editPastClient].url}
                              onChange={(e) => {
                                const updated = e.currentTarget.value;
                                setPastClients((prev) =>
                                  prev
                                    .slice(0, editPastClient)
                                    .concat([
                                      {
                                        ...prev[editPastClient],
                                        url: updated,
                                      },
                                    ])
                                    .concat(prev.slice(editPastClient + 1)),
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ExpertProfileModalFormField">
                          <div className="ExpertProfileModalFormFieldTitle">
                            Client Industry *
                          </div>
                          <div className="ExpertProfileModalFormFieldInput">
                            <select
                              className="ThreadDetailSidebarAdminFormInput"
                              required
                              value={pastClients[editPastClient].industryTag}
                              onChange={(e) => {
                                const updated = e.currentTarget.value;
                                setPastClients((prev) =>
                                  prev
                                    .slice(0, editPastClient)
                                    .concat([
                                      {
                                        ...prev[editPastClient],
                                        industryTag: updated,
                                      },
                                    ])
                                    .concat(prev.slice(editPastClient + 1)),
                                );
                              }}
                            >
                              <option value="">Select Industry</option>
                              {INDUSTRY_OPTIONS.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="ExpertProfileModalFormField">
                          <div className="ExpertProfileModalFormFieldTitle">
                            What did you do for them? *
                          </div>
                          <div className="ExpertProfileModalFormFieldTagger">
                            <MultiSelect
                              options={SKILL_OPTIONS}
                              currValues={pastClients[editPastClient].skills}
                              withTags
                              onChange={(vals) => {
                                setPastClients((prev) =>
                                  prev
                                    .slice(0, editPastClient)
                                    .concat([
                                      {
                                        ...prev[editPastClient],
                                        skills: vals,
                                      },
                                    ])
                                    .concat(prev.slice(editPastClient + 1)),
                                );
                              }}
                              max={3}
                            />
                          </div>
                        </div>
                        <div className="ExpertProfileModalFormField">
                          <div className="ExpertProfileModalFormFieldTitle">
                            Tell us more about your collaboration
                          </div>
                          <div
                            className="ExpertProfileModalFormFieldHidden"
                            data-tip="Not shown to potential clients. This info is used to improve our matching and introductions"
                          >
                            hidden
                          </div>
                          <div className="ExpertProfileModalFormFieldInput">
                            <TextareaAutosize
                              type="text"
                              spellCheck="true"
                              className="ExpertProfileSettingsFieldTextarea"
                              placeholder="I worked closely with their CMO and CTO to revamp the current site architecture..."
                              value={pastClients[editPastClient].details || ''}
                              onChange={(e) => {
                                const updated = e.currentTarget.value;
                                setPastClients((prev) =>
                                  prev
                                    .slice(0, editPastClient)
                                    .concat([
                                      {
                                        ...prev[editPastClient],
                                        details: updated,
                                      },
                                    ])
                                    .concat(prev.slice(editPastClient + 1)),
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="ExpertProfileModalFormField">
                          <div className="ExpertProfileModalFormFieldTitle">
                            Who collaborated with you?
                          </div>
                          <div
                            className="ExpertProfileModalFormFieldHidden"
                            data-tip="Not shown to potential clients. This info is used to improve our matching and introductions"
                          >
                            hidden
                          </div>
                          <div className="ExpertProfileModalFormFieldInput">
                            <TextareaAutosize
                              type="text"
                              spellCheck="true"
                              className="ExpertProfileSettingsFieldTextarea"
                              placeholder={
                                'Jony Ive - Design\nAda Lovelace - Backend Development'
                              }
                              value={
                                pastClients[editPastClient].collaborators || ''
                              }
                              onChange={(e) => {
                                const updated = e.currentTarget.value;
                                setPastClients((prev) =>
                                  prev
                                    .slice(0, editPastClient)
                                    .concat([
                                      {
                                        ...prev[editPastClient],
                                        collaborators: updated,
                                      },
                                    ])
                                    .concat(prev.slice(editPastClient + 1)),
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="ExpertProfileModalClose"
                      onClick={closeClientEditor}
                    >
                      done
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {currentTab === 'templates' && expertDetails && (
            <div className="ExpertProfilePanel">
              <div className="ExpertProfileSection">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Quote Templates
                </div>
                <div className="ExpertProfileSectionAction">
                  <div
                    className="ExpertProfileSectionActionBtn"
                    onClick={() => openTemplateEditor('new', true)}
                  >
                    Create Quote
                  </div>
                </div>
                {!quoteTemplates.length && (
                  <div className="ExpertProfileInfo">
                    <div className="ExpertProfileInfoTitle">
                      No quote templates found
                    </div>
                    <div className="ExpertProfileInfoDesc">
                      You don&apos;t need these necessarily, but some experts
                      like to create quote templates to save them time later.
                    </div>
                  </div>
                )}
                <div className="ExpertProfileServices">
                  {quoteTemplates.map((p) => (
                    <div key={p.id} className="ExpertProfileService">
                      <div
                        className="ExpertProfileServiceCard"
                        onClick={() => openTemplateEditor(p.id, true)}
                      >
                        <div className="ExpertProfileServiceTitle">
                          {p.title}
                        </div>
                      </div>
                      <div className="ExpertProfileServiceFooter">
                        <div
                          className="ExpertProfileServiceFooterLeft"
                          onClick={() => openTemplateEditor(p.id, true)}
                        >
                          Edit
                        </div>
                        <div
                          className="ExpertProfileServiceFooterLeftNext"
                          onClick={() => deleteTemplate(p.id, true)}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="ExpertProfileSection ExpertProfileSectionBelow">
                <div className="ExpertProfileSectionTitle ExpertProfileSectionTitleWithAction">
                  Email Templates
                </div>
                <div className="ExpertProfileSectionAction">
                  <div
                    className="ExpertProfileSectionActionBtn"
                    onClick={() => openTemplateEditor('new', false)}
                  >
                    Create Email
                  </div>
                </div>
                {!emailTemplates.length && (
                  <div className="ExpertProfileInfo">
                    <div className="ExpertProfileInfoTitle">
                      No email templates found
                    </div>
                    <div className="ExpertProfileInfoDesc">
                      You don&apos;t need these necessarily, but some experts
                      like to create email templates to save them time later.
                    </div>
                  </div>
                )}
                <div className="ExpertProfileServices">
                  {emailTemplates.map((p) => (
                    <div key={p.id} className="ExpertProfileService">
                      <div
                        className="ExpertProfileServiceCard"
                        onClick={() => openTemplateEditor(p.id, false)}
                      >
                        <div className="ExpertProfileServiceTitle">
                          {p.title}
                        </div>
                      </div>
                      <div className="ExpertProfileServiceFooter">
                        <div
                          className="ExpertProfileServiceFooterLeft"
                          onClick={() => openTemplateEditor(p.id, false)}
                        >
                          Edit
                        </div>
                        <div
                          className="ExpertProfileServiceFooterLeftNext"
                          onClick={() => deleteTemplate(p.id, false)}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {!!editTemplate && (
                <div className="ExpertProfileModal">
                  <div
                    className="ExpertProfileModalCover"
                    onClick={closeTemplateEditor}
                  />
                  <div className="ExpertProfileModalContainer">
                    <div className="ExpertProfileModalContainerBody ExpertProfileModalContainerBodyEditTemplate">
                      <div className="ExpertProfileModalContainerTitle">
                        {editTemplate === 'new' ? 'Create' : 'Edit'}{' '}
                        {templateIsQuote ? 'Quote' : 'Email'} Template
                      </div>
                      <div className="ExpertProfileModalForm">
                        <div className="ExpertProfileModalFormField">
                          <div className="ExpertProfileModalFormFieldTitle">
                            Title
                          </div>
                          <div className="ExpertProfileModalFormFieldInput">
                            <input
                              type="text"
                              autoComplete="new-off"
                              spellCheck="true"
                              placeholder="Title"
                              value={templateTitle}
                              onChange={(e) =>
                                setTemplateTitle(e.currentTarget.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="ExpertProfileModalFormField">
                          <div className="ExpertProfileModalFormFieldTitle">
                            Description
                          </div>
                          <div className="ExpertProfileModalFormFieldRich">
                            <RichTextEditor
                              initContent={templateInitDesc}
                              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer convallis congue sem, ac consectetur magna. In eget malesuada."
                              onUpdated={setTemplateDescription}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            'ExpertProfileModalFormAction ' +
                            (actionLoading
                              ? ' ExpertProfileModalFormActionLoading '
                              : '')
                          }
                          onClick={templateSubmit}
                        >
                          Save
                        </div>
                      </div>
                    </div>
                    <div
                      className="ExpertProfileModalClose"
                      onClick={closeTemplateEditor}
                    >
                      cancel
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!!fullErrorCover && (
        <div className="DashboardErrorCover">
          <div className="DashboardErrorCoverOver" />
          <div className="DashboardErrorCoverPop">
            <Link to="/experts" className="DashboardErrorCoverNav">
              back
            </Link>
            <div className="DashboardErrorCoverContent">{fullErrorCover}</div>
          </div>
        </div>
      )}
    </div>
  );
};

ExpertProfileView.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  expertId: PropTypes.string.isRequired,
  quoteTemplates: PropTypes.array.isRequired,
  refetchTemplates: PropTypes.func.isRequired,
};

export default ExpertProfileView;
